import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { select } from '../../../common';
import { HEADER_TYPE_MOBILE_BASE } from '../section/Header';

const SearchBar = (props) => {
  const CommonStore = useSelector((state) => state.CommonStore);
  const header = CommonStore.header;

  const q = new URLSearchParams(props.location.search).get('q');
  const [searchText, setSearchText] = useState(q || '');
  const [searchPlaceholder, setSearchPlaceHolder] = useState('어떤 성장을 원하고 있나요?');

  const onSearch = () => {
    if (searchText) {
      setSearchPlaceHolder('');
      props.history.push(`/search?q=${searchText}`);
    } else {
      setSearchPlaceHolder('어떤 성장을 원하고 있나요?');
    }
  };

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.code === 'NumpadEnter') {
      onSearch();
    }
  };

  return (
    <div className='search-bar-wrap'>
      <div className='search-bar'>
        <button onClick={onSearch} />
        <input
          type='text'
          name='search'
          value={searchText}
          onKeyDown={handleKeyDown}
          onChange={onChangeSearchText}
          placeholder={select(header.pc_header_type === HEADER_TYPE_MOBILE_BASE, searchPlaceholder)}
          autoComplete='off'
        />
      </div>
    </div>
  );
};

export default withRouter(SearchBar);
