import React, { useEffect, useRef, useState } from 'react';
import SelectBox from '../../_common/component/SelectBox';
import { init_comma } from '../../../common';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PurchaseModel from '../../../model/PurchaseModel';
import { set_tmp } from '../../../redux/common/action';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useDialogContext } from '../../_common/dialog/DialogContextProvider';

const propTypes = {
  setIsPurchase: PropTypes.func,
  kit_info_set: PropTypes.array,
  invoice_type: PropTypes.number,
};

const KitInvoice = (props) => {
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { kit_info_set } = props;
  const AccountStore = useSelector((state) => state.AccountStore);
  const dispatch = useDispatch();
  const [total_price, setTotalPrice] = useState(0);
  const [purchase_price, setPurchasePrice] = useState(null);
  const [kit_set, setKitSet] = useState([]);
  const [_is_load, setIsLoad] = useState(false);
  let select_kit_set = [];
  const _is_mount = useRef(false);
  const { confirm, alert } = useDialogContext();

  useEffect(() => {
    if (kit_info_set.length && kit_info_set[0].inventory_count) {
      setKitSet(kit_info_set[0] ? [kit_info_set[0]] : []);
      setIsLoad(true);
    }
  }, [kit_info_set]);

  useEffect(() => {
    _is_mount.current = true;

    return () => {
      _is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    let validate_kit_set = [];
    for (let i = 0; i < kit_set.length; i++) {
      let check = false;
      for (let j = 0; j < validate_kit_set.length; j++) {
        if (validate_kit_set[j].id === kit_set[i].id) {
          validate_kit_set[j].order_count++;
          check = true;
          break;
        }
      }

      if (!check) {
        validate_kit_set.push({ id: kit_set[i].id, order_count: 1 });
      }
    }
    if (_is_load) {
      PurchaseModel.priceValidate({
        currency_code: 'KRW',
        kit_set: validate_kit_set,
      }).then(async ({ code, total_price, purchase_price }) => {
        if (_is_mount.current) {
          if (code === 200) {
            setTotalPrice(total_price);
            setPurchasePrice(purchase_price);
          } else if (code === 4805) {
            await alert('최대 수량 이상 선택할 수 없습니다.');
          } else if (code === 4701) {
            await alert('품절된 상품입니다.');
            setKitSet((prevState) => {
              prevState.pop();
              return [...prevState];
            });
          } else {
            await alert('잘못된 요청입니다.');
          }
        }
      });
    }
  }, [kit_set, _is_load]);

  const onChange = (target_id) => {
    let select_kit = kit_info_set.find((kit) => kit.id === target_id.value);
    let select_count = 0;
    for (let i = 0; i < kit_set.length; i++) {
      if (kit_set[i].id === select_kit.id) {
        select_count++;
      }
    }
    if (select_count < select_kit.inventory_count) {
      if (select_count < select_kit.max_buy_count) {
        select_kit_set.push(select_kit);
        setKitSet((state) => [...state, ...select_kit_set]);
      }
    }
  };

  const onKitRemoveBtn = (target_id) => {
    kit_set.splice(
      kit_set.findIndex((kit) => kit.id === target_id),
      1,
    );
    setKitSet((state) => [...state, ...select_kit_set]);
  };

  const onPurchase = async () => {
    if (AccountStore) {
      if (!AccountStore.info?.name || !AccountStore.info?.email) {
        if (await confirm('결제를 위해 개인정보를 입력해주세요.')) {
          props.history.push({
            pathname: '/user/info',
            state: {
              redirect_url: `${props.location.pathname}${props.location.search}`,
            },
          });
        }
      } else {
        if (!kit_set.length) {
          await alert('최소 하나의 옵션상품을 선택 하셔야합니다.');
        } else {
          dispatch(
            set_tmp('purchase', {
              purchase_type: 'kit',
              title: `${kit_set[0]?.title}${kit_set.length > 1 ? '외 ' + (kit_set.length - 1) + '건' : ''}`,
              can_coupon: kit_set.find((kit) => kit.can_discount),
              purchase_item: {
                kit_id: kit_set.id,
                description: kit_set.description,
              },
              kit_set: kit_set,
              // kit 는 purchase.js 에서 orderd_kit_set 로 사용하고있어 별도로 보내지 않음
              // gtm_data: {
              //     pnm: kit_info_set[0]?.title.replace(/\r\n/g, " "),
              //     pid: `k${kit_info_set[0]?.id.toString().padStart(8, "0")}`,
              //     ppc: kit_info_set[0]?.price_info.map(price => price.our_sale_price),
              //     ppb: "",
              //     pca: "옵션상품",
              // },
              expire_date: new Date(),
            }),
          );
          props.history.push('/purchase');
        }
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  return (
    <div
      className='invoice-wrap gtm-detail gtm-data'
      data-ai-pnm={kit_info_set[0]?.title.replace(/\r\n/g, ' ')}
      data-ai-pid={`k${kit_info_set[0]?.id.toString().padStart(8, '0')}`}
      data-ai-ppc={kit_info_set[0]?.price_info.map((price) => price.our_sale_price)}
      data-ai-ppb=''
      data-ai-pca={'옵션상품'}
    >
      <div
        className='invoice-title-wrap'
        style={!is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
      >
        <div className='invoice-title'>
          {kit_info_set.length > 0 && (
            <>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/label_product.png`}
                alt='kit 상품'
              />
              <span>{kit_info_set[0]?.title}</span>
            </>
          )}
        </div>
        <img
          className={`kit-detail-id-${kit_info_set[0]?.id} ${kit_set[0]?.is_favorite ? '' : 'enp_mobon_wish'}`}
          src={
            kit_info_set[0]?.is_favorite
              ? `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detailpage-active-2.png`
              : `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detail-2.png`
          }
          onClick={props.onFavorite}
          alt='저장하기'
        />
      </div>
      <div
        className='mo-invoice-title-wrap'
        style={is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
      >
        <h3>구매하기</h3>
        <button
          onClick={() => {
            props.setIsPurchase(false);
          }}
        >
          닫기
        </button>
      </div>
      <div className='invoice-content-wrap'>
        <div className='option-select-wrap'>
          <div className='kit-option-select-wrap'>
            <h4>옵션 상품</h4>
            <SelectBox
              select_type={3}
              name='kit'
              select_set={kit_info_set}
              placeholder={kit_info_set[0]?.title}
              changeListener={onChange}
            />
          </div>
        </div>
        <div className='invoice-content'>
          <div className='purchase-list-wrap'>
            <h4>구매 목록</h4>
            <ul className='selected-list'>
              {kit_set &&
                kit_set.length > 0 &&
                kit_set?.map((kit, index) => {
                  return (
                    <li className='kit-list' key={index}>
                      <div className='kit-title'>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-delete.png`}
                          alt='삭제'
                          onClick={() => {
                            onKitRemoveBtn(kit.id);
                          }}
                        />
                        <h5>{kit.title}</h5>
                      </div>
                      <h6 className='kit-subtitle'>
                        {init_comma(kit.price_info.map((price) => price.our_sale_price))}원
                      </h6>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className='purchase-info'>
            <ul>
              <li>
                <h5>실 구매가</h5>
                <h6>{kit_info_set && init_comma(total_price)}원</h6>
              </li>
            </ul>
          </div>
          <div className='total-price'>
            <div className='total-price-wrap'>
              <h4>결제 금액</h4>
              {kit_info_set ? <h6>{init_comma(Math.floor(purchase_price))}원</h6> : <h6>월 0원</h6>}
            </div>
          </div>
        </div>
      </div>
      <div className='purchase-btn-wrap'>
        {kit_set?.length ? (
          <button className='td-btn' onClick={onPurchase}>
            구매하기
          </button>
        ) : null}
      </div>
    </div>
  );
};

KitInvoice.propTypes = propTypes;

export default withRouter(KitInvoice);
