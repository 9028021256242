import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

const CurationCard = (props) => {
    const curation = props.curtaion;
    const [img_set, setImgSet] = useState([]);
    const [tag_set, setTagSet] = useState([]);

    const is_tablet_or_mobile = useMediaQuery({query: "(max-width: 767px)"});

    useEffect(() => {
        let tmp_set = [];
        let tag_set = [];
        for (let i = 0; i < curation.product_set.length; i++) {
            if (curation.product_set[i].circle_image_url) {
                if (tmp_set.length < 6) {
                    tmp_set.push(curation.product_set[i].circle_image_url);
                }
            }
        }
        for (let i = 0; i < curation.course_set.length; i++) {
            if (curation.course_set[i].circle_image_url) {
                if (tmp_set.length < 6) {
                    tmp_set.push(curation.course_set[i].circle_image_url);
                }
            }
            for (let k = 0; k < curation.course_set[i].tag_set.length; k++) {
                tag_set.push(curation.course_set[i].tag_set[k]);
            }
        }
        if (tmp_set.length < 6) {
            for (let i = 0; i < 5 - tmp_set.length; i++) {
                tmp_set.push("");
            }
        }
        setImgSet(tmp_set);
        setTagSet(tag_set);
    }, []);
    return (
        <Link className="curation-card" to={`/curation/${curation.id}`}>
            <div className="curation-desc-wrapper">
                <div className="title-wrap">
                    <p className="title">{curation.title}</p>
                    <img src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/search/ic_arrow_r_b1.svg` } alt={"오른쪽 화살표"} />
                </div>
                <p className="description">{curation.description}</p>
                <p className="tag">
                    {tag_set.map((tag, index) => {
                        if (index < 2) {
                            return (
                                <span key={index}>#{tag}</span>
                            );
                        } else {
                            return null;
                        }
                    })}
                </p>
            </div>
            {!is_tablet_or_mobile &&
            <div className="curation-course-wrapper">
                {img_set.map((img_url, index) => {
                    return (
                        <div className="curation-course" key={index}>
                            {img_url ?
                                <>
                                    <div className="background">
                                        <div className="img"
                                             style={{background: `url(${img_url}) no-repeat center center/cover`}}>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="img empty-img"/>
                            }
                        </div>
                    );
                })}
            </div>}
        </Link>
    );
};

export default withRouter(CurationCard);
