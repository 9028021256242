import React, { useEffect } from 'react';
import '../../assets/scss/page/info_viewer.scss';
import { useDispatch } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const InfoViewer = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      set_header_type(0, 2, {
        title: '문제해결 가이드',
        no_footer: true,
        no_top_btn: true,
        no_back_btn: true,
      }),
    );
    dispatch(set_footer_type(0));
    props.ready();
  }, []);
  return (
    <div className='info-container'>
      <Helmet>
        <title>문제해결 가이드</title>
      </Helmet>
      <p className='info-title'>영상 재생관련 문제 해결 방법 안내</p>
      <div className='info-box'>
        <p className='box-sub-title'>안녕하세요 베어유입니다 🙂</p>
        <p className='box-title'>
          <span>영상 재생</span>에 문제가 있으신가요?
        </p>
      </div>
      <div className='info-wrapper'>
        <p className='info-hint'>아래 간단한 방법으로 문제를 해결해 보세요.</p>
        <p className='title'>
          <span>첫 번째!</span> [캐쉬 및 쿠키 초기화]
        </p>
        <p className='info'>
          1. 사용하고 계신 인터넷 브라우저 캐쉬 및 쿠키를 초기화 후 재부팅
          <br />
          2. 베어유 로그인 후 다시 재생
        </p>
        <p className='title'>
          <span>두 번째!</span> [영상 CDN 설정]
        </p>
        <p className='info'>
          1.{' '}
          <a href='https://player.vimeo.com/flags?p=general' rel='noreferrer' target='_blank'>
            https://player.vimeo.com/flags?p=general
          </a>{' '}
          에 접속
          <br />
          <img
            alt=''
            src={`${process.env.REACT_APP_IMG_URL}/assets/service/200318_viewer_problem_notice.jpg`}
          />
          2. <b>CDN Priority</b>에서 <b>Disabled</b> 로 설정되어 있는 부분을 클릭
          <br />
          3. <b>Disabled</b>를 <b>Akamai</b> 로 설정 후 창을 닫는다.
          <br />
          4. 베어유 홈페이지 재접속 후 영상 시청
          <br />
          5. 그래도 재생이 안 될 경우 3번 방법에서 <b>Fastly</b> 로 변경 후 재시청
          <br />
        </p>
        <p className='info-hint' style={{ marginTop: '50px' }}>
          * 사용하시는 브라우저에 따라 다를 수 있기 때문에 꼭 확인을 해주세요.
        </p>

        <p className='title'>
          <span>그래도 안되신다면?</span> [베어유 실험실 기능 사용]
        </p>
        <p className='info'>
          1. <Link to={'/service/info/lab'}>베어유 실험실</Link> 이동 후<br />
          2. V2b 베어유 플레이어 사용 체크
          <br />
          3. 강의실로 이동후 다시 새로고침하여 영상 시청
        </p>
      </div>
    </div>
  );
};

export default InfoViewer;
