export const RENTAL_FOREVER_IN_DAY = 365 * 1000;
export const DEFAULT_RENTAL_TIME_IN_MONTH = 12;
export const RENTAL_FOREVER_OVER_YEAR = 3000;

export const get_rental_month = (rental_time_in_day = 0) => {
  return Math.floor(rental_time_in_day / 30) || 0;
};

export const get_rental_month_period = (rental_time_in_day) => {
  if (rental_time_in_day >= RENTAL_FOREVER_IN_DAY) {
    return DEFAULT_RENTAL_TIME_IN_MONTH;
  } else {
    return get_rental_month(rental_time_in_day);
  }
};

export const get_rental_month_readable = (rental_time_in_day) => {
  if (rental_time_in_day >= RENTAL_FOREVER_IN_DAY) {
    return '평생소장';
  } else if (rental_time_in_day >= 30) {
    return `수강기간 ${get_rental_month_period(rental_time_in_day)}개월`;
  } else {
    if (rental_time_in_day) {
      return `수강기간 ${rental_time_in_day}일`;
    } else {
      return '개별 수강기간';
    }
  }
};

export const sort_kit_list = (kit_list) => {
  // inventory count 순 + 글자순
  kit_list.sort((a, b) => {
    if (a.inventory_count === b.inventory_count) {
      return a.title > b.title ? -1 : a.title < b.title ? 1 : 0;
    } else if (a.inventory_count > b.inventory_count) {
      return -1;
    } else {
      return 1;
    }
  });
};
