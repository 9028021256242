import React, {useState} from "react";
import TemplateInput from "../_template/TemplateInput";
import PropTypes from "prop-types";
import InputLabel from "./InputLabel";

const propTypes = {
    require: PropTypes.bool, // require 표시 여부
    default_value: PropTypes.string, // 회원 수정 시 받아올 default value
    auto_focus: PropTypes.string, // input autoFocus
    input_list_ref: PropTypes.array, // focus event ref
    on_data_changed: PropTypes.func, // text, validated
};

const NameInput = (props) => {
    const [name_error_message, setNameErrorMessage] = useState("");
    const [is_name_error, setIsNameError] = useState(false);
    const require = props?.require ?? true;

    // 이름 유효성 검사
    const checkNameValidate = (text) => {
        const regex_name = /^[가-힣a-zA-Z\s]+$/;
        return regex_name.test(text);
    };

    return (
        <div className="name-input-wrap">
            <InputLabel label={"이름"} require={require} />
            <TemplateInput
                is_error={is_name_error}
                error_message={name_error_message}
                on_validate={(text) => {
                    let validated = checkNameValidate(text);
                    if (text === "") {
                        setIsNameError(true);
                        setNameErrorMessage("필수항목입니다");
                    } else if (text === "베어유" || text === "관리자") {
                        setIsNameError(true);
                        setNameErrorMessage("사용할 수 없는 이름입니다");
                    } else if (validated) {
                        setIsNameError(false);
                        setNameErrorMessage("");
                    } else {
                        setIsNameError(true);
                        setNameErrorMessage("한글 또는 영문으로만 입력 가능합니다");
                    }
                }}
                input_props={{
                    placeholder: "이름을 입력해주세요",
                    defaultValue: props?.default_value,
                    autoFocus: props?.auto_focus,
                    maxLength: 32,
                    ref: props?.input_list_ref,
                    onFocus: () => {
                        setIsNameError(false);
                        setNameErrorMessage("");
                    },
                    onChange: (text) => {
                        props.on_data_changed && props.on_data_changed(text, checkNameValidate(text));
                    },
                }}
            />
        </div>
    );
};

NameInput.prototype = propTypes;

export default NameInput;
