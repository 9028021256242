import React from "react";
import {Link} from "react-router-dom";
import {convert_redirect_url} from "../common";

// 베어유 -> <Link to> / 외부링크 - <a>
const ConvertRedirectUrl = (props) => {
    const is_not_bearu_link = props.is_not_bearu_link ?? false;

    const convert_url = () => {
        if (is_not_bearu_link) {
            return (
                <a href={props.redirect_url} target="_blank" rel="noreferrer">
                    {props.children}
                </a>
            );
        } else {
            return <Link to={convert_redirect_url(props.redirect_url)}>{props.children}</Link>;
        }
    };

    return <>{convert_url()}</>;
};

export default ConvertRedirectUrl;