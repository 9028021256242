import React, { useEffect, useRef, useState } from 'react';
import CommonModel from '../../../model/CommonModel';
import { convert_redirect_url, replace_cloudfront_url } from '../../../common';
import { withRouter } from 'react-router-dom';

const SubBanner = () => {
  const [sub_banner, setSubBanner] = useState(null);
  const _is_mount = useRef(false);

  useEffect(() => {
    _is_mount.current = true;
    CommonModel.bannerList({ page: 1, size: 1, banner_type_id: 2 }).then(({ banner_set }) => {
      if (_is_mount.current) {
        let _sub_banner = banner_set[0] || {
          id: 1,
          redirect_url: 'https://tally.so/r/nW8JaR',
          image_url:
            'https://s3.ap-northeast-2.amazonaws.com/dev-img.bear-u.com/static/v2/png/banner/master_apply_banner_2.png',
          title: '클래스 판매등록 신청',
        };
        setSubBanner(_sub_banner);
      }
    });
    return () => {
      _is_mount.current = false;
    };
  }, []);

  return (
    <>
      {sub_banner ? (
        <div
          className='sub-banner-wrap'
          key={sub_banner?.id}
          style={{
            visibility: sub_banner ? 'visible' : 'hidden',
          }}
        >
          <a href={convert_redirect_url(sub_banner?.redirect_url)}>
            <img src={replace_cloudfront_url(sub_banner?.image_url)} alt={sub_banner?.title} />
          </a>
        </div>
      ) : null}
    </>
  );
};

export default withRouter(SubBanner);
