import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ProductModel from '../../model/ProductModel';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval, useObserver } from '../../util/BearuHooks';
import update from 'react-addons-update';
import '../../assets/scss/page/product_detail.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import ProductCurriculum from './section/ProductCurriculum';
import ProductReview from './section/ProductReview';
import ProductInvoice from './component/ProductInvoice';
import LazyLoad from 'react-lazyload';
import {
  alert_and_redirect,
  init_comma,
  replace_cloudfront_url,
  set_favorite_icon,
} from '../../common';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { set_account_favorite_count } from '../../redux/account/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import { get_rental_month_period, get_rental_month_readable } from '../../bearu_utils';
import CountClock from '../_common/component/CountClock';
import PurchaseModel from '../../model/PurchaseModel';
import EarlyPrice from '../course/EarlyPrice';
import PixelModel from '../../model/PixelModel';
SwiperCore.use([Navigation, Pagination]);

const ProductDetail = (props) => {
  const AccountStore = useSelector((state) => state.AccountStore);
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();
  const { confirm, alert } = useDialogContext();

  const [product, setProduct] = useState(null);
  const [clone_course_set, setCloneCourseSet] = useState([]);
  const [is_sticky, setIsSticky] = useState({
    nav_sticky: false,
    invoice_sticky: false,
  });
  const [is_visible, setIsVisible] = useState(false);
  const [_is_view, setIsView] = useState([false, false, false]);
  const [_is_enable, setIsEnable] = useState(false);
  const [event, setEvent] = useState(null);
  const [load_count, setLoadCount] = useState(0);
  const [_is_course_view_set, setCourseViewSet] = useState([]);
  const [select_index, setSelectIndex] = useState(0);
  const [select_course_id, setSelectCourseId] = useState(0);
  const [rental_time_desc, setRentalTimeDesc] = useState('');
  const [expire_time, setExpireTime] = useState(null);
  const [is_purchase, setIsPurchase] = useState(false);
  const [total_price, setTotalPrice] = useState(0);
  const [discount_set, setDiscountSet] = useState([]);
  const [purchase_price, setPurchasePrice] = useState(null);
  const [product_banner_img_set, setProductBannerImgSet] = useState([]);

  const purchase_info_desc_ref = useRef(null);
  const error_message = useRef(null);
  const fixed_content_ref = useRef(null);
  const is_slider = useRef(null);
  const _favorite_pending = useRef(false);
  const _is_mount = useRef(false);

  const [todayStart, setTodayStart] = useState('');
  const [todayEnd, setTodayEnd] = useState('');
  {
    /* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */
  }
  /*const [seatCount, setSeatCount] = useState(0);*/
  const nav_set = [{ name: '강의소개' }, { name: '커리큘럼' }, { name: '수강후기' }];

  const nav_ref = useObserver(
    {},
    () => {
      setIsSticky((state) =>
        update(state, {
          nav_sticky: { $set: false },
        }),
      );
    },
    () => {
      setIsSticky((state) =>
        update(state, {
          nav_sticky: { $set: true },
        }),
      );
    },
    [],
  );

  const fixed_on_ref = useObserver(
    {},
    () => {
      setIsSticky((state) =>
        update(state, {
          invoice_sticky: { $set: false },
        }),
      );
      setIsEnable(false);
    },
    () => {
      setIsSticky((state) =>
        update(state, {
          invoice_sticky: { $set: true },
        }),
      );
      setIsEnable(true);
    },
    [],
  );

  const info_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [0]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [0]: { $set: false },
        }),
      );
    },
    [],
  );

  const curriculum_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [1]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [1]: { $set: false },
        }),
      );
    },
    [],
  );

  const review_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [2]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [2]: { $set: false },
        }),
      );
    },
    [],
  );

  const onOutsideClick = (e) => {
    if (purchase_info_desc_ref.current && !purchase_info_desc_ref.current.contains(e.target)) {
      setIsVisible(false);
    }
  };

  ///mobon script start///
  const [kit_set, setKitSet] = useState([]);
  const [jsAppend, setJsAppend] = useState(0);

  useEffect(() => {
    if (product !== null) {
      let validate_kit_set = [];
      for (let i = 0; i < kit_set.length; i++) {
        let check = false;
        for (let j = 0; j < validate_kit_set.length; j++) {
          if (validate_kit_set[j].id === kit_set[i].id) {
            validate_kit_set[j].order_count++;
            check = true;
            break;
          }
        }
        if (!check) {
          validate_kit_set.push({ id: kit_set[i].id, order_count: 1 });
        }
      }

      if (product) {
        PurchaseModel.priceValidate({
          currency_code: 'KRW',
          package_id: product?.id,
          kit_set: validate_kit_set,
          event_code: event?.event_code,
        }).then(async ({ code, total_price, purchase_price, discount_set }) => {
          if (_is_mount.current) {
            if (code === 200) {
              setTotalPrice(total_price);
              setPurchasePrice(purchase_price);
              setDiscountSet(discount_set);
            } else if (code === 4805) {
              await alert('최대 수량 이상 선택할 수 없습니다.');
            } else if (code === 4701) {
              await alert('품절된 상품입니다.');
              setKitSet((prevState) => {
                prevState.pop();
                return [...prevState];
              });
            } else if (code === 4812) {
              alert_and_redirect(
                alert,
                props.history,
                '해당 이벤트는 첫 구매시에만 참여가능합니다.',
                `/package/${product.id}`,
              );
            } else if (code === 4813) {
              alert_and_redirect(
                alert,
                props.history,
                '이미 참여하신 이벤트입니다.',
                `/package/${product.id}`,
              );
            } else {
              await alert('잘못된 요청입니다.');
            }
          }
        });
      }
      /*
        const device = "B";
        if(purchase_price !== null && jsAppend === 0){
            const script = document.createElement("script");
            script.type= 'text/javascript';
            script.innerText = 'var ENP_VAR = {'
            + '    collect: {},'
            + '    conversion: { product: [] }'
            + '    };'
            + '    ENP_VAR.collect.productCode = "p' + product.id + '";'
            + '    ENP_VAR.collect.productName = "' + product.title + '";'
            + '    ENP_VAR.collect.price = "' + product.original_price + '";'
            + '    ENP_VAR.collect.dcPrice = "' + purchase_price + '";'
            + '    ENP_VAR.collect.imageUrl = \'' + product.main_image_url + '\';'
            + '    ENP_VAR.conversion.product.push({'
            + '            productCode : "p' + product.id + '",'
            + '            productName : "' + product.title + '",'
            + '            price : "' + product.original_price + '",'
            + '            dcPrice : "' + purchase_price + '",'
            + '            imageUrl : \'' + product.main_image_url + '\','
            + '    });'
            + '(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src=\'https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js\';t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");enp(\'create\', \'collect\', \'tendominc\', { device: \'' + device + '\' });'
            + 'enp(\'create\', \'wish\', \'tendominc\', { device: \'' + device + '\', btnSelector: \'.package-detail-id-' + product?.id + ' })';
            document.body.appendChild(script);
            setJsAppend(1);
        }*/
    }
  }, [product, purchase_price]);
  ///mobon script end///

  useInterval(
    () => {
      let today = moment();
      if (event && event.event_code) {
        let tmp_expire_time = moment(event.expire_time).diff(today);
        let day = Math.floor(moment.duration(tmp_expire_time).asDays());
        let hour = moment.duration(tmp_expire_time).hours().toString().padStart(2, '0');
        let minute = moment.duration(tmp_expire_time).minutes().toString().padStart(2, '0');
        let second = moment.duration(tmp_expire_time).seconds().toString().padStart(2, '0');

        if (day <= 0 && hour <= 0 && minute <= 0 && second <= 0) {
          alert_and_redirect(
            alert,
            props.history,
            '이벤트가 마감 되었습니다.',
            `/package/${product.id}`,
          );
        } else {
          setExpireTime({ day, hour, minute, second });
        }
      }
    },
    event && product ? 1000 : null,
  );

  useEffect(() => {
    let concat_course_set;
    let event_code = new URLSearchParams(props.location.search).get('event_code');
    ProductModel.packageDetail({ package_id: props.match.params.id, event_code }).then(
      ({ code, product, event }) => {
        if (_is_mount.current) {
          if (code === 200) {
            error_message.current = event?.event_msg;
            setProduct(product);
            setEvent(event);
            if (event) {
              if (event?.event_msg === '존재하지 않는 이벤트입니다.') {
                alert_and_redirect(alert, props.history, event?.event_msg);
              }

              setTodayStart(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
              if (event?.expire_time) {
                setTodayEnd(moment(event?.expire_time).format('YYYY-MM-DD HH:mm:ss'));
              } else {
                setTodayEnd(moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
              }
            } else {
              setTodayStart(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
              setTodayEnd(moment().startOf('day').endOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }

            setRentalTimeDesc(get_rental_month_readable(product?.rental_time));
            setProductBannerImgSet(
              [{ main_thumbnail_image_url: product.main_thumbnail_image_url }].concat(
                product.course_set,
              ),
            );
            if (product.course_set.length < 4) {
              concat_course_set = product.course_set.concat([
                {
                  main_thumbnail_image_url: `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/package-logo.png`,
                },
              ]);
              setCloneCourseSet(concat_course_set);
              is_slider.current = false;
            } else {
              is_slider.current = true;
            }
            setSelectCourseId(product.course_set[0].id);
            setCourseViewSet([product.course_set[0].id]);
            if (review_ref.current) {
              review_ref.current.style.minHeight = 'inherit';
            }
            setLoadCount(1);
            props.ready();
          } else {
            alert_and_redirect(alert, props.history, '해당 패키지는 전량소진 되었습니다.');
          }
        }
      },
    );
  }, [props.match.params.id]);

  useEffect(() => {
    dispatch(set_footer_type(1));
    dispatch(
      set_header_type(2, 2, {
        title: product && `[${product.short_title}] ${product.title}`,
      }),
    );

    {
      /* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */
    }
    /*if(product){
            PurchaseModel.getProductSeatCount({
                type: "product",
                type_id: product.id
            }).then(({code, data}) => {
                if (code === 200) {
                    setSeatCount(data.counter.count);
                } else {
                    console.log('error' + code);
                }
            });
        }*/

    if (!product) return;
    PixelModel.postConversionAPI({
      eventName: 'ViewContent',
      AccountStore: AccountStore,
      customDataOptions: {
        content_name: `[${product.short_title}] ${product.title}`,
      },
    });
  }, [product]);

  useEffect(() => {
    if (is_purchase) document.body.style.overflow = 'hidden';
    _is_mount.current = true;
    document.addEventListener('click', onOutsideClick, true);

    return () => {
      document.body.style.overflow = 'overlay';
      _is_mount.current = false;
      document.removeEventListener('click', onOutsideClick, true);
    };
  }, [is_purchase]);

  const onRefToScrollClick = (target_index) => {
    if (target_index === 0) {
      window.scrollTo(0, 0);
    } else if (target_index === 1) {
      window.scrollTo(0, curriculum_ref.current.getBoundingClientRect().top + window.scrollY - 60);
    } else if (target_index === 2) {
      window.scrollTo(0, review_ref.current.getBoundingClientRect().top + window.scrollY - 60);
    }
  };

  const onSelectTabClick = (target_index, course_id) => {
    let selected = select_index === target_index ? target_index : target_index;
    let course_view_set = _is_course_view_set;
    setSelectIndex(selected);
    setSelectCourseId(course_id);
    if (!course_view_set.find((data) => data === course_id)) {
      course_view_set.push(course_id);
      setCourseViewSet(course_view_set);
    }
  };

  const onFavorite = async () => {
    if (AccountStore) {
      if (!_favorite_pending.current) {
        _favorite_pending.current = true;
        ProductModel.packageFavorite({
          package_id: product?.id,
        }).then(({ is_favorite, favorite_count, account_favorite_count }) => {
          if (_is_mount.current) {
            dispatch(set_account_favorite_count(account_favorite_count));
            set_favorite_icon('package', product?.id, is_favorite, favorite_count);
            _favorite_pending.current = false;
          }
        });
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  // swiper_options
  const product_slider_option = {
    slidesPerView: 4,
    spaceBetween: 0,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    allowTouchMove: false,
  };

  const curriculum_tab_option = {
    spaceBetween: 12,
    allowTouchMove: !is_slider.current,
    navigation: {
      prevEl: is_slider.current ? '.tab-swiper-prev-unique' : '',
      nextEl: is_slider.current ? '.tab-swiper-next-unique' : '',
    },
    breakpoints: {
      481: {
        slidesPerView: 4,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  const detail_top_slider_option = {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      clickable: true,
    },
    initialSlide: 1,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
  };

  return (
    <div className='td-container detail-container product-dark'>
      <input name='courseId' type='hidden' value='' />
      <Helmet>
        {product && (
          <title>
            [{product.short_title}] {product.title}
          </title>
        )}
      </Helmet>

      <div
        className={'detail-sticky-nav' + (is_sticky.nav_sticky ? ' sticky' : '')}
        ref={nav_ref}
        style={
          is_purchase
            ? { pointerEvents: 'none', opacity: 0 }
            : { pointerEvents: 'inherit', opacity: 1 }
        }
      >
        {product && (
          <ul className='td-wrapper'>
            {nav_set.map((nav, index) => {
              return (
                <li
                  key={index}
                  className={index === _is_view.findIndex((ele) => ele) ? ' selected' : ''}
                  onClick={() => {
                    onRefToScrollClick(index);
                  }}
                >
                  {nav.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className='content-wrap'>
        <div className='td-wrapper'>
          <div className='detail-content'>
            <div ref={nav_ref} style={{ paddingBottom: 40 }}>
              {is_tablet_or_mobile && product ? (
                <>
                  <div className='mo-product-course-img-slider'>
                    <Swiper {...detail_top_slider_option}>
                      {product_banner_img_set.map((course, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div
                              className='class-thumbnail-img'
                              style={
                                course?.main_thumbnail_image_url
                                  ? {
                                      background: `url(${replace_cloudfront_url(
                                        course.main_thumbnail_image_url,
                                      )}) no-repeat center center/cover`,
                                    }
                                  : null
                              }
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>

                  <div className='mo-detail-header-wrap'>
                    <div className='product-title'>
                      <h6>PACKAGE</h6>
                      <h4>
                        [{product.short_title}] {product.title}
                      </h4>
                    </div>

                    <div className='product-course-period-wrap'>
                      <h5>
                        <b>PACKAGE</b>&nbsp;&nbsp;수강권
                      </h5>
                      {rental_time_desc ? <h5>{rental_time_desc}</h5> : null}
                    </div>

                    <div className='img-group'>
                      <div className='course-ticket-wrap'>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-ticket-confirmation-outline-white.svg`}
                          alt='수강권'
                        />
                        <span>수강권</span>
                      </div>
                      <div className='course-secret-note-wrap'>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-notebook-outline-white.svg`}
                          alt='비법노트 제공'
                        />
                        <span>비법노트</span>
                      </div>
                      <p>
                        구성된 강의 {product.course_set?.length}개의 내용을 모두 포함하여
                        제공합니다.
                      </p>
                    </div>

                    <div className='invoice-content'>
                      <div className='purchase-info'>
                        <ul>
                          <li>
                            <h5>실구매가</h5>
                            <h6>{init_comma(product.original_price)}원</h6>
                          </li>

                          {discount_set
                            .filter((_discount) => _discount.price !== 0)
                            .map((discount, index) => {
                              return (
                                <li key={index}>
                                  <h5 style={{ color: '#ff0067' }}>{discount.title}</h5>
                                  <h6
                                    style={{
                                      color: '#ff0067',
                                      fontWeight: '700',
                                    }}
                                  >
                                    -{init_comma(discount.price)}원
                                  </h6>
                                </li>
                              );
                            })}

                          <li className='calculate-price'>
                            <h6>
                              <b>
                                {Math.round(
                                  100 -
                                    (-(total_price - purchase_price - product.original_price) /
                                      product.original_price) *
                                      100,
                                ) || 0}
                                %
                              </b>
                              {init_comma(-(total_price - purchase_price - product.original_price))}
                              원
                            </h6>
                          </li>
                        </ul>
                      </div>

                      <div className='total-price'>
                        {is_visible && (
                          <div ref={purchase_info_desc_ref} className='questions-box'>
                            <p>*수강기간 기준 월 금액, 무이자 최대 6개월 가능</p>
                          </div>
                        )}

                        <div
                          className='purchase-info-description-wrap'
                          style={{
                            ...(product?.rental_time < 30 && { visibility: 'hidden' }),
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-information-outline-white.svg`}
                            alt='수강기간 기준 월 금액, 무이자 최대 6개월 가능'
                            onClick={() => setIsVisible(true)}
                          />
                          <span>
                            수강기간 {get_rental_month_period(product?.rental_time)}개월시
                          </span>
                        </div>

                        <div className='total-price-wrap'>
                          <h6>
                            {get_rental_month_period(product?.rental_time) > 1 ? '월' : ''}{' '}
                            {init_comma(
                              Math.floor(
                                purchase_price /
                                  parseInt(
                                    parseInt(get_rental_month_period(product?.rental_time) || 1),
                                  ),
                              ),
                            )}
                            원
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='product-top-wrap'>
                  <div
                    className='product-top-image'
                    style={
                      product?.main_thumbnail_image_url
                        ? {
                            background: `url(${replace_cloudfront_url(
                              product.main_thumbnail_image_url,
                            )}) no-repeat center center/cover`,
                          }
                        : null
                    }
                  />

                  <div className={'product-class-group' + (!is_slider.current ? ' no-slider' : '')}>
                    {!is_slider.current ? (
                      clone_course_set.map((course, index) => {
                        return (
                          <div
                            key={index}
                            className='class-thumbnail-img'
                            style={
                              course?.main_thumbnail_image_url
                                ? {
                                    background: `url(${replace_cloudfront_url(
                                      course.main_thumbnail_image_url,
                                    )}) no-repeat center center/cover`,
                                  }
                                : null
                            }
                          />
                        );
                      })
                    ) : (
                      <Swiper {...product_slider_option}>
                        {product?.course_set?.map((course, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div
                                className='class-thumbnail-img'
                                style={
                                  course?.main_thumbnail_image_url
                                    ? {
                                        background: `url(${replace_cloudfront_url(
                                          course.main_thumbnail_image_url,
                                        )}) no-repeat center center/cover`,
                                      }
                                    : null
                                }
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */}
            {/*<div className='early-wrap-box'>
                            <>
                                <div className="flex-all-center flex-col pb-6 pt-6">
                                    <div className="count-clock-info">
                                        <h5>인원마감 {seatCount}명 남았습니다.</h5>
                                        <p>마감 시 가격이 상승할 수 있습니다.</p>
                                    </div>
                                    {
                                        <CountClock
                                            class_name={'custom'}
                                            event={event}
                                            start_time={todayStart}
                                            end_time={todayEnd}
                                        />
                                    }
                                </div>
                                {
                                    product && (
                                        <>
                                            <EarlyPrice
                                                product={product}
                                                product_type='product'
                                            />
                                        </>
                                    )
                                }
                            </>
                        </div>*/}

            <ul className='detail-nav'>
              {product && (
                <>
                  {nav_set.map((nav, index) => {
                    return (
                      <li
                        key={index}
                        className={index === 0 ? ' selected' : ''}
                        onClick={() => {
                          onRefToScrollClick(index);
                        }}
                      >
                        {nav.name}
                      </li>
                    );
                  })}
                </>
              )}
            </ul>

            <div className='course-info-wrap' ref={info_ref}>
              {product && (
                <>
                  <h6>패키지 정보</h6>
                  <h3>
                    <b>{product?.info_description}</b>
                    <br />
                    패키지 입니다.
                  </h3>
                  <p>{product?.point_description}</p>
                  <ul className='info-group'>
                    {rental_time_desc ? (
                      <li>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-calendar-point.svg`}
                          alt={rental_time_desc}
                        />
                        <span>{rental_time_desc}</span>
                      </li>
                    ) : null}
                    {product?.content_count ? (
                      <li>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-play-circle-outline-point.svg`}
                          alt={'콘텐츠 ' + product?.content_count + '개'}
                        />
                        <span>{'콘텐츠 ' + product?.content_count + '개'}</span>
                      </li>
                    ) : null}
                  </ul>
                  {product?.content_html && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replace_cloudfront_url(product.content_html),
                      }}
                    />
                  )}
                </>
              )}
            </div>

            <div className='course-curriculum-wrap' ref={curriculum_ref} style={{ minHeight: 300 }}>
              {product && (
                <>
                  <h6>상세내용</h6>
                  <h3>
                    클래스별 <b>상세내용</b>을 확인하세요.
                  </h3>
                  <div className='curriculum-tab-wrap'>
                    <div className='curriculum-tab'>
                      <Swiper {...curriculum_tab_option}>
                        {product &&
                          product.course_set?.map((course, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div
                                  className={
                                    'tab-item' + (index === select_index ? ' selected' : '')
                                  }
                                  onClick={() => {
                                    onSelectTabClick(index, course.id);
                                  }}
                                  id={index}
                                >
                                  <h5>CLASS {index + 1}</h5>
                                  <h4>{course.short_title}</h4>
                                  <div className='tab-img-wrap'>
                                    <div className='border-top-anim' />
                                    <div
                                      className='tab-img'
                                      style={
                                        course?.main_thumbnail_image_url
                                          ? {
                                              background: `url(${replace_cloudfront_url(
                                                course.main_thumbnail_image_url,
                                              )}) no-repeat center center/cover`,
                                            }
                                          : null
                                      }
                                    />
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                      </Swiper>
                      {is_slider.current ? (
                        <>
                          <div className='tab-swiper-prev-unique' />
                          <div className='tab-swiper-next-unique' />
                        </>
                      ) : null}
                    </div>
                    <span className='curriculum-title'>
                      {`${product.course_set[select_index].short_title} - 커리큘럼`}
                    </span>

                    <div className='curriculum-list'>
                      {_is_course_view_set.map((course_id) => {
                        return (
                          <div
                            key={course_id}
                            className='curriculum'
                            style={
                              select_course_id === course_id
                                ? { display: 'block' }
                                : { display: 'none' }
                            }
                          >
                            <ProductCurriculum course_id={course_id} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='course-review-wrap' ref={review_ref} style={{ minHeight: 300 }}>
              {product && (
                <>
                  <h6>수강후기</h6>
                  <h3>
                    클래스별 <b>진솔한 후기</b>입니다.
                  </h3>
                  <LazyLoad>
                    {_is_course_view_set.map((course_id) => {
                      return (
                        <div
                          key={course_id}
                          style={
                            select_course_id === course_id ? { display: '' } : { display: 'none' }
                          }
                        >
                          <ProductReview course_id={course_id} />
                        </div>
                      );
                    })}
                  </LazyLoad>
                </>
              )}
            </div>
          </div>

          <div
            className='pc-visible'
            style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '1px' }}
            ref={fixed_on_ref}
          />

          {product && (
            <div
              className='mo-purchase-btn-wrap'
              style={is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
            >
              <div
                className='icon-wrap gtm-detail gtm-data'
                data-ai-pid={`p${product?.id.toString().padStart(8, '0')}`}
                data-ai-pnm={product?.title.replace(/\r\n/g, ' ')}
                data-ai-ppc={product?.price}
                data-ai-ppb=''
                data-ai-pca='패키지'
              >
                <img
                  className={`td-favorite package-detail-id-${product?.id} ${product?.is_favorite ? '' : ' enp_mobon_wish'}`}
                  src={
                    product?.is_favorite
                      ? `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detailpage-active-2.png`
                      : `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detail-2.png`
                  }
                  alt='저장하기'
                  onClick={onFavorite}
                />
              </div>

              <button
                className='td-btn'
                onClick={() => {
                  setIsPurchase(true);
                }}
              >
                {event?.is_display_time && expire_time ? (
                  <>
                    <img
                      className='animate__animated animate__tada'
                      src={`${process.env.REACT_APP_IMG_URL}/static/png/common/icon-thunder%402x.png`}
                      alt='타임특가'
                    />
                    <span>{`${expire_time.day}일 ${expire_time.hour}:${expire_time.minute}:${expire_time.second} 수강 신청`}</span>
                    <br />
                    {/* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */}
                    {/*<span className="seat-count">남은자리 <span>{seatCount}</span>건</span>*/}
                  </>
                ) : (
                  <p className='purchase-btn-text'>
                    {/* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */}
                    수강 신청{' '}
                    {/*<span className="seat-count">남은자리 <span>{seatCount}</span>건</span>*/}
                  </p>
                )}
              </button>
            </div>
          )}
          <div
            className={
              'fixed-content td-dark-scroll' +
              (!is_tablet_or_mobile && is_sticky.invoice_sticky
                ? ' sticky'
                : is_purchase
                  ? ' is-purchase-active'
                  : '')
            }
            ref={fixed_content_ref}
            style={
              !is_tablet_or_mobile && _is_enable
                ? { height: '100vh', overflowY: 'auto' }
                : {
                    height: 'inherit',
                    overflowY: 'hidden',
                  }
            }
          >
            {is_purchase && (
              <div
                className='dimmed-layer'
                onClick={() => {
                  if (is_tablet_or_mobile) setIsPurchase(false);
                }}
              />
            )}
            <ProductInvoice
              product={product}
              event={event}
              /* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */
              //seat_count={seatCount}
              load_count={load_count}
              rental_time_desc={rental_time_desc}
              setIsPurchase={setIsPurchase}
              set_total_price={setTotalPrice}
              set_purchase_price={setPurchasePrice}
              set_discount_price={setDiscountSet}
              onFavorite={onFavorite}
              error_message={error_message.current}
            />
            {!is_tablet_or_mobile && expire_time && event?.is_display_time ? (
              <div className='event-msg-wrap'>
                <p>
                  <img
                    className='animate__animated animate__tada'
                    src={`${process.env.REACT_APP_IMG_URL}/static/png/common/icon-thunder%402x.png`}
                    alt='타임특가'
                  />
                  {expire_time.day}일 {expire_time.hour}:{expire_time.minute}:{expire_time.second}
                </p>
              </div>
            ) : null}
            {!is_tablet_or_mobile && event?.event_msg ? (
              <div className='event-msg-wrap'>
                <p className='event-finish-text'>{event.event_msg}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
