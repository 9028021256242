import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { main_review_option } from '../../../constant/option';

SwiperCore.use([Navigation]);

const ReviewSlider = (props) => {
  return (
    <div className='review-card-slide'>
      <h3 className='review-title'>
        현직자가 말하는 베어유 <br />
        <b>100% 리얼 후기</b>
      </h3>
      <Swiper {...main_review_option}>
        {review_card_set.map((review, index) => {
          return (
            <SwiperSlide key={index}>
              <div className='review-card-wrap'>
                <div className='review-info-wrap'>
                  <div
                    className='thumbnail'
                    style={{
                      background: `url(${review.img_url}) no-repeat center center/cover`,
                    }}
                  />
                  <div className='content'>
                    <h4>{review.team}</h4>
                    <h5>
                      {review.name}
                      <span>님</span>
                    </h5>
                  </div>
                </div>
                <div className='review-comment-wrap'>
                  <p>{review.review_content}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className='swiper-review-slider-prev'>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_arrow_l_blue.svg`}
          alt='이전버튼'
        />
      </div>
      <div className='swiper-review-slider-next'>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_arrow_r_blue.svg`}
          alt='다음버튼'
        />
      </div>
    </div>
  );
};

export default ReviewSlider;

const review_card_set = [
  {
    id: 1,
    team: 'ab180',
    name: '최혁순',
    review_content:
      '일단 어려운 내용도 부담없이 배울 수 있어서 좋았어요! 인강은 늘 지루하다고만 생각했는데 베어유는 같은 내용도 쉽게 정리해주는게 특징인 것 같아요.',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_1.png`,
  },
  {
    id: 2,
    team: '코람코자산신탁',
    name: '최연태',
    review_content:
      '군더더기 없이 포인트만 딱딱 짚어줘서 속이 시원한 강의였어요! 정말 필요한 핵심만 알려줘서 저처럼 바쁜 시간 쪼개서 배우는 사람에게는 최고였어요.',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_2.png`,
  },
  {
    id: 3,
    team: '플랫팜',
    name: '이효섭',
    review_content:
      '강의가 10분내외로 짧아서 잠깐씩 집중해서 듣기좋아요. 따로 많은 시간과 에너지를 들이지 않아도 출퇴근길 틈틈이 자기계발이 가능하네요!',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_3.png`,
  },
  {
    id: 4,
    team: '휴마트컴퍼니',
    name: '김동현',
    review_content:
      '활용도 200%!! 말그대로 찐실무를 위한 커리큘럼이네요. 가장 많이 쓰는 부분만 집중적으로 배울 수 있어서 짧은 시간에 진짜 큰 도움이 됐어요.',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_4.png`,
  },
  {
    id: 5,
    team: 'VNTC',
    name: '노경석',
    review_content:
      '원하는 시간에 원하는 곳에서 짧게짧게 공부할 수 있어서 좋네요. 인강이라 어떨까 싶었는데 저는 오히려 학원보다 더 효율적이어서 훨씬 편리했어요.',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_5.png`,
  },
  {
    id: 5,
    team: '현대백화점',
    name: '김성우',
    review_content:
      '이론보다는 실습위주의 클래스이다보니 이해하기가 훨씬 쉬웠어요. 화면을 보며  따라하면서 배우니까 확실히 경험치가 빨리 쌓여서 너무 좋았습니다.',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_6.png`,
  },
  {
    id: 7,
    team: '삼성전자',
    name: '박태수',
    review_content:
      '수업내용도 깔끔! 편집도 깔끔! 사운드도 깔끔!! 여러모로 신경을 많이 써서 제작했다는 느낌이 들어요. 인강이지만 집중이 잘돼요!!',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_7.png`,
  },
  {
    id: 8,
    team: '건국대학교',
    name: '안준혁',
    review_content:
      '배우고 싶은건 많고 시간은 없어서 자기계발은 엄두가 안났는데, 이렇게 컴팩트한 인강이라면 저도 가능하네요! 복잡한 내용을 심플하게 배울 수 있어요!',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_8.png`,
  },
  {
    id: 9,
    team: '대한민국 육군',
    name: '이준호',
    review_content:
      '일하다보면 오늘 배워 내일 바로 쓸 수 있는 공부가 절실한데, 실무에 바로 쓸 내용만 쉽고 빠르게 배울 수 있는 부분이 너무 마음에 들어요.',
    img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/png/main/review_thumbnail_9.png`,
  },
];
