import React, {useState} from "react";
import PropTypes from "prop-types";
import {useInterval} from "../../../util/BearuHooks";
import {date_diff} from "../../../common";
import moment from "moment";
import "../../../assets/scss/page/countClock.scss";

const propTypes = {
    is_start: PropTypes.bool, // 시작 X -> false
    is_expire: PropTypes.bool, // 만료 X -> false
    start_time: PropTypes.string, // 시작 날짜 & 시간
    expire_time: PropTypes.string, // 종료 날짜 & 시간
    class_name: PropTypes.string,
    event: PropTypes.object,
};

const CountClock = (props) => {
    const [is_loaded, setLoaded] = useState(false);
    const [count_time, setCountTime] = useState(null);
    const {is_start, is_expire, start_time, end_time, event} = props;

    useInterval(
        () => {
            let start_diff = date_diff(start_time, moment());
            let end_diff = date_diff(end_time, moment());
            let is_not_started =
                start_diff.day >= 0 && start_diff.hour >= 0 && start_diff.minute >= 0 && start_diff.second >= 0;

            let _is_expire = end_diff.day <= 0 && end_diff.hour <= 0 && end_diff.minute <= 0 && end_diff.second <= 0;
            const makeCountDate = (_date_diff) => {
                return {
                    day: _date_diff.day,
                    hour: _date_diff.hour.toString().padStart(2, "0"),
                    minute: _date_diff.minute.toString().padStart(2, "0"),
                    second: _date_diff.second.toString().padStart(2, "0"),
                };
            };

            // 만료 시
            if (_is_expire) {
                let temp_start_time = moment().startOf("day").format('YYYY-MM-DD HH:mm:ss');
                let temp_end_time = moment().startOf("day").endOf("day").format('YYYY-MM-DD HH:mm:ss');

                start_diff = date_diff(temp_start_time, moment());
                end_diff = date_diff(temp_end_time, moment());

                is_not_started =
                    start_diff.day >= 0 && start_diff.hour >= 0 && start_diff.minute >= 0 && start_diff.second >= 0;
            }

            // 시작 전 or 진행중
            if (start_time === null) {
                setCountTime(makeCountDate(end_diff));
            } else if (is_not_started) {
                setCountTime(makeCountDate(start_diff));
            } else {
                setCountTime(makeCountDate(end_diff));
                if (_is_expire) {
                    setCountTime({day: "0", hour: "00", minute: "00", second: "00"});
                }
            }

            setLoaded(true);
        },
        end_time ? 1000 : null
    );

    return (
        is_loaded && (
            <div className={"count-clock-wrap " + props.class_name + (is_expire ? " expire" : "")}>
                <div className={"count-time-wrap"}>
                    <div className="time-wrap">
                        {
                            event && (
                                <span className='pr-2'>
                                    {count_time?.day}일
                                </span>
                            )
                        }

                        <span>{count_time?.hour}</span>
                        <span className="colon">:</span>
                        <span>{count_time?.minute}</span>
                        <span className="colon">:</span>
                        <span>{count_time?.second}</span>
                    </div>
                </div>
            </div>
        )
    );
};

CountClock.propsTypes = propTypes;

export default CountClock;
