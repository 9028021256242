import React, {useEffect, useRef, useState} from "react";
import {Link, withRouter, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import MyProfileModal from "../dialog/MyProfileModal";
import NotificationModal from "../dialog/NotificationModal";
import {useMediaQuery} from "react-responsive";
import {set_header_type} from "../../../redux/common/action";
import CommonModel from "../../../model/CommonModel";
import {dialog_type_custom, useDialogContext} from "../dialog/DialogContextProvider";
import {custom_hash, select} from "../../../common";
import {url_data_set} from "../../../constant/common_url";

// (베어유로고)-(카테고리)---(검색)-(알림)-(마이페이지) 배경: #fff
export const HEADER_TYPE_PC_BASE = 0;

// (홈아이콘)(h2 태그) 배경: transparent
export const HEADER_TYPE_PC_MASTER = 1;

// (베어유로고)-(카테고리)---(검색)-(알림)-(마이페이지) 배경: transparent
export const HEADER_TYPE_PC_TRANSPARENT = 2;

// (홈아이콘)(베어유로고) 배경: transparent
export const HEADER_TYPE_PC_SIMPLE_TRANSPARENT = 3;

// (베어유로고)----------(마이페이지) 배경: #fff, height: 50px, max-width: 1280px
export const HEADER_TYPE_PC_VIEWER = 4;

// (베어유로고) 배경: transparent, read-only
export const HEADER_TYPE_PC_READ_ONLY = 5;

// (이벤트)-(베어유로고)-(알림)(검색) 배경: transparent
export const HEADER_TYPE_MOBILE_BASE = 0;

// (이벤트)-(베어유로고)-(알림)(검색) 배경: #fff
export const HEADER_TYPE_MOBILE_CLASS = 1;

// (화살표)-(타이틀)-(검색) 배경: 패키지(transparency-type)- #1e1e1e , 클래스- #fff
export const HEADER_TYPE_MOBILE_SUB = 2;

// (이벤트)-(베어유로고)-(알림)(검색) 배경: #fff, 포지션: fixed
export const HEADER_TYPE_MOBILE_FIXED = 3;

// (뒤로가기)-(검색)-(홈)
export const HEADER_TYPE_MOBILE_SEARCH = 4;

// (이벤트)-(베어유로고)-(알림)(검색) 배경: #fff, 포지션: relative
export const HEADER_TYPE_MOBILE_MYPAGE = 5;

const Header = (props) => {
    //Search
    let q = new URLSearchParams(props.location.search).get("q");
    const [search_text, setSearchText] = useState(q);
    const [search_placeholder, setSearchPlaceHolder] = useState("");
		const location = useLocation();

		const isMainPage = location.pathname.includes('/overview/');
		const isEbookPage = location.pathname.includes('/ebook');

    //Sub_Menu
    const [category_list, setCategoryList] = useState(null); // @TODO 종민 향후에 redux로 overview랑 같이 쓰면 좋을듯
    const [event_list, setEventList] = useState(null);
    const [is_category_open, setCategoryOpen] = useState(false);
    const [is_event_open, setEventOpen] = useState(false);

    //Redux & Header_Type
    const AccountStore = useSelector((state) => state.AccountStore);
    const CommonStore = useSelector((state) => state.CommonStore);
    const header = CommonStore.header;
    const prev_header_type = useRef(null);
    const dispatch = useDispatch();

    //Modal
    const {showDialog} = useDialogContext();
    const notification_info = useRef(null);

    //MediaQuery
    const is_tablet_or_mobile = useMediaQuery({query: "(max-width: 767px)"});
    const [hash_id, setHashId] = useState("");

		const currentDate = new Date();
		const compareDate = new Date('2024-06-19T23:59:59');
		const isActiveRecruitForm = currentDate > compareDate;

		const requestUpdateCategoryList = async () => {
        const {code_set} = await CommonModel.staticCode({code_name: "course_category_set"});
        let category_list = [
            {
                id: "package",
                name: "추천패키지",
                url: "/overview/package",
            },
            ...code_set,
        ];
        setCategoryList(category_list);
    };

    const requestSetEventList = async () => {
        const {notice_set} = await CommonModel.noticeList({
            page: null,
            size: 3,
            notice_category_id: 2,
        });
        setEventList(notice_set);
    };

    useEffect(() => {
        requestUpdateCategoryList();
        requestSetEventList();
        if (AccountStore) {
            custom_hash(AccountStore.id).then((data) => {
                setHashId(data);
            });
        }
    }, []);

    useEffect(() => {
        setSearchText(q);
    }, [q]);

    useEffect(() => {
        let unlisten = props.history.listen((location) => {
            prev_header_type.current = null;
        });
        return () => {
            unlisten();
        };
    }, []);

    useEffect(() => {
        setCategoryOpen(false);
        setEventOpen(false);
    }, [props.location.pathname]);

    const onSearch = () => {
        if (search_text) {
            setSearchPlaceHolder("");
            props.history.push(`/search?q=${search_text}`);
        } else {
            setSearchPlaceHolder("검색어를 입력해주세요.");
        }
    };

    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    const toggleCategory = (is_category_open) => setCategoryOpen(is_category_open);
    const toggleEvent = (is_event_open) => setEventOpen(is_event_open);

    const onMouseOver = () => {
        if (header.pc_header_type === HEADER_TYPE_PC_TRANSPARENT) {
            prev_header_type.current = {
                pc_header_type: header.pc_header_type,
                mo_header_type: header.mo_header_type,
            };
            dispatch(
                set_header_type(HEADER_TYPE_PC_BASE, HEADER_TYPE_MOBILE_BASE, {
                    title: header.header_set.title,
                })
            );
        }
    };

    const onMouseLeave = () => {
        const cur_path = props.location.pathname;

        if (prev_header_type.current !== null) {
            dispatch(
                set_header_type(prev_header_type.current?.pc_header_type, prev_header_type.current?.mo_header_type, {
                    title: header.header_set.title,
                })
            );
        } else if (cur_path === "/" && header.pc_header_type === 0) {
            prev_header_type.current = {
                pc_header_type: HEADER_TYPE_PC_TRANSPARENT,
            };
        }
    };

    const getHeaderType = () => {
        if (
            header.pc_header_type === HEADER_TYPE_PC_MASTER ||
            header.pc_header_type === HEADER_TYPE_PC_SIMPLE_TRANSPARENT ||
            header.pc_header_type === HEADER_TYPE_PC_READ_ONLY
        ) {
            return " transparent-type";
        } else {
            if (header.pc_header_type === HEADER_TYPE_PC_TRANSPARENT) {
                return " transparency-type";
            } else {
                if (header.pc_header_type === HEADER_TYPE_PC_VIEWER) {
                    return " viewer-type";
                } else {
                    return "";
                }
            }
        }
    };

    const getMobileHeaderType = () => {
        if (header.mo_header_type === HEADER_TYPE_MOBILE_SUB) {
            return " mo-sub";
        } else {
            if (header.mo_header_type === HEADER_TYPE_MOBILE_BASE) {
                return " mo-main";
            } else {
                if (header.mo_header_type === HEADER_TYPE_MOBILE_FIXED) {
                    return " mo-fixed";
                } else {
                    if (header.mo_header_type === HEADER_TYPE_MOBILE_SEARCH) {
                        return " mo-search";
                    } else {
                        return " mo-class";
                    }
                }
            }
        }
    };

    const getProfileImage = () => {
        if (header.pc_header_type === HEADER_TYPE_PC_TRANSPARENT) {
            return {
                background: `url(${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_my_w.svg) no-repeat center center/cover`,
            };
        } else {
            return {
                background: `url(${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_my_g.svg) no-repeat center center/cover`,
            };
        }
    };

    //ClassName Function for SCSS
    const header_type = getHeaderType();
    const m_header_type = getMobileHeaderType();
    const profile_photo = getProfileImage();

    return (
        <>
            <div
                className={"hdr" + header_type}
                style={{
                    position:
                        is_tablet_or_mobile &&
                        (header.mo_header_type === HEADER_TYPE_PC_TRANSPARENT ||
                            header.mo_header_type === HEADER_TYPE_MOBILE_FIXED ||
                            header.mo_header_type === HEADER_TYPE_MOBILE_SEARCH)
                            ? "sticky"
                            : "absolute",
                }}
                onMouseOver={is_tablet_or_mobile ? null : onMouseOver}
                onMouseLeave={is_tablet_or_mobile ? null : onMouseLeave}>
                <div className="td-wrapper-v2 gnb">
                    <input name="hashId" type="hidden" value={hash_id} />
                    {header.pc_header_type !== HEADER_TYPE_PC_MASTER &&
                    header.pc_header_type !== HEADER_TYPE_PC_SIMPLE_TRANSPARENT &&
                    header.pc_header_type !== HEADER_TYPE_PC_READ_ONLY ? (
                        <>
                            <div className="pc-visible">
                                <div className="left-wrap">
                                    <h1>
                                        <Link to="/" />
                                    </h1>
                                    <ul className="nav-menu">
                                        <li
                                            className="class-category"
                                            onMouseEnter={() => toggleCategory(true)}
                                            onMouseLeave={() => toggleCategory(false)}>
                                            <Link to="/overview/all">클래스</Link>
                                            {is_category_open && (
                                                <ul className="category-list-container">
                                                    {category_list?.map((category) => (
                                                        <li
                                                            key={category.id}
                                                            onClick={() => {
                                                                props.history.push(
                                                                    category.url || `/overview/${category.id}`
                                                                );
                                                            }}>
                                                            {category.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                        <li className="ebook-category">
                                            <Link to="/ebook">전자책</Link>
                                        </li>
                                        <li className="study-category">
                                            <Link
                                                to={isActiveRecruitForm ? '/bgd' : '/hardstudy'}
                                                onClick={() => {
                                                    let current_url = window.location.pathname + window.location.search;
                                                    if (current_url !== (isActiveRecruitForm ? '/bgd' : '/hardstudy')) {
                                                        CommonModel.postAnalytics({
                                                            action: "click",
                                                            value: "hardstudy_gnb",
                                                        });
                                                    }
                                                }}>
                                                빡공단
                                            </Link>
                                        </li>
                                        {/* //TODO:  2023-09-4, 17:47 -kwak: 0원 수강 비활성화 */}
                                        {/*<li className="superz-category">
                                            <Link to="/package/262">0원 수강</Link>
                                        </li>*/}
                                        <li
                                            className="event-category"
                                            onMouseEnter={() => toggleEvent(true)}
                                            onMouseLeave={() => toggleEvent(false)}>
                                            <Link to="/service/event">이벤트</Link>
                                            {/* {is_event_open && (
                                                <ul className="event-list-container">
                                                    {event_list?.map((event, idx) => {
                                                        console.log(event_list);
                                                        return (
                                                            <li key={idx}>
                                                                <Link to={event.url}>{event.title}</Link>
                                                                <div className="direction" />
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            )} */}
                                        </li>
                                        <li className="b2b-category">
                                            <Link to="/creditbank">학점은행제</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right-wrap">
																	{ (!isMainPage && !isEbookPage) && (
																		<div className="search-bar">
																			<button onClick={onSearch}/>
																			<input
																				value={search_text ? search_text : ''}
																				onKeyDown={(e) => {
																					if (e.key === 'Enter' || e.code === 'NumpadEnter') {
																						onSearch()
																					}
																				}}
																				onChange={onChangeSearchText}
																				type="text"
																				placeholder={select(
																					header.pc_header_type === HEADER_TYPE_MOBILE_BASE,
																					search_placeholder
																				)}
																				name="search"
																				autoComplete={'none'}
																			/>
																		</div>
																	)
																	}
																	<div
																		className="noti-btn"
																		id="noti-modal-btn"
																		onClick={() => {
																			showDialog({
																				type: dialog_type_custom,
																				component: NotificationModal,
																				component_props: {
																					noti_count: CommonStore.tmp?.noti_count,
																					new_noti: CommonStore.tmp?.is_new_noti,
																					notification_info: notification_info.current,
																					setOnNotificationList: (new_notification_info) => {
																						notification_info.current = new_notification_info
																					},
																				},
																			})
																		}}>
                                        {CommonStore.tmp?.is_new_noti && <div className="new-notice" />}
                                    </div>
                                    {AccountStore ? (
                                        <div className="login-button user-profile-btn">
                                            <div
                                                id="profile-modal-btn"
                                                onClick={() => {
                                                    AccountStore &&
                                                        showDialog({
                                                            type: dialog_type_custom,
                                                            component: MyProfileModal,
                                                        });
                                                }}
                                                style={profile_photo}
                                            />
                                        </div>
                                    ) : (
                                        <div className="login-btn">
                                            <button
                                                onClick={() => {
                                                    props.history.push({
                                                        pathname: "/user/signin",
                                                        state: {step: 1},
                                                    });
                                                }}>
                                                로그인
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={"mo-visible" + m_header_type}>
                                {header.mo_header_type === HEADER_TYPE_MOBILE_SEARCH ? (
                                    <>
                                        {!header.header_set.no_back_btn && (
                                            <div className="back-btn-wrapper" onClick={() => props.history.goBack()}>
                                                <div className="back-btn" />
                                            </div>
                                        )}
                                        <div className="mobile-search-bar">
                                            <input
                                                value={search_text ? search_text : ""}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter" || e.code === "NumpadEnter") {
                                                        onSearch();
                                                    }
                                                }}
                                                onChange={onChangeSearchText}
                                                placeholder={"어떤 성장을 원하고 있나요?"}
                                                type="text"
                                                name="search"
                                                autoComplete={"none"}
                                            />
                                        </div>
                                        <button className="search-btn-wrapper" onClick={onSearch}>
                                            <div className="mobile-search-btn" />
                                        </button>
                                    </>
                                ) : header.mo_header_type === HEADER_TYPE_MOBILE_SUB ? (
                                    <>
                                        {!header.header_set.no_back_btn && (
                                            <div className="back-btn-wrapper" onClick={() => props.history.goBack()}>
                                                <div className="back-btn" />
                                            </div>
                                        )}
                                        <h3>{header.header_set.title}</h3>
                                        {header.header_set.no_search_btn ? (
                                            <div className="home-btn-wrapper">
                                                <Link to="/" className="home-btn" />
                                            </div>
                                        ) : (
                                            <div className="search-btn-wrapper">
                                                <Link to="/search" className="search-btn" />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="left-wrap">
                                            <Link className="event-btn" to="/service/event" />
                                        </div>
                                        <h1 className="">
                                            <Link to="/" />
                                        </h1>
                                        <div className="right-wrap">
                                            <div
                                                className="noti-btn"
                                                id="noti-modal-btn"
                                                onClick={() => {
                                                    showDialog({
                                                        type: dialog_type_custom,
                                                        component: NotificationModal,
                                                        component_props: {
                                                            noti_count: CommonStore.tmp?.noti_count,
                                                            new_noti: CommonStore.tmp?.is_new_noti,
                                                            notification_info: notification_info.current,
                                                            setOnNotificationList: (new_notification_info) => {
                                                                notification_info.current = new_notification_info;
                                                            },
                                                        },
                                                    });
                                                }}>
                                                {CommonStore.tmp?.is_new_noti && <div className="new-notice" />}
                                            </div>
                                            {!header.header_set.no_search_btn && (
                                                <Link to="/search" className="search-btn" />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    ) : header.pc_header_type === HEADER_TYPE_PC_SIMPLE_TRANSPARENT ? (
                        <>
                            <h1>
                                <Link to="/" className="curation" />
                            </h1>
                            <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/bearu-logo-white.svg`}
                                alt="베어유"
                                onClick={() => {
                                    props.history.push("/");
                                }}
                                style={{cursor: "pointer"}}
                            />
                        </>
                    ) : header.pc_header_type === HEADER_TYPE_PC_READ_ONLY ? (
                        <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/bearu-logo-white.svg`}
                            alt="베어유"
                        />
                    ) : (
                        <>
                            <h1>
                                <Link to="/" className="master" />
                            </h1>
                            <h2>Bear-U Master</h2>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default withRouter(Header);
