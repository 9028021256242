import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import AccountModel from "../model/AccountModel";
import {stored_account_info} from "../redux/account/action";
import {reset_tmp} from "../redux/common/action";
import {useHistory} from "react-router-dom";

const InitAuthCheck = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const AccountStore = useSelector(state => state.AccountStore);
    const before_url = useRef(null);

    useEffect(() => {
        AccountModel.validate({access_token: null}).then(({code, account}) => {
            if (code === 200) {
                dispatch(stored_account_info(account));
            } else {
                dispatch(reset_tmp());
                dispatch(stored_account_info(null));
            }
        });

        // 탈퇴, 정지 유저 관리
        onLocationChange(history.location);

        let unlisten = history.listen(onLocationChange);

        return () => {
            unlisten();
        };
    }, []);

    const onLocationChange = (location) => {
        const new_url = location.pathname + location.search;

        if (AccountStore && before_url.current !== new_url) {
            AccountModel.sessionCheck().then((code) => {
                if (code >= 300) {
                    dispatch(reset_tmp());
                    dispatch(stored_account_info(null));
                }
            });
        }

        before_url.current = new_url;
    }

    return null;
};

export default InitAuthCheck;
