import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { useMount } from '../../util/BearuHooks';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { replace_cloudfront_url, select } from '../../common';
import '../../assets/scss/page/event.scss';
import CommonModel from '../../model/CommonModel';
import { HEADER_TYPE_MOBILE_FIXED, HEADER_TYPE_PC_BASE } from '../_common/section/Header';

const Event = (props) => {
  //Redux
  const dispatch = useDispatch();

  //MediaQuery
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 767px)' });

  //Event List
  const [event_map, setEventMap] = useState([]); // {{page}: [{notice}, ...], ...}
  const [event_filter, setEventFilter] = useState(false);

  //Pagination
  const [page, setPage] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const max_page = useRef(0);

  //Common
  const [isMount] = useMount();

  useEffect(() => {
    dispatch(set_header_type(HEADER_TYPE_PC_BASE, HEADER_TYPE_MOBILE_FIXED, { title: '이벤트' }));
    dispatch(set_footer_type(0));

    updateList(1);
  }, []);

  const updateList = async (_page = 1, only_activated = false, reset_event_map = false) => {
    try {
      window.scrollTo(0, 0);
      let new_event_map = reset_event_map ? [] : event_map;
      if (!new_event_map[_page]) {
        let { notice_set, total_count } = await getEventInfo(_page, only_activated);
        // init
        if (isMount()) {
          new_event_map[_page] = notice_set;
          setEventMap(new_event_map);
          setTotalCount(total_count);
          max_page.current = Math.ceil(total_count / 12);
        }
      }

      setPage(_page);

      props.ready();
    } catch (e) {
      console.log(e);
      // alert("앗!... 리스트를 가져오는 중 에러가 발생했습니다 ㅠㅠ");
    }
  };

  const getEventInfo = async (_page = 1, only_activated = false) => {
    let data = await CommonModel.noticeList({
      page: _page,
      size: 12,
      notice_category_id: 2,
      status_type: select(only_activated, 'is_active'),
    });
    if (data.code === 200) {
      return data;
    } else {
      return null;
    }
  };

  const doFiltering = () => {
    let new_event_filter = !event_filter;
    setEventFilter(new_event_filter);

    //clear
    updateList(1, new_event_filter, true);
  };

  const getTimeTag = (event) => {
    const open = new Date(event.start_time);
    const end = new Date(event.expire_time);
    const now = new Date().getTime();
    const start = now - open;
    const expired = end - now;
    const days = Math.floor(expired / (1000 * 60 * 60 * 24));

    if (
      (event.start_time === null && event.expire_time === null) ||
      (event.start_time !== null && event.expire_time === null && start >= 0)
    ) {
      return '상시';
    } else if (start <= 0) {
      return '오픈예정';
    } else if (expired <= 0) {
      return '종료';
    } else if (days === 0) {
      return 'D-day';
    } else {
      return `D-${days}`;
    }
  };

  return (
    <div className='event-container'>
      <Helmet>
        <title>이벤트</title>
      </Helmet>
      <div className='event-top-content'>
        <p className='title'>이벤트</p>
        <div className='event-btn-container'>
          <p className='btn-desc'>진행중인 이벤트만 보기</p>
          <button
            className={event_filter ? 'event-handle-btn on' : 'event-handle-btn'}
            onClick={doFiltering}
          >
            {event_filter ? <p className='btn-on'>ON</p> : <p className='btn-off'>OFF</p>}
          </button>
        </div>
      </div>
      <div className='event-list'>
        {event_map[page]?.map((event, index) => {
          let time_tag = getTimeTag(event);
          return (
            <div className='event-content-wrapper' key={index}>
              <Link key={event.id} to={`/service/notice/${event.id}`}>
                <div className='event-content'>
                  <div className='event-img'>
                    {time_tag === '종료' && <div className='dimmed' />}
                    <img
                      className={select(!event.thumbnail_image_url, 'default')}
                      src={
                        event.thumbnail_image_url
                          ? replace_cloudfront_url(event.thumbnail_image_url)
                          : `${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-common-icon.png`
                      }
                      alt=''
                    />
                    <div className='event-date-wrap'>
                      <p className='event-date'>{time_tag}</p>
                    </div>
                  </div>
                  <p className='event-title'>{event.title}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      {total_count ? (
        <div className='event-pagination'>
          <div className='page'>
            <img
              onClick={() => {
                if (page > 1) {
                  updateList(page - 1, select(event_filter, 'is_active'));
                }
              }}
              className={'arrow left' + (page === 1 ? ' disabled' : '')}
              src={
                is_tablet_or_mobile
                  ? `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_l_m.svg`
                  : `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_l_lg.svg`
              }
              alt='arrow'
            />
            {page}&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
            {max_page.current}
            <img
              onClick={() => {
                if (max_page.current > page) {
                  updateList(page + 1, select(event_filter, 'is_active'));
                }
              }}
              className={'arrow right' + (page === max_page.current ? ' disabled' : '')}
              src={
                is_tablet_or_mobile
                  ? `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_r_m.svg`
                  : `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_r_lg.svg`
              }
              alt='arrow'
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Event;
