import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const EventTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return <div />;
};

export default EventTracker;
