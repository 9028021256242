import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import { useParams, useHistory } from 'react-router-dom';

import { set_footer_type, set_header_type } from '../../redux/common/action';
import '../../assets/scss/page/terms_policy.scss';

import TermsItem240101 from './terms_item/240101/TermsItem';
import TermsItem230922 from './terms_item/230922/TermsItem';
import TermsItem210521 from './terms_item/210521/TermsItem';

const components = {
  240101: () => <TermsItem240101 />,
  230922: () => <TermsItem230922 />,
  210521: () => <TermsItem210521 />,
};

const Terms = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { term_id } = useParams();
  const [selectedVersion, setSelectedVersion] = useState('');

  useEffect(() => {
    dispatch(set_header_type(0, 2, { title: '이용약관' }));
    dispatch(set_footer_type(0));
    if (props.ready && typeof props.ready === 'function') {
      props.ready();
    }
  }, []);

  useEffect(() => {
    if (term_id) {
      const existTerm = TERMLIST.some((item) => item.value === term_id);
      if (!existTerm) history.push('/service/terms');
      setSelectedVersion(existTerm ? term_id : '');
    }
  }, [term_id]);

  const ComponentToRender =
    selectedVersion === '' ? Object.values(components).reverse()[0] : components[selectedVersion];

  return (
    <div className='terms-policy-container'>
      <Helmet>
        <title>이용약관</title>
      </Helmet>
      <section className='terms-wrap'>
        <p className='title pc-visible'>이용약관</p>
        {ComponentToRender && <ComponentToRender />}

        <div className='react-select'>
          <Select
            options={TERMLIST}
            defaultValue={TERMLIST[0]}
            value={TERMLIST.find((item) => item.value === selectedVersion)}
            onChange={(val) => {
              setSelectedVersion(val.value);
              window.scrollTo(0, 0);
            }}
            classNamePrefix='react-select'
          />
        </div>
      </section>
    </div>
  );
};

const TERMLIST = [
  { id: 0, value: '', label: '이전 약관 보기' },
  { id: 1, value: '240101', label: '약관-2024년01월01일' },
  { id: 2, value: '230922', label: '약관-2023년09월22일' },
  { id: 3, value: '210521', label: '약관-2021년05월21일' },
];

export default Terms;
