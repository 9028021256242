import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_footer_type, set_header_type, stored_service } from '../../redux/common/action';
import { Helmet } from 'react-helmet';

const BearuLab = (props) => {
  const dispatch = useDispatch();

  const CommonStore = useSelector((state) => state.CommonStore);

  useEffect(() => {
    dispatch(set_footer_type(0));
    dispatch(
      set_header_type(0, 2, {
        title: '베어유 실험실',
        no_footer: true,
        no_top_btn: true,
        no_back_btn: true,
      }),
    );
    props.ready();
  }, []);

  const onV2Player = (e) => {
    dispatch(
      stored_service('v2_player', { is_enabled: e.target.checked, update_date: new Date() }),
    );
  };

  return (
    <div className='info-container' style={{ minHeight: '500px' }}>
      <Helmet>
        <title>베어유 실험실</title>
      </Helmet>
      <p className='info-title'>베어유 실험실</p>
      <div className='info-wrapper'>
        <p className='info-hint info-box'>
          아래 설정은 실험실 기능으로, 정식 지원 기능이 아닙니다.
          <br />
          <span>(추후 정식 서비스에 업데이트 될 수 있습니다.)</span>
          <br />
          <br />
          실험실 기능을 설정한 경우 <b>최대 15일간 해당 기능을 사용</b>합니다.
        </p>
        <p className='title'>
          <span>+</span> V2b 베어유 플레이어 사용
        </p>
        <p className='info'>
          <input
            type='checkbox'
            style={{ appearance: 'checkbox' }}
            onClick={onV2Player}
            defaultChecked={CommonStore.service.v2_player.is_enabled}
          />{' '}
          가능한 경우 V2b 버전의 플레이어를 사용 (강의실에서 새로고침 후 반영됩니다.)
        </p>
      </div>
    </div>
  );
};

export default BearuLab;
