import axios from 'axios';

class RecruitFormModel {
  async getCurrentRecruitingBgd() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/bgd/recruit-form/bgd`,
    );

    return data;
  }
  async getCurrentRecruitingBgdX() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/bgd/recruit-form/bgdx`,
    );
    return data;
  }

  async postRecruitingBgd(bgd_recruit_form_id, account_id, phone, email, answers) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/bgd/recruit-form/${bgd_recruit_form_id}/submit`,
      {
        account_id,
        phone,
        email,
        answers,
      },
    );
    return data;
  }

	async postRecruitingBgd(bgd_recruit_form_id,account_id,phone,email,answers) {
		const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/bgd/recruit-form/${bgd_recruit_form_id}/submit`,{
			account_id,
			phone,
			email,
			answers
		});
		return data;
	}

	async getClasses({ eventCode }) {
		const {
			data: {
				data: {
					event: { course_set },
				},
			},
		} = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/bgd/event/${eventCode}`);
		return course_set;
	}

  async getClasses({ eventCode }) {
    const {
      data: {
        data: {
          event: { course_set },
        },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/bgd/event/${eventCode}`);
    return course_set;
  }

  async getEventInfo({ eventCode }) {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/event/time/${eventCode}`);
    return data;
  }
}

export default new RecruitFormModel();
