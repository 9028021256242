import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

const propTypes = {
    notification: PropTypes.object
};

const NoticeContentsModal = (props) => {
    //Dialog Context
    const dialog_utils = props.dialog_utils || {};
    const dialog_props = props.dialog_props || {};

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    return (
        <div className="td-modal">
            <div className="td-dimmed"
                 onClick={onCloseModalBtn} />
            <div className="modal-content">
                <div className="modal-section simple-section">
                    <h3>{props.notification?.notice_category_id === 6 ? "쿠폰" : "알림"}</h3>
                    <div
                        className="modal-close-btn"
                        style={{background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`}}
                        onClick={onCloseModalBtn}
                    />
                    <div className="modal-section-wrap">
                        <h4>{props.notification?.title}</h4>
                        <p dangerouslySetInnerHTML={props.notification?.content ? {__html: props.notification?.content} : null} />
                    </div>
                </div>
            </div>
        </div>
    );
};

NoticeContentsModal.propTypes = propTypes;

export default withRouter(NoticeContentsModal);
