import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import CourseModel from "../../../model/CourseModel";
import {useDialogContext} from "../dialog/DialogContextProvider";
import moment from "moment";
import {useSelector} from "react-redux";
import QnaReply from "./QnaReply";
import PropTypes from "prop-types";
import {ProfileImage, resetSizeTextarea} from "../section/QnaContainer";
import {useMediaQuery} from "react-responsive";
import {ableSelectAll} from "../section/QnaContainer";
import LoadingSpinner from "./LoadingSpinner";
import {moveRefToTop} from "../../course/CourseDetail";

const propTypes = {
    qna: PropTypes.object,
    key: PropTypes.number,
    user_nickname: PropTypes.string,
    visible_edit_buttons: PropTypes.bool,
    resetSwiperHeight: PropTypes.func,
    onSecChange: PropTypes.func,
    getPlayedTime: PropTypes.func,
};

const Qna = (props) => {
    //댓글 관련 useState
    const [stated_qna, setStatedQna] = useState(props.qna);
    const [edit_mode, setEditMode] = useState(false);

    //대댓글 관련 useState
    const [reply_list, setReplyList] = useState(null);
    const [write_reply_mode, setWriteReplyMode] = useState(false);
    const [is_opened_reply_area, setIsOpenedReplyArea] = useState(false);
    const [just_wrote_reply_list, setJustWroteReplyList] = useState([]);
    const [visible_more_button, setVisibleMoreButton] = useState(true);
    const [is_request_loading, setIsRequestLoading] = useState(false);

    // dom ref
    const qna_textarea_ref = useRef(null);
    const write_reply_textarea_ref = useRef(null);
    const reply_list_ref = useRef(null);
    const qna_ref = useRef(null);
    const qna_text_ref = useRef(null);

    // ref
    const writing_reply_content = useRef("");
    const is_posting_reply = useRef(false);

    const is_tablet_or_mobile = useMediaQuery({query: "(max-width: 765px)"});
    const AccountStore = useSelector((state) => state.AccountStore);
    const {confirm, alert} = useDialogContext();

    let params = new URLSearchParams(props.location.search);
    let q_subject_id = params.get("subject_id");

    useEffect(() => {
        if (props.resetSwiperHeight && is_tablet_or_mobile) {
            props.resetSwiperHeight();
        }
    }, [qna_ref.current?.scrollHeight, reply_list?.length, write_reply_mode]);

    const deleteQna = async () => {
        let {code} = await CourseModel.courseQNADelete({qna_id: stated_qna.id});

        if (code === 200) {
            setStatedQna({...stated_qna, content: "삭제한 댓글입니다.", reply_status_id: 3});
            setJustWroteReplyList([]);
        } else {
            alert("댓글 삭제 실패(네트워크 오류)");
        }
    };

    const modifyQna = async () => {
        let {code, qna} = await CourseModel.courseQNAModify({
            qna_id: stated_qna.id,
            content: qna_textarea_ref.current.value.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
        });

        if (code === 200) {
            is_posting_reply.current = false;

            setStatedQna({...qna, comment_count: stated_qna.comment_count});
            setEditMode(false);
        } else {
            alert("댓글 수정 실패(네트워크 오류)");
        }
    };

    const requestNextQnaReplyList = async (is_reset = false) => {
        const page_size = 10;

        let last_visible_id = reply_list === null ? null : reply_list[reply_list?.length - 1].id;

        let {code, qna_set} = await CourseModel.courseQNAList({
            parent_id: stated_qna.id,
            size: page_size,
            last_visible_id: last_visible_id,
        });

        if (code === 200) {
            let new_qna_list = is_reset ? [] : reply_list;

            if (is_reset) {
                setIsOpenedReplyArea(true);

                // 방금 쓴 댓글 있을 시, qna_set에서 중복 제거
                if (just_wrote_reply_list.length > 0) {
                    let already_has_reply_id = just_wrote_reply_list.map((reply) => reply.id);
                    qna_set = qna_set.filter((qna) => !already_has_reply_id.includes(qna.id));
                }
            }

            setVisibleMoreButton(qna_set.length >= page_size);
            setReplyList([...new_qna_list, ...qna_set]);
            setIsRequestLoading(false);
        } else {
            alert("대댓글 불러오기에 실패했습니다 ㅠㅠ");
        }
    };

    const writeQnaReply = async () => {
        let {code, qna} = await CourseModel.courseQNAWrite({
            course_id: stated_qna.course_id,
            parent_id: stated_qna.id,
            content: writing_reply_content.current.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
        });

        if (code === 200) {
            is_posting_reply.current = false;

            setStatedQna({...stated_qna, comment_count: stated_qna.comment_count + 1});
            setJustWroteReplyList([qna, ...just_wrote_reply_list]);
            setWriteReplyMode(false);
        } else {
            alert("대댓글 작성 실패(네트워크 오류)");
        }
    };

    const createQnaReply = (reply) => {
        let user_nickname = reply.account_nickname;
        if (!reply.account_nickname) {
            user_nickname = "익명의 수강생";
        }

        if (reply.account_type_id === 5) {
            user_nickname = "관리자";
        }

        return (
            <QnaReply
                reply={reply}
                key={reply.id}
                user_nickname={user_nickname}
                visible_edit_buttons={
                    AccountStore !== null && //로그인한 사람만
                    reply.reply_status_id < 3 && // 삭제되지 않은 댓글만
                    (AccountStore.id === reply.account_id || AccountStore.account_type_id === 5) //작성자, 관리자만
                }
            />
        );
    };

    const createMoveToQuestionTimeBtn = () => {
        if (stated_qna.index_name && stated_qna.question_video_second && stated_qna.reply_status_id < 3) {
            return (
                <div
                    className={`question-time-wrap clickable ${!is_tablet_or_mobile ? "pc" : ""}`}
                    onClick={async () => {
                        // props.is_mine !== undefined   => 상세페이지일 때
                        if (props.is_mine !== undefined) {
                            if (AccountStore !== null && props.is_mine) {
                                if (await confirm("해당 강의로 이동하시겠어요?")) {
                                    props.history.push(
                                        `/course/${stated_qna.course_id}/viewer?subject_id=${stated_qna.subject_id}&question_time=${stated_qna.question_video_second}`
                                    );
                                }
                            } else if (!AccountStore) {
                                if (await confirm(`로그인이 필요한 서비스입니다.\n로그인 하시겠어요?`)) {
                                    props.history.push(`/user/signin`);
                                }
                            } else if (props.is_mine === false) {
                                alert("클래스 구매 시 이용 가능합니다 :)");
                            } else {
                                props.history.push(`/`);
                            }
                        } else {
                            // 뷰어 페이지 일 때

                            if (await confirm("해당 강의로 이동하시겠어요?")) {
                                props.history.push(
                                    `/course/${stated_qna.course_id}/viewer?subject_id=${
                                        stated_qna.subject_id
                                    }&question_time=${
                                        stated_qna.question_video_second
                                    }&prev_subject=${q_subject_id}&prev_cur_time=${props.getPlayedTime() || 0}`
                                );
                            }
                        }
                    }}>
                    <div className="question-time-info completed">
                        <h5>{stated_qna.index_name}</h5>
                        <p>{`0${Math.floor(stated_qna.question_video_second / 60)}`.slice(-2)}</p>:
                        <p>{`0${stated_qna.question_video_second % 60}`.slice(-2)}</p>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="content qna" ref={qna_ref}>
            <div className="pc-profile">
                <ProfileImage img_src={stated_qna.account_profile_img_url} auth_check={props.qna} />
            </div>

            <div className="main-column-wrap">
                <div className="profile-info-row">
                    {is_tablet_or_mobile ? (
                        <div className="mobile-profile">
                            <ProfileImage img_src={stated_qna.account_profile_img_url} auth_check={props.qna} />
                            <h4>{props.user_nickname}</h4>
                            {createMoveToQuestionTimeBtn()}
                        </div>
                    ) : (
                        <h4>{props.user_nickname}</h4>
                    )}

                    {props.visible_edit_buttons ? (
                        <span className="modify-wrap">
                            {edit_mode ? (
                                <button
                                    onClick={() => {
                                        setEditMode(false);
                                    }}>
                                    <img
                                        className="close-btn-img"
                                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
                                        alt="닫기 버튼"
                                    />
                                </button>
                            ) : (
                                <>
                                    <button
                                        onClick={() => {
                                            setEditMode(true);
                                            setTimeout(() => {
                                                resetSizeTextarea(qna_textarea_ref);
                                                qna_textarea_ref.current.focus();
                                            }, 50);
                                        }}>
                                        수정
                                    </button>
                                    <button
                                        onClick={async () => {
                                            if (await confirm("정말로 삭제 할까요?")) {
                                                await deleteQna(stated_qna, setStatedQna);
                                            }
                                        }}>
                                        삭제
                                    </button>
                                </>
                            )}
                        </span>
                    ) : null}
                </div>

                {!is_tablet_or_mobile ? <>{createMoveToQuestionTimeBtn()}</> : null}

                {edit_mode ? (
                    <div className="textarea-wrap edit-mode" ref={qna_text_ref}>
                        <textarea
                            placeholder="내용을 입력하세요."
                            maxLength={300}
                            onKeyDown={(e) => ableSelectAll(e)}
                            ref={qna_textarea_ref}
                            defaultValue={stated_qna.content}
                            onFocus={() => {
                                if (is_tablet_or_mobile) {
                                    moveRefToTop(qna_textarea_ref, props.is_viewer_ver ? 400 : 0);
                                }
                            }}
                            onChange={() => {
                                resetSizeTextarea(qna_textarea_ref);
                            }}
                        />
                        <button
                            className="submit-save-btn"
                            onClick={async () => {
                                if (!qna_textarea_ref.current.value) {
                                    alert("질문을 작성해주세요");
                                } else if (!is_posting_reply.current) {
                                    is_posting_reply.current = true;

                                    await modifyQna();
                                }
                            }}>
                            <p>저장</p>
                        </button>
                    </div>
                ) : (
                    <p className={stated_qna.reply_status_id > 2 ? "deleted" : ""} ref={qna_text_ref}>
                        {stated_qna.content}
                    </p>
                )}
                <div className="bottom-row">
                    {stated_qna.reply_status_id < 3 ? (
                        <>
                            {stated_qna.comment_count > 0 ? (
                                <span
                                    onClick={async () => {
                                        if (is_opened_reply_area) {
                                            setIsOpenedReplyArea(false);
                                            setJustWroteReplyList([]);
                                            setReplyList(null);
                                        } else if (!is_request_loading) {
                                            setIsRequestLoading(true);
                                            await requestNextQnaReplyList(true);
                                        }

                                        if (props.is_viewer_ver && props.resetSwiperHeight) {
                                            props.resetSwiperHeight();
                                        }
                                    }}>
                                    <div>답글 {stated_qna.comment_count}개</div>

                                    <img
                                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_arrow_${
                                            is_opened_reply_area ? "up" : "down"
                                        }.png`}
                                        alt="답글 펼치기 아이콘"
                                    />
                                </span>
                            ) : null}
                            {AccountStore !== null ? (
                                <span
                                    onClick={() => {
                                        setWriteReplyMode(true);
                                        // 답글 달기 클릭 시 textarea로 이동
                                        // (ref 없을 때 대비해 setTimeout)
                                        setTimeout(() => {
                                            write_reply_textarea_ref?.current.focus();
                                        }, 50);
                                    }}>
                                    답글달기
                                </span>
                            ) : null}
                        </>
                    ) : null}

                    <span className={"reply-date-info"}>
                        {`${moment(stated_qna.update_time ? stated_qna.update_time : stated_qna.create_time).format(
                            "YYYY.MM.DD"
                        )} ${stated_qna.update_time ? "수정됨" : ""}`}
                    </span>
                </div>

                {/*대댓글 달기 start*/}
                {write_reply_mode ? (
                    <div className="content reply" ref={reply_list_ref}>
                        <img
                            className="reply-icon"
                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_reply.png`}
                            alt="댓글 아이콘"
                        />
                        <div className="main-column-wrap">
                            <div className="profile-info-row">
                                <span>
                                    <ProfileImage
                                        img_src={AccountStore?.info?.picture_thumbnail_image_url}
                                        auth_check={AccountStore}
                                    />
                                    <h4>
                                        {AccountStore?.account_type_id === 5 ? "관리자" : (AccountStore?.info?.nickname || "익명의 수강생")}
                                    </h4>
                                </span>
                                <button
                                    onClick={() => {
                                        setWriteReplyMode(false);
                                    }}>
                                    <img
                                        className="close-btn-img"
                                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
                                        alt="닫기 버튼"
                                    />
                                </button>
                            </div>
                            <div className="textarea-wrap">
                                <textarea
                                    className="new_reply_textarea"
                                    placeholder="내용을 입력하세요."
                                    maxLength={300}
                                    ref={write_reply_textarea_ref}
                                    onKeyDown={(e) => ableSelectAll(e)}
                                    onFocus={() => {
                                        if (is_tablet_or_mobile) {
                                            moveRefToTop(
                                                qna_text_ref,
                                                props.is_viewer_ver
                                                    ? qna_text_ref.current.offsetHeight + 400
                                                    : qna_text_ref.current.offsetHeight - 100
                                            );
                                        }
                                    }}
                                    onChange={(e) => {
                                        writing_reply_content.current = e.target.value;
                                        resetSizeTextarea(write_reply_textarea_ref);
                                    }}
                                />
                                <button
                                    className="submit-save-btn"
                                    onClick={async () => {
                                        if (!writing_reply_content.current) {
                                            alert("댓글을 입력해주세요");
                                        } else if (!is_posting_reply.current) {
                                            is_posting_reply.current = true;

                                            await writeQnaReply();
                                        }
                                    }}>
                                    <p>등록</p>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
                {/*대댓글 달기 end*/}

                {/*방금 쓴 대댓글(상단 표시 대댓글)*/}
                {just_wrote_reply_list.map((just_wrote_reply) => {
                    return createQnaReply(just_wrote_reply);
                })}

                {/*댓글 열기, 접기*/}
                {is_opened_reply_area
                    ? reply_list?.map((reply) => {
                          return createQnaReply(reply);
                      })
                    : null}
                {is_opened_reply_area && visible_more_button ? (
                    <div className="more-reply-button">
                        <img
                            className="reply-icon"
                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_reply.png`}
                            alt="댓글 아이콘"
                        />
                        <button
                            onClick={async () => {
                                if (!is_request_loading) {
                                    setIsRequestLoading(true);
                                    await requestNextQnaReplyList();
                                }
                            }}>
                            답글 더보기
                        </button>
                        {is_request_loading ? <LoadingSpinner /> : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

Qna.propTypes = propTypes;

export default withRouter(Qna);
