import React, { useEffect, useState } from 'react';
import { alert_and_redirect, replace_cloudfront_url } from '../../common';
import '../../assets/scss/page/time_event.scss';
import EventModel from '../../model/EventModel';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval, useObserver } from '../../util/BearuHooks';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import update from 'react-addons-update';
import { Link } from 'react-router-dom';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import { useMount } from '../../util/BearuHooks';
import PackageCard2 from '../_common/component/PackageCard2';
import CourseCard2 from '../_common/component/CourseCard2';
import { category_info_set } from '../../constant/category';

const TimeEventForHardStudy = (props) => {
  const dispatch = useDispatch();
  const { alert } = useDialogContext();
  const [isMount] = useMount();
  const AccountStore = useSelector((state) => state.AccountStore);

  const [course_list, setCourseList] = useState({});
  const [package_list, setPackageList] = useState({});
  const [event, setEvent] = useState(null);
  const [expire_time, setExpireTime] = useState(null);
  const [is_expire, setIsExpire] = useState(null);
  const [is_sticky, setIsSticky] = useState(false);
  const is_tablet = useMediaQuery({ query: '(max-width: 768px)' });

  const header_nav_control_ref = useObserver(
    {},
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: false,
        }),
      );
    },
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: true,
        }),
      );
    },
    [],
  );

  useInterval(
    () => {
      let today = moment();
      if (event && !is_expire) {
        let tmp_expire_time = moment(event.expire_time).diff(today);
        let day = Math.floor(moment.duration(tmp_expire_time).asDays());
        let hour =
          moment.duration(tmp_expire_time).hours() < 10
            ? '0' + moment.duration(tmp_expire_time).hours()
            : moment.duration(tmp_expire_time).hours();
        let minute =
          moment.duration(tmp_expire_time).minutes() < 10
            ? '0' + moment.duration(tmp_expire_time).minutes()
            : moment.duration(tmp_expire_time).minutes();
        let second =
          moment.duration(tmp_expire_time).seconds() < 10
            ? '0' + moment.duration(tmp_expire_time).seconds()
            : moment.duration(tmp_expire_time).seconds();

        if (day <= 0 && hour <= 0 && minute <= 0 && second <= 0) {
          setExpireTime(null);
          setIsExpire(true);
        } else {
          setExpireTime({ day, hour, minute, second });
        }
      }
    },
    !is_expire && event ? 1000 : null,
  );

  useEffect(() => {
    props.ready();
    dispatch(set_header_type(3, null, null));
    dispatch(set_footer_type(0));

    if (isMount()) {
      if (props.match.params?.id) {
        EventModel.eventTime({ event_id: props.match.params.id }).then(
          ({ code, event, is_expire, course_replace_sale_info }) => {
            if (code === 200) {
              if (event.is_trial_version && AccountStore?.course_count) {
                alert_and_redirect(
                  alert,
                  props.history,
                  '최초 강의 보유자에게만 제공되는 이벤트입니다.',
                );
                return;
              } else {
                // 대체 판매항목 있을 경우 대체시키기
                if (course_replace_sale_info.length > 0) {
                  if (event.course_set.length > 0) {
                    event.course_set.forEach((course, index) => {
                      course_replace_sale_info.forEach((sale_info) => {
                        if (sale_info.course_id === course.id) {
                          event.course_set[index].min_price = sale_info.price;
                          event.course_set[index].original_price = sale_info.original_price;
                          event.course_set[index].rental_time_in_day = sale_info.rental_time_in_day;
                        }
                      });
                    });
                  }
                }

                // new_package
                let new_package_list = [];
                event.product_set.forEach((_product, _index) => {
                  new_package_list.push({
                    data: _product,
                  });
                });

                // new_course
                let new_course_list = [];
                const category_match_data = {};

                event.course_set.forEach((_course, _index) => {
                  new_course_list.push({
                    data: _course,
                    ...category_info_set[_course.id],
                  });
                });

                category_name_set.forEach((name) => {
                  category_match_data[name] = [];
                  new_course_list.forEach((list) => {
                    if (list.category_name === name) {
                      return category_match_data[name].push(list.data);
                    }
                  });
                });

                setCourseList(category_match_data);
                setPackageList(new_package_list);
                setEvent(event);
                setIsExpire(is_expire);
              }
            } else {
              setIsExpire(true);
            }
          },
        );
      } else {
        alert_and_redirect(alert, props.history, '잘못된 접근입니다.');
      }
    }
  }, []);

  const swiper_option = {
    slidesPerView: 'auto',
    breakpoints: {
      961: {
        spaceBetween: 0,
      },
      320: {
        spaceBetween: 16,
      },
    },
  };

  return (
    <>
      {is_sticky && (
        <div className={'time-event-nav' + (is_sticky ? ' sticky' : '')}>
          <div className='td-wrapper'>
            <Link to='/'>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-home-icon-white.png`}
                alt='홈으로 가기'
              />
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/bearu-logo-white.svg`}
                alt='베어유 로고'
              />
            </Link>
          </div>
        </div>
      )}

      <div className='time-event-container hard-study'>
        <Helmet>
          <title>{event?.title}</title>
        </Helmet>

        <div className='time-event-top-header'>
          {event?.main_image_url === null ? (
            <div className='making-banner-image default' />
          ) : (
            <div
              className='making-banner-image'
              style={{
                background:
                  'url(' +
                  replace_cloudfront_url(event?.main_image_url) +
                  ') no-repeat center top/cover',
              }}
            />
          )}
          <div className='show-up-anim'>
            <div className={'time-box-wrap animate__animated animate__jello'}>
              <div className='box-front' />
              <div className='time-event-count-wrap'>
                <h3 className='count-down'>
                  {expire_time && (
                    <>
                      <span className='day'>{expire_time?.day}</span>
                      <span className='day-text'>일</span>
                      <span className='time'>{expire_time?.hour}</span>
                      <span className='time-colon'>:</span>
                      <span className='minute'>{expire_time?.minute}</span>
                      <span className='time-colon'>:</span>
                      <span className='second'>{expire_time?.second}</span>
                    </>
                  )}
                  {is_expire && (
                    <span className='event-finished-text'>
                      이벤트가 종료 되었습니다. {is_tablet ? <br /> : ''}다음 <b>특가</b>를
                      기대해주세요!
                    </span>
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: '100%', height: '1px' }} ref={header_nav_control_ref} />

        <div className='td-wrapper'>
          <div className='time-event-list hard-study'>
            <div className='time-event-category-name'>
              <p>오직 빡공단만을 위한 특별혜택</p>
              <span>49,000원 참가비</span>로 수강할 수 있어요
            </div>

            <div className='overview-course-list'>
              {/* course */}
              {event &&
                Object.entries(course_list).map((data, index) => {
                  if (data[1].length === 0) {
                    return null;
                  } else {
                    return (
                      <div key={index}>
                        <h3 className='content-title curation'>{data[0]}</h3>
                        <div className='course-list overview-list'>
                          {data[1].map((list, index) => {
                            return (
                              <CourseCard2
                                course={list}
                                key={index}
                                hide_bookmark={false}
                                redirect_to_url={`/course/${list.id}?event_code=${props.match.params.id}`}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}

              {/* package */}
              {event && package_list.length > 0 && (
                <>
                  <h3 className='content-title curation'>패키지</h3>
                  <div className='course-list overview-list'>
                    {package_list.map((list, index) => {
                      return (
                        <PackageCard2
                          product={list.data}
                          key={index}
                          hide_bookmark={false}
                          redirect_to_url={`/package/${list.data.id}?event_code=${props.match.params.id}`}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className='learning-reason-wrap'>
          <div className='td-wrapper'>
            <h3>왜 베어유에서 배워야 할까?</h3>
            <ul>
              <Swiper {...swiper_option}>
                {learning_reason_set.map((item, index) => {
                  let item_desc;
                  if (index === 0) {
                    item_desc = (
                      <p>
                        베어유는 서비스 12개월 만에
                        <br />
                        <b>누적 재생 시간 1,100,000시간</b>을<br />
                        달성하였습니다.
                      </p>
                    );
                  } else if (index === 1) {
                    item_desc = (
                      <p>
                        베어유는 <b>누군가의 성장을</b>
                        <br />
                        <b>이끄는 힘</b>을 만드는 온라인
                        <br />
                        클래스 서비스입니다.
                      </p>
                    );
                  } else if (index === 2) {
                    item_desc = (
                      <p>
                        베어유는 최상의 교육 콘텐츠를
                        <br />
                        고민하여<b> 핵심 위주로 구성된</b>
                        <br />
                        <b>프리미엄 콘텐츠</b>를 제공합니다.
                      </p>
                    );
                  } else if (index === 3) {
                    item_desc = (
                      <p>
                        누구에게나 <b>쉽고 친절한</b> 강의
                        <br />
                        <b>대상과 컨셉 목표가 명확한</b> 강의
                        <br />
                        <b>자기주도 학습이 가능한</b> 강의
                      </p>
                    );
                  }
                  return (
                    <SwiperSlide key={index}>
                      <li>
                        <div className='icon-wrap'>
                          <img src={item.img_url} alt={item.title} />
                        </div>
                        <h4>{item.title}</h4>
                        <h3>
                          {item.value[0]}
                          <span>{item.value[1]}</span>
                        </h3>
                        <div className='hash-tag-wrap'>
                          <div>{item.tag_set[0]}</div>
                          <div>{item.tag_set[1]}</div>
                        </div>
                        {item_desc}
                      </li>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

// data_set
const category_name_set = [
  '디자인 뽀개기',
  '취업문 뽀개기',
  '창업문 뽀개기',
  '주식 뽀개기',
  '어학점수 뽀개기',
  '코딩 뽀개기',
  '마케팅 뽀개기',
  '데이터 뽀개기',
];

const learning_reason_set = [
  {
    img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/account-supervisor-icon.png`,
    title: '누적 재생시간',
    value: ['1,100,000', '시간'],
    tag_set: ['#12개월만에', '#빡공데이터'],
  },
  {
    img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/sale-icon.png`,
    title: '재구매율',
    value: ['32', '%'],
    tag_set: ['#업계최고', '#검증된클래스'],
  },
  {
    img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/card-account-details-star-icon.png`,
    title: '강의 만족도',
    value: ['95', '%'],
    tag_set: ['#새로운1위', '#클래스는베어유'],
  },
  {
    img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/margin-icon.png`,
    title: '강의 완강률',
    value: ['37', '%'],
    tag_set: ['#업계최고', '#완강률'],
  },
];

export default TimeEventForHardStudy;
