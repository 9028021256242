import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import update from 'react-addons-update';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { alert_and_redirect, replace_cloudfront_url } from '../../common';
import EventModel from '../../model/EventModel';
import '../../assets/scss/page/time_event.scss';
import TimeCourseCard from '../_common/component/TimeCourseCard';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useObserver } from '../../util/BearuHooks';
import clock_lottie from '../../assets/lottie/clock.json';
import TimePackageCard from '../_common/component/TimePackageCard';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import CountBanner from '../_common/component/CountBanner';

const ITEM_TYPE_PRODUCT = 'product';
const ITEM_TYPE_COURSE = 'course';

const TimeEvent = (props) => {
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const { alert } = useDialogContext();

  const [event, setEvent] = useState(null);
  const [item_list, setItemList] = useState([]);
  const [is_expire, setIsExpire] = useState(null);
  const [is_start, setIsStart] = useState(null);
  const [is_sticky, setIsSticky] = useState(false);

  const _is_mount = useRef(false);
  const is_mobile = useMediaQuery({ query: '(max-width: 480px)' });

  const header_nav_control_ref = useObserver(
    {},
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: false,
        }),
      );
    },
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: true,
        }),
      );
    },
    [],
  );

  const learning_reason_set = [
    {
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/account-supervisor-icon.png`,
      title: '누적 재생시간',
      value: ['1,100,000', '시간'],
      tag_set: ['#12개월만에', '#빡공데이터'],
    },
    {
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/sale-icon.png`,
      title: '재구매율',
      value: ['32', '%'],
      tag_set: ['#업계최고', '#검증된클래스'],
    },
    {
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/card-account-details-star-icon.png`,
      title: '강의 만족도',
      value: ['95', '%'],
      tag_set: ['#새로운1위', '#클래스는베어유'],
    },
    {
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/event/margin-icon.png`,
      title: '강의 완강률',
      value: ['37', '%'],
      tag_set: ['#업계최고', '#완강률'],
    },
  ];

  useEffect(() => {
    props.ready();
    _is_mount.current = true;

    // header, footer
    dispatch(set_header_type(3, null, null));
    dispatch(set_footer_type(0));

    if (props.match.params?.id) {
      EventModel.eventTime({ event_id: props.match.params.id }).then(
        ({ code, event, is_expire, is_start, course_replace_sale_info }) => {
          if (_is_mount.current) {
            if (code === 200) {
              if (event.is_trial_version && AccountStore?.course_count) {
                alert_and_redirect(
                  alert,
                  props.history,
                  '최초 강의 보유자에게만 제공되는 이벤트입니다.',
                );
                return;
              } else {
                // 대체 판매항목 있을 경우 대체시키기
                if (course_replace_sale_info.length > 0) {
                  if (event.course_set.length > 0) {
                    event.course_set.forEach((course, index) => {
                      course_replace_sale_info.forEach((sale_info) => {
                        if (sale_info.course_id === course.id) {
                          event.course_set[index].min_price = sale_info.price;
                          event.course_set[index].original_price = sale_info.original_price;
                          event.course_set[index].rental_time_in_day = sale_info.rental_time_in_day;
                        }
                      });
                    });
                  }
                }

                let new_item_list = [];
                event.product_set.forEach((_product, _index) => {
                  new_item_list.push({
                    type: ITEM_TYPE_PRODUCT,
                    data: _product,
                  });
                });
                event.course_set.forEach((_course, _index) => {
                  new_item_list.push({
                    type: ITEM_TYPE_COURSE,
                    data: _course,
                  });
                });

                setItemList(new_item_list);
                setEvent(event);
                setIsStart(is_start);
                setIsExpire(is_expire);
              }
            } else if (code === 4803) {
              alert_and_redirect(alert, props.history, '이미 종료된 이벤트입니다 😥');
            } else {
              setIsExpire(true);
            }
          }
        },
      );
    } else {
      alert_and_redirect(alert, props.history, '잘못된 접근입니다.');
    }

    return () => {
      _is_mount.current = false;
    };
  }, []);

  const lottie_options = {
    loop: true,
    autoplay: true,
    animationData: clock_lottie,
    rendererSettings: {
      className: 'clock-anim',
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const swiper_option = {
    slidesPerView: 'auto',
    breakpoints: {
      961: {
        spaceBetween: 0,
      },
      320: {
        spaceBetween: 16,
      },
    },
  };

  return (
    <>
      {is_sticky && (
        <div className={'time-event-nav' + (is_sticky ? ' sticky' : '')}>
          <div className='td-wrapper'>
            <Link to='/'>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-home-icon-white.png`}
                alt='홈으로 가기'
              />
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/bearu-logo-white.svg`}
                alt='베어유 로고'
              />
            </Link>
          </div>
        </div>
      )}

      <div className='time-event-container'>
        <Helmet>
          <title>{event?.title}</title>
        </Helmet>

        <div className='time-event-top-header'>
          {event?.main_image_url === null ? (
            <>
              <div className='td-wrapper'>
                <img
                  className='time-event-logo animate__animated animate__jackInTheBox'
                  src={`${process.env.REACT_APP_IMG_URL}/static/svg/event/img_timesale.svg`}
                  alt='타임특가'
                />
              </div>
              <div className='animation-wrap'>
                <Lottie options={lottie_options} />
                <div
                  className='default-background-image'
                  style={{
                    background: is_mobile
                      ? `url(${process.env.REACT_APP_IMG_URL}/static/png/event/layer-6.png) no-repeat center center/cover`
                      : `url(${process.env.REACT_APP_IMG_URL}/static/png/event/img_time.png) no-repeat center center/cover`,
                  }}
                />
              </div>
            </>
          ) : (
            <div
              className='making-banner-image'
              style={{
                background:
                  'url(' +
                  replace_cloudfront_url(event?.main_image_url) +
                  ') no-repeat center top/cover',
              }}
            />
          )}
        </div>

        <div style={{ width: '100%', height: '1px' }} ref={header_nav_control_ref} />

        <div className='time-special-price td-wrapper'>
          <CountBanner
            is_start={is_start}
            is_expire={is_expire}
            start_time={event?.start_time}
            end_time={event?.expire_time}
          />
          <div className='time-event-list'>
            <div className='time-event-category-list'>
              <p className='time-event-category-name'>클래스를 둘러보세요</p>

              <div className={'course-list'}>
                {event &&
                  item_list.map((item, index) => {
                    if (item.type === ITEM_TYPE_PRODUCT) {
                      return (
                        <TimePackageCard
                          product={item.data}
                          key={index}
                          event_code={props.match.params.id}
                          discount_info={event.discount_info}
                          is_expire={is_expire}
                        />
                      );
                    } else if (item.type === ITEM_TYPE_COURSE) {
                      return (
                        <TimeCourseCard
                          course={item.data}
                          key={index}
                          event_code={props.match.params.id}
                          discount_info={event.discount_info}
                          is_expire={is_expire}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className='learning-reason-wrap'>
          <div className='td-wrapper'>
            <h3>왜 베어유에서 배워야 할까?</h3>
            <ul>
              <Swiper {...swiper_option}>
                {learning_reason_set.map((item, index) => {
                  let item_desc;
                  if (index === 0) {
                    item_desc = (
                      <p>
                        베어유는 서비스 12개월 만에
                        <br />
                        <b>누적 재생 시간 1,100,000시간</b>을<br />
                        달성하였습니다.
                      </p>
                    );
                  } else if (index === 1) {
                    item_desc = (
                      <p>
                        베어유는 <b>누군가의 성장을</b>
                        <br />
                        <b>이끄는 힘</b>을 만드는 온라인
                        <br />
                        클래스 서비스입니다.
                      </p>
                    );
                  } else if (index === 2) {
                    item_desc = (
                      <p>
                        베어유는 최상의 교육 콘텐츠를
                        <br />
                        고민하여<b> 핵심 위주로 구성된</b>
                        <br />
                        <b>프리미엄 콘텐츠</b>를 제공합니다.
                      </p>
                    );
                  } else if (index === 3) {
                    item_desc = (
                      <p>
                        누구에게나 <b>쉽고 친절한</b> 강의
                        <br />
                        <b>대상과 컨셉 목표가 명확한</b> 강의
                        <br />
                        <b>자기주도 학습이 가능한</b> 강의
                      </p>
                    );
                  }
                  return (
                    <SwiperSlide key={index}>
                      <li>
                        <div className='icon-wrap'>
                          <img src={item.img_url} alt={item.title} />
                        </div>
                        <h4>{item.title}</h4>
                        <h3>
                          {item.value[0]}
                          <span>{item.value[1]}</span>
                        </h3>
                        <div className='hash-tag-wrap'>
                          <div>{item.tag_set[0]}</div>
                          <div>{item.tag_set[1]}</div>
                        </div>
                        {item_desc}
                      </li>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeEvent;
