import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { get_past_day, set_favorite_icon } from '../../../common';
import BaseCard, { BADGE_TYPE_OPEN, BADGE_TYPE_BEST, BADGE_TYPE_NEW } from './BaseCard';
import CourseModel from '../../../model/CourseModel';
import { set_account_favorite_count } from '../../../redux/account/action';
import { useDialogContext } from '../dialog/DialogContextProvider';

const propTypes = {
  course: PropTypes.object,
};

const CourseCard2 = (props) => {
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const _favorite_pending = useRef(false);
  const { confirm } = useDialogContext();

  const course = props.course;

  const onBookmarkClick = async () => {
    if (AccountStore) {
      if (!_favorite_pending.current) {
        _favorite_pending.current = true;
        CourseModel.courseFavorite({
          course_id: course.id,
        }).then(({ is_favorite, favorite_count, account_class_favorite_count }) => {
          // @TODO 병곤 향후 add favorite item 느낌으로 작업해서, MyFavoriteList2 쪽에서 갱신하는 코드를 안넣을수도 있는 가정 있음 (id 중복체크 가능성
          dispatch(set_account_favorite_count(account_class_favorite_count));
          set_favorite_icon('course', course.id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      }
      // @TODO 봄 중복코드 추후에 업그레이드 with 혁님
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  // 1. 오픈예정 -> course_sale_status_id == 1
  // 2. best -> badge_type_name == 'BEST'
  // 3. new -> write_time < 60일
  const getBadge = (course) => {
    if (course.course_sale_status_id === 1) {
      return BADGE_TYPE_OPEN;
    } else if (course.badge_type_name === 'BEST') {
      return BADGE_TYPE_BEST;
    } else if (get_past_day(course.write_time) < 60) {
      return BADGE_TYPE_NEW;
    }
    return null;
  };

  return (
    <div
      className={`gtm-data course-id-${course.id} card-item-v2`}
      data-ai-pid={`c${course.id.toString().padStart(8, '0')}`}
      data-ai-pnm={course.title.replace(/\r\n/g, ' ')}
      data-ai-ppc={course.min_price}
      data-ai-ppb={course.brand_name}
      data-ai-pca={course.category_name}
    >
      <BaseCard
        card_badge={getBadge(course)}
        image_url={course.main_thumbnail_image_url}
        short_title={course.short_title}
        title={course.title}
        tag_set={course.tag_set}
        redirect_to_url={props.redirect_to_url || `/course/${course.id}`}
        on_bookmark_click={onBookmarkClick}
        is_bookmark={course.is_favorite}
        favorite_count={course.favorite_count}
        hide_bookmark={props.hide_bookmark}
        brand_name={course.brand_name}
        category_name={course.category_name}
      />
    </div>
  );
};

CourseCard2.propTypes = propTypes;

export default withRouter(CourseCard2);
