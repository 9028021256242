import React, {useState} from "react";
import TemplateInput from "../_template/TemplateInput";
import PropTypes from "prop-types";
import {add_dash_to_phone_number} from "../../../common";
import InputLabel from "./InputLabel";

const propTypes = {
    default_value: PropTypes.string, // 회원 수정 시 받아올 default value
    require: PropTypes.bool, // require 표시 여부
    input_list_ref: PropTypes.array, // focus event ref
    on_data_changed: PropTypes.func, // text, validated
};

const PhoneInput = (props) => {
    const [phone_input, setPhoneInput] = useState(props?.default_value ? props.default_value : "");
    const [phone_error_message, setPhoneErrorMessage] = useState("");
    const [is_phone_error, setIsPhoneError] = useState(false);
    const require = props?.require ?? true;

    // 유효성 검사
    const checkPhoneValidate = (text) => {
        const regex_phone = /^[0-9\b -]{0,13}$/;
        return regex_phone.test(text);
    };

    return (
        <div className="phone-input-wrap">
            <InputLabel label={"휴대전화"} require={require} />
            <TemplateInput
                is_error={is_phone_error}
                error_message={phone_error_message}
                on_validate={(text) => {
                    if (text === "") {
                        setIsPhoneError(true);
                        setPhoneErrorMessage("필수항목입니다");
                    } else if (text.length !== 13) {
                        setIsPhoneError(true);
                        setPhoneErrorMessage("입력값을 확인해주세요");
                    } else {
                        setIsPhoneError(false);
                        setPhoneErrorMessage("");
                    }
                }}
                input_props={{
                    value: phone_input,
                    placeholder: "010-0000-0000",
                    maxLength: 13,
                    ref: props?.input_list_ref,
                    onFocus: () => {
                        setIsPhoneError(false);
                        setPhoneErrorMessage("");
                    },
                    onBlur: (text) => {
                        let validated = checkPhoneValidate(text) && text.length === 13;
                        props.on_data_changed && props.on_data_changed(text, validated);
                    },
                    onChange: (text) => {
                        let validated = checkPhoneValidate(text) && text.length === 13;
                        if (checkPhoneValidate(text)) {
                            setPhoneInput(add_dash_to_phone_number(text).replace(/\s/gi, ""));
                        }
                        props.on_data_changed && props.on_data_changed(text, validated);
                    },
                }}
            />
        </div>
    );
};

PhoneInput.prototype = propTypes;

export default PhoneInput;
