import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import 'core-js/stable';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import { PersistGate } from 'redux-persist/integration/react';
import { isIE } from 'react-device-detect';

import { routes } from './route/route';
import configureStore from './store/index';
import './assets/scss/base.scss';
import './assets/scss/component/__interface.scss';
import Footer from './views/_common/section/Footer';
import Header from './views/_common/section/Header';
import InitAuthCheck from './util/InitAuthCheck';
import TopButton from './views/_common/component/TopButton';
import ScrollToTop from './util/ScrollToTop';
import BottomNav from './views/_common/section/BottomNav';
import IEError from './views/_common/IEError';
import { DialogContextProvider } from './views/_common/dialog/DialogContextProvider';
import { BroadcastContextProvider } from './views/_common/broadcast/BroadcastContextProvider';
import TopBanner from './views/_common/component/TopBanner';
import PopUpButton from './views/_common/component/PopUpButton';
import PopUpBanner from './views/_common/component/PopUpBanner';
import EventTracker from './EventTracker';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

const { store, persistor } = configureStore();

const PIXEL_ID = process.env.REACT_APP_PIXEL_ID;
ReactPixel.init(PIXEL_ID);
TagManager.initialize({ gtmId: process.env.REACT_APP_GMT_ID });

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {isIE ? (
          <IEError />
        ) : (
          <BrowserRouter>
            <EventTracker />
            <DialogContextProvider forward_history={false}>
              <BroadcastContextProvider>
                <InitAuthCheck />
                <TopButton />
                <PopUpButton />
                {/*<ChannelTalkButton />*/}
                <TopBanner />
                <div style={{ minHeight: '100vh', position: 'relative' }}>
                  <Header />
                  <PopUpBanner />
                  <ScrollToTop />
                  <Switch>
                    {routes.map(({ path, Component }) => (
                      <Route key={path} exact path={path} component={Component} />
                    ))}
                  </Switch>
                </div>
                <Footer />
                <BottomNav />
              </BroadcastContextProvider>
            </DialogContextProvider>
          </BrowserRouter>
        )}
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
