import React, { useRef, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import 'swiper/swiper.scss';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import update from 'react-addons-update';
import { useMediaQuery } from 'react-responsive';

import '../../assets/scss/page/main.scss';
import PopularList2 from '../_common/section/PopularList2';
import ReviewSlider from './section/ReviewSlider';
import PromotionVideo from './component/PromotionVideo';
import { useObserver } from '../../util/BearuHooks';
import NotificationModal from '../_common/dialog/NotificationModal';
import { dialog_type_custom, useDialogContext } from '../_common/dialog/DialogContextProvider';

const Main = (props) => {
  // main nav
  const [nav_sticky, setNavSticky] = useState(false);
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 767px)' });

  //알림 모달 용
  const { showDialog } = useDialogContext();
  const notification_info = useRef(null);

  const CommonStore = useSelector((state) => state.CommonStore);
  const header = CommonStore.header;

  // move to home
  useLayoutEffect(() => {
    if (window.location.pathname === '/') {
      props.history.replace('/overview/all');
    }
  }, []);

  const nav_ref = useObserver(
    {},
    () => {
      setNavSticky((nav_sticky) =>
        update(nav_sticky, {
          $set: false,
        }),
      );
    },
    () => {
      setNavSticky((nav_sticky) =>
        update(nav_sticky, {
          $set: true,
        }),
      );
    },
    [],
  );

  return (
    <div className='td-container-v2 main'>
      <Helmet>
        <title>베어유 : 시작이 막막할 때, 베어유에서 배워요</title>
      </Helmet>

      {/* main nav section */}
      {is_tablet_or_mobile && nav_sticky && (
        <>
          <div className='main-nav-wrap'>
            <div className='main-left-wrap'>
              <Link className='main-event-btn' to='/service/event' />
            </div>
            <h1 className=''>
              <Link to='/' />
            </h1>
            <div className='main-right-wrap'>
              <div
                className='main-noti-btn'
                id='main-noti-modal-btn'
                onClick={() => {
                  showDialog({
                    type: dialog_type_custom,
                    component: NotificationModal,
                    component_props: {
                      noti_count: CommonStore.tmp?.noti_count,
                      new_noti: CommonStore.tmp?.is_new_noti,
                      notification_info: notification_info.current,
                      setOnNotificationList: (new_notification_info) => {
                        notification_info.current = new_notification_info;
                      },
                    },
                  });
                }}
              >
                {CommonStore.tmp?.is_new_noti && <div className='main-new-notice' />}
              </div>
              {!header.header_set.no_search_btn && (
                <Link to='/search' className='main-search-btn' />
              )}
            </div>
          </div>
        </>
      )}

      {/* main video section */}
      <div className='main-video-wrap'>
        <div ref={nav_ref} />
        <div className='video-bg' />
        <PromotionVideo />
        <div className='td-wrapper-v2'>
          <div className='video-content'>
            <h2 className='pc-title'>
              하루 10분이면 끝. <br />
              쉽고 빠르게 제대로 <br />
              No.1 온라인클래스 베어유
            </h2>
            <Link to='/overview/all'>
              <button>내게 맞는 클래스 보러가기</button>
            </Link>
          </div>
        </div>
      </div>

      {/* top content section */}
      <div className='top-content-wrap td-wrapper-v2'>
        <ul className='top-content-section'>
          <li>
            <div className='img-wrap' />
            <div className='text-desc'>
              <h4>
                핵심만 모아서 <br />단 10분으로 끝!
              </h4>
              <h5>
                길고 지루한 온라인클래스는 그만! <br />
                베어유는 언제 어디서나 10분 안에 핵심만 간단하게.
              </h5>
            </div>
          </li>
          <li>
            <div className='img-wrap' />
            <div className='text-desc'>
              <h4>
                실전에 필요한 것만 <br />
                골라서 제대로!
              </h4>
              <h5>
                활용할 수 없었던 지루한 이론 강의가 아닌, <br />
                듣고 바로 써먹을 수 있는 실무 중심 실속 커리큘럼
              </h5>
            </div>
          </li>
          <li>
            <div className='img-wrap' />
            <div className='text-desc'>
              <h4>
                No.1 완강률, 만족도 <br />
                끝까지 함께하니까
              </h4>
              <h5>
                중간에 포기했던 돈 아까운 지난날은 안녕! <br />
                베어유의 독보적인 학습관리와 함께라면 누구나 끝까지.
              </h5>
            </div>
          </li>
        </ul>
      </div>

      {/* review section */}
      <div className='review-content-wrap'>
        <div className='td-wrapper-v2'>
          <ReviewSlider />
        </div>
      </div>

      {/* popular class section */}
      <div className='popular-class-wrap'>
        <div className='td-wrapper-v2'>
          <LazyLoad height={300} offset={50}>
            <PopularList2 list_type={0} with_product_set={true} promotion_type_id={2} />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default Main;
