import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import '../../assets/scss/page/ebook_detail.scss';
import SubBanner from '../_common/component/SubBanner';
import ReviewContainer from '../_common/section/ReviewContainer';
import { set_footer_type, set_header_type, set_tmp } from '../../redux/common/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import EbookModel from '../../model/EbookModel';
import LoadingSpinner from '../_common/component/LoadingSpinner';
import { init_comma } from '../../common';
import useEbookBookmark from '../../hook/useEbookBookmark';
import useEbookViewer from '../../hook/useEbookViewer';
import PixelModel from '../../model/PixelModel';

function EbookDetail() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [ebook, setEbook] = useState(null);
  const [event, setEvent] = useState(null);
  const { confirm, alert } = useDialogContext();
  const [openPurchaseForMobile, setOpenPurchaseForMobile] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { toggleEbookBookmark } = useEbookBookmark();
  const { openEbookViewer } = useEbookViewer();

  useEffect(() => {
    if (!ebook) return;
    dispatch(set_header_type(0, 2, { title: ebook?.title }));
    dispatch(set_footer_type(0));

    const IsEbookAuth = Cookies.get('ebookAuth');
    if (!IsEbookAuth && ebook.is_mine) {
      Cookies.set('ebookAuth', `${AccountStore?.id}`, {
        domain: '.bear-u.com',
        path: '/',
        expires: 3,
      });
    }

    PixelModel.postConversionAPI({
      eventName: 'ViewContent',
      AccountStore: AccountStore,
      customDataOptions: {
        content_name: ebook.title,
      },
    });
  }, [ebook]);

  useEffect(() => {
    if (isMobile && openPurchaseForMobile) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobile, openPurchaseForMobile]);

  const initBook = useCallback(async () => {
    if (!id) {
      alert('잘못된 접근입니다.');
      return;
    }

    setLoading(true);
    const bookResponse = await EbookModel.ebookDetail({ ebook_id: id });
    if (bookResponse.code !== 200) {
      alert('책을 불러오는데 실패했습니다.');
      setLoading(false);
      return;
    }

    ReactPixel.trackCustom('전자책 상세 페이지 조회', {
      ebook_title: bookResponse?.data?.ebook?.title,
    });

    setEbook(bookResponse?.data.ebook);
    setEvent(bookResponse?.data.event);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    initBook();
  }, [initBook]);

  const handleBookmarkClick = async () => {
    const result = await toggleEbookBookmark(ebook.id);
    if (result) {
      setEbook((prev) => ({
        ...prev,
        is_favorite: result.is_favorite,
        favorite_count: result.favorite_count,
      }));
    }
  };

  const onPurchase = async () => {
    if (isMobile) {
      if (!openPurchaseForMobile) {
        setOpenPurchaseForMobile(true);
        return;
      }
    }

    if (AccountStore) {
      if (!AccountStore.info?.name || !AccountStore.info?.email || !AccountStore.info?.phone) {
        if (await confirm('결제를 위해 개인정보를 입력해주세요.')) {
          history.push({
            pathname: '/user/info',
            state: {
              redirect_url: `${location.pathname}${location.search}`,
            },
          });
        }
      } else {
        const saleInfo = ebook.sale_info_set[0];
        if (!saleInfo) {
          alert('해당 책은 판매중인 상품이 아닙니다.');
          return;
        }
        dispatch(
          set_tmp('purchase', {
            purchase_type: 'ebook',
            title: ebook.title,
            can_coupon: event ? event.can_use_with_coupon : !ebook.cannot_use_coupon,
            purchase_item: {
              sale_id: saleInfo.id,
              ebook_id: ebook.id,
              short_title: saleInfo.sale_short_name,
              original_price: saleInfo.price_info[0].original_price,
            },
            event_code: event?.event_code,
            kit_set: [],
            gtm_data: {
              pnm: ebook.title.replace(/\r\n/g, ' '),
              pid: `c${ebook.id.toString().padStart(8, '0')}`,
              ppc: saleInfo?.price_info[0]?.price,
              ppb: ebook.author_name,
              pca: ebook.category_name,
            },
            expire_date: new Date(),
          }),
        );
        let need_move_to_purchase = true;
        if (event?.event_msg) {
          need_move_to_purchase = await confirm(`${event?.event_msg}\n결제페이지로 이동할까요?`);
        }

        if (need_move_to_purchase) {
          history.push('/purchase');
        }
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${location.pathname}${location.search}`,
          },
        });
      }
    }
  };

  if (!ebook) return null;
  if (loading) return <LoadingSpinner />;

  const salesInfo = ebook.sale_info_set[0];
  const originPrice = salesInfo?.price_info[0]?.original_price || null;
  const salePrice = salesInfo?.price_info[0]?.price || null;
  const salePercent = Math.round((1 - salePrice / originPrice) * 100);

  return (
    <div className='ebook-detail-container'>
      <Helmet>
        <title>{ebook.title}</title>
      </Helmet>

      <div className='ebook-detail-content'>
        <div className='ebook-area'>
          <div className='book-cover'>
            <img src={ebook.main_image_url} alt='book-cover' />
          </div>

          <ul className='book-navi'>
            <li className='active'>
              <a href='#introduce'>책 소개</a>
            </li>
            <li>
              <a href='#index'>전체 목차</a>
            </li>
            <li>
              <a href='#review'>리뷰{reviewData && `(${reviewData.total_count})`}</a>
            </li>
          </ul>

          <div
            id='introduce'
            className='book-introduce'
            dangerouslySetInnerHTML={{ __html: ebook.ebook_body.content_html }}
          />

          <ul className='book-navi'>
            <li>
              <a href='#introduce'>책 소개</a>
            </li>
            <li className='active'>
              <a href='#index'>전체 목차</a>
            </li>
            <li>
              <a href='#review'>리뷰{reviewData && `(${reviewData.total_count})`}</a>
            </li>
          </ul>

          <div
            id='index'
            className='book-index'
            dangerouslySetInnerHTML={{ __html: ebook.ebook_body.table_of_contents_html }}
          />

          <ul className='book-navi'>
            <li>
              <a href='#introduce'>책 소개</a>
            </li>
            <li>
              <a href='#index'>전체 목차</a>
            </li>
            <li className='active'>
              <a href='#review'>리뷰{reviewData && `(${reviewData.total_count})`}</a>
            </li>
          </ul>

          <div id='review'>
            <ReviewContainer
              type='ebook'
              ebook_id={id}
              input_placeholder={
                ebook?.is_mine || AccountStore?.account_type_id === 5 || ebook.is_ebook_author
                  ? '전자책 후기를 남겨주세요.'
                  : '전자책 구매 후 작성이 가능합니다.'
              }
              is_mine={ebook?.is_mine}
              review_size={5}
              visible_top_row={false}
              default_textarea_height={!isMobile ? 30 : 80}
              is_ebook_author={ebook?.is_ebook_author}
              onLoadReview={setReviewData}
            />
          </div>
        </div>
        <div className={`purchase-area ${openPurchaseForMobile ? 'open' : ''}`}>
          <div className='purchase-inner'>
            <div className='purchase-box'>
              <div className='purchase-wrap'>
                <div className='category-wrap'>
                  <div className='book-category'>PDF &middot; {ebook.category_name}</div>
                  <div className='book-utils'>
                    <button
                      type='button'
                      onClick={() => {
                        const content = document.createElement('input');
                        content.setAttribute(
                          'value',
                          `${window.location.origin}${window.location.pathname}`,
                        );
                        document.body.appendChild(content);
                        content.select();
                        document.execCommand('copy');
                        document.body.removeChild(content);
                        alert('링크가 복사되었습니다.');
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_share.svg`}
                        alt='공유하기'
                      />
                    </button>
                    <button type='button' onClick={handleBookmarkClick}>
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_bookmark_new_${
                          ebook.is_favorite ? 1 : 0
                        }.svg`}
                        alt='북마크'
                        className={`${ebook?.is_favorite ? '' : ' enp_mobon_wish'}`}
                      />
                    </button>
                  </div>
                </div>

                <div className='book-title'>{ebook.title}</div>

                <div className='book-info'>
                  {ebook.author_name} 지음 &middot; {init_comma(ebook.total_pages)}페이지
                </div>
                <div className='book-stat'>
                  <div className='book-star-count'>
                    {reviewData?.avg_rating?.toFixed(1) || 0}({reviewData?.total_count})
                  </div>
                  <div className='book-bookmark-count'>{init_comma(ebook.favorite_count)}</div>
                </div>

                <div className='price-area'>
                  {ebook.ebook_sale_status !== 1 && (
                    <>
                      {salePercent > 0 && (
                        <div className='origin-price'>{init_comma(originPrice)}원</div>
                      )}
                      <div className='real-price-wrap'>
                        <div className='real-price'>{init_comma(salePrice)}원</div>
                        {salePercent > 0 && (
                          <div className='discount-rate'>{salePercent}% 할인</div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {ebook.is_mine ? (
                <>
                  <button
                    type='button'
                    className='td-btn'
                    onClick={() => openEbookViewer(ebook.user_ebook_code)}
                  >
                    바로 읽기
                  </button>
                  <div className='popup-desc'>
                    <p>전자책을 보기 위해 팝업차단을 해제해주세요</p>
                    <a
                      href='https://glib-badge-aa5.notion.site/7709a41b1ce7434d92b95f76d6185358?pvs=4'
                      target='_blank'
                      rel='noreferrer'
                    >
                      팝업차단 해제 방법
                    </a>
                  </div>
                </>
              ) : ebook.ebook_sale_status === 1 ? (
                <div className='coming-soon-btn'>오픈예정</div>
              ) : (
                <button type='button' className='td-btn' onClick={onPurchase}>
                  구매하기
                </button>
              )}

              <button
                type='button'
                className='close-popup'
                onClick={() => setOpenPurchaseForMobile(false)}
              >
                닫기
              </button>
            </div>

            <SubBanner />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EbookDetail;
