import React from 'react';
import PropTypes from 'prop-types';
import '../../../assets/scss/component/_book_list.scss';
import { Link } from 'react-router-dom';
import { get_past_day } from '../../../common';

const getBadge = (book) => {
  if (book.ebook_sale_status === 1) {
    return <div className='badge soon'>오픈예정</div>;
  } else if (book.badge_type_name === 'BEST') {
    return <div className='badge best'>BEST</div>;
  } else if (get_past_day(book.write_time) < 60) {
    return <div className='badge new'>NEW</div>;
  }
  return null;
};

function BookList({ bookList, onBookmarkClick }) {
  return (
    <div className='book-list-wrap'>
      <div className='book-list'>
        {bookList.map((book) => {
          const badgeElement = getBadge(book);

          return (
            <div key={book.id} className='book-item'>
              <Link to={`/ebook/${book.id}`}>
                <div className='book-thumbnail'>
                  <img src={book.main_image_url} alt='book-thumbnail' />
                  <div className='bookmark-area'>
                    <button
                      className='bookmark-button'
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        onBookmarkClick(book);
                      }}
                    >
                      <div className={`bookmark-icon ${book.is_favorite ? 'active' : ''}`} />
                    </button>
                  </div>
                </div>
                {badgeElement && <div className='book-badges'>{badgeElement}</div>}
                <div className='book-title'>{book.title}</div>
                <div className='book-info'>
                  <span className='book-author'>{book.brand_name}</span>
                  <span className='dot'>&middot;</span>
                  <span className='book-category'>{book.category_name}</span>
                </div>
                {book.favorite_count > 0 && (
                  <div className='bookmark-count-area'>{book.favorite_count}</div>
                )}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

BookList.propTypes = {
  bookList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      author: PropTypes.string,
      category: PropTypes.string,
    }),
  ),
  onBookmarkClick: PropTypes.func,
};

BookList.defaultProps = {
  bookList: [],
};

export default BookList;
