import circle_lottie from "../assets/lottie/circle.json";
import time_bear_lottie from "../assets/lottie/time_bear.json";
import gift_bear_lottie from "../assets/lottie/gift_bear.json";

// Swiper Options
// overview slide-banner
export const overview_option = {
    navigation: true,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    pagination: {
        clickable: true,
    },
    initialSlide: 1,
    breakpoints: {
        768: {
            slidesPerView: 1,
            centeredSlides: true,
        },
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
    },
};

// main review-slider
export const main_review_option = {
    slidesPerGroup: 1,
    initialSlide: 1,
    loop: true,
    loopedSlides: 9,
    navigation: {
        prevEl: ".swiper-review-slider-prev",
        nextEl: ".swiper-review-slider-next",
    },
    autoplay: {
        delay: 3000,
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        0: {
            slidesPerView: "auto",
            spaceBetween: 10,
        },
    },
};

// main popular-list-slider
export const popular_list_option = {
    preventClicks: false,
    navigation: {
        prevEl: ".swiper-popular-slider-prev",
        nextEl: ".swiper-popular-slider-next",
    },
    breakpoints: {
        1128: {
            allowTouchMove: false,
            slidesPerView: 4,
            spaceBetween: 12,
        },
        768: {
            allowTouchMove: true,
            slidesPerView: 4,
            spaceBetween: 12,
        },
        0: {
            allowTouchMove: true,
            slidesPerView: "auto",
            spaceBetween: 10,
        },
    },
};

// hardstudy review-slider
export const hardstudy_review_option = {
    slidesPerGroup: 1,
    loop: true,
    loopedSlides: 9,
    centeredSlides: true,
    navigation: {
        prevEl: ".swiper-slider-prev",
        nextEl: ".swiper-slider-next",
    },
    breakpoints: {
        768: {
            slidesPerView: 3,
            spaceBetween: 19,
        },
        0: {
            slidesPerView: "auto",
            spaceBetween: 10,
        },
    },
};

// result-slider-wrap
export const result_slider_option = {
    autoplay: {
        delay: 500,
    },
    speed: 2000,
    centeredSlides: true,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
        prevEl: ".swiper-slider-prev",
        nextEl: ".swiper-slider-next",
    },
    breakpoints: {
        1128: {
            slidesPerView: 5,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
        0: {
            slidesPerView: 3,
            spaceBetween: 5,
        },
    },
};

// Lottie Options
// resume-content-wrap
export const circle_option = {
    loop: false,
    autoplay: false,
    animationData: circle_lottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

// time-event-banner
export const time_bear_active_option = {
    loop: false,
    autoplay: true,
    animationData: time_bear_lottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const time_bear_inactive_option = {
    loop: false,
    autoplay: false,
    animationData: time_bear_lottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

// pop-up-button
export const gift_bear_option = {
    loop: true,
    autoplay: true,
    animationData: gift_bear_lottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
