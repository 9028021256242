import React, { useRef, useState } from 'react';
import { button_type_negative, button_type_positive } from './DialogContextProvider';

// useDialogContext 를 사용하세요
const Confirm = (props) => {
    const dialog_props = props.dialog_props || {};
    const dialog_utils = props.dialog_utils || {};
    
    const [is_shake, setShake] = useState(false);
    const time_out = useRef(null);

    const doAbortAnimation = () => {
        setShake(true);
        if (time_out.current) {
            clearTimeout(time_out.current);
        }
        time_out.current = setTimeout(() => {
            setShake(false);
        }, 400);
    };

    return (
        <div className="confirm-container">
            <div className="confirm-block-layer" onClick={()=>{
                // dismiss
                if(dialog_props.cancelable ? false : true){
                    doAbortAnimation();
                } else {
                    dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
                }
            }}/>
            <div className={"confirm-content" + (is_shake ? " shake-anim" : "")}>
                <p>{dialog_props.message}</p>
                <div className="confirm-btn-group">
                    <button className="td-btn cancel-btn" onClick={() => {
                        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id, button_type_negative);
                    }}>{dialog_props.negative_button_message || "취소"}
                    </button>
                    <button className="td-btn confirm-btn" onClick={() => {
                        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id, button_type_positive);
                    }}>{dialog_props.positive_button_message || "확인"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Confirm;