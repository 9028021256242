import React, {useEffect, useLayoutEffect, useState} from "react";
import CommonModel from "../../../model/CommonModel";
import {useMount} from "../../../util/BearuHooks";
import {useLocation} from "react-router-dom";
import {random_pick, storage_get, storage_set} from "../../../common";
import ConvertRedirectUrl from "../../../util/ConvertRedirectUrl";
import moment from "moment";
import {useMediaQuery} from "react-responsive";

const PopUpBanner = () => {
    const location = useLocation();
    const [isMount] = useMount();
    const [banner, setBanner] = useState(null);
    const [is_visible, setVisible] = useState(false);
    const is_tablet_or_mobile = useMediaQuery({query: "(max-width: 767px)"});
    const scroll_position = window.pageYOffset;

    const pop_up_banner = {
        name: "pop_up_banner",
    };

    useEffect(() => {
        getPopUpBannerSet();
    }, [location.pathname]);

    useEffect(() => {
        if (is_tablet_or_mobile) {
            if (banner && is_visible) {
                preventScroll();
            } else {
                resetPreventScroll();
            }
        }
    }, [is_visible, banner]);

    useLayoutEffect(() => {
        let local_saved_banner_info = storage_get(pop_up_banner.name);
        let is_closed = local_saved_banner_info
            ? moment().isAfter(moment(local_saved_banner_info.closed_date), "day")
            : false;

        if (local_saved_banner_info === null) {
            setVisible(true);
        } else {
            if (is_closed) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
    }, [pop_up_banner.name]);

    const getPopUpBannerSet = async () => {
        let {banner_set} = await CommonModel.popUpBannerList(window.location.href);

        if (isMount()) {
            let picked_banner = random_pick(banner_set);
            if (picked_banner?.redirect_url.indexOf("bear-u.com") === -1) {
                picked_banner.is_not_bearu_link = true;
            }
            setBanner(picked_banner);
        }
    };

    // 하루동안 안 보기
    const closePopUpBanner = () => {
        setTimeout(() => {
            storage_set(pop_up_banner.name, {
                closed_date: moment().format("YYYY-MM-DD"),
            });
            setVisible(false);
        }, 100);
    };

    // dim 뒤 스크롤 방지
    const preventScroll = () => {
        document.body.style.cssText = `overflow: hidden; position: fixed; top: -${scroll_position}px; width: 100%;`;
    };

    // 스크롤 방지 reset
    const resetPreventScroll = () => {
        document.body.style.cssText = "";
        window.scrollTo(0, scroll_position);
    };

    if (!banner) {
        return null;
    } else {
        if (!is_visible) {
            return null;
        } else {
            return (
                <div className="pop-up-banner-wrap">
                    <div className="pop-up-banner">
                        <ConvertRedirectUrl
                            redirect_url={banner.redirect_url}
                            is_not_bearu_link={banner.is_not_bearu_link}>
                            <div
                                className="thumbnail"
                                style={{
                                    background: `url(${banner.thumbnail_image_url}) no-repeat center center/cover`,
                                }}
                            />
                        </ConvertRedirectUrl>

                        <div className="button-wrap">
                            <p onClick={closePopUpBanner}>하루동안 안 보기</p>
                            <p
                                onClick={() => {
                                    setVisible(false);
                                }}>
                                닫기
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default PopUpBanner;
