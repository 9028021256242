import React from 'react';

import '../../../../assets/scss/page/terms_policy.scss';
import TermsItem14b from './TermsItem14b';

const TermsItem = (props) => {
  return (
    <ul className='terms-list'>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제1조 [목적]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            이 약관은 (주)텐덤(이하 “회사”라 합니다)이 제공하는 베어유(베어유 웹 사이트) 및 베어유
            관련 제반 서비스(이하 “서비스”라 합니다)를 이용고객(이하 “회원”이라 합니다)이 이용함에
            있어 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 구체적으로 규정함을
            목적으로 합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제2조 [용어의 정의]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>1.이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
          <p className='terms-text'>
            가.“서비스”란 구현되는 단말기와 상관없이 “회원” 혹은 웹사이트 방문자가 이용할 수 있는
            서비스를 의미합니다.
          </p>
          <p className='terms-text'>
            나.“이용자”란 “서비스”에 접속하여 이 약관에 따라 “서비스”를 이용하는 회원 및 비회원을
            말합니다.
          </p>
          <p className='terms-text'>
            다.“회원”이란 “회사”의 “서비스”에 접속하여 본 약관에 따라 “회사”와 이용계약을 체결하고
            “회사”가 제공하는 “서비스”를 이용하는 고객을 말합니다.
          </p>
          <p className='terms-text'>
            라.“이용계약”이란 이 약관을 포함하여 “서비스” 이용과 관련하여 “회사”와 “회원” 간에
            체결하는 모든 계약을 말합니다.
          </p>
          <p className='terms-text'>
            마.계약해지란 “회사” 또는 “회원”이 이용계약을 해지하는 것을 말합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제3조 [약관 효력 및 변경]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회사”는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호,
            연락처(전화, 팩스, 이메일 주소 등) 등을 이용자가 알 수 있도록 “서비스”의 초기화면에
            게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
          </p>
          <p className='terms-text'>
            2. “회사”는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한
            법률」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「전자문서 및 전자거래기본법」,
            「전자금융거래법」, 「전자서명법」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등
            관련법을 위배하지 않는 범위에서 사전 고지 없이 이 약관의 내용을 변경할 수 있습니다.
          </p>
          <p className='terms-text'>
            3.“회사”가 약관을 개정할 경우에는 적용일자 및 개정사유(개정될 내용 중 중요사항에 대한
            설명을 포함한다)를 명시하여 현행 약관과 함께 “서비스”의 초기화면에 그 적용일자
            7일(이용자에게 불리하거나 중대한 사항의 변경은 30일) 이전부터 적용일 후 7일 동안
            공지합니다.
          </p>
          <p className='terms-text'>
            4.“회원”이 변경된 약관의 효력발생일 이후에도 “서비스”를 계속 이용할 경우, 약관의
            변경사항에 동의한 것으로 간주됩니다.
          </p>
          <p className='terms-text'>
            5.“회원”이 개정약관의 적용에 동의하지 않는다는 의사표시를 명시적으로 한 경우 “회사”는
            개정약관의 내용을 적용할 수 없으며, 이 경우 “회원”은 이용계약을 해지할 수 있습니다. 단,
            기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 “회사”도 이용계약을 해지할 수
            있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제4조 [약관 외 준칙]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            “회사”는 필요한 경우 “서비스” 내의 개별 항목에 대하여 개별약관 또는 운영원칙(이하
            “서비스 별 안내”라 합니다)을 정할 수 있으며, “회원”은 각 서비스 별 안내에 대해
            회원가입과 동시에 동의한 것으로 간주합니다. 본 약관과 서비스 별 안내의 내용이 상충되는
            경우에는 서비스 별 안내의 내용을 우선하여 적용합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제5조 [이용계약 체결]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.이용계약은 “회원”이 되고자 하는 자(이하 “가입신청자”라 합니다)가 이 약관의 내용에
            대하여 동의를 한 다음 회원가입신청을 하고 “회사”가 이러한 신청에 대하여 승낙함으로써
            체결됩니다.
          </p>
          <p className='terms-text'>
            2.“회사”는 가입신청자의 신청에 대하여 “서비스” 이용을 전산적으로 자동화 된 방법으로
            승낙함을 원칙으로 합니다. 다만, “회사”는 다음 각 호에 해당하는 신청에 대하여는 승낙을
            하지 않거나 사후에 이용계약을 해지할 수 있습니다.
          </p>
          <p className='terms-text'>
            가.가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의
            회원 재가입 승낙을 얻은 경우는 예외
          </p>
          <p className='terms-text'>나.실명이 아니거나 타인의 명의를 이용한 경우</p>
          <p className='terms-text'>다.등록 내용에 허위, 기재누락, 오기가 있는 경우</p>
          <p className='terms-text'>
            라.14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
          </p>
          <p className='terms-text'>
            마.이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며
            신청하는 경우
          </p>
          <p className='terms-text'>
            3.제1항에 따른 신청에 있어 “회사”는 “회원”의 종류에 따라 전문기관을 통한 실명확인 및
            본인인증을 요청할 수 있습니다.
          </p>
          <p className='terms-text'>
            4.“회사”는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는
            승낙을 유보할 수 있습니다.
          </p>
          <p className='terms-text'>
            5.제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, “회사”는
            원칙적으로 이를 가입신청자에게 알리도록 합니다.
          </p>
          <p className='terms-text'>
            6.이용계약의 성립 시기는 “회사”가 가입 완료를 신청절차 상에서 표시한 시점으로 합니다.
          </p>
          <p className='terms-text'>
            7.“회사”는 “회원”의 편의를 위해 “회원”의 사용 정보를 포괄적으로 저장하며, 사용정보에
            따라 개인화된 서비스를 제공할 수 있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제6조 [회원정보의 입력 및 변경]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.“회원”은 가입 시에 안내에 따라 실명과 이메일 주소를 사실대로 기록하여야 합니다. 실명과
            이메일 주소가 정확하지 않은 경우 서비스 이용이나 소비자로서 권리행사가 제한됩니다.
          </p>
          <p className='terms-text'>
            2.“회원”은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수
            있습니다. 다만, 서비스 관리를 위해 필요한 이메일 주소, 실명 등은 수정이 불가능합니다.
          </p>
          <p className='terms-text'>
            3.“회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 서비스 내에서 수정을 하거나
            이메일 및 서비스 내 문의창구 등의 방법으로” 회사”에게 그 변경사항을 알려야 합니다.
          </p>
          <p className='terms-text'>
            4. 제3항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지
            않습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제7조 [개인정보보호 의무]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.“회사”는 “회원”의 “개인정보” 수집 시 필요한 최소한의 정보를 수집합니다.
          </p>
          <p className='terms-text'>
            2.“회사”는 “회원”의 “개인정보”를 수집할 때 다음 각 호의 1에 해당하는 경우를 제외하고는
            그 “회원”의 동의를 받습니다.
          </p>
          <p className='terms-text'>가.법률에 특별한 규정이 있는 경우</p>
          <p className='terms-text'>나.전자거래 계약의 이행을 위하여 필요한 경우</p>
          <p className='terms-text'>다.“서비스” 제공에 따른 요금 정산을 위하여 필요한 경우</p>
          <p className='terms-text'>
            3.“회사”는 “회원”의 “개인정보”를 그 “회원”의 동의 없이 목적 외로 사용하거나 제3자에게
            제공할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우에는 예외로 합니다.
          </p>
          <p className='terms-text'>가.법률에 특별한 규정이 있는 경우</p>
          <p className='terms-text'>나.“서비스” 제공에 따른 요금 정산을 위해 필요한 경우</p>
          <p className='terms-text'>
            다.통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는
            형태로 제공되는 경우
          </p>
          <p className='terms-text'>
            4.“회원”은 “웹사이트”상의 개인정보관리 시스템을 통해 언제든지 본인의 “개인정보”를
            열람하고 수정할 수 있습니다.
          </p>
          <p className='terms-text'>
            5.“회원”은 가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정해야 하며, 이를
            수정하지 아니하여 발생하는 문제의 책임은 “회원”에게 있습니다.
          </p>
          <p className='terms-text'>
            6.“개인정보”의 수집, 보관, 이용, 위탁 등에 관한 제반 사항은 “회사”가 게시한
            개인정보처리방침에서 확인할 수 있습니다.
          </p>
          <p className='terms-text'>
            7.“회사”는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관계 법령이 정하는 바에
            따라 “회원”의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는
            관련법 및 “회사”의 개인정보취급방침이 적용됩니다. 다만, “회사”에서 직접 제공하는 서비스
            외 링크된 사이트 등에서는 “회사”의 개인정보취급방침이 적용되지 않습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제8조 [회원의 정보관리 의무]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로
            불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가
            이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의
            하드디스크에 저장되기도 합니다.
          </p>
          <p className='terms-text'>
            2.쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
            인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
            사용됩니다.
          </p>
          <p className='terms-text'>
            3.쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의
            옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
          </p>
          <p className='terms-text'>
            4.쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제9조 [회원에 대한 정보제공 및 통지] </h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.“회사”는 “회원”에게 서비스 이용에 필요가 있다고 인정되는 각종 정보를 이메일이나 우편,
            SMS, 전화 등의 방법으로 제공할 수 있습니다.
          </p>
          <p className='terms-text'>
            2.“회사”가 “회원”에게 의사표시 기타 통지를 하는 경우 이 약관에 별도 규정이 없는 한
            서비스 내 알림, 이메일, 서비스 내 쪽지 등으로 할 수 있습니다. 단, 회원정보에 기입된
            이메일 주소 또는 이동전화번호를 항상 정확한 정보로 유지하는 책임은 “회원”에게 있습니다.
          </p>
          <p className='terms-text'>
            3.“회사”는 불특정다수 “회원”에 대한 통지의 경우 1주일 이상 “서비스”에 게시함으로써 개별
            통지에 갈음할 수 있습니다.
          </p>
          <p className='terms-text'>
            4.“회사”는 서비스 개선 및 “회원” 대상의 서비스 소개 홍보 등의 목적으로 “회원”의 동의
            하에 관련 법령에 따라 추가적인 개인 정보를 수집할 수 있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제10조 [회사의 의무]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.“회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며,
            계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
          </p>
          <p className='terms-text'>
            2.“회사”는 “회원”이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를
            위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
          </p>
          <p className='terms-text'>
            3.“회사”는 서비스 이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고
            인정할 경우에는 이를 처리하여야 합니다. “회원”이 제기한 의견이나 불만사항에 대해서는
            서비스 내 문의창구, 서비스 내 알림, 서비스 내 쪽지, 이메일 등을 통하여 “회원”에게
            처리과정 및 결과를 전달합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제11조 [회원의 의무]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>1.“회원”은 다음 행위를 하여서는 안 됩니다.</p>
          <p className='terms-text'>가.신청 또는 변경 시 허위내용의 등록</p>
          <p className='terms-text'>나.타인의 정보도용</p>
          <p className='terms-text'>다.“회사”가 게시한 정보의 변경</p>
          <p className='terms-text'>
            라.“회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
          </p>
          <p className='terms-text'>마.“회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
          <p className='terms-text'>
            바.“회사” 및 기타 제3자에 대한 비방, 명예 훼손, 개인정보 침해, 업무방해 등 기타 피해를
            야기하는 행위
          </p>
          <p className='terms-text'>
            사.다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는
            행위
          </p>
          <p className='terms-text'>
            아.“서비스”의 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
          </p>
          <p className='terms-text'>자.불쾌한 언어 사용 및 행동, 서비스와 관련 없는 요청</p>
          <p className='terms-text'>
            차.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개
            또는 게시하는 행위
          </p>
          <p className='terms-text'>
            카.서비스의 목적에 벗어나 영리를 목적으로 서비스를 사용하는 행위
          </p>
          <p className='terms-text'>
            2."회원”은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한
            주의사항,“회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는
            행위를 하여서는 안 됩니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제12조 [서비스의 제공]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>1.“회사”는 “회원”에게 아래와 같은 서비스를 제공합니다.</p>
          <p className='terms-text'>가.온오프라인 강좌 중개 서비스</p>
          <p className='terms-text'>나.다양한 교육 관련 서비스</p>
          <p className='terms-text'>
            다.기타 “회사”가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는
            일체의 서비스
          </p>
          <p className='terms-text'>
            2.“서비스” 이용은 “회사”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간
            운영을 원칙으로 합니다.
          </p>
          <p className='terms-text'>
            3.“서비스”를 구성하고 있는 제3자에게 기술적 문제나 운영상 문제 등이 발생하면 “서비스”는
            언제든 중단 될 수 있으며 “회사”는 이에 대해 책임지지 않습니다.
          </p>
          <p className='terms-text'>
            4.“회사”의 명백한 책임으로 서비스가 중단되었고 중단된 시간이 6시간을 초과하는 경우
            “회사”는 유료사용자에 대하여 중단된 시간만큼 추가이용시간을 지급합니다.
          </p>
          <p className='terms-text'>
            5.“회사”는 서비스의 업데이트, 정기점검 등으로 서비스를 중단할 수 있으며 이 경우 서비스
            내에서 사전고지 합니다.
          </p>
          <p className='terms-text'>
            6.“회사”는 일부 서비스의 이용 가능 시간을 별도로 정할 수 있습니다. 이 경우 서비스
            이용안내를 통해 공지합니다.
          </p>
          <p className='terms-text'>
            7.서비스의 구조, 인터페이스, 디자인, 경험 등은 회사의 정책에 따라 상시적으로 변경될 수
            있으며, 유료 서비스를 지불한 회원의 사용목적을 침해하지 않은 이상 “회사”는 위 변경에
            대해 별도의 보상, 환불 등을 하지 않습니다.
          </p>
          <p className='terms-text'>
            8.“회원”이 별도로 비용을 지불하지 않은 서비스는 회사 또는 해당 정보를 게시한 자에 의해
            언제든지 중단될 수 있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제13조 [유료 서비스 거래 규정]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1.별도로 표시된 유료 서비스를 제외한 모든 서비스는 “회원”에게 무료로 제공됩니다.
          </p>
          <p className='terms-text'>
            2.유료 서비스의 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는 규정에 따릅니다.
          </p>
          <p className='terms-text'>
            3.“회원”이 유료 서비스에 대한 결제를 완료한 때 유료 서비스 이용 계약이 성립된 것으로
            봅니다.
          </p>
          <p className='terms-text'>4.유료 서비스는 각각마다 제공기간이 정해집니다.</p>
          <p className='terms-text'>
            5.“회사”는 “회원”이 일정 금액을 지불하는 경우 제공기간을 연장하는 기능을 제공할 수
            있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제14조 환불 규정 주의사항</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            상황에 따라 사전 공지 없이 할인이 조기 마감되거나 연장될 수 있습니다. 천재지변, 폐업 등
            서비스 중단이 불가피한 상황에는 서비스가 종료될 수 있습니다. 콘텐츠는 향후 당사의 일정에
            따라 추가 또는 업데이트 될 수 있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제14-1조 환불 규정 (관계 법령상 환불 정책)</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. 회사의 본 약관상 환불 규정 등은 “온라인 디지털콘텐츠산업 발전법”, “전자상거래
            등에서의 소비자보호에 관한 법률” 등을 준수합니다.
          </p>
          <p className='terms-text'>
            2. 전 항의 관계법령에 따른 소비자의 청약철회가 불가능한 경우는 다음과 같습니다.
          </p>
          <p className='terms-text lv2'>
            가. 「문화산업진흥 기본법」 제2조제5호의 디지털콘텐츠의 제공이 개시된 경우.
          </p>
          <p className='terms-text'>
            3. 「문화산업진흥 기본법」 제2조제5호에 따라 베어유 에서 제공하는 영상들은
            디지털콘텐츠에 해당합니다.
          </p>
        </div>
      </li>
      <TermsItem14b />
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제14-3조 환불 규정 (옵션상품 환불 규정)</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. 옵션상품이란 베어유에서 판매 하는 상품 중 단과 영상강의 콘텐츠를 제외한 항목을
            말합니다.
          </p>
          <p className='terms-text'>
            2. 옵션상품은, 베어유와 제휴되어있는 제휴사에서 직접 배송하는 제품 입니다.
          </p>
          <p className='terms-text'>3. 옵션상품이 이미 배송된 경우 주문취소는 불가능 합니다.</p>
          <p className='terms-text'>
            4. 반품 및 교환은 상품수령을 확인 하지 않은 상태에서 상품 수령 후 7일 이내 제휴사와
            협의하여 신청할 수 있습니다.
          </p>
          <p className='terms-text'>
            5. 지정 택배가 정해져 있으므로 교환 및 반품 시 반드시 베어유(회사)로 먼저 문의를 주시기
            바랍니다. (문의없이 개별 교환 및 반품 시 발생하는 택배비는 개인부담)
          </p>
          <p className='terms-text'>
            6. 상품 수령 후 7일 이후, 별다른 추가 문의사항이 없는경우 자동 구매 확정이 됩니다.
          </p>
          <p className='terms-text'>7. 반품/교환 사유에 따른 요청 가능 기간</p>
          <p className='terms-text lv2'>
            : 구매자 단순 변심은 상품 수령 후 7일 이내 (구매자 반품배송비 부담), 베어유(회사)로
            문의(채널톡)
          </p>
          <p className='terms-text lv2'>
            : 발송한 상품에 하자가 있는경우 수령 후 7일 이내 (배송자 반품배송비 부담), 상품의 사진과
            함께 베어유(회사)로 문의(채널톡)
          </p>
          <p className='terms-text'>8. 반품/교환 불가능 사유</p>
          <p className='terms-text lv2'>
            : 구매자 단순 변심 시, 반품가능기간(상품 수령 후 7일)이 경과된 경우
          </p>
          <p className='terms-text lv2'>
            : 포장을 개봉하였거나 포장이 훼손되어 상품가치가 현저히 상실된 경우 (예. 식품, 화장품,
            향수류 등)
          </p>
          <p className='terms-text lv2'>
            : 구매자의 사용(라벨이 떨어진 의류 등) 또는 시간의 경과에 의하여 상품의 가치가 현저히
            감소한 경우
          </p>
          <p className='terms-text lv2'>
            : 고객주문 확인 후, 상품제작에 진행되는 주문제작 상품인 경우
          </p>
          <p className='terms-text lv2'>
            : 복제가 가능한 상품 등의 포장을 훼손한 경우 (CD, DVD, 게임, 도서 등의 경우 포장 개봉
            시)
          </p>
          <p className='terms-text lv2'>
            : 위 내용과 일치 할 경우 반품, 환불, 교환이 절대 불가합니다.
          </p>
          <p className='terms-text'>9. 환불 금액은 제품 수령 및 확인 후 환불 처리 됩니다.</p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제15조[서비스 이용 동기부여 목적의 환급금 관련 규정]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            - 서비스 이용에 있어서 동기부여를 제공하기 위하여 특정 이벤트를 통해 목표 달성시, 해당
            이벤트에서 제공하는 만큼 베어유 내부 포인트로 환급을 진행할 수 있습니다.
          </p>
          <p className='terms-text'>
            - 동기부여 목적의 환급금의 경우 베어유에서만 사용할 수 있는 포인트로 외부에서 현금성으로
            거래가 불가능합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제16조 [과오금]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1."회사"는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을
            환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.
          </p>
          <p className='terms-text'>
            2."회사"의 책임 있는 사유로 과오금이 발생한 경우 "회사"는 계약비용, 수수료 등에 관계없이
            과오금 전액을 환불합니다. 다만, "회원"의 책임 있는 사유로 과오금이 발생한 경우, "회사"가
            과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 "회원"이 부담하여야 하며, 이는
            반환되는 과오금에서 공제될 수 있습니다.
          </p>
          <p className='terms-text'>
            3.“회사”는 "회원"이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용대금이
            부과되었음을 입증할 책임을 집니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제17조 [회원의 게시물]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. 게시물은 “회원”이 서비스를 이용하면서 게시한 글, 사진, 각종 파일과 링크, 디지털
            콘텐츠 등을 말합니다.
          </p>
          <p className='terms-text'>
            2. “회원”이 서비스에 등록하는 게시물 등으로 인하여 본인 또는 타인에게 손해나 기타 문제가
            발생하는 경우 “회원”은 이에 대한 전적인 책임을 지며, 회사는 이에 대하여 어떤 경우에도
            책임을 지지 않습니다.
          </p>
          <p className='terms-text'>
            3. 회사는 다음 각 호에 해당하는 게시물 등을 회원의 사전 동의 없이 임시 게시중단, 수정,
            삭제, 이동 또는 등록 거부 등의 관련 조치를 취할 수 있습니다.
          </p>
          <p className='terms-text'>
            가. 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
          </p>
          <p className='terms-text'>
            나. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크하는 경우
          </p>
          <p className='terms-text'>다.불법복제 또는 해킹을 조장하는 내용인 경우</p>
          <p className='terms-text'>라.영리를 목적으로 하는 광고일 경우</p>
          <p className='terms-text'>마.범죄와 결부된다고 객관적으로 인정되는 내용일 경우</p>
          <p className='terms-text'>
            바.다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          </p>
          <p className='terms-text'>
            사.사적인 정치적 판단이나 종교적 견해의 내용으로 회사가 서비스 성격에 부합하지 않는다고
            판단하는 경우
          </p>
          <p className='terms-text'>
            아.회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우
          </p>
          <p className='terms-text'>
            자.게시물이 내용의 질 뿐만 아니라 화질 등에 있어 회사가 정한 규정을 만족하지 못한 경우
          </p>
          <p className='terms-text'>차.기타 관계법령에 위배된다고 판단되는 경우</p>
          <p className='terms-text'>
            4. “회사”는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의 권리 침해를 이유로
            게시중단 요청을 받은 경우 이를 임시로 게시를 중단 할 수 있으며, 이의를 제기한 자와
            게시물 등록자 간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를
            근거로 회사에 신청이 있는 경우에만 상기 임시로 게시가 중단된 게시물은 다시 게시될 수
            있습니다.
          </p>
          <p className='terms-text'>
            5. “회원”이 이용계약해지를 한 경우 “회원”이 등록한 게시물은 자동 삭제되지 않으며 필요한
            경우 일부만 익명화 처리됩니다. “회원”은 이용계약 해지 전에 자신의 등록한 게시물을 언제든
            직접 삭제할 수 있으며, 이용계약 해지 이후에도 “회사”에 그 삭제를 요청할 수 있습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제18조 [게시물에 대한 저작권]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회사”가 작성한 게시물 또는 저작물에 대한 저작권, 기타 지적재산권은 “회사”에
            귀속합니다.
          </p>
          <p className='terms-text'>
            2. “회원”이 서비스 내에 게시한 게시물의 저작권은 원칙적으로 게시한 “회원”에게
            귀속됩니다. 단, “회사”는 서비스의 운영, 전시, 전송, 배포, 홍보 등의 목적으로 “회원”의
            별도의 허락 없이 무상으로 “회원”이 등록한 게시물을 사용할 수 있습니다.
          </p>
          <p className='terms-text'>
            3. “회원”이 올린 디지털 콘텐츠가 “회원”의 동의 없이 타인에 의해 무단, 불법 복제되어
            “회원”뿐 아니라 “회사”의 이익에 상충할 경우 “회사”는 “회원”을 대리해 지적재산권을 행사할
            수 있습니다. 단, 이 경우 “회사”는 “회원”에게 개별적으로 동의를 받아야 합니다.
          </p>
          <p className='terms-text'>
            4. “회사”는 본 조 제 2항 이외의 목적으로 “회원”의 게시물을 사용하고자 하는 경우 사전에
            “회원”의 동의를 얻어 사용합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제19조 [광고]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회원”은 “서비스” 이용 시 노출되는 광고게재에 대해 동의합니다.
          </p>
          <p className='terms-text'>
            2. “회사”는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에 “회원”이
            참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제20조 [회원 탈퇴 및 자격상실]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회원”은 언제든지 서비스 내 문의 기능을 통하여 이용계약 해지(탈퇴) 신청을 할 수
            있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
          </p>
          <p className='terms-text'>
            2. “회원”이 “회사”로부터 제23조에 따른 이용제한의 일환으로 영구이용정지 통보를 받거나
            사망한 경우 해당 회원은 그 자격을 상실하며, 회사와의 모든 이용계약은 해지됩니다.
          </p>
          <p className='terms-text'>
            3. 제1항에 의한 회원탈퇴 또는 제2항에 의한 회원자격상실 및 이용계약해지가 확정되는
            시점은 아래와 같습니다.
          </p>
          <p className='terms-text'>
            가. 회원자격상실의 경우 통보일에 회원 자격상실이 확정됩니다. 단, 사망으로 인한
            자격상실의 경우에는 회사의 통보여부와 상관없이 회원 사망일에 자격상실이 확정되며, 당해
            회원에게 제공된 기 회사의 서비스나 관련된 권리는 회원의 상속인에게 상속되지 않습니다.
          </p>
          <p className='terms-text'>
            4. 상속인에게 상속되지 않습니다.“회원”이 탈퇴하거나 자격을 상실할 경우, 관련법 및
            개인정보취급방침에 따라 “회사”가 회원정보를 보유하는 경우를 제외하고는 그 효력발생 시
            “회원”의 모든 정보는 소멸됩니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제21조 [이용제한]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회사”는 “회원”이 본 약관 제11조 제1항의 의무를 위반하거나 서비스의 정상적인 운영을
            방해한 경우, 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 제한할 수 있습니다.
          </p>
          <p className='terms-text'>
            2. “회사”는 “회원”이 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및
            컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한
            불법통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과행위 등과 같이 관련법을 위반한
            경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 서비스 이용을
            통해 획득한 수익 등 기타 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지
            않습니다.
          </p>
          <p className='terms-text'>
            3. “회사”는 “회원”이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의
            효율성을 위해 이용을 제한할 수 있습니다.
          </p>
          <p className='terms-text'>
            4. “회사”는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별
            서비스 상의 운영정책에서 정하는 바에 의합니다.
          </p>
          <p className='terms-text'>
            5. 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 “회사”는 그 사유 및
            제한기간 등을 회원에게 알려야 합니다.
          </p>
          <p className='terms-text'>
            6. “회원”은 본 조에 따른 이용제한 등에 대해 “회사”가 정한 절차에 따라 이의신청을 할 수
            있습니다. “회사”는 이의가 정당하다고 인정하는 경우 즉시 서비스의 이용을 재개합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제22조 [부정이용 금지 및 차단]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회사”는 다음 각 호에 해당하는 경우를 부정 이용행위로 봅니다.
          </p>
          <p className='terms-text'>
            가.동일한 계정으로 2대 이상의 PC에서 동시접속이 발생하는 경우
          </p>
          <p className='terms-text'>
            나.동일한 계정으로 다수의 PC 또는 IP에서 서비스를 이용하는 경우
          </p>
          <p className='terms-text'>
            다.자신의 계정 및 강좌 등의 서비스를 타인이 이용하도록 하는 경우
          </p>
          <p className='terms-text'>
            라.자신의 계정 및 강좌 등의 서비스를 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는
            행위를 하는 경우{' '}
          </p>
          <p className='terms-text'>
            마.서비스 이용 중, 복제프로그램을 실행하는 경우 또는 녹화를 하거나 시도하는 경우
          </p>
          <p className='terms-text'>
            2. “회사”는 전항에 따른 부정 이용자가 발견 되었을 경우, 해당 이용자를 강제 탈퇴 처리하며
            회사의 법률 대리인을 통한 고발조치와 민사소송을 진행할 수 있습니다.
          </p>
          <p className='terms-text'>
            3. “회원”은 제2항 의 조치를 이유로, 서비스 이용기간의 연장이나 환불을 요구할 수
            없습니다.
          </p>
          <p className='terms-text'>
            4. “회원”은 “회사”로부터의 제2항의 조치에 대해 이의가 있는 경우, 회사의 법률 대리인을
            통해 이를 소명할 수 있습니다.
          </p>
          <p className='terms-text'>5. 부정이용 식별 및 차단은 아래 각 호의 방법에 따릅니다.</p>
          <p className='terms-text'>
            가.“회사”는 “회원”이 서비스를 이용하는 중에 수집한 자료를 토대로 부정이용 여부를
            확인합니다b.“회사”는 이용자가 서비스 이용 중에 복제프로그램을 실행시키거나 동일한
            계정으로 동시 접속을 하는 경우, 서비스 이용 접속을 강제로 종료 시킵니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제23조 [면책]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. “회사”가 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
            경우에는 이에 대한 책임을 지지 않습니다.
          </p>
          <p className='terms-text'>
            2. “회사”는 “회원”의 귀책사유(지원되지 않는 환경을 이용하는 것을 포함)로 인한 서비스
            이용의 장애에 대하여는 책임을 지지 않습니다.
          </p>
          <p className='terms-text'>
            3. “회사”는 “회원”이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의
            내용에 관하여는 책임을 지지 않습니다.
          </p>
          <p className='terms-text'>
            4.“회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한
            경우에는 이로 인해 발생한 문제에 대해 책임을 지지 않습니다.
          </p>
          <p className='terms-text'>
            5.“회사”는 “회사”가 무료로 제공하는 서비스의 이용과 관련하여 관련법에 특별한 규정이 없는
            한 책임을 지지 않습니다.
          </p>
          <p className='terms-text'>
            6.“회사”는 콘텐츠 공급자가 제공하거나 “회원”이 작성하는 등의 방법으로 서비스에 게재된
            정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한
            “회원”의 손해에 대하여는 책임을 부담하지 아니합니다.
          </p>
          <p className='terms-text'>
            7.“회사”는 “회원”이 “회사”에 사실과 다른 내용을 제공함에 따라 발생하는 정보, 인증서 등의
            오류에 대해 책임을 지지 않습니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제26조 [재판관할 및 준거법]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            본 약관의 준거법은 대한민국법으로 하며, 서비스 이용과 관련하여 “회사”와 “회원” 간에
            발생한 분쟁에 대해서는 서울중앙지방법원을 전속적 합의관할로 합니다.
          </p>
        </div>
      </li>
      <li className='terms-con'>
        <h4 className='terms-subtitle'>제27조 [규정의 준용]</h4>
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            본 약관에 명시되지 않은 사항에 대해서는 한국법의 관계법령에 의하고, 법에 명시되지 않은
            부분에 대하여는 관습에 의합니다.
            <br />본 약관은 2021년 05월 21일부터 개정하여 적용됩니다.
          </p>
        </div>
      </li>
    </ul>
  );
};

export default TermsItem;
