import React, {useEffect, useState} from "react";
import TemplateInput from "../_template/TemplateInput";
import PropTypes from "prop-types";
import {dialog_type_custom, useDialogContext} from "../dialog/DialogContextProvider";
import AddressChangeModal from "../dialog/AddressChangeModal";
import AccountModel from "../../../model/AccountModel";

const propTypes = {
    on_data_changed: PropTypes.func,
    default_value: PropTypes.string,
};

const AddressInput = (props) => {
    const {showDialog} = useDialogContext();
    const [is_error, setIsError] = useState(false);

    const [address_values, setAddressValues] = useState({
        address: props.input_values.current.address,
        address_detail: props.input_values.current.address_detail,
        address_code: props.input_values.current.address_code,
    });

    useEffect(() => {
        if (props.force_error) {
            setIsError(false);
        } else {
            setIsError(true);
        }
    }, [props.force_error]);

    // 주소 찾기 모달
    const addressModalOpen = () => {
        showDialog({
            type: dialog_type_custom,
            component: AddressChangeModal,
            component_props: {
                is_detail: true,
                onComplete: onAddressChange,
            },
        });
    };

    // 주소 변경
    const onAddressChange = (address) => {
        AccountModel.profileEdit({
            address: address.roadAddress,
            address_code: address.zonecode,
            address_detail: address.address_detail,
        }).then(({account}) => {
            setAddressValues({
                ...address_values,
                address: address.roadAddress,
                address_code: address.zonecode,
                address_detail: address.address_detail,
            });
            props.input_values.current = {
                ...address,
                address: address.roadAddress,
                address_code: address.zonecode,
                address_detail: address.address_detail,
            };
        });
        setIsError(false);
    };

    return (
        <div className="address-wrap">
            {/* 주소 검색 */}
            <div className="address-top-wrap" onClick={addressModalOpen}>
                <TemplateInput
                    input_props={{
                        placeholder: "주소를 검색해주세요",
                        value: address_values.address,
                        disabled: true,
                    }}
                />
                <button className="address-search-btn" tabIndex={0}>
                    주소 검색
                </button>
            </div>

            {/* 상세 주소 */}
            <div onClick={addressModalOpen}>
                <TemplateInput
                    input_props={{
                        placeholder: "상세주소를 입력해주세요",
                        value: address_values.address_detail,
                        disabled: true,
                    }}
                />
            </div>

            <div className="address-info">
                <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/account/ic_alert.svg`}
                    className="info-icon"
                    alt="정보 아이콘"
                />
                <p>입력하신 주소로 배송이 진행됩니다</p>
                {is_error && <p className="default-msg">주소를 입력해주세요</p>}
            </div>
        </div>
    );
};

AddressInput.prototype = propTypes;

export default AddressInput;
