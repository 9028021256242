import React from 'react';
import '../../../../assets/scss/page/terms_policy.scss';

const TermsItem14b = (props) => {
  const hide_title = props.hide_title ?? false;

  return (
    <>
      <li className='terms-con'>
        {!hide_title && (
          <h4 className='terms-subtitle'>
            제14-2조 환불 규정 (단과 및 패키지 영상강의 및 전자책 환불 규정)
          </h4>
        )}
        <div className='terms-text-wrap'>
          <p className='terms-text'>
            1. 회사는 회원의 편의를 위하여 전 조 제1항의 관계법령에도 불구하고 본 약관을 통하여
            회원에게 좀 더 유리한 별도의 환불 규정을 적용합니다.
          </p>
          <p className='terms-text'>
            2. 회원은 환불 또는 변경을 유ㆍ무선의 방법을 통하여 회사에 그 의사를 표시하여야 하며,
            회사는 환불요청을 접수하고 회원의 요청과 환불 규정 확인 후, 최대한 신속하게 환불하여
            드립니다.
          </p>
          <p className='terms-text'>
            3. 회원은 다음 각 호의 사항에 따라 회사에 유료 콘텐츠 서비스의 환불 또는 변경을 요청할
            수 있습니다. 단, 강의파일을 스마트폰, 스마트패드, PMP등 전자기기등에서 여는 등의 행위는
            1파일당 1강좌를 수강한 것으로 간주합니다.
          </p>
          <p className='terms-text'>
            4. 강의 동영상이 60초 이상 재생 되어 컨텐츠가 제공 된 경우, 해당 강의 동영상을 수강완료
            및 수강시작이 된 것으로 간주합니다.
          </p>
          <p className='terms-text'>
            5. 수강기간은 구매완료 시점부터 구매한 해당 상품의 VOD 제공기간까지 이며, 결제가
            완료되는 즉시 수강을 시작할 수 있는 권한을 받습니다.
          </p>
          <p className='terms-text'>
            6. 전자책의 경우 ‘지금읽기’를 클릭하여 콘텐츠가 제공된 경우, 해당 전자책을 열람한 것으로
            간주합니다.
          </p>
          <p className='terms-text'>7. 회사의 환불 원칙은 다음과 같습니다.</p>
          <p className='terms-text lv2'>
            가. 단과 영상강의 환불 규정 (※단과 영상강의란, 베어유에서 판매하는 개별 영상강의
            콘텐츠를 말합니다.)
          </p>
          <p className='terms-text lv3'>
            1) 결제완료일 포함 3일 이내 전액 환불 가능 하나, 아래 1-1), 1-2) 항목 내용 중 하나라도
            해당이 되면 부분 환불 대상 입니다.
          </p>
          <p className='terms-text lv4'>
            1-1) 4강 이상 수강시 부분 환불 대상 (맛보기 등 무료체험 강의 포함)
          </p>
          <p className='terms-text lv4'>
            1-2) 프로모션 및 이벤트 등을 통해 받은 사은품의 포장을 개봉 하였거나 포장이 훼손되어
            상품 가치가 현저히 상실된 경우, 부분 환불 대상
          </p>
          <p className='terms-text lv3'>
            2) 부분 환불 대상의 경우 아래 환불 규정에따라 부분 환불 가능 하나, 단 결제완료일로 부터
            2주 초과시 환불 불가 대상입니다.
          </p>
          <p className='terms-text lv4'>
            : 부분 환불 받으실 금액 = 실 결제 금액 X (미 수강된 강의수 / 구매하신 강좌에 구성된 총
            강의 수)
          </p>
          <p className='terms-text lv4'>
            : 프로모션 및 이벤트 등을 통해 무료 연장을 받은 기간은 제외
          </p>
          <p className='terms-text lv3'>
            3) 결제완료일(수강시작)로부터 수강하신 기간(환불요청시기)이 2주를 초과한 경우 환불이
            불가 합니다.
          </p>
          <p className='terms-text lv3'>
            4) 신용카드 결제, 가상계좌 이체 등 회원님이 결제한 수단과 동일한 방식으로 환불 하는 것을
            원칙으로 하나, 부분 환불 등 환불이 불가능한 경우에 한해 기타 협의된 방식으로 환불합니다.
            (단, 휴대폰 결제의 경우 결제 당월은 결제 취소만 가능, 익월의 경우 휴대폰 결제 건 입금
            확인 후 결제자 본인 계좌로만 환불 가능)
          </p>
          <p className='terms-text lv2'>
            나. 패키지 영상강의 환불 규정 (※패키지 영상강의란, 베어유에서 판매하는 상품 중 단과
            영상강의를 패키지로 묶은 콘텐츠를 말합니다.)
          </p>
          <div className='terms-text lv2'>
            <table className='terms-table'>
              <thead>
                <tr>
                  <th>환불 구분</th>
                  <th>내용</th>
                  <th>상세 내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>전체 환불</td>
                  <td style={{ width: '45%' }}>
                    수강시작일로부터 7일 이내, 4강 미만 수강 시 전액 환불 가능
                  </td>
                  <td>4강 이상 수강 시 부분 환불로 진행, 10강 이상 수강 시 환불 불가</td>
                </tr>
                <tr>
                  <td rowSpan='5'>부분 환불</td>
                  <td style={{ width: '45%' }}>수강시작일로부터 7일 이내, 4강-9강 수강했을 경우</td>
                  <td>반환액 : 총 결제금액 - 결제금액의 10% 위약금(서비스 이용금액)</td>
                </tr>
                <tr>
                  <td style={{ width: '45%' }}>
                    수강시작일로부터 7일 이내, 10강 이상 수강했을 경우
                  </td>
                  <td>환불 불가</td>
                </tr>
                <tr>
                  <td style={{ width: '45%' }}>
                    수강시작일로부터 7일 초과, 10강 미만 수강했을 경우
                  </td>
                  <td>
                    반환액 : 총 결제금액에서 결제금액의 10% 위약금(서비스 이용금액)을 제외한 나머지
                    금액 * 잔여 이용 일수/180일
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '45%' }}>
                    수강시작일로부터 7일 초과, 10강 이상 수강했을 경우
                  </td>
                  <td>환불 불가</td>
                </tr>
                <tr>
                  <td style={{ width: '45%' }}>수강시작일로부터 14일 초과된 경우</td>
                  <td>수강 강의 수에 상관 없이 환불 불가</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className='terms-text lv2'>
            다. 전자책(PDF) 환불 규정(※전자책이란 베어유에서 판매하는 PDF를 뷰어 기능으로 제공하는
            콘텐츠를 말합니다.)
          </p>
          <div className='terms-text lv2'>
            <table className='terms-table'>
              <thead>
                <tr>
                  <th>환불 구분</th>
                  <th>내용</th>
                  <th>상세 내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan='2'>전체 환불</td>
                  <td>전자책을 열람하지 않은 경우, 결제일 7일 이내</td>
                  <td rowSpan='2'>100% 환불</td>
                </tr>
                <tr>
                  <td>서비스 오류로 열람이 불가하고 열람 이력이 없을 경우</td>
                </tr>
                <tr>
                  <td>부분 환불</td>
                  <td>전자책을 열람하지 않고 7일 경과 14일 이내</td>
                  <td>90% 환불</td>
                </tr>
                <tr>
                  <td rowSpan='5'>환불 불가</td>
                  <td style={{ width: '45%' }}>
                    전자책 결제 완료일로부터 14일을 초과하는 경우 환불이 불가
                  </td>
                  <td rowSpan='5'></td>
                </tr>
                <tr>
                  <td style={{ width: '45%' }}>전자책 열람을 진행한 경우에는 환불이 불가</td>
                </tr>
                <tr>
                  <td style={{ width: '45%' }}>
                    내용의 불만족에 의한 사유로는 환불이 불가
                    <br />
                    <br />
                    단, 전자책 소개 페이지에 기재된 내용과 전자책에 포함된 내용이 다른 경우
                    베어유에서 확인하여 환불을 진행
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className='terms-text lv2'>
            라. 외부 사이트(와디즈, 카카오, 지마켓 등)를 통해 결제/구매하신 경우, 구매하신 판매
            사이트의 환불 규정등을 통한 별도의 수강 취소, 변경 및 환불 규정이 적용될 수 도 있습니다.
          </p>
          <p className='terms-text'>8. 회사의 환불 예외사항은 다음과 같습니다.</p>
          <p className='terms-text lv2'>
            가. 회원이 관계법령 및 본 약관의 규정을 위반하여 회사로부터 강제탈퇴 처리가 되는
            경우에는 본 조의 환불 규정이 적용되지 않습니다.
          </p>
          <p className='terms-text lv2'>
            나. 회사가 회원에게 무료로 지급한 할인쿠폰 등으로 인해 회원이 획득하게 된 금액에
            대해서는 환불되지 않습니다.
          </p>
          <p className='terms-text'>
            9. 환불 과정에서 금융수수료가 발생하는 경우, 관련 법률에 따라 결제금액의 10%를 공제하고
            환불할 수 있습니다.
          </p>
          <p className='terms-text'>
            10. 4항에도 불구하고 “회사”는 신뢰도 제고나 이벤트를 위해 일시적으로 환불 조건을 완화할
            수 있습니다.
          </p>
        </div>
      </li>
    </>
  );
};

export default TermsItem14b;
