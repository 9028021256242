import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";


const propTypes = {
    content: PropTypes.string,
    content_title: PropTypes.string,
    set_never_show: PropTypes.func
};

const CourseNoticeModal = (props) => {
    //Dialog Context
    const dialog_utils = props.dialog_utils || {};
    const dialog_props = props.dialog_props || {};

    const set_never_show = (e) => {
        props.set_never_show(e);
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    return (
        <div className="td-modal">
            <div className="td-dimmed"
                 onClick={onCloseModalBtn} />
            <div className="modal-content">
                <div className="modal-section simple-section">
                    <h3>강의 공지</h3>
                    <div
                        className="modal-close-btn"
                        style={{background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`}}
                        onClick={onCloseModalBtn}
                    />
                    <div className="modal-section-wrap">
                        <h4>{props.content_title}</h4>
                        <p dangerouslySetInnerHTML={{__html: props.content}} />
                    </div>
                    {props.set_never_show &&
                    <div className="read-notice-btn" onClick={set_never_show}>다시 보지않기</div>
                    }
                </div>
            </div>
        </div>
    );
};

CourseNoticeModal.propTypes = propTypes;

export default withRouter(CourseNoticeModal);
