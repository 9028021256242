import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../assets/scss/page/ebook.scss';
import '../../assets/scss/component/_search-bar.scss';
import CategoryNav from '../_common/component/CategoryNav';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import EbookModel from '../../model/EbookModel';
import * as qs from '../../util/query-string';
import BookList from './component/BookList';
import BookReviewSlider from './component/BookReviewSlider';
import ClassSlider from '../_common/component/ClassSlider';
import { overview_option } from '../../constant/option';
import LoadingSpinner from '../_common/component/LoadingSpinner';
import { useObserver } from '../../util/BearuHooks';
import CommonModel from '../../model/CommonModel';
import useEbookBookmark from '../../hook/useEbookBookmark';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../common'
import { HEADER_TYPE_MOBILE_BASE } from '../_common/section/Header'
import SearchBar from '../_common/component/SearchBar'

const ORDER_OPTIONS = [
  { value: 'popular', label: '인기순' },
  { value: 'newset', label: '등록순' },
  { value: 'sales', label: '구매순' },
];

function Ebook(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => qs.parse(location.search), [location.search]);
  const searchParams = useMemo(
    () => ({
      category_id: params.category_id || '',
      order_option: params.order_option || 'popular',
    }),
    [params],
  );
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [categories, setCategories] = useState([]);
  const isLast = useRef(false);
  const { toggleEbookBookmark } = useEbookBookmark();

	const CommonStore = useSelector((state) => state.CommonStore);
	const header = CommonStore.header;
	let q = new URLSearchParams(props.location.search).get("q");
	const [search_text, setSearchText] = useState(q);
	const [search_placeholder, setSearchPlaceHolder] = useState("");
	const onSearch = () => {
		if (search_text) {
			setSearchPlaceHolder("");
			props.history.push(`/search?q=${search_text}`);
		} else {
			setSearchPlaceHolder("검색어를 입력해주세요.");
		}
	};

	const onChangeSearchText = (e) => {
		setSearchText(e.target.value);
	};

  const initCategories = useCallback(async () => {
    const { code, code_set } = await CommonModel.staticCode({ code_name: 'ebook_category_set' });
    if (code !== 200) {
      alert('카테고리를 불러오는데 실패했습니다.');
      return;
    }
    setCategories(code_set);
  }, []);

  const initEbook = useCallback(
    async (page) => {
      try {
        if (!page) {
          window.scrollTo(0, 0);
          setLoading(true);
        }

        isLast.current = false;
        const result = await EbookModel.ebookList({ ...searchParams, page: page || 1 });
        setBooks((prev) => (!page ? result.books : [...prev, ...result.books]));
        setPage(page || 1);

        if (result.books.length < 10) {
          isLast.current = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [searchParams],
  );

  const initBookReview = useCallback(async () => {
    try {
      const { code, reviews } = await EbookModel.ebookRealtimeReviewList({
        size: 20,
      });
      if (code !== 200) {
        alert('실시간 리뷰를 불러오는데 실패했습니다.');
        return;
      }
      setReviewData(reviews);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    dispatch(set_header_type(0, 1, { no_top_btn: true, pop_up_btn: true }));
    dispatch(set_footer_type(0));
  }, []);

  useEffect(() => {
    initCategories();
  }, [initCategories]);

  useEffect(() => {
    initEbook();
  }, [initEbook]);

  useEffect(() => {
    initBookReview();
  }, [initBookReview]);

  const load_ref = useObserver(
    { threshold: 1 },
    () => {
      if (!books.length || loading || isLast.current) return;

      initEbook(page + 1);
    },
    null,
    [books, loading, page],
  );

  const handleBookmarkClick = async (book) => {
    const result = await toggleEbookBookmark(book.id);
    setBooks((prev) =>
      prev.map((b) =>
        b.id === book.id
          ? {
              ...b,
              is_favorite: result.is_favorite,
              favorite_count: result.favorite_count,
            }
          : b,
      ),
    );
  };

  return (
    <div className='ebook-container'>
      <Helmet>
        <title>베어유 : 시작이 막막할 때, 베어유에서 배워요</title>
      </Helmet>
			<div className="ebook-content">
				<div className="overview-slider-wrap">
					<ClassSlider option={overview_option} slide_type={1}/>
				</div>

				<div className="book-area flex-all-center">
					<div className="book-category-wrap">
					  <CategoryNav
						currentCategory={{ category: { id: searchParams.category_id || 'all' } }}
						categoryList={[
						  { id: 'all', name: '전체', url: location.pathname },
						  ...categories.map((c) => ({
							...c,
							id: c.id.toString(),
							url: `/ebook?${qs.stringify({
							  ...searchParams,
							  category_id: c.id
							})}`,
						  })),
						]}
					  />
					</div>
					<div className="flex-all-center w-full">
						<SearchBar/>
					</div>
				</div>

				{loading ? (
					<LoadingSpinner/>
				) : (
					<>
						{books.length === 0 && (
							<div className="empty-img">
								<em className="empty-title">결과가 없습니다</em>
								<img
									src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/search/logo_bear_b1.svg`}
									alt={'검색결과가 없습니다.'}
								/>
							</div>
						)}

						<div className="book-area">
							<div className='d-flex justify-content-center align-content-center'>
								<Select
									className="order-box"
									value={ORDER_OPTIONS.find((o) => o.value === searchParams.order_option)}
									isSearchable={false}
									options={ORDER_OPTIONS}
									onChange={({ value }) => {
										history.push({
											search: qs.stringify({
												...searchParams,
												order_option: value,
											}),
										})
									}}
								/>
							</div>
							<BookList bookList={books.slice(0, 10)}
												onBookmarkClick={handleBookmarkClick}/>
						</div>

						{reviewData?.realtime_reviews?.length > 0 && (
							<>
								<em className="review-title">실시간 리뷰</em>
								<div className="review-area">
									<BookReviewSlider reviewList={reviewData?.realtime_reviews}/>
								</div>
							</>
						)}

						<div className="book-area">
							<BookList bookList={books.slice(10)}
												onBookmarkClick={handleBookmarkClick}/>
						</div>
					</>
				)}
			</div>

			<div style={{ display: 'block', width: '100%', height: '1px' }}
					 ref={load_ref}/>
		</div>
	);
}

export default Ebook;
