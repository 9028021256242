import React from 'react';
import PropTypes from "prop-types";
import ReactPlayer from "react-player/youtube";

const propTypes = {
    video_id: PropTypes.string.isRequired,
}

const PreviewModal = (props) => {
    //Dialog
    const dialog_props = props.dialog_props || {};
    const dialog_utils = props.dialog_utils || {};

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    }

    return (
        <div className="td-modal w640-modal">
            <div className="td-dimmed" onClick={onCloseModalBtn}/>
            <div className="modal-content">
                <div className="modal-close-btn preview-close-btn">
                    <img src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon_close_white.svg`}
                         alt="모달창 닫기"
                         onClick={onCloseModalBtn}/>
                </div>
                <ReactPlayer
                    className="youtube-player"
                    url={`https://www.youtube.com/watch?v=${props.video_id}`}
                    playing={true}
                    controls={true}
                />
            </div>
        </div>
    )
}

PreviewModal.propTypes = propTypes;

export default PreviewModal;
