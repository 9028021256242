import {
    STORED_STATIC_CODE,
    SET_HEADER_TYPE,
    SET_FOOTER_TYPE,
    SET_TMP,
    STORED_SERVICE,
    RESET_TMP,
    SET_NOTI,
    SET_VERSION,
    RESET_STATIC_CODE,
} from "../action_type";


// 스태틱 코드
export const stored_static_code = (code_name, data) => ({
    type: STORED_STATIC_CODE,
    code_name,
    data
});

// 스태틱 코드 초기화
export const reset_static_code = () => ({
    type: RESET_STATIC_CODE
});

// 서비스 관련된 스태틱 객체 (스토어 업데이트시 이자식 사용)
export const stored_service = (key, data) => ({
    type: STORED_SERVICE,
    key,
    data
});

// data = {
//   title: (str) 헤더에 표현되는 타이틀 (mo_header_type === 2 일때 동작),
//   no_footer: (bool),
//   no_top_btn: (bool),
//   no_channel_talk_button: (bool),
// }
export const set_header_type = (pc_header_type, mo_header_type, data = null) => ({
    type: SET_HEADER_TYPE,
    pc_header_type,
    mo_header_type,
    data
});

export const set_footer_type = (pc_footer_type, data = null) => ({
    type: SET_FOOTER_TYPE,
    pc_footer_type,
    data
});

export const set_tmp = (key, data) => ({
    type: SET_TMP,
    key,
    data
});


export const reset_tmp = () => ({
    type: RESET_TMP
});


export const set_noti = (noti_count = 0, is_new_noti = false) => ({
    type: SET_NOTI,
    noti_count,
    is_new_noti
});

export const set_version = (version) => ({
    type: SET_VERSION,
    version,
});