import React, { useEffect, useState } from "react";
import "../../../assets/scss/page/empty_list.scss";
import {
    MYPAGE_COUPON,
    MYPAGE_COURSE,
    MYPAGE_EBOOK,
    MYPAGE_FAVORITE, MYPAGE_FAVORITE_EBOOK,
    MYPAGE_SHIPPING
} from "../../_collections/mypage";

const propTypes = {};

const EmptyList = (props) => {
    const [empty_dialog, setEmptyDialog] = useState("");
    const [empty_image, setEmptyImage] = useState("");

    useEffect(() => {
        if (props.category === MYPAGE_COURSE) {
            setEmptyDialog("구매한 클래스가 없습니다");
            setEmptyImage("class_0");
        } else if (props.category === MYPAGE_FAVORITE) {
            setEmptyDialog("저장한 클래스가 없습니다");
            setEmptyImage("class_0");
        } else if (props.category === MYPAGE_COUPON) {
            setEmptyDialog("보유한 쿠폰이 없습니다");
            setEmptyImage("coupon_0");
        } else if (props.category === MYPAGE_SHIPPING) {
            setEmptyDialog("배송 내역이 없습니다");
            setEmptyImage("shipping_0");
        } else if (props.category === MYPAGE_EBOOK) {
            setEmptyDialog("구매한 전자책이 없습니다");
            setEmptyImage("ebook_0");
        } else if (props.category === MYPAGE_FAVORITE_EBOOK) {
            setEmptyDialog("저장한 전자책이 없습니다");
            setEmptyImage("ebook_0");
        }
    });

    return (
        <div className="empty-list-wrap">
            <img
                className="empty-list-img"
                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/${empty_image}.png`}
                alt="내역 없음 이미지"
            />
            <p className="empty-list-dialog">{empty_dialog}</p>
        </div>
    );
};

EmptyList.propTypes = propTypes;

export default EmptyList;
