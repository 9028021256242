// data_set
// 강의 new_category
export const category_info_set = {
    1: {category_name: "디자인 뽀개기"},
    2: {category_name: "취업문 뽀개기"},
    3: {category_name: "창업문 뽀개기"},
    4: {category_name: "주식 뽀개기"},
    9: {category_name: "어학점수 뽀개기"},
    11: {category_name: "디자인 뽀개기"},
    13: {category_name: "마케팅 뽀개기"},
    14: {category_name: "취업문 뽀개기"},
    15: {category_name: "마케팅 뽀개기"},
    17: {category_name: "창업문 뽀개기"},
    18: {category_name: "디자인 뽀개기"},
    19: {category_name: "코딩 뽀개기"},
    20: {category_name: "마케팅 뽀개기"},
    21: {category_name: "취업문 뽀개기"},
    22: {category_name: "취업문 뽀개기"},
    24: {category_name: "취업문 뽀개기"},
    25: {category_name: "코딩 뽀개기"},
    26: {category_name: "주식 뽀개기"},
    27: {category_name: "어학점수 뽀개기"},
    28: {category_name: "주식 뽀개기"},
    30: {category_name: "주식 뽀개기"},
    31: {category_name: "주식 뽀개기"},
    33: {category_name: "마케팅 뽀개기"},
    34: {category_name: "디자인 뽀개기"},
    36: {category_name: "디자인 뽀개기"},
    39: {category_name: "디자인 뽀개기"},
    46: {category_name: "취업문 뽀개기"},
    47: {category_name: "데이터 뽀개기"},
    49: {category_name: "디자인 뽀개기"},
    50: {category_name: "디자인 뽀개기"},
    64: {category_name: "어학점수 뽀개기"},
    65: {category_name: "창업문 뽀개기"},
    66: {category_name: "주식 뽀개기"},
    67: {category_name: "코딩 뽀개기"},
    68: {category_name: "주식 뽀개기"},
    69: {category_name: "창업문 뽀개기"},
    70: {category_name: "창업문 뽀개기"},
    71: {category_name: "디자인 뽀개기"},
    72: {category_name: "창업문 뽀개기"},
    73: {category_name: "창업문 뽀개기"},
    74: {category_name: "취업문 뽀개기"},
    75: {category_name: "코딩 뽀개기"},
    77: {category_name: "어학점수 뽀개기"},
    79: {category_name: "마케팅 뽀개기"},
    82: {category_name: "코딩 뽀개기"},
    83: {category_name: "어학점수 뽀개기"},
    85: {category_name: "코딩 뽀개기"},
    87: {category_name: "취업문 뽀개기"},
    88: {category_name: "디자인 뽀개기"},
    91: {category_name: "마케팅 뽀개기"},
    92: {category_name: "디자인 뽀개기"},
    93: {category_name: "디자인 뽀개기"},
    94: {category_name: "주식 뽀개기"},
    98: {category_name: "코딩 뽀개기"},
    99: {category_name: "코딩 뽀개기"},
    100: {category_name: "마케팅 뽀개기"},
    101: {category_name: "디자인 뽀개기"},
    103: {category_name: "취업문 뽀개기"},
    104: {category_name: "코딩 뽀개기"},
    109: {category_name: "마케팅 뽀개기"},
    110: {category_name: "코딩 뽀개기"},
    111: {category_name: "코딩 뽀개기"},
    112: {category_name: "데이터 뽀개기"},
    113: {category_name: "디자인 뽀개기"},
    114: {category_name: "코딩 뽀개기"},
    115: {category_name: "디자인 뽀개기"},
    116: {category_name: "데이터 뽀개기"},
    117: {category_name: "마케팅 뽀개기"},
    118: {category_name: "데이터 뽀개기"},
    138: {category_name: "디자인 뽀개기"},
    140: {category_name: "취업문 뽀개기"},
    141: {category_name: "마케팅 뽀개기"},
    143: {category_name: "취업문 뽀개기"},
    144: {category_name: "취업문 뽀개기"},
};
