import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  card_badge: PropTypes.number,
  image_url: PropTypes.string,
  short_title: PropTypes.string,
  title: PropTypes.string,
  tag_set: PropTypes.array,
  redirect_to_url: PropTypes.string,
  on_bookmark_click: PropTypes.func,
  is_bookmark: PropTypes.bool,
  favorite_count: PropTypes.number,
  loading: PropTypes.bool,
  classification_name: PropTypes.string,
};

export const BADGE_TYPE_OPEN = 0;
export const BADGE_TYPE_BEST = 1;
export const BADGE_TYPE_NEW = 2;
export const BADGE_TYPE_PACKAGE = 3;

const BaseCard = (props) => {
  const hide_bookmark = props.hide_bookmark ?? true;

  const badge = (props) => {
    switch (props.card_badge) {
      case BADGE_TYPE_OPEN:
        return <p className='badge-open'>오픈예정</p>;
      case BADGE_TYPE_BEST:
        return <p className='badge-best'>Best</p>;
      case BADGE_TYPE_NEW:
        return <p className='badge-new'>New</p>;
      case BADGE_TYPE_PACKAGE:
        return <p className='badge-package'>패키지</p>;
      default:
        return null;
    }
  };

  return (
    <div className={'base-card' + (props.loading ? ' loading' : '')}>
      {props.loading ? null : (
        <>
          <Link to={props.redirect_to_url}>
            <div className='thumbnail-wrap'>
              <div
                className='thumbnail'
                style={{ background: `url(${props.image_url}) no-repeat center center/cover` }}
              />

              {hide_bookmark && (
                <div
                  className={'bookmark-wrap' + (props.is_bookmark ? ' active' : ' enp_mobon_wish')}
                  onClick={(e) => {
                    e.preventDefault();
                    props.on_bookmark_click && props.on_bookmark_click(props.is_bookmark);
                  }}
                >
                  <div className='bookmark-icon' />
                </div>
              )}

              {badge(props) && <div className='base-badge-wrap'>{badge(props)}</div>}
            </div>

            <div className='text-content'>
              <div className='title'>{props.title}</div>
            </div>

            <div className='card-master-desc'>
              {props.brand_name && props.category_name
                ? `${props.category_name}`
                : props.tag_set?.map((tag, index) => (
                    <React.Fragment key={index}>
                      {tag}
                      {index !== props.tag_set.length - 1 && ', '}
                    </React.Fragment>
                  ))}
            </div>
          </Link>
        </>
      )}
    </div>
  );
};

BaseCard.propTypes = propTypes;

export default BaseCard;
