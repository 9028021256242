import React from "react";
import MyPageMain from "../../account/section/MyPageMain";

// @TODO hpark 알림 자체 뷰 눌렀을때는 안꺼지게
// @TODO hpark CSS 위치깨짐

const MyProfileModal = (props) => {
    //Modal
    const dialog_utils = props.dialog_utils || {};
    const dialog_props = props.dialog_props || {};

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    return (
        <>
            <div className="header-modal-wrap"
                 onClick={onCloseModalBtn} />
            <div className="modal-container">
                <div className={"header-modal my-profile-modal td-scroll"}>
                    <div className="header-modal-content">
                        <MyPageMain is_modal={true} is_mobile={false} />
                    </div>
                </div>
            </div>
        </>
    );
};


export default MyProfileModal;
