import React, { useRef, useState } from 'react';
import { init_comma, set_favorite_icon } from '../../../common';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../../../assets/scss/component/_favorite_card.scss';
import CourseModel from '../../../model/CourseModel';
import { set_account_favorite_count } from '../../../redux/account/action';
import ProductModel from '../../../model/ProductModel';
import { get_rental_month_period } from '../../../bearu_utils';

const propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  discount_rate: PropTypes.number,
  price: PropTypes.number,
  original_price: PropTypes.number,
  favorite_count: PropTypes.number,
  main_thumbnail_image_url: PropTypes.string,
  period_time_in_day: PropTypes.number,
};

const FavoriteCard = (props) => {
  const { id, description, discount_rate, price, main_thumbnail_image_url, period_time_in_day } = props?.data;
  const category = props.category;
  const [is_favorite, setFavorite] = useState(true);
  const period_month = get_rental_month_period(period_time_in_day);
  const _favorite_pending = useRef(false);
  const dispatch = useDispatch();

  const doFavorite = async (e) => {
    setFavorite(!is_favorite);

    if (!_favorite_pending.current) {
      _favorite_pending.current = true;

      if (category === 'course') {
        CourseModel.courseFavorite({
          course_id: id,
        }).then(({ is_favorite, favorite_count, account_class_favorite_count }) => {
          dispatch(set_account_favorite_count(account_class_favorite_count));
          set_favorite_icon('course', id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      } else if (category === 'package') {
        ProductModel.packageFavorite({
          package_id: id,
        }).then(({ is_favorite, favorite_count, account_favorite_count }) => {
          dispatch(set_account_favorite_count(account_favorite_count));
          set_favorite_icon('package', id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      } else if (category === 'kit') {
        ProductModel.kitFavorite({
          kit_id: id,
        }).then(({ is_favorite, favorite_count, account_favorite_count }) => {
          dispatch(set_account_favorite_count(account_favorite_count));
          set_favorite_icon('kit', id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      }
      e.preventDefault();
    }
  };

  return (
    <Link className='favorite-course-card' to={`/${category}/${id}`}>
      <div className='course-main-info-wrap'>
        <div className='thumbnail-wrap'>
          <img
            src={
              main_thumbnail_image_url
                ? main_thumbnail_image_url
                : `${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_bear_m3.png`
            }
            alt='원형 강의 이미지'
          />
          <div
            className={`bookmark-wrap ${is_favorite ? '' : 'enp_mobon_wish'}`}
            onClick={(e) => doFavorite(e, category)}
          >
            <div className={`bookmark-icon ${is_favorite ? 'active' : ''}`} />
          </div>
        </div>
      </div>

      <div className='desc-wrap'>
        <p>{description}</p>
      </div>

      <div className='course-price-info-wrap'>
        {discount_rate > 0 ? (
          <span>
            <h4>{discount_rate}% 할인</h4>
          </span>
        ) : (
          <div />
        )}

        <h3>
          {period_month > 1
            ? `월 ${init_comma(Math.ceil(price / period_month || 0))}원`
            : `${init_comma(price || 0)}원`}
        </h3>
      </div>
    </Link>
  );
};

FavoriteCard.propTypes = propTypes;

export default FavoriteCard;
