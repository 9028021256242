import axios from "axios";
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

class PixelModel {
	async postConversionAPI({
		eventName,
		AccountStore,
		customDataOptions = {}
	}) {
		try {
			const PIXEL_ID = process.env.REACT_APP_PIXEL_ID;
			const PIXEL_ACCESS_TOKEN = process.env.REACT_APP_PIXEL_ACCESS_TOKEN;
			const PIXEL_TEST_ID = process.env.REACT_APP_PIXEL_TEST_ID;
			const sha256Sync = (message) => CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
			const unixTimestamp = Math.floor(Date.now() / 1000);

			await axios.post(
				`https://graph.facebook.com/v18.0/${PIXEL_ID}/events`,
				new URLSearchParams({
					data: JSON.stringify([
						{
							event_name: eventName,
							event_id: `${eventName}-${uuidv4()}`,
							event_time: unixTimestamp,
							action_source: 'website',
							user_data: {
								client_ip_address: null,
								client_user_agent: navigator.userAgent,
								fn: [sha256Sync(AccountStore ? AccountStore?.info?.name : 'guest')],
								em: [sha256Sync(AccountStore ? AccountStore?.info?.email : 'guest')],
								ph: [sha256Sync(AccountStore ? AccountStore?.info?.phone.replaceAll('-','') : 'guest')],
								fbc:'fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
								fbp:'fb.1.1558571054389.1098115397',
							},
							custom_data: {
								currency: 'KRW',
								...customDataOptions,
							},
						},
					]),
					access_token: PIXEL_ACCESS_TOKEN,
					test_event_code: PIXEL_TEST_ID,
				}),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					withCredentials: false,
				},
			)
		} catch (error) {
			console.log("priceValidate error::",error);
		}
	}
}

export default new PixelModel();
