import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

const propTypes = {
    shipping_status: PropTypes.number.isRequired
}
const ShippingProgress = (props) => {
    const progress_div = [
        {class_name: "step"},
        {class_name: "step-line"},
        {class_name: "step"},
        {class_name: "step-line"},
        {class_name: "step"},
    ];

    return (
        <div className="shipping-progress">
            {progress_div.map((elem, index) => {
                let active_class_name = "";
                if (props.shipping_status !== 1) {
                    if (props.shipping_status >= index) {
                        active_class_name += " active";
                    } else if (props.shipping_status === 3) {
                        active_class_name += " active";
                    }
                } else {
                    if (index === 0) {
                        active_class_name += " active";
                    }
                }
                return (
                    <div key={index} className={elem.class_name + active_class_name}/>
                );
            })}
        </div>
    )
}

ShippingProgress.propTypes = propTypes;

export default withRouter(ShippingProgress);
