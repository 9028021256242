import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import '../../../assets/scss/component/category_nav.scss';

const ICON_ARRAY = [
  {
    name: '전체',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_전체.png`,
  },
  {
    name: '추천패키지',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_추천.png`,
  },
  {
    name: '커리어스킬',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_커리어스킬.png`,
  },
  {
    name: '오피스툴',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_오피스툴.png`,
  },
  {
    name: '직무인사이트',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_직무인사이트.png`,
  },
  {
    name: '디자인/영상',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_디자인.png`,
  },
  {
    name: '마케팅',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_마케팅.png`,
  },
  {
    name: 'IT/개발',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_IT개발.png`,
  },
  {
    name: '어학',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_어학.png`,
  },
  {
    name: 'N잡',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_N잡.png`,
  },
  {
    name: '창업',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_창업.png`,
  },
  {
    name: '재테크',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_재테크.png`,
  },
  {
    name: '크리에이티브',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_크리에이티브.png`,
  },
  {
    name: '기타',
    url: `${process.env.REACT_APP_IMG_URL}/assets/images/icons/main_page/icon_기타.png`,
  },
];

const propTypes = {
  currentCategory: PropTypes.any,
  categoryList: PropTypes.array,
  nav_type: PropTypes.string,
  selected_category: PropTypes.any,
};

const CategoryNav = ({ currentCategory, categoryList, history }) => {
  const categoryRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const isVisible = (category_id) => {
    return currentCategory.category?.id === category_id;
  };

  const checkScroll = () => {
    if (categoryRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = categoryRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useLayoutEffect(() => {
    checkScroll();

    const handleResize = () => {
      checkScroll();
    };

    window.addEventListener('resize', handleResize);
    if (categoryRef.current) {
      categoryRef.current.addEventListener('scroll', checkScroll);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (categoryRef.current) {
        categoryRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, []);

  useEffect(() => {
    checkScroll();
  }, [categoryList]);

  const scroll = (scrollOffset) => {
    if (categoryRef.current) {
      categoryRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
      setTimeout(checkScroll, 300);
    }
  };

  const flattenedCategories = categoryList.flatMap((category) => {
    if (category.sub_category_set && category.sub_category_set.length > 0) {
      return [category, ...category.sub_category_set];
    }
    return [category];
  });

  return (
    <div className='category-nav-container'>
      {!isTabletOrMobile && canScrollLeft && (
        <button onClick={() => scroll(-200)} className='category-nav-button' style={{ left: 0 }}>
          <img
            src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/arrow_l.svg`}
            alt='left arrow'
          />
        </button>
      )}

      <ul
        ref={categoryRef}
        className={`category-nav-list ${isTabletOrMobile ? 'tablet-or-mobile' : 'desktop'}`}
      >
        {flattenedCategories.map((category) => (
          <li
            key={category.id}
            className={`category-nav-item ${isTabletOrMobile ? '' : 'desktop'} ${
              isVisible(category.id) ? 'visible' : ''
            }`}
          >
            <a
              onClick={() => {
                history.push(category.url || `/overview/${category.id}`);
              }}
            >
              <img
                src={ICON_ARRAY.find((icon) => icon.name === category.name).url}
                alt={ICON_ARRAY.find((icon) => icon.name === category.name).name}
              />
              <span>{category.name}</span>
            </a>
          </li>
        ))}
      </ul>

      {!isTabletOrMobile && canScrollRight && (
        <button onClick={() => scroll(200)} className='category-nav-button' style={{ right: 0 }}>
          <img
            src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/arrow_r.svg`}
            alt='right arrow'
          />
        </button>
      )}
    </div>
  );
};

CategoryNav.propTypes = propTypes;

export default withRouter(CategoryNav);
