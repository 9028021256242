import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import update from 'react-addons-update';
import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';

import { set_footer_type, set_header_type, stored_static_code } from '../../redux/common/action';
import '../../assets/scss/component/_search-bar.scss';
import '../../assets/scss/page/overview.scss';
import CategoryNav from '../_common/component/CategoryNav';
import ClassSlider from '../_common/component/ClassSlider';
import CommonModel from '../../model/CommonModel';
import CourseModel from '../../model/CourseModel';
import CourseCard2 from '../_common/component/CourseCard2';
import AllCourseList from '../_common/section/AllCourseList';
import { getSeed, useMount, useObserver } from '../../util/BearuHooks';
import PackageCard2 from '../_common/component/PackageCard2';
import ProductModel from '../../model/ProductModel';
import { overview_option } from '../../constant/option';
import BaseCard from '../_common/component/BaseCard';
import SearchBar from '../_common/component/SearchBar';

const OPTION_RANDOM = { value: 'random', label: '랜덤순' };
const OPTION_LATEST = { value: 'latest', label: '최신순' };
const OPTION_POPULAR = { value: 'popular', label: '인기순' };
const ORDER_OPTIONS = [OPTION_RANDOM, OPTION_LATEST, OPTION_POPULAR];

const init_category_set = [
  {
    id: 'all',
    name: '전체',
    url: '/overview/all',
    title: '베어유 :  시작이 막막할 때, 베어유에서 배워요',
  },
  {
    id: 'package',
    name: '추천패키지',
    url: '/overview/package',
  },
];

const Overview = (props) => {
  const dispatch = useDispatch();
  const [isMount] = useMount([props.match.params.id]);

  const [seo_title, setSeoTitle] = useState('');
  const [overview_set, setOverviewSet] = useState(null);
  const [is_sticky, setIsSticky] = useState(false);
  const [category_list, setCategoryList] = useState([]);
  const [category, setCategory] = useState(null);
  const [selected_value, setSelectedValue] = useState(OPTION_RANDOM.value);
  const [product_set, setProductSet] = useState(null);
  const [curation_set, setCurationSet] = useState(null);
  const [start_animate, setStartAnimate] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const filterRef = useRef(OPTION_RANDOM.value);
  let isLast = useRef(false);
  let isPending = useRef(false);
  let page = useRef(1);
  let size = 8;

  const load_ref = useObserver(
    { threshold: 0 },
    () => {
      requestUpdateList(filterRef.current);
    },
    null,
    [props.match.params.id],
  );

  const nav_ref = useObserver(
    {},
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: false,
        }),
      );
    },
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: true,
        }),
      );
    },
    [],
  );

  const animate_trigger = useObserver(
    {},
    () => {
      setStartAnimate((start_animate) =>
        update(start_animate, {
          $set: false,
        }),
      );
    },
    () => {
      setStartAnimate((start_animate) =>
        update(start_animate, {
          $set: true,
        }),
      );
    },
    [],
  );

  useEffect(() => {
    dispatch(set_header_type(0, 1, { no_top_btn: true, pop_up_btn: true }));
    dispatch(set_footer_type(0));
  }, []);

  useEffect(() => {
    requestGetCategorySet();
    requestGetProductSet();
    requestGetCurationSet();
  }, []);

  useEffect(() => {
    let new_selected_option = OPTION_RANDOM.value;

    isLast.current = false;
    isPending.current = false;
    page.current = 1;
    setOverviewSet(null);
    setSelectedValue(new_selected_option);
    filterRef.current = OPTION_RANDOM.value;

    // 중복코드
    let find_category = findCategory(props.match.params.id, category_list);
    let new_title = find_category.category?.name || '베어유 : 시작이 막막할 때, 베어유에서 배워요';
    setSeoTitle(new_title);
    setCategory(find_category);

    if (find_category?.category?.id !== 'all') {
      requestUpdateList(new_selected_option);
    }

    props.ready();
  }, [props.match.params.id]);

  const requestGetCurationSet = async () => {
    CommonModel.curationList({ search_text: null }).then(({ curation_set }) => {
      if (isMount()) {
        setCurationSet(curation_set);
      }
    });
  };

  const requestGetProductSet = async () => {
    ProductModel.packageList({ page: 1, size: 30 }).then(({ product_set }) => {
      if (isMount()) {
        setProductSet(product_set);
      }
    });
  };

  const requestGetCategorySet = async () => {
    const { code_set } = await CommonModel.staticCode({ code_name: 'course_category_set' });
    if (!isMount()) return;

    let new_category_list = [...init_category_set, ...(code_set || [])];
    setCategoryList(new_category_list);
    dispatch(stored_static_code('course_category_set', code_set));

    // 중복코드
    let find_category = findCategory(props.match.params.id, new_category_list);
    let new_title =
      find_category.category?.title ||
      find_category.category?.name ||
      '베어유 :  시작이 막막할 때, 베어유에서 배워요';
    setSeoTitle(new_title);
    setCategory(find_category);
  };

  const requestUpdateList = (order_option = filterRef.current) => {
    if (!isLast.current && !isPending.current) {
      isPending.current = true;
      if (parseInt(props.match.params.id)) {
        CourseModel.courseList({
          page: page.current,
          size: size,
          category_id: props.match.params.id,
          order_option: order_option,
          is_mine: 0,
          seed: getSeed(),
        }).then(({ course_set }) => {
          if (isMount()) {
            setOverviewSet((state) => [...(state || []), ...course_set]);
            if (course_set.length < size) {
              isLast.current = true;
            }
            page.current++;
            isPending.current = false;
          }
        });
      } else if (props.match.params.id === 'package') {
        ProductModel.packageList({
          page: page.current,
          size: size,
          order_option: order_option,
          seed: getSeed(),
        }).then(({ product_set }) => {
          if (isMount()) {
            setOverviewSet((state) => [...(state || []), ...product_set]);
            if (product_set.length < size) {
              isLast.current = true;
            }
            page.current += 1;
            isPending.current = false;
          }
        });
      }
    }
  };

  const findCategory = (category_id, category_list) => {
    let find = {
      category: null,
      parent_category: null,
    };
    let category_list_size = category_list ? category_list.length : 0;
    for (let i = 0; i < category_list_size; i++) {
      let category = category_list[i];

      let sub_category_set_length = category.sub_category_set?.length;
      for (let j = 0; j < sub_category_set_length; j++) {
        let sub_category = category.sub_category_set[j];

        if (sub_category.id == category_id) {
          find.category = sub_category;
          find.parent_category = category;
          break;
        }
      }

      if (category.id == category_id) {
        find.category = category;
        break;
      }
    }
    return find;
  };

  const DropdownIndicator = (props) => {
    return isTabletOrMobile ? (
      <components.DropdownIndicator {...props}>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/overview/ic_array.svg`}
          alt={'필터아이콘'}
        />
      </components.DropdownIndicator>
    ) : (
      <components.DropdownIndicator {...props}>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/overview/ic_arrow_down.svg`}
          alt={'필터아이콘'}
        />
      </components.DropdownIndicator>
    );
  };

  const filteringClass = (value) => {
    isLast.current = false;
    isPending.current = false;
    page.current = 1;
    setSelectedValue(value);
    setOverviewSet(null);
    requestUpdateList(value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'none',
      boxShadow: 'none',
      border: state.isFocused && 'none',
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && 'transparent',
      color: state.isFocused && '#4745FF',
      cursor: 'pointer',
      '&:hover': { backgroundColor: 'transparent' },
    }),

    singleValue: (provided) => ({
      ...provided,
      backgroundColor: 'none',
    }),
  };

  return (
    <div className='td-container-v2 overview-container'>
      <Helmet>
        <title>{seo_title}</title>
      </Helmet>

      {is_sticky && (
        <div className='overview-nav-wrap'>
          <ul>
            {category_list.map((category, index) => {
              return (
                <li
                  key={index}
                  className={
                    props.match.params.id.toString() === category.id.toString() ? 'selected' : ''
                  }
                >
                  <Link to={category.url || `/overview/${category.id}`}>{category.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {!isTabletOrMobile && <div className='block-width-full-height-one' ref={animate_trigger} />}

      <div className='overview-slider-wrap'>
        <ClassSlider option={overview_option} slide_type={1} />
      </div>

      {isTabletOrMobile && <div className='block-width-full-height-one' ref={animate_trigger} />}

      <div className={`td-wrapper-v2`}>
        <div ref={nav_ref}>
          <CategoryNav currentCategory={category} categoryList={category_list} />
        </div>
      </div>

      <SearchBar />

      <div className='course-content'>
        <div className='td-wrapper-v2'>
          {overview_set && !overview_set.length ? (
            <div className='empty-content'>
              <div className='empty-img'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/search/logo_bear_b1.svg`}
                  alt={'검색결과가 없습니다.'}
                />
              </div>
              <p className='title'>강의를 준비중입니다</p>
            </div>
          ) : (
            <>
              {props.match.params.id === 'all' ? (
                <>
                  <AllCourseList />
                </>
              ) : (
                <div className='overview-course-wrap'>
                  <div className='class-filter'>
                    <Select
                      options={ORDER_OPTIONS}
                      onChange={(options) => {
                        filteringClass(options.value);
                        filterRef.current = options.value;
                      }}
                      value={ORDER_OPTIONS.filter((option) => {
                        return option.value === selected_value;
                      })}
                      styles={customStyles}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                      }}
                      isSearchable={false}
                    />
                  </div>

                  <div className='course-list overview-list'>
                    {overview_set
                      ? overview_set.map((item, index) => {
                          if (props.match.params.id === 'package') {
                            return <PackageCard2 key={index} product={item} />;
                          } else {
                            return <CourseCard2 key={index} course={item} />;
                          }
                        })
                      : Array.from({ length: 12 }).map((data, index) => {
                          return <BaseCard key={index} loading={true} />;
                        })}
                  </div>
                </div>
              )}
            </>
          )}

          <div className='block-width-full-height-one' ref={load_ref} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
