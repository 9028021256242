import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import CourseInvoice from '../../course/component/CourseInvoice';
import CourseModel from '../../../model/CourseModel';

const propTypes = {
  id: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

// @TODO hpark 뒤로가기 에러있음 반드시 확인
const PurchaseModal = (props) => {
  //Dialog
  const dialog_props = props.dialog_props || {};
  const dialog_utils = props.dialog_utils || {};

  //MediaQuery
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });

  //Course
  const [course, setCourse] = useState(null);

  useEffect(() => {
    CourseModel.courseDetail({ course_id: props.id, only_type: 'extend_sale' }).then(
      ({ course }) => {
        setCourse(course);
      },
    );
  }, []);

  const onCloseModalBtn = () => {
    dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
  };

  return (
    <div className='td-modal purchase-modal'>
      <div className='td-dimmed' onClick={onCloseModalBtn} />
      <div className='modal-content'>
        {is_tablet_or_mobile && course && (
          <div className='mo-invoice-title-wrap'>
            <h3>기간연장</h3>
          </div>
        )}
        <div
          className='modal-close-btn'
          style={{
            background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
          }}
          onClick={onCloseModalBtn}
        />
        {is_tablet_or_mobile && course && (
          <h3 className='purchase-modal-title'>{course.short_title}</h3>
        )}
        {course ? (
          <CourseInvoice
            course={course}
            invoice_type={2}
            moveToPurchase={() => {
              dialog_utils.moveTo && dialog_utils.moveTo({ url: '/purchase' });
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

PurchaseModal.propTypes = propTypes;

export default PurchaseModal;
