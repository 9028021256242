import axios from 'axios';

class AccountModel {
  async signIn({ email, password }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/signin`, {
      email,
      password,
    });
    let account = code === 200 ? data.account : null;
    return { code, account };
  }

  async signUp({
    email,
    password,
    name,
    nickname,
    gender,
    birth,
    job_class_id,
    address,
    address_detail,
    address_code,
    phone,
  }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/signup`, {
      email,
      password,
      name,
      nickname,
      gender,
      birth,
      job_class_id,
      address,
      address_detail,
      address_code,
      phone,
    });
    let account = code === 200 ? data.account : null;

    return { code, account };
  }

  async signOut() {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/signout`);
    return { code };
  }

  async forceSignIn(account_id) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/force-signin/${account_id}`);
    return { code, account:data.account };
  }

  async socialSign({ provider_type, social_token, redirect_url }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/social`, {
      provider_type,
      social_token,
      redirect_url,
    });

    let account = code === 200 ? data.account : null;
    let is_sign_up = code === 200 ? data.is_sign_up : null;

    return { code, account, is_sign_up };
  }

  async bgdCheck() {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/bgd/`);
    return { data };
  }

  async duplicateCheck({ req_type, req_value }) {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/check`, {
      req_type,
      req_value,
    });
    return { code };
  }

  async validate({ access_token = null }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/validate`, {
      access_token,
    });
    let account = code === 200 ? data.account : null;
    return { code, account };
  }

  async notificationList({ page, size }) {
    const {
      data: {
        code,
        data: { notification_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/notification`, {
      params: { page, size },
    });
    return { code, notification_set };
  }

  async notificationRead({ notification_id }) {
    const {
      data: { code },
    } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/account/notification/${notification_id}`,
    );

    return { code };
  }

  async notificationNewCheck() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/notification/check`);
    let is_new_noti = code === 200 ? data.is_new_noti : null;
    let total_count = code === 200 ? data.total_count : 0;
    return { code, is_new_noti, total_count };
  }

  async sessionCheck() {
    const {
      data: { code },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/session/check`);
    return code;
  }

  async myCourseList() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/course`);
    let course_set = [];
    if (code === 200) {
      for (let i = 0; i < data.course_set.length; i++) {
        data.course_set[i].id = data.course_set[i].course_id;
        delete data.course_set[i].course_id;
      }
      course_set = data.course_set;
    }
    return { code, course_set };
  }

  async myShippingList() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/shipping`);
    let kit_set = code === 200 ? data.kit_set : [];

    return { code, kit_set };
  }

  async myFavoriteList() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/favorite`);
    let course_favorite_set = [];
    let package_favorite_set = [];
    let kit_favorite_set = [];
    if (code === 200) {
      for (let i = 0; i < data.course_favorite_set.length; i++) {
        data.course_favorite_set[i].id = data.course_favorite_set[i].course_id;
        delete data.course_favorite_set[i].course_id;
      }
      for (let i = 0; i < data.package_favorite_set.length; i++) {
        data.package_favorite_set[i].id = data.package_favorite_set[i].package_id;
        delete data.package_favorite_set[i].package_id;
      }
      for (let i = 0; i < data.kit_favorite_set.length; i++) {
        data.kit_favorite_set[i].id = data.kit_favorite_set[i].kit_id;
        delete data.kit_favorite_set[i].kit_id;
      }
      course_favorite_set = data.course_favorite_set;
      package_favorite_set = data.package_favorite_set;
      kit_favorite_set = data.kit_favorite_set;
    }

    return { code, course_favorite_set, package_favorite_set, kit_favorite_set };
  }

  async myCouponList() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/coupon`);
    let coupon_set = code === 200 ? data.coupon_set : null;
    return { code, coupon_set };
  }

  async myEbookList() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/ebook`);
    const ebook_set = code === 200 ? data.ebook_set : [];
    return { code, ebook_set };
  }

  async myFavoriteEbookList() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/account/ebook-favorite`);
    const ebook_favorite_set = code === 200 ? data.ebook_favorite_set : [];
    return { code, ebook_favorite_set };
  }

  async myCertificationDetail({ course_id }) {
    const {
      data: { code, data },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/account/certification/course/${course_id}`,
    );

    return { code, data };
  }

  async profileEdit({
    email = null,
    name = null,
    nickname = null,
    gender = null,
    birth = null,
    job_class_id = null,
    address = null,
    address_detail = null,
    address_code = null,
    phone = null,
    profile_img = null,
    is_del_img = 0,
  }) {
    let form_data = new FormData();
    form_data.append('email', email);
    form_data.append('name', name);
    form_data.append('nickname', nickname);
    form_data.append('gender', gender);
    form_data.append('birth', birth);
    form_data.append('job_class_id', job_class_id);
    form_data.append('address', address);
    form_data.append('address_detail', address_detail);
    form_data.append('address_code', address_code);
    form_data.append('phone', phone);
    form_data.append('profile_img', profile_img);
    form_data.append('is_del_img', is_del_img);
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/profile`, form_data);

    let account = code === 200 ? data.account : null;
    return { code, account };
  }

  async passwordEdit({ password, new_password }) {
    const {
      data: { code },
    } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/account/password`, {
      password,
      new_password,
    });
    return { code };
  }

  async emailFind({ name, birth, phone }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/emailfind`, {
      name,
      birth,
      phone,
    });
    let email = code === 200 ? data.email : null;
    return { code, email };
  }

  async dropOut() {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/dropout`);
    return { code };
  }

  async passwordReset({ email }) {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/account/password`, { email });
    return { code };
  }
}

export default new AccountModel();
