import React, {useEffect, useRef, useState} from "react";
import CourseCard2 from "../component/CourseCard2";
import CourseModel from "../../../model/CourseModel";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation} from "swiper";
import PropTypes from "prop-types";
import PackageCard2 from "../component/PackageCard2";
import {shuffle} from "../../../common";
import {Link} from "react-router-dom";
import {popular_list_option} from "../../../constant/option";

SwiperCore.use([Navigation]);

const propTypes = {
    course_type: PropTypes.number,
    title: PropTypes.string,
    desc: PropTypes.string,
    course_set: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.number,
    category: PropTypes.object,
    with_product_set: PropTypes.bool // def false
};

const PopularList2 = (props) => {
    const [promotion_set, setPromotionSet] = useState([]);
    const _is_mount = useRef(false);

    useEffect(() => {
        _is_mount.current = true;
        CourseModel.promotionList({
            promotion_type_id: props.promotion_type_id,
            with_product_set: props.with_product_set
        }).then(({course_set, product_set}) => {
            if (_is_mount.current) {
                let with_product_set = props.with_product_set || false;

                // data initialize
                let new_promotion_set = [];
                course_set.forEach((course) => {
                    new_promotion_set.push({
                        type: "course",
                        data: course
                    });
                });
                if (with_product_set) {
                    // @default version
                    // product_set.forEach((product) => {
                    //     new_promotion_set.push({
                    //         type: "product",
                    //         data: product,
                    //     });
                    // });

                    // @기획 version, 랜덤 그리고 2,4 번째 패키지 넣기
                    shuffle(new_promotion_set);
                    let product_set_size = product_set ? product_set.length : 0;
                    if (product_set_size > 0) {
                        if (product_set[0]) {
                            new_promotion_set.splice(2 - 1, 0, {
                                type: "product",
                                data: product_set[0]
                            });
                        }
                        if (product_set[1]) {
                            new_promotion_set.splice(4 - 1, 0, {
                                type: "product",
                                data: product_set[1]
                            });
                        }
                    }
                }
                setPromotionSet(new_promotion_set);
                if (props.setLoading) props.setLoading(true);
            }
        });
        return () => {
            _is_mount.current = false;
        };
    }, []);

    return (
        <>
            {props.list_type ? (
                <div className="popular-course-content">
                    <h3 className="popular-content-title">
                        지금 베어유에서 가장 인기있는 클래스
                    </h3>
                    <div className="course-list overview-list">
                        {promotion_set?.slice(0, 4).map((promotion, index) => {
                            if (promotion.type === "course") {
                                return <CourseCard2 key={index} course={promotion.data} />
                            } else if (promotion.type === "product") {
                                return <PackageCard2 key={index} product={promotion.data} />
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            ) : (
                // 메인홈 인기 클래스
                <div className="popular-course-content-main">
                    <div className="content-title">
                        <Link to="/overview/all">
                            <h3>지금 가장 인기있는 클래스</h3>
                        </Link>
                        <Link to="/overview/all">
                            <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_arrow_r_b.svg`}
                                alt="다음버튼"
                            />
                        </Link>
                    </div>
                    <div className="popular-course-slide">
                        <Swiper {...popular_list_option}>
                            {promotion_set?.map((promotion, index) => {
                                if (promotion.type === "course") {
                                    return (
                                        <SwiperSlide key={index}>
                                            <CourseCard2 course={promotion.data} />
                                        </SwiperSlide>
                                    );
                                } else if (promotion.type === "product") {
                                    return (
                                        <SwiperSlide key={index}>
                                            <PackageCard2 product={promotion.data} />
                                        </SwiperSlide>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Swiper>
                        <div className="swiper-popular-slider-prev">
                            <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_arrow_l_b.svg`}
                                alt="이전버튼"
                            />
                        </div>
                        <div className="swiper-popular-slider-next">
                            <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_arrow_r_b.svg`}
                                alt="다음버튼"
                            />
                        </div>
                    </div>

                    <div className="popular-course-btn">
                        <Link to="/overview/all">
                            <span>더보러가기</span>
                            <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_arrow_r_b2.svg`}
                                alt="더보기버튼"
                            />
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};
PopularList2.propTypes = propTypes;

export default PopularList2;
