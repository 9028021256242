import React, {useEffect, useRef, useState} from "react";
import {set_footer_type, set_header_type} from "../../redux/common/action";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";
import "../../assets/scss/page/account_info_edit.scss";
import {dialog_type_custom, useDialogContext} from "../_common/dialog/DialogContextProvider";
import InputLabel from "../_common/Input/InputLabel";
import NameInput from "../_common/Input/NameInput";
import PhoneInput from "../_common/Input/PhoneInput";
import BirthInput from "../_common/Input/BirthInput";
import GenderButton from "../_common/Input/GenderButton";
import JobSelectBox from "../_common/Input/JobSelectBox";
import NicknameInput from "../_common/Input/NicknameInput";
import AddressInput from "../_common/Input/AddressInput";
import ProfilePhotoModal from "../_common/dialog/ProfilePhotoModal";
import {add_dash_to_phone_number} from "../../common";
import AccountModel from "../../model/AccountModel";
import EmailInput from "../_common/Input/EmailInput";
import {stored_account_info} from "../../redux/account/action";
import {useMount} from "../../util/BearuHooks";
import {useBroadcastContext} from "../_common/broadcast/BroadcastContextProvider";
import {BROADCAST_NICKNAME_VALIDATION_CHECK} from "../../constant/broadcast";

const AccountInfoEdit = (props) => {
    const dispatch = useDispatch();
    const {AccountStore} = useSelector((state) => state);
    const {alert, showDialog} = useDialogContext();
    const {broadcast} = useBroadcastContext();
    const [isMount] = useMount();

    useEffect(() => {
        dispatch(
            set_header_type(0, 2, {
                title: "개인정보 수정",
                no_footer: true,
                no_top_btn: true,
            })
        );
        dispatch(set_footer_type(0));
        props.ready();
    }, []);

    const input_values = useRef({
        email: AccountStore?.info?.email || "",
        name: AccountStore?.info?.name || "",
        phone: add_dash_to_phone_number(AccountStore?.info?.phone) || "",
        birth: AccountStore?.info?.birth || "",
        gender: AccountStore?.info?.gender || "",
        job_class_id: AccountStore?.info?.job_class || "",
        nickname: AccountStore?.info?.nickname || "",
        profile_image: AccountStore?.info?.picture_thumbnail_image_url || "",
    });

    const input_address_values = useRef({
        address: AccountStore?.info?.address || "",
        address_detail: AccountStore?.info?.address_detail || "",
        address_code: AccountStore?.info?.address_code || "",
    });

    const is_values_check = useRef({
        email: !!input_values.current.email,
        name: !!input_values.current.name,
        phone: !!input_values.current.phone,
        birth: !!input_values.current.birth,
        gender: !!input_values.current.gender,
        job_class_id: !!input_values.current.job_class_id,
        nickname: !!input_values.current.nickname,
    });

    const input_list_ref = useRef({
        email: null,
        name: null,
        phone: null,
        birth: null,
    });

    const [error, setError] = useState({
        gender: true,
        job_class_id: true,
        address: true,
    });

    // 프로필 모달창
    const profilePhotoModalOpen = () => {
        showDialog({
            type: dialog_type_custom,
            component: ProfilePhotoModal,
        });
    };

    const checkValidate = () => {
        return Object.values(is_values_check.current).every((value) => value);
    };

    const checkAddressValidate = () => {
        return Object.values(input_address_values.current).every((value) => value);
    };

    // 개인정보 수정
    const onAccountSubmit = async () => {
        if (!checkValidate() || !checkAddressValidate()) {
            await alert("입력항목을 다시 확인해주세요!");
            broadcast.send(BROADCAST_NICKNAME_VALIDATION_CHECK);
            Object.values(input_list_ref.current).forEach((el) => {
                el.focus({preventScroll: true});
                el.blur();
            });
            setError({
                ...error,
                gender: is_values_check.current.gender,
                job_class_id: is_values_check.current.job_class_id,
                address: checkAddressValidate(),
            });
            return;
        }

        let {code, account} = await AccountModel.profileEdit({
            email: input_values.current.email,
            name: input_values.current.name,
            nickname: input_values.current.nickname,
            phone: input_values.current.phone,
            birth: input_values.current.birth,
            gender: input_values.current.gender,
            job_class_id: input_values.current.job_class_id,
            address: input_address_values.current.address,
            address_detail: input_address_values.current.address_detail.replace(/^\s+|\s+$/g, ""),
            address_code: input_address_values.current.address_code,
        });

        if (isMount()) {
            if (code === 200) {
                dispatch(stored_account_info(account));
                await alert("성공적으로 수정되었습니다.");
                if (props.location?.state?.redirect_url) {
                    window.location.replace(props.location.state.redirect_url);
                } else {
                    window.location.reload();
                }
            } else if (code === 4501) {
                await alert("이미 가입되어 있는 이메일입니다.");
            } else {
                await alert("오류가 발생하였습니다.");
            }
        }
    };

    return (
        <div className="user-container account-info-edit-wrap">
            <Helmet>
                <title>개인정보 수정</title>
            </Helmet>

            <div className="td-wrapper-v2">
                <div className="content">
                    <h2 className="common-center-title type2">개인정보 수정</h2>

                    <div className="template-input-wrap">
                        {/* 프로필 사진 */}
                        <div className="profile-image-edit-wrap">
                            <InputLabel label={"프로필 사진"} />
                            <div onClick={profilePhotoModalOpen}>
                                <img
                                    className="profile-img"
                                    src={
                                        input_values.current.profile_image
                                            ? input_values.current.profile_image
                                            : `${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_bear_m3.png`
                                    }
                                    alt="프로필 이미지"
                                />
                                <img
                                    className="profile-edit-icon"
                                    src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_edit.png`}
                                    alt="수정 아이콘"
                                />
                            </div>
                        </div>

                        {/* 텐덤 */}
                        {AccountStore.account_provider_id === 1 && (
                            <>
                                {/* 텐덤 */}
                                <div className="account-email-wrap">
                                    <InputLabel label={"계정명"} require={true} />
                                    <p className="email-address">{AccountStore.account_name}</p>
                                </div>

                                <div className="password-change-wrap">
                                    <InputLabel label={"비밀번호"} require={true} />
                                    <div
                                        onClick={() => {
                                            props.history.push({
                                                pathname: "/user/password/change",
                                                state: {email: input_values.current.email},
                                            });
                                        }}>
                                        <span>변경하기</span>
                                        <img
                                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/account/ic_arrow_r_g4.svg`}
                                            alt="변경하기 버튼"
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        <EmailInput
                            label="이메일"
                            input_list_ref={(el) => (input_list_ref.current.email = el)}
                            default_value={input_values.current.email}
                            on_data_changed={(text, validated) => {
                                if (validated) {
                                    input_values.current.email = text;
                                    is_values_check.current.email = true;
                                } else {
                                    input_values.current.email = "";
                                    is_values_check.current.email = false;
                                }
                            }}
                        />

                        {/* 이름 */}
                        <NameInput
                            input_list_ref={(el) => (input_list_ref.current.name = el)}
                            default_value={input_values.current.name}
                            on_data_changed={(text, validated) => {
                                if (validated) {
                                    input_values.current.name = text.trim();
                                    is_values_check.current.name = true;
                                } else {
                                    input_values.current.name = "";
                                    is_values_check.current.name = false;
                                }
                            }}
                        />

                        {/* 닉네임 */}
                        <NicknameInput
                            default_value={input_values.current.nickname}
                            is_values_check={is_values_check}
                            on_data_changed={(text) => {
                                is_values_check.current.nickname = false;
                                if (text === "") {
                                    is_values_check.current.nickname = false;
                                } else {
                                    input_values.current.nickname = text.trim();
                                }
                            }}
                        />

                        {/* 휴대전화 */}
                        <PhoneInput
                            input_list_ref={(el) => (input_list_ref.current.phone = el)}
                            default_value={input_values.current.phone}
                            on_data_changed={(text, validated) => {
                                if (validated) {
                                    input_values.current.phone = text;
                                    is_values_check.current.phone = true;
                                } else {
                                    input_values.current.phone = "";
                                    is_values_check.current.phone = false;
                                }
                            }}
                        />

                        {/* 생년월일 */}
                        <BirthInput
                            input_list_ref={(el) => (input_list_ref.current.birth = el)}
                            default_value={input_values.current.birth}
                            on_data_changed={(text, validated) => {
                                if (validated) {
                                    input_values.current.birth = text;
                                    is_values_check.current.birth = true;
                                } else {
                                    input_values.current.birth = "";
                                    is_values_check.current.birth = false;
                                }
                            }}
                        />

                        {/* 성별 */}
                        <GenderButton
                            force_error={error.gender}
                            default_value={input_values.current.gender}
                            on_data_changed={(text, validated) => {
                                if (validated) {
                                    input_values.current.gender = text;
                                    is_values_check.current.gender = true;
                                } else {
                                    input_values.current.gender = "";
                                    is_values_check.current.gender = false;
                                }
                            }}
                        />

                        {/* 직종 */}
                        <JobSelectBox
                            force_error={error.job_class_id}
                            default_value={input_values.current.job_class_id}
                            on_data_changed={(text) => {
                                if (text) {
                                    input_values.current.job_class_id = text;
                                    is_values_check.current.job_class_id = true;
                                } else {
                                    input_values.current.job_class_id = "";
                                    is_values_check.current.job_class_id = false;
                                }
                            }}
                        />

                        {/* 주소 */}
                        <InputLabel label={"주소"} require={true} />
                        <AddressInput force_error={error.address} input_values={input_address_values} />

                        <div className="edit-btn-group">
                            <div className="drop-out-btn">
                                <Link to="/user/dropout">회원 탈퇴</Link>
                            </div>
                            <div className="btn-wrap">
                                <Link to="/" className="btn cancel-btn">
                                    취소
                                </Link>
                                <button className="btn save-info-btn" onClick={onAccountSubmit}>
                                    저장
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AccountInfoEdit);
