import React, { useEffect, useRef, useState } from 'react';
import '../../assets/scss/page/faq.scss';
import CommonModel from '../../model/CommonModel';
import { useDispatch, useSelector } from 'react-redux';
import { set_footer_type, set_header_type, stored_static_code } from '../../redux/common/action';
import { Helmet } from 'react-helmet';

const Faq = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const [faq_set, setFaqSet] = useState([]);
  const [select_tab, setSelectTab] = useState(0);
  const [select_item, setSelectItem] = useState(false);
  const _is_mount = useRef(false);

  useEffect(() => {
    _is_mount.current = true;
    window.scrollTo(0, 0);
    if (!CommonStore.static_code.faq_category_set.length) {
      CommonModel.staticCode({ code_name: 'faq_category_set' }).then(({ code_set }) => {
        if (_is_mount.current) {
          dispatch(stored_static_code('faq_category_set', code_set));
        }
      });
    }
    CommonModel.faqList().then(({ faq_set }) => {
      if (_is_mount.current) {
        setFaqSet(faq_set);
      }
    });
    dispatch(set_footer_type(0));
    dispatch(
      set_header_type(0, 2, {
        title: 'FAQ',
      }),
    );
    props.ready();
    return () => {
      _is_mount.current = false;
    };
  }, []);
  return (
    <div className='faq-container'>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <p className='faq-title'>베어유 FAQ</p>
      <p className='faq-desc'>
        베어유 에서 자주 묻는 질문들을 모았습니다.
        <br />
        그래도 해결되지 않는 궁금증이 있다면 언제든지 베어유 고객센터에 문의 부탁드리겠습니다.
      </p>
      <div className='faq-tab-wrapper'>
        <div
          className={0 === select_tab ? 'faq-tab selected' : 'faq-tab'}
          onClick={() => {
            setSelectTab(0);
            setSelectItem(false);
          }}
        >
          <p>전체</p>
        </div>
        {CommonStore.static_code.faq_category_set.map((faq) => {
          return (
            <div
              key={faq.id}
              className={faq.id === select_tab ? 'faq-tab selected' : 'faq-tab'}
              onClick={() => {
                setSelectTab(faq.id);
                setSelectItem(false);
              }}
            >
              <p>{faq.name}</p>
            </div>
          );
        })}
      </div>
      <div className='faq-content-wrapper'>
        {faq_set.map((faq) => {
          return (
            <div
              style={
                select_tab === 0 || select_tab === faq.faq_category_id
                  ? { display: 'block' }
                  : { display: 'none' }
              }
              onClick={() => {
                if (faq.id === select_item) {
                  setSelectItem(false);
                } else {
                  setSelectItem(faq.id);
                }
              }}
              className={select_item === faq.id ? 'faq-content selected' : 'faq-content'}
              key={faq.id}
            >
              <p className='faq-content-title'>Q. {faq.question}</p>
              <p className='faq-content-desc' dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
