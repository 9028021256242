import React, {useState, useEffect} from "react";
import TemplateInput from "../_template/TemplateInput";
import PropTypes from "prop-types";
import InputLabel from "./InputLabel";

const propTypes = {
    require: PropTypes.bool, // require 표시 여부
    force_error: PropTypes.bool, // props error 받아서 에러 상태 변경
    label: PropTypes.string, // label 이름 변경 시
    error_text: PropTypes.string, // 비밀번호 오류 시 메세지
    on_validate: PropTypes.func, // re_password 처리
    auto_focus: PropTypes.string, // input autoFocus
    input_list_ref: PropTypes.func, // input ref
    on_data_changed: PropTypes.func, // text, validated
    enter_listener: PropTypes.func, // Enter 이벤트 리스너
    enable_show_password: PropTypes.bool, // eye-icon on/off
};

const PasswordInput = (props) => {
    const [re_password_error, setRePasswordError] = useState(false);
    const [password_error_message, setPasswordErrorMessage] = useState("");
    const [is_password_error, setIsPasswordError] = useState(false);
    const [password_type, setPasswordType] = useState({
        type: "password",
        visible: false,
    });
    const require = props?.require ?? true;

    useEffect(() => {
        if (props.force_error) {
            setRePasswordError(true);
        } else {
            setRePasswordError(false);
        }
    }, [props.force_error]);

    // 유효성 검사
    const checkPasswordValidate = (password) => {
        const regex_password = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=*]).*$/;
        return regex_password.test(password);
    };

    // 비밀번호 타입 변경
    const handlePasswordType = () => {
        setPasswordType(() => {
            if (!password_type.visible) {
                return {type: "text", visible: true};
            }
            return {type: "password", visible: false};
        });
    };

    return (
        <div className="password-input-wrap">
            <InputLabel label={props?.label ?? "비밀번호"} require={require} />
            <TemplateInput
                is_error={is_password_error}
                error_message={password_error_message}
                on_validate={(text) => {
                    let validated = checkPasswordValidate(text) && !props.force_error;
                    if (text === "") {
                        setIsPasswordError(true);
                        setPasswordErrorMessage("필수항목입니다");
                    } else if (validated) {
                        setIsPasswordError(false);
                        setPasswordErrorMessage("");
                    } else {
                        setIsPasswordError(true);
                        setPasswordErrorMessage(
                            props.error_text ?? "영문/숫자/특수문자를 포함하여 8글자 이상 작성해주세요"
                        );
                    }
                    props.on_validate && props.on_validate(validated);
                }}
                input_props={{
                    placeholder: "영문/숫자/특수문자 포함 8자 이상",
                    type: password_type.type,
                    autoFocus: props?.auto_focus,
                    ref: props?.input_list_ref,
                    autoComplete: props?.auto_complete,
                    ...props?.input_props,
                    onFocus: () => {
                        setIsPasswordError(false);
                        setPasswordErrorMessage("");
                    },
                    onBlur: () => {
                        if (re_password_error) {
                            setIsPasswordError(true);
                            setPasswordErrorMessage("비밀번호와 동일하지 않습니다");
                        }
                    },
                    onChange: (text) => {
                        props.on_data_changed && props.on_data_changed(text, checkPasswordValidate(text));
                    },
                    onKeyUp: (event) => {
                        if (props.enter_listener && (event.key === "Enter" || event.code === "NumpadEnter")) {
                            props.enter_listener();
                        }
                    },
                }}
            />
            {props.enable_show_password && (
                <span onClick={handlePasswordType} className="eye-icon">
                    {password_type.visible ? (
                        <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/account/ic_eye_on.svg`}
                            alt="아이콘 보이기"
                        />
                    ) : (
                        <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/account/ic_eye_off.svg`}
                            alt="아이콘 숨기기"
                        />
                    )}
                </span>
            )}
        </div>
    );
};

PasswordInput.propTypes = propTypes;

export default PasswordInput;
