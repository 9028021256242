import React, {useEffect} from "react";
import CommonModel from "../../../model/CommonModel";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {stored_service} from "../../../redux/common/action";

// pc_footer = 0 - 기본 (모바일 혼용)
// pc_footer = 1 - 패키지 페이지 (모바일 혼용)
const Footer = () => {
    // Redux
    const dispatch = useDispatch();
    const CommonStore = useSelector((state) => state.CommonStore);
    const footer = CommonStore.footer || {};

    useEffect(() => {
        if (!CommonStore.service?.info) {
            CommonModel.serviceInfo().then(({service_info}) => {
                dispatch(stored_service("info", service_info));
            });
        }

        ///mobon script start///
        let referrer = document.referrer;
        let referrerDomain = "";
        const domain = window.location.hostname;
        if (referrer !== "") {
            referrerDomain = document.referrer.split("/")[2];
        }

        const device = "B";
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerText =
            '(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src="https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js";t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");enp("create", "common", "tendominc", { device: "' +
            device +
            '", adverId:"tendominc", domain:"' +
            domain +
            '", referrer:"' +
            referrer +
            '", referrerDomain:"' +
            referrerDomain +
            '", solutionType:"" });enp("send", "common", "tendominc");';

        document.body.appendChild(script);
        ///mobon script end///
    }, []);

    // dark-type 은 패키지 상세페이지에서만 작동
    const footer_type = footer?.pc_footer_type === 1 ? " dark-type" : " light-type";

    // 링크 클릭 X -> ignore 추가 시 하위 태그에 각각 적용 가능
    const read_only = footer?.footer_set?.read_only ? " read-only" : "";

    return (
        <>
            {!CommonStore.header.header_set?.no_footer && CommonStore.service?.info && (
                <div className={"ftr" + footer_type + read_only}>
                    <div className="td-wrapper-v2">
                        <div className="ftr-menu">
                            <Link to="/" className="ftr-logo ignore">
                                베어유
                            </Link>
                            <ul className="ftr-menu-list invisible">
                                <li className="accent">
                                    <a href="https://tally.so/r/nW8JaR">클래스 판매등록 신청</a>
                                </li>
                                <li className="accent">
                                    <a target="_blank" rel="noreferrer" href={"https://bearu-ebook.oopy.io"}>
                                        전자책 작가 신청
                                    </a>
                                </li>
                                <li>
                                    <Link to="/service/notice">공지사항</Link>
                                </li>
                                <li>
                                    <Link to="/service/faq">FAQ</Link>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href={"https://bearub2b.oopy.io"}>
                                        기업교육
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href={"https://www.tendom.co.kr/"}>
                                        텐덤을 소개합니다
                                    </a>
                                </li>
                            </ul>
                            <ul className="ftr-sns invisible">
                                {CommonStore.service.info.facebook_url ? (
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={CommonStore.service.info.facebook_url}>
                                            <div className="facebook" />
                                        </a>
                                    </li>
                                ) : null}
                                {CommonStore.service.info.youtube_url ? (
                                    <li>
                                        <a rel="noreferrer" target="_blank" href={CommonStore.service.info.youtube_url}>
                                            <div className="youtube" />
                                        </a>
                                    </li>
                                ) : null}
                                {CommonStore.service.info.instagram_url ? (
                                    <li>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={CommonStore.service.info.instagram_url}>
                                            <div className="instagram" />
                                        </a>
                                    </li>
                                ) : null}
                                {CommonStore.service.info.naver_url ? (
                                    <li>
                                        <a rel="noreferrer" target="_blank" href={CommonStore.service.info.naver_url}>
                                            <div className="naver" />
                                        </a>
                                    </li>
                                ) : null}
                            </ul>
                        </div>
                        <div className="ftr-info">
                            <div className="contact-info-wrapper">
                                <p>문의</p>
                                <ul className="contact-info">
                                    <li>
                                        <a href={`mailto: ${CommonStore.service.info.contact_email}`}>
                                            {CommonStore.service.info.contact_email}
                                        </a>
                                    </li>
                                </ul>
                                <ul className="ftr-terms">
                                    <li>
                                        <Link to="/service/terms">이용약관</Link>
                                    </li>
                                    <li>
                                        <Link to="/service/privacy">개인정보처리방침</Link>
                                    </li>
                                    <li>
                                        <Link to="/service/platform-policy">중개플랫폼 민원책임</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="ftr-info-wrapper">
                                {/* <div className="company-info">
                                    <p>{CommonStore.service.info.company_name}</p>
                                    대표자 · 개인정보책임관리자 {CommonStore.service.info.manager_name} | 사업자등록번호{" "}
                                    {CommonStore.service.info.business_number} | 통신판매업 신고번호{" "}
                                    {CommonStore.service.info.tel_business_number} | 원격평생교육시설 신고 제 2021-19 호
                                    | {CommonStore.service.info.company_address}
                                </div> */}
																<div className="company-info">
																		<p>{CommonStore.service.info.company_name}</p>
																		대표자 · 개인정보책임관리자 유원일 | 서울특별시 마포구 독막로34길 4-4,
																		2층(신수동)<br/>
																		전자책 결제 : 주식회사 텐덤(286-88-00335), 클래스 결제 : 주식회사
																		텐덤(818-85-01902)<br/>
																		통신판매업 신고번호 2016-서울마포-1971호 | 원격평생교육시설 신고 제
																		2021-19 호
																</div>
														</div>
                            <div className="ftr-terms-wrapper">
                                <p>ⓒ {new Date().getFullYear()} TENDOM Inc. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Footer;
