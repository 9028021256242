import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import {blob_to_data_url} from "../../../common";
import {useDialogContext} from "./DialogContextProvider";
import moment from "moment";
import LoadingSpinner from "../component/LoadingSpinner";
import PropTypes from "prop-types";
import AccountModel from "../../../model/AccountModel";

const propTypes = {
    course_id: PropTypes.number.isRequired,
};

const init_course_certificate_object = {
    blob: null,
    url: null,
};

const CourseCertificateModal = (props) => {
    //Dialog
    const {confirm, alert} = useDialogContext();
    const dialog_props = props.dialog_props || {};
    const dialog_utils = props.dialog_utils || {};

    //Redux
    const AccountStore = useSelector((state) => state.AccountStore);

    //Certification State
    const [certificate, setCertificate] = useState(null);
    const [is_hover, setIsHover] = useState(false);

    //Certificate Ref
    let course_certificate_object = useRef(init_course_certificate_object);
    const certificate_canvas_ref = useRef(null);
    const certificate_img_ref = useRef(null);
    const save_certificate_btn_ref = useRef(null);
    const button_object_ref = useRef(null);

    let _is_mount = false;

    useEffect(async () => {
        document.body.onselectstart = () => true;
        document.body.oncontextmenu = () => true;
        _is_mount = true;
        if (AccountStore.info) {
            AccountModel.myCertificationDetail({course_id: props.course_id}).then(async ({code, data}) => {
                course_certificate_object.current = init_course_certificate_object;
                if (_is_mount) {
                    if (code === 200) {
                        setCertificate(data);
                    } else {
                        await alert("작업에 실패했습니다.");
                    }
                }
            });
        } else {
            if (await confirm("수료증 발급을 위해, 개인정보 수정에서 정보를 입력해주세요!")) {
                props.history.push({
                    pathname: "/user/info",
                    state: {
                        redirect_url: `${props.location.pathname}${props.location.search}`,
                    },
                });
            } else {
                dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
            }
        }
        return () => {
            _is_mount = false;
            document.body.onselectstart = () => false;
            document.body.oncontextmenu = () => false;
        };
    }, []);

    useEffect(() => {
        if (certificate) {
            drawCertificate(certificate, async (_flag) => {
                if (_flag) {
                    makeCertificateObject((create_cert_obj) => {
                        if (isMobile) {
                            button_object_ref.current.style.opacity = 1;
                            if (isIOS) {
                                save_certificate_btn_ref.current.style.display = "none";
                                setTimeout(async () => {
                                    await alert("이미지를 길게 누르면 저장할 수 있습니다.");
                                }, 100);
                            }
                        }
                        course_certificate_object.current = create_cert_obj;
                        if (course_certificate_object.current) {
                            certificate_canvas_ref.current.style.display = "none";
                            certificate_img_ref.current.src = course_certificate_object.current.url;
                        } else {
                            certificate_canvas_ref.current.style.display = "block";
                            certificate_img_ref.current.src = "";
                        }
                    });
                } else {
                    certificate_canvas_ref.current.style.display = "block";
                    await alert("클래스 수료증 발급중 에러가 발생했습니다. 관리자에게 문의 해주세요.");
                }
            });
        }
    }, [certificate]);

    const object_key_size = (obj) => {
        let object_key_size = 0;
        if (obj) {
            object_key_size = Object.keys(obj).length;
        }
        return object_key_size;
    };

    const loadAssets = (load_object, func) => {
        let load_object_size = object_key_size(load_object);
        let load_count = 0;

        if (load_object_size) {
            let onLoad = () => {
                load_count++;
                if (load_count === load_object_size) {
                    func(load_object);
                }
            };

            for (let key in load_object) {
                (function () {
                    let _load_object = load_object[key];
                    let image = new Image();
                    image.crossOrigin = "anonymous";
                    image.onload = () => {
                        _load_object["result"] = image;
                        onLoad();
                    };
                    image.onerror = () => {
                        onLoad();
                    };
                    image.src = _load_object["url"];
                })();
            }
        }
    };

    const drawCertificate = (certificate, func) => {
        if (certificate_canvas_ref.current.getContext) {
            if (!certificate) {
                func(false);
                return;
            }

            let ctx = certificate_canvas_ref.current.getContext("2d");

            loadAssets(
                {
                    master_sign_img: {url: certificate.master_sign_image_url},
                    bearu_sign_img: {
                        url: `${process.env.REACT_APP_IMG_URL}/static/png/mypage/img-bearu-certifiedmark.png`,
                    },
                    certificate_bg_img: {url: `${process.env.REACT_APP_IMG_URL}/static/png/mypage/certificate_bg.jpg`},
                },
                function (load_object) {
                    let certificate_bg = load_object.certificate_bg_img.result;
                    let master_sign = load_object.master_sign_img.result;
                    let bearu_sign = load_object.bearu_sign_img.result;

                    let issued_number = certificate.document_uid;
                    let user_name = AccountStore.info.name;
                    let course_name = certificate.short_title;
                    let issued_date = moment().format("YYYY년 MM월 DD일");
                    let running_time = moment
                        .utc(certificate.class_running_time_in_seconds * 1000)
                        .format("H시간 mm분");
                    let teacher_name = certificate.teacher_name;

                    const text_value = "bold 18px 'Nanum Myeongjo'";
                    const color = "#444444";

                    setDevicePixelRatio(ctx);
                    ctx.drawImage(certificate_bg, 0, 0, 476, 674);

                    // 발행번호
                    ctx.font = "11px 'Nanum Myeongjo'";
                    ctx.fillStyle = "#666666";
                    ctx.fillText(issued_number, 32, 40);

                    // 항목 데이터 - 유저이름
                    ctx.font = text_value;
                    ctx.fillStyle = color;
                    ctx.textAlign = "center";
                    ctx.fillText(user_name, 238, 232);

                    // 항목 데이터 - 수료 클래스
                    ctx.font = text_value;
                    ctx.fillStyle = color;
                    ctx.textAlign = "center";
                    ctx.fillText(course_name, 238, 302);

                    // 항목 데이터 - 발행일자
                    ctx.font = "12px 'Nanum Myeongjo'";
                    ctx.fillStyle = color;
                    ctx.textAlign = "center";
                    ctx.fillText(issued_date, 162, 458);

                    // 항목 데이터 - 수강시간
                    ctx.font = "12px 'Nanum Myeongjo'";
                    ctx.fillStyle = color;
                    ctx.textAlign = "center";
                    ctx.fillText(running_time, 310, 458);

                    if (certificate.master_sign_image_url) {
                        let ratio = master_sign.height > 80 ? 80 / master_sign.height : 1;
                        let estimatedWidth = master_sign.width * ratio;
                        let estimatedHeight = master_sign.height * ratio;
                        ctx.drawImage(
                            master_sign,
                            238 - estimatedWidth / 2,
                            561 - estimatedHeight / 2,
                            estimatedWidth,
                            estimatedHeight
                        );
                        ctx.font = "bold 11px 'Noto Sans KR'";
                        ctx.fillStyle = "#cccccc";
                        ctx.textAlign = "center";
                        ctx.fillText(teacher_name, 238, 618);
                    } else {
                        ctx.drawImage(bearu_sign, 156, 535, 164, 60);
                        ctx.font = "bold 11px 'Noto Sans KR'";
                        ctx.fillStyle = "#cccccc";
                        ctx.textAlign = "center";
                        ctx.fillText("베어유에서 인증합니다.", 238, 618);
                    }

                    func(true);
                }
            );
        } else {
            func(false);
        }
    };

    const setDevicePixelRatio = (ctx) => {
        let pixelRatio = 1;
        if (window.hasOwnProperty("devicePixelRatio")) {
            window.devicePixelRatio = 2;
            pixelRatio = window.devicePixelRatio;
            certificate_canvas_ref.current.width = Math.floor(476 * pixelRatio);
            certificate_canvas_ref.current.height = Math.floor(674 * pixelRatio);
            ctx.scale(pixelRatio, pixelRatio);
        }
    };

    const makeCertificateObject = (func) => {
        certificate_canvas_ref.current.toBlob(function (_blob) {
            blob_to_data_url(_blob, function (_url) {
                func({
                    blob: _blob,
                    url: _url,
                });
            });
        });
    };

    const onDownloadBtn = () => {
        const fileName = `${AccountStore.info.name}_${certificate.short_title}_수료증.png`;
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.download = fileName;
        if (isAndroid) {
            // 안드로이드에서...... 왜 오류나지...?
            anchor.href = URL.createObjectURL(course_certificate_object.current.blob);
            anchor.click();
            document.body.removeChild(anchor);
        } else {
            anchor.href = course_certificate_object.current.url;
            anchor.click();
            document.body.removeChild(anchor);
        }
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    return (
        <div className="td-modal course-certificate-modal">
            <div className="td-dimmed" onClick={onCloseModalBtn} />
            <div
                className={"modal-content" + (!isMobile && is_hover ? " hover" : "")}
                onMouseLeave={() => {
                    if (!isMobile) setIsHover(false);
                }}
                onMouseEnter={() => {
                    if (!isMobile) setIsHover(true);
                }}>
                {!certificate ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="btn-object-group" ref={button_object_ref}>
                            <div className="modal-close-btn" onClick={onCloseModalBtn}>
                                <img
                                    src={`${process.env.REACT_APP_IMG_URL}/static/png/common/popup-close-btn-white.png`}
                                    alt="닫기"
                                />
                                <span>닫기</span>
                            </div>
                            <div
                                className="save-certificate-btn"
                                onClick={onDownloadBtn}
                                ref={save_certificate_btn_ref}>
                                <img
                                    src={`${process.env.REACT_APP_IMG_URL}/static/png/mypage/file-download-icon.png`}
                                    alt="수료증 다운받기"
                                />
                                <span>수료증 다운받기</span>
                            </div>
                        </div>
                        <canvas ref={certificate_canvas_ref} id="certificate-canvas" width="476" height="674" />
                        <img alt="cert-img" ref={certificate_img_ref} id="certificate-img" />
                    </>
                )}
            </div>
        </div>
    );
};

CourseCertificateModal.propTypes = propTypes;

export default withRouter(CourseCertificateModal);
