import React, { useEffect, useRef, useState } from 'react';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import AccountModel from '../../model/AccountModel';
import '../../assets/scss/page/password_change.scss';
import { alert_and_redirect } from '../../common';
import { stored_account_info } from '../../redux/account/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import PasswordInput from '../_common/Input/PasswordInput';
import { useMount } from '../../util/BearuHooks';
import InputLabel from '../_common/Input/InputLabel';
import TemplateInput from '../_common/_template/TemplateInput';

const PasswordChange = (props) => {
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const { alert } = useDialogContext();
  const [isMount] = useMount();

  const [password_error_message, setPasswordErrorMessage] = useState('');
  const [old_password_error, setOldPasswordError] = useState(false);
  const [re_password_error, setRePasswordError] = useState(false);

  const input_values = useRef({
    old_password: '',
    password: '',
    re_password: '',
  });

  const input_list_ref = useRef({
    old_password: null,
    password: null,
    re_password: null,
  });

  const is_values_check = useRef({
    old_password: false,
    password: false,
    re_password: false,
  });

  useEffect(() => {
    if (AccountStore.account_provider_id === 1) {
      dispatch(
        set_header_type(0, 2, {
          title: '비밀번호 변경',
          no_footer: true,
          no_top_btn: true,
        }),
      );
      dispatch(set_footer_type(0));
      props.ready();
    } else {
      alert_and_redirect(alert, props.history, '잘못된 접근입니다.');
    }
    return () => {};
  }, []);

  const checkRePasswordValidate = () => {
    const current_password = input_values.current.password;
    const current_re_password = input_values.current.re_password;

    if (current_password.length === 0 || current_re_password.length === 0) {
      setRePasswordError(false);
    } else {
      if (current_password !== current_re_password) {
        setRePasswordError(true);
      } else {
        setRePasswordError(false);
      }
    }
  };

  const onSignOut = () => {
    AccountModel.signOut().then(() => {
      dispatch(stored_account_info(null));
      props.history.push({
        pathname: '/user/signin',
        state: { step: 2 },
      });
    });
  };

  const checkValidate = () => {
    return Object.values(is_values_check.current).every((value) => value);
  };

  const onPwdChange = async () => {
    const old_password = input_values.current.old_password;
    const new_password = input_values.current.password;

    Object.values(input_list_ref.current).forEach((el) => {
      el.focus();
      el.blur();
    });

    if (!checkValidate()) {
      return;
    }

    let { code } = await AccountModel.signIn({
      email: props.location.state?.email,
      password: old_password,
    });

    if (isMount()) {
      if (code !== 200) {
        await alert('기존 비밀번호를 다시 확인해주세요.');
      } else if (old_password === new_password) {
        await alert('새로운 비밀번호가 기존 비밀번호와 동일합니다.');
      } else {
        let { code } = await AccountModel.passwordEdit({
          password: old_password,
          new_password: new_password,
        });
        if (code === 200) {
          await alert('변경된 비밀번호로 다시 로그인 해주세요.');
          onSignOut();
        } else {
          await alert('오류가 발생하였습니다.');
        }
      }
    }
  };

  return (
    <div className='user-container password-change-wrap'>
      <Helmet>
        <title>비밀번호 변경</title>
      </Helmet>
      {AccountStore && AccountStore.account_provider_id === 1 ? (
        <div className='td-wrapper'>
          <div className='content'>
            <h2 className='common-center-title type2'>비밀번호 변경</h2>

            <form>
              <div className='template-input-wrap'>
                {/* 기존 비밀번호 */}
                <div className='old-password-wrap'>
                  <InputLabel label={'기존 비밀번호'} />
                  <TemplateInput
                    is_error={old_password_error}
                    error_message={password_error_message}
                    input_props={{
                      placeholder: '기존 비밀번호를 입력해주세요',
                      type: 'password',
                      ref: (el) => (input_list_ref.current.old_password = el),
                      onChange: (password) => {
                        input_values.current.old_password = password;
                      },
                      onFocus: () => {
                        setOldPasswordError(false);
                        setPasswordErrorMessage('');
                      },
                      onBlur: (password) => {
                        if (password === '') {
                          setOldPasswordError(true);
                          setPasswordErrorMessage('기존 비밀번호를 입력해주세요');
                        } else {
                          is_values_check.current.old_password = true;
                        }
                      },
                    }}
                  />
                </div>

                {/* 새로운 비밀번호 */}
                <PasswordInput
                  input_list_ref={(el) => (input_list_ref.current.password = el)}
                  label='새로운 비밀번호'
                  require={false}
                  enable_show_password={true}
                  input_props={{ placeholder: '비밀번호를 다시 입력해주세요' }}
                  on_data_changed={(password, validated) => {
                    if (validated) {
                      input_values.current.password = password;
                      is_values_check.current.password = true;
                    } else {
                      input_values.current.password = '';
                      is_values_check.current.password = false;
                    }
                    checkRePasswordValidate();
                  }}
                  on_validate={(validated) => {
                    if (re_password_error) {
                      input_list_ref.current.re_password.focus();
                      input_list_ref.current.re_password.blur();
                    }
                    is_values_check.current.password = !!validated;
                  }}
                />

                {/* 새로운 비밀번호 확인 */}
                <PasswordInput
                  input_list_ref={(el) => (input_list_ref.current.re_password = el)}
                  label='새로운 비밀번호 확인'
                  require={false}
                  enable_show_password={true}
                  force_error={re_password_error}
                  input_props={{ placeholder: '비밀번호를 다시 입력해주세요' }}
                  error_text={'비밀번호와 동일하지 않습니다'}
                  on_data_changed={(re_password, validated) => {
                    if (validated) {
                      input_values.current.re_password = re_password;
                      is_values_check.current.re_password = true;
                    } else {
                      input_values.current.re_password = '';
                      is_values_check.current.re_password = false;
                    }
                    checkRePasswordValidate();
                  }}
                  on_validate={(validated) => {
                    is_values_check.current.re_password = !!validated;
                  }}
                />
              </div>
            </form>

            <div className='td-btn pwd-change-btn' onClick={onPwdChange}>
              변경
            </div>
          </div>
        </div>
      ) : null}
      `
    </div>
  );
};

export default PasswordChange;
