import React, {useEffect, useRef, useState} from "react";
import CourseCard from "../component/CourseCard";
import CourseModel from "../../../model/CourseModel";
import PackageCard from "../component/PackageCard";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation} from "swiper";
import PropTypes from "prop-types";
import SmallCourseCard from "../../account/component/SmallCourseCard";

SwiperCore.use([Navigation]);

const propTypes = {
    is_mobile: PropTypes.bool,
    is_loading: PropTypes.object,
    setLoading: PropTypes.func,
    only_course: PropTypes.bool,
    style_type: PropTypes.number,
    promotion_type_id: PropTypes.number,
    with_product_set: PropTypes.bool
};

// @Deprecated
const PopularList = (props) => {
    const [promotion_set, setPromotionSet] = useState({
        course_set: [],
        product_set: []
    });
    const _is_mount = useRef(false);

    useEffect(() => {
        _is_mount.current = true;
        CourseModel.promotionList({
            promotion_type_id: props.promotion_type_id,
            with_product_set: props.with_product_set
        }).then(({course_set, product_set}) => {
            if (_is_mount.current) {
                setPromotionSet({...promotion_set, course_set, product_set});
                if (props.setLoading) props.setLoading(true);
            }
        });
        return () => {
            _is_mount.current = false;
        };
    }, []);

    const slider_option = {
        slidesPerView: 2,
        spaceBetween: 16,
        loop: false,
        navigation: {
            prevEl: ".popular-course-swiper-prev-unique",
            nextEl: ".popular-course-swiper-next-unique"
        }
    };

    return (
        <>
            {props.list_type ? (
                <div className="popular-course-content">
                    <h3 className="content-title">지금 많이 본 인기 클래스</h3>
                    <div className="course-list">
                        <Swiper {...slider_option}>
                            {promotion_set?.course_set.map((course) => {
                                return (
                                    <SwiperSlide key={course.id}>
                                        <React.Fragment>
                                            <CourseCard course={course} />
                                        </React.Fragment>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <div className="popular-course-swiper-prev-unique" />
                        <div className="popular-course-swiper-next-unique" />
                    </div>
                </div>
            ) : props.is_mobile ? (
                props.is_loading.list_loading && props.is_loading.popular_loading ? (
                    <>
                        <h6 className="title" style={{marginBottom: 10}}>
                            이 강의는 어떠세요?
                        </h6>
                        <div>
                            {promotion_set?.course_set.map((course, index) => {
                                return (
                                    <React.Fragment key={course.id}>
                                        <SmallCourseCard course={course} />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </>
                ) : null
            ) : (
                <div className="popular-course-content">
                    <h3 className="content-title type1 popular">
                        지금 베어유에서 가장 인기있는 클래스
                    </h3>
                    <div
                        className={
                            "course-list" +
                            (props.style_type ? " overview-list" : " promotion-list")
                        }
                    >
                        {promotion_set?.course_set.map((course, index) => {
                            let package_course = null;

                            if (
                                !props.only_course &&
                                promotion_set.product_set &&
                                index % 2
                            ) {
                                if (promotion_set.product_set[parseInt(index / 2)]) {
                                    package_course = (
                                        <PackageCard
                                            product={promotion_set.product_set[parseInt(index / 2)]}
                                        />
                                    );
                                }
                            }

                            return (
                                <React.Fragment key={course.id}>
                                    {
                                        // Main 페이지에서 모바일에서 (min-600px)
                                        // 인기 클래스 프로모션 처럼 맨 앞에 2개가 패키지여야하는 경우
                                    }
                                    {props.is_tablet_or_mobile &&
                                    index === 0 &&
                                    !props.style_type &&
                                    !props.only_course && (
                                        <>
                                            {promotion_set.product_set[0] && (
                                                <PackageCard product={promotion_set.product_set[0]} />
                                            )}
                                            {promotion_set.product_set[1] && (
                                                <PackageCard product={promotion_set.product_set[1]} />
                                            )}
                                        </>
                                    )}
                                    {
                                        // 만약 product가 없을 경우 4개만
                                    }
                                    {promotion_set.product_set?.length ? (
                                        index < 6 - promotion_set.product_set.length && (
                                            <CourseCard course={course} />
                                        )
                                    ) : (
                                        <CourseCard course={course} />
                                    )}
                                    {!props.is_tablet_or_mobile && package_course}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

PopularList.propTypes = propTypes;

export default PopularList;
