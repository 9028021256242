import {createContext, useContext, useMemo, useRef} from "react";

const broadcast_context = createContext();

export const BroadcastContextProvider = (props) => {
    const broadcasts = useRef([]);

    const addBroadCast = (keys, callback) => {
        let broadcast = {keys, callback};
        broadcasts.current.push(broadcast);
        return broadcast;
    };

    const removeBroadCast = (broadcast) => {
        broadcasts.current = broadcasts.current.filter((_broadcast) => {
            return _broadcast.callback !== broadcast.callback;
        });
    };

    const broadcast = useMemo(() => {
        return {
            listen: (keys, callback) => {
                return addBroadCast(keys, callback);
            },
            unlisten: (broadcast) => {
                return removeBroadCast(broadcast);
            },
            send: (key, value) => {
                broadcasts.current.forEach((_broadcast) => {
                    _broadcast.keys.forEach((_key) => {
                        if (_key === key) {
                            _broadcast.callback({key, value});
                        }
                    });
                });
            },
        };
    }, []);

    return (
        <broadcast_context.Provider
            value={{
                broadcast,
            }}>
            {props.children}
        </broadcast_context.Provider>
    );
};

export const useBroadcastContext = () => {
    return useContext(broadcast_context);
};
