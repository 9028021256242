import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {set_account_favorite_count} from "../../../redux/account/action";
import ProductModel from "../../../model/ProductModel";
import {replace_cloudfront_url, set_favorite_icon} from "../../../common";
import {useDialogContext} from "../dialog/DialogContextProvider";
import BaseCard from "./BaseCard";

const KitCard2 = (props) => {
    const kit = props.kit;
    const dispatch = useDispatch();
    const AccountStore = useSelector(state => state.AccountStore);
    const {confirm} = useDialogContext();

    const onBookmarkClick = async () => {
        if (AccountStore) {
            ProductModel.kitFavorite({
                kit_id: kit.id
            }).then(({
                         is_favorite,
                         favorite_count,
                         account_favorite_count
                     }) => {
                // @TODO 병곤 향후 add favorite item 느낌으로 작업해서, MyFavoriteList2 쪽에서 갱신하는 코드를 안넣을수도 있는 가정 있음 (id 중복체크 가능성)
                dispatch(set_account_favorite_count(account_favorite_count));
                set_favorite_icon("kit", kit.id, is_favorite, favorite_count);
            });
        } else {
            // @TODO 봄 여기 월요일에 최적화 해보기
            if (await confirm("로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?")) {
                props.history.push({
                    pathname: "/user/signin",
                    state: {
                        step: 1,
                        redirect_url: `${props.location.pathname}${props.location.search}`
                    }
                });
            }
        }
    };

    return (
        <div className={`gtm-data kit-id-${kit.id} card-item-v2`}
             data-ai-pid={`c${kit.id.toString().padStart(8, "0")}`}
             data-ai-pnm={kit.title.replace(/\r\n/g, " ")}
             data-ai-ppc={kit.min_price}
             data-ai-ppb={kit.brand_name}
             data-ai-pca={kit.category_name}>

            <BaseCard
                image_url={kit.circle_image_url ? replace_cloudfront_url(kit.circle_image_url)
                    : `${process.env.REACT_APP_IMG_URL}/static/v2/jpg/common/circle_kit.jpg`}
                short_title={kit.title}
                title={kit.description}
                tag_set={kit.tag_set}
                redirect_to_url={`/kit/${kit.id}`}
                on_bookmark_click={onBookmarkClick}
                is_bookmark={kit.is_favorite}
                favorite_count={kit.favorite_count}/>
        </div>
    );
};

export default withRouter(KitCard2);