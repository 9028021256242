import {
    SET_ACCOUNT_EBOOK_FAVORITE_COUNT,
    SET_ACCOUNT_FAVORITE_COUNT,
    STORED_ACCOUNT_INFO
} from "../action_type";
import update from "react-addons-update";

const INIT_STATE = null;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case STORED_ACCOUNT_INFO:
            return action.data;
        case SET_ACCOUNT_FAVORITE_COUNT:
            return update(
                state,
                {
                    favorite_count: {$set: action.count}
                }
            );
        case SET_ACCOUNT_EBOOK_FAVORITE_COUNT:
            return update(
                state,
                {
                    ebook_favorite_count: {$set: action.count}
                }
            );
        default:
            return state;
    }
}
