import React from 'react';
import { withRouter } from 'react-router-dom'

const BgdNotActive = () => {
	return (
		<article className='not-active-page'>
			<div className='content-top-img'>
				<img
					src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgd_prev_main_img.png`}
					alt='자기계발 챌린지 빡공단 하루 10분, 30일 동안 습관과 스펙을 만드세요.'
				/>
			</div>

			<div className='content-area flex-all-center'>
				<div className='desc-area'>

					<div>
						<div className='gray-card desc-text'>
							<div>💡</div>
							<div>
								<p className='text-gray-03'> 자기계발이 막막하다면 빡공단으로 시작하세요!</p>
							</div>
						</div>
					</div>

					<div className='info-item'>
						<h3>
							자기계발과 습관을 한 번에
						</h3>
						<p>
							&lt;맥스웰 몰츠 성공의 법칙&gt;저자이자 의사인 맥스웰 몰츠(Maxwell Maltz)의 말에 따르면 습관을
							만드는데는 최소 21일이 하다고 합니다. 장기간 꾸준히 행동을 반복하면 행동이 뇌에 각인되고, 몸에 각인되어 습관이 될
							수 있습니다. <br/>
							<br/>
							매일 꾸준히 한다는 것, 쉽지 않습니다. 대부분 생각만 하거나 하더라도 작심삼일로 끝나기 마련이죠. 하만
							빡공단은 하루 10분씩 누구나 쉽게 시작할 수 있습니다. 30일동안 차근차근 습관을 만들어보세요. 저희가 도와드릴게요.
						</p>
						<img
							src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgd_img_01.png`}
							alt='10 Min 30 Days'
						/>
					</div>

					<div className='info-item'>
						<h3>
							도전하는 사람을 위한 선물
						</h3>
						<p>
							자기계발을 하겠다고 마음 먹었나요? 내적인 동기부여도 큰 힘이 되지만 외적 동기도 무시 못합니다.
							여러분이 열심히 성장할 수 있도록 다양한 상품과 장학금 쿠폰, 수료증을 준비했습니다.
							게다가 참여자 전원에게는 왠지 공부가 더 잘 될 거 같은 ‘빡곰이 굿노트 템플릿’도 무료로 드립니다.
						</p>
						<img
							src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgd_img_02.png`}
							alt='클래스 완강자 수강 확인증을 최종 수료자에게는 챌린지 수료증과 베어유 학습 장학금(BEARU Coupon 50,000) 모두 제공'
						/>
						<img
							src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgd_img_03.png`}
							alt='우수자 최대 10명에게 배달의민족 5만원'
							className='mt-5'
						/>
					</div>

					<div className='info-item'>
						<h3>
							빡공단을 해야하는 이유
						</h3>
						<h4>
							40개의 다양한 클래스와 할인
						</h4>
						<p>
							40개의 클래스 중 꼭 배우고 싶은 클래스를 신청하세요. 빡공단 참여 인원에게만 15만원
							상당의 클래스를 각 59,000원 제공합니다. 보상형 할인쿠폰이 아닙니다. 할인받고 시작하세요.
						</p>
						<div className='gray-card desc-text'>
							<div>🎁</div>
							<div>
								<p className='text-yellow-04 m-0'>
									여러분의 성장과 성공을 믿기에 먼저 할인해 드립니다.
								</p>
							</div>
						</div>
						<h4>
							커뮤니티로 함께 성장
						</h4>
						<p>
							혼자서 하는 자기계발은 쉽게 포기하기 마련입니다. 빡공단은 함께 할 수 있는 많은 사람들이
							있습니다. 다른 사람들이 배운 내용, 내가 배운 내용을 살펴보고 소통하며 함께 성장하세요!
						</p>
						<img
							src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgd_img_04.png`}
							alt='10 Min 30 Days'
						/>
					</div>

					<div className='info-item'>
						<h3>
							매일 10분, 30일동안 학습
						</h3>
						<p>
							베어유의 온라인 클래스는 길지 않습니다. 10분 내외의 짧은 강의로 누구나 쉽게, 꾸준히 학습할 수 있습니다. 길지
							않다고 ‘배울 것이 없지 않을까?’ 고민하지 않으셔도 괜찮아요. 이미 5만 명이 빡공단으로 성장했습니다. 아래 후기를
							확인해보세요.
						</p>

						<div className='video-container'>
							<iframe width='560' height='315'
											src='https://www.youtube.com/embed/dHlMqyeSUaU?si=7HSbZGWs4ynn_o-V'
											title='YouTube video player'
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
											referrerPolicy='strict-origin-when-cross-origin'
											allowFullScreen
							/>
						</div>
					</div>

					<div className='info-item'>
						<h3>
							30일동안 활동합니다
						</h3>
						<ul className='bgd-run-guide'>
							<li className='item'>
								<h4 className='title'>30일 학습일지 작성</h4>
								<p>
									매일 10분, 클래스를 수강하고 30일동안 학습일지를 작성합니다. 종강 후 나만의 학습기록을 확인하세요!
								</p>
							</li>
							<li className='item'>
								<h4 className='title'>커리어 프로필 만들기</h4>
								<p>
									활동기간동안 커리어에 도움될 수 있는 커리어프로필을 제작합니다. 희망인원에 따라 채용연계까지 도와드립니다.
								</p>
							</li>
							<li className='item'>
								<h4 className='title'>커뮤니티로 인사이트 공유</h4>
								<p>
									빡공단 카페에서 함께 활동하는 사람들에게 인사이트 또는 커리어에 도움되는 글을 쓰고 공유하여 성장해보세요.
									대외활동, 공모전, 자기계발 글/영상 어느 것이든 좋습니다.
								</p>
							</li>
							<li className='item'>
								<h4 className='title'>최종학습 후기</h4>
								<p>
									소유하고 있는 채널에 빡공단을 통해 성장한 내용을 후기로 작성하세요. 더 많은 사람들에게 여러분의 자기계발과
									커리어
									성장을 알려주세요.
								</p>
							</li>
						</ul>
					</div>

					<div className='info-item'>
						<h3>
							40개 빡공단 클래스를 골라보세요
						</h3>
						<p>
							빡공단 클래스는 대학생활, 취업과 창업을 위한 알짜배기 필수 스킬들로만 구성했습니다. 배워보고 싶었던 스킬들을
							찾아보세요. 따끈따끈한 ChatGPT, 언리얼엔진5 등 신규 클래스도 생겼답니다. 여러 개를 신청해도 좋아요. 다만
							본인의 일정 등을 고려하여 최대 개수는 3개까지 신청하는 것을 추천드립니다.
						</p>
						<img
							src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgd_img_05.gif`}
							alt='40개 클래스'
						/>
					</div>

					<div  className='info-item bgd-info-wrap'>
						<h3>
							안내사항
						</h3>
						<ul className='bgd-info-list dropdown'>
							<li>
								<h4 className='title'>학습비 지원</h4>
								<p>
									빡공단 합격자에 한하여 15만원 상당의 클래스를 한 클래스 당 59,000원에 제공합니다.
									그 외 추가적인 비용이 발생하지 않습니다. 다만 프로모션 할인이 적용되어 있는 상품이기
									때문에 가입이나 이벤트로 제공 받은 할인쿠폰은 사용할 수 없습니다.
								</p>
							</li>
							<li>
								<h4 className='title'>환불</h4>
								<p>
									빡공단 자기계발 챌린지는 특별할인 지원이 적용된 프로그램으로 활동이 시작되면 환불이 불가합니다. 이점 유의하여
									수강신청해주세요.
								</p>
							</li>
							<li>
								<h4 className='title'>클래스 신청</h4>
								<p>
									클래스는 신청기간에만 수강하기를 통해 구매할 수 있습니다. 한 클래스당 59,000원 비용이 발생하며, 개인의
									상황에 따라 여러 클래스를 구매하실 수 있습니다. 클래스의 수강기간은 구매일로부터 60일입니다.
								</p>
							</li>
							<li>
								<h4 className='title'>상품안내</h4>
								<p>
									빡공단 우수자 제공 상품은 기수별로 변동될 수 있으며, 우수 기준 미충족시 우수자
									미선발로 상품이 지급되지 않을 수 있습니다. 5만원 초과되는 상품은 제세공과금이
									발생하며 이는 우수자 개인의 부담해야합니다. 재고 및 운영 상황에 따라 상품은 변동될 수 있습니다.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</article>
	);
};

export default withRouter(BgdNotActive)
