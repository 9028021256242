import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import "../../assets/scss/page/password_find.scss";
import AccountModel from "../../model/AccountModel";
import {set_footer_type, set_header_type} from "../../redux/common/action";
import {useDispatch} from "react-redux";
import {useDialogContext} from "../_common/dialog/DialogContextProvider";
import EmailInput from "../_common/Input/EmailInput";
import {useMount} from "../../util/BearuHooks";

const PasswordFind = (props) => {
    const dispatch = useDispatch();
    const {alert} = useDialogContext();
    const [isMount] = useMount();

    const [validate, setValidate] = useState(false);
    const input_values = useRef({
        email: "",
    });

    useEffect(() => {
        dispatch(
            set_header_type(0, 2, {
                title: "비밀번호 찾기",
                no_footer: true,
                no_top_btn: true,
            })
        );
        dispatch(set_footer_type(0));
        props.ready();
    }, []);

    const onAccountValidate = async () => {
        if (!validate) {
            return;
        }

        let {code} = await AccountModel.passwordReset({email: input_values.current.email});
        if (isMount()) {
            if (code === 200) {
                await alert("해당 메일로 임시 비밀번호를 발송했습니다.");
                props.history.push({
                    pathname: "/user/signin",
                    state: {step: 2},
                });
            } else if (code === 4505) {
                await alert("탈퇴한 계정입니다.");
            } else if (code === 4506) {
                await alert("이용정지 계정입니다.");
            } else {
                await alert("존재하지 않는 계정입니다.");
            }
        }
    };

    return (
        <div className="user-container password-find-wrap">
            <Helmet>
                <title>비밀번호 찾기</title>
            </Helmet>

            <div className="td-wrapper">
                <div className="content">
                    <h2 className="common-center-title type2">비밀번호 찾기</h2>

                    {/* template-input */}
                    <div className="template-input-wrap">
                        <EmailInput
                            require={false}
                            auto_focus="autoFocus"
                            on_data_changed={(text, validated) => {
                                if (validated) {
                                    input_values.current.email = text;
                                    setValidate(true);
                                } else {
                                    input_values.current.email = "";
                                    setValidate(false);
                                }
                            }}
                        />
                    </div>

                    <div className="td-btn submit-btn" onClick={onAccountValidate}>
                        확인
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordFind;
