import React, { useEffect } from 'react';
import '../../assets/scss/page/terms_policy.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { set_footer_type, set_header_type } from '../../redux/common/action';

const PlatformPolicy = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);

  useEffect(() => {
    dispatch(set_header_type(0, 2, { title: '이용약관' }));
    dispatch(set_footer_type(0));
    props.ready();
  }, []);

  return (
    <div className='terms-policy-container'>
      <Helmet>
        <title>중개플랫폼 민원책임</title>
      </Helmet>
      <p className='title pc-visible'>중개플랫폼 민원책임</p>
      <ul className='terms-list'>
        <li className='terms-con'>
          <h4 className='terms-subtitle'>안내</h4>
          <div className='terms-text-wrap'>
            <p className='terms-text'>
              모든 거래에 대한 책임과 배송/교환/환불/민원 등의 처리는 주식회사 텐덤 에서 진행합니다.
            </p>
            <p className='terms-text'>민원 담당자: 고객센터</p>
            <p className='terms-text'>연락처: {CommonStore.service.info.contact_tel_number}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default PlatformPolicy;
