import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {set_tmp} from "../../../redux/common/action";
import {transform_legacy_get_parameters, create_notification_url, try_json_parse} from "../../../common";
import {dialog_type_custom, useDialogContext} from "./DialogContextProvider";
import {useDispatch, useSelector} from "react-redux";
import {useObserver} from "../../../util/BearuHooks";
import moment from "moment";
import AccountModel from "../../../model/AccountModel";
import PropTypes from "prop-types";
import NoticeContentsModal from "./NoticeContentsModal";


const propTypes = {
    noti_count: PropTypes.number.isRequired,
    new_noti: PropTypes.bool,
    notification_info: PropTypes.object,
    setOnNotificationList: PropTypes.func
};

const init_notification_info = {
    page: 1,
    set: [],
    is_last: false,
    is_pending: false,
    size: 16
};

// @TODO hpark 처음 로딩시 사이즈 fitting 안되는 문제 있음
const NotificationModal = (props) => {
    //Redux
    const dispatch = useDispatch();
    const AccountStore = useSelector(state => state.AccountStore);

    //Modal
    const {showDialog} = useDialogContext();
    const dialog_utils = props.dialog_utils || {};
    const dialog_props = props.dialog_props || {};

    //Notification
    const notification_info = useRef(props.notification_info || init_notification_info);
    const [notification_info_set, setNotificationInfoSet] = useState(notification_info.current.set);

    const is_redirect = useRef(false);

    const load_ref = useObserver({threshold: 0}, () => {
        requestNotificationList();
    }, null, [props.noti_count]);

    useEffect(() => {
        notification_info.current = init_notification_info;
        // set pagesize 1
    }, [props.noti_count]);

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    const requestNotificationList = () => {
        if (!notification_info.current.is_last && !notification_info.current.is_pending) {
            notification_info.current.is_pending = true;
            AccountModel.notificationList({
                page: notification_info.current.page,
                size: notification_info.current.size
            }).then(({notification_set}) => {
                if (notification_info.current.page === 1) {
                    // test
                    // let noti_course_set = CommonStore.tmp.noti_course_set;
                    // notification_info.current.set = noti_course_set || [];
                }
                let new_notification_set = [...notification_info.current.set, ...notification_set];
                notification_info.current.set = new_notification_set;
                setNotificationInfoSet(new_notification_set);

                props.setOnNotificationList && props.setOnNotificationList({
                    ...notification_info,
                    set: new_notification_set
                });

                notification_info.current.is_last = notification_set.length < notification_info.current.size;
                notification_info.current.page++;
                notification_info.current.is_pending = false;
            });
        }
    };

    const redirect = ({url, pathname, state}) => {
        is_redirect.current = true;
        if (url) {
            props.history.replace(url);
        } else {
            props.history.replace({pathname, state});
        }
    };

    const redirect_push = ({url, pathname, state}) => {
        is_redirect.current = true;
        if (url) {
            props.history.replace(url);
        } else {
            props.history.replace({pathname, state});
        }
    };

    const onNoticeDetail = async (notification) => {
        if (notification.notice_category_id < 100) { // notice_category_id 100 은 내부에서 별도로 관리되는 수강 만료알림이다, 나중에 상수화
            if (AccountStore && !notification.is_read) {
                await AccountModel.notificationRead({notification_id: notification.id});
                AccountModel.notificationNewCheck().then(({is_new_noti}) => {
                    dispatch(set_tmp("is_new_noti", is_new_noti));
                });
            }
        }

        notification_info.current.set = notification_info.current.set.map(data => {
            if (data.id === notification.id) data.is_read = true;
            return data;
        });

        let redirect_url = notification.redirect_url || notification.redirect_site_map_url;
        if (notification.is_notice) {
            if (notification.notice_category_id < 100) {
                redirect({
                    url: `/service/notice/${notification.id}`
                });
            } else {

            }
        } else if (notification.content || redirect_url) {
            if (redirect_url) {
                let measured_redirect_url = null;
                if (notification.parameter_data) {
                    let get_object = try_json_parse(notification.parameter_data);
                    let transform_get_object = transform_legacy_get_parameters(get_object);

                    if (redirect_url === '/ebook' && transform_get_object?.ebookId) {
                        redirect_url = `/ebook/${transform_get_object.ebookId}`;
                    }

                    if (transform_get_object) {
                        measured_redirect_url = create_notification_url(redirect_url, transform_get_object);
                    } else {
                        measured_redirect_url = redirect_url;
                    }
                } else {
                    measured_redirect_url = redirect_url;
                }

                // 상황에 따라 향후에 bear-u.com 도메인이 아닌경우 새창으로 띄울 수 있음s
                redirect_push({url: measured_redirect_url});
            } else {
                showDialog({
                    type: dialog_type_custom,
                    component: NoticeContentsModal,
                    component_props: {
                        notification: notification
                    }
                });
            }
        } else {
            // 표시할 콘텐츠 없음, 알림 제거
            // alert("해당 알림에 표시할 메시지가 없습니다.");
        }

    };

    return (
        <>
            <div className="header-modal-wrap"
                 onClick={onCloseModalBtn} />
            <div className="modal-container">
                <div className="header-modal noti-modal td-scroll">
                    <div className="header-modal-content">
                        <>
                            <h3 className="modal-title">알림</h3>
                            <div
                                className="modal-close-btn"
                                style={{background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`}}
                                onClick={onCloseModalBtn}
                            />
                        </>
                        <ul>
                            {notification_info_set.map(notification => {
                                let noti_lable = null;
                                if (notification.notice_category_id === 1) {
                                    noti_lable = <h4>공지</h4>;
                                } else if (notification.notice_category_id === 2) {
                                    noti_lable = <h4 className="event">이벤트</h4>;
                                } else if (notification.notice_category_id === 3) {
                                    noti_lable = <img className="course-related"
                                                      src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-ticket.svg`}
                                                      alt="수강관련 알림" />;
                                } else if (notification.notice_category_id === 4) {
                                    noti_lable = <img className="blog"
                                                      src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-blog-noti.svg`}
                                                      alt="블로그 알림" />;
                                } else if (notification.notice_category_id === 5) {
                                    noti_lable = <img className="etc"
                                                      src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-etc.svg`}
                                                      alt="기타 알림" />;
                                } else if (notification.notice_category_id === 6) {
                                    noti_lable = <h4 className="coupon">쿠폰</h4>;
                                } else if (notification.notice_category_id === 7) {
                                    noti_lable = <h4>배송</h4>;
                                } else if (notification.notice_category_id === 100) {
                                    noti_lable = <h4>D-{notification.notice_category_name}</h4>;
                                }
                                return (
                                    <li key={notification.id} className={notification.is_read ? "is-read" : ""}>
                                        <div className="noti-item"
                                             onClick={() => onNoticeDetail(notification)}>
                                            <div
                                                className={"noti-label" + (notification.notice_category_id === 100 ? " d-day-count" : "")}>
                                                {noti_lable}
                                            </div>
                                            <div className="noti-segment">
                                                {notification.notice_category_id === 100 ?
                                                    <h4 className="noti-title">
                                                        {notification.title} 수강기간이 <b>{notification.notice_category_name}일</b> 남았습니다.
                                                    </h4>
                                                    : <h4>{notification.title}</h4>
                                                }
                                                <h6 className="noti-date">
                                                    {notification.notice_category_id === 100 ?
                                                        "~" + moment(notification.write_time).add(notification.notice_category_name, "days").format("YY.MM.DD") + " 까지" : moment(notification.write_time).format("YY.MM.DD")
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div style={{height: "1px", width: "100%", bottom: 0, position: "relative"}} ref={load_ref} />
                </div>
            </div>
        </>
    );
};

NotificationModal.propTypes = propTypes;

export default withRouter(NotificationModal);
