import React, { useEffect, useState } from 'react';
import '../../assets/scss/page/more_info.scss';
import { useDispatch, useSelector } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ChannelTalkService from '../../util/ChannelTalkService';
import CommonModel from '../../model/CommonModel';
import MyPageMain from '../account/section/MyPageMain';
import { createCategoryInfo } from '../_collections/mypage';
import { useMediaQuery } from 'react-responsive';
import { dialog_type_custom } from '../_common/dialog/DialogContextProvider';
import CouponModal from '../_common/dialog/CouponModal';

const MoreInfo = (props) => {
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);

  const [category, setCategory] = useState(null);
  const [eventlist, setEventlist] = useState(null);

  const init_category = [
    {
      id: 'package',
      name: '추천패키지',
      url: '/overview/package',
    },
  ];

  const getCategorySet = async () => {
    const { code_set } = await CommonModel.staticCode({ code_name: 'course_category_set' });
    let category_list = [...(code_set || []), ...init_category];
    setCategory(category_list);
  };

  const getEvent = async () => {
    const { notice_set } = await CommonModel.noticeList({
      page: null,
      size: 3,
      notice_category_id: 2,
    });
    setEventlist(notice_set);
  };

  useEffect(() => {
    dispatch(
      set_header_type(0, 1, {
        no_footer: true,
        no_channel_talk_button: true,
        no_top_btn: true,
      }),
    );
    dispatch(set_footer_type(0));
    ChannelTalkService.boot({
      pluginKey: '79a6853b-a92f-4152-a01a-ba2fc53f0e54',
      memberId: AccountStore?.id,
      customLauncherSelector: '.contact-btn',
      hideDefaultLauncher: true,
    });
    getCategorySet();
    getEvent();
    props.ready();
  }, []);

  const selected_category = props.match.params.category;
  const selected_category_info = createCategoryInfo();
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [login_icon_url, setLoginIconUrl] = useState(null);

  useEffect(() => {
    if (AccountStore?.account_provider_id === 1) {
    } else if (AccountStore?.account_provider_id === 2) {
      setLoginIconUrl(`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_fb.png`);
    } else if (AccountStore?.account_provider_id === 3) {
    } else if (AccountStore?.account_provider_id === 4) {
      setLoginIconUrl(`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_kakao.png`);
    }
  }, []);

  const getNickname = () => {
    if (AccountStore?.account_type_id === 5) {
      if (!AccountStore?.info?.nickname) {
        return `익명의 관리자`;
      } else {
        return `${AccountStore?.info?.nickname} (관리자)`;
      }
    } else if (!AccountStore?.info?.nickname) {
      return '익명의 수강생';
    } else {
      return AccountStore?.info?.nickname;
    }
  };
  return (
    <div className='more-info-container'>
      <Helmet>
        <title>메뉴</title>
      </Helmet>
      {!is_tablet_or_mobile ||
        (!selected_category && is_tablet_or_mobile && (
          // 모바일일 때 마이페이지 메인, 웹일 땐 사이드 바
          <Link to='/user/mypage'>
            <div className={`account-card ${is_tablet_or_mobile ? '' : 'side-bar'}`}>
              <div className='account-wrap'>
                <div className='account-info'>
                  <div className='info-wrap'>
                    <div className='profile-img-wrap'>
                      <img
                        className='profile-img'
                        src={
                          AccountStore?.info?.picture_thumbnail_image_url
                            ? AccountStore?.info?.picture_thumbnail_image_url
                            : `${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_bear_m3.png`
                        }
                        alt='프로필이미지'
                      />
                    </div>
                    <div>
                      <div className='account-info-row'>
                        {login_icon_url !== null && <img src={login_icon_url} alt='sns 이미지' />}
                        <p>{getNickname()}</p>
                      </div>
                      {AccountStore?.account_provider_id === 1 && (
                        <div className='email-info'>{AccountStore?.account_name}</div>
                      )}
                    </div>
                  </div>
                </div>
                <span className='enrollment-btn mt-4'>마이페이지로 가기</span>
              </div>
            </div>
          </Link>
        ))}

      <div className='link-menu-list'>
        <div className='info-class'>
          <Link to='/overview/all'>
            <div className='menu-title'>
              <p>클래스</p>
              <div className='direction' />
            </div>
          </Link>
          <ul className='class-category'>
            {category?.map((category) => {
              return (
                <li key={category.id} className='category-title'>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.history.push(category.url || `/overview/${category.id}`);
                    }}
                  >
                    <p>{category.name}</p>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='info-ebook menu-li'>
          <Link to='/ebook'>전자책</Link>
        </div>

        <div className='info-hardstudy menu-li'>
          <Link to='/bgd'>빡공단</Link>
        </div>
        {/*<div className="info-superz">
                    <Link to="/package/262">
                        <div className="menu-title">
                            <p>0원 수강</p>
                        </div>
                    </Link>
                </div>*/}

        <div className='info-event'>
          <Link to='/service/event'>
            <div className='menu-title'>
              <p>이벤트</p>
              <div className='direction' />
            </div>
          </Link>
          <ul className='event-menu'>
            {eventlist?.map((event) => {
              return (
                <li key={event.id} className='event-title'>
                  <a
                    style={{ display: 'flex', cursor: 'pointer', lineHeight: '25px' }}
                    onClick={() => {
                      props.history.push(event.url || `/service/notice/${event.id}`);
                    }}
                  >
                    <p>{event.title}</p>
                    <div className='direction' />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        <div className='info-event'>
          <Link to='/creditbank'>
            <div className='menu-title'>
              <p>학점은행제</p>
            </div>
          </Link>
        </div>

        <div className='info-notice menu-li'>
          <Link to='/service/notice'>공지사항</Link>
        </div>

        <div className='info-master menu-li'>
          <a href='https://tally.so/r/nW8JaR'>클래스 판매등록 신청</a>
        </div>

        <div className='none-border menu-li'>
          <a target='_blank' rel='noreferrer' href={'https://bearu-ebook.oopy.io'}>
            전자책 작가 신청
          </a>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
