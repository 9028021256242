import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { replace_cloudfront_url } from '../../../common';
import { dialog_type_custom, useDialogContext } from '../../_common/dialog/DialogContextProvider';
import CourseModel from '../../../model/CourseModel';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import LoadingSpinner from '../../_common/component/LoadingSpinner';
import PreviewModal from '../../_common/dialog/PreviewModal';

const propTypes = {
  course_id: PropTypes.number.isRequired,
};

const ProductCurriculum = (props) => {
  //Modal
  const { showDialog } = useDialogContext();

  //MediaQuery
  const is_mobile = useMediaQuery({ query: '(max-width: 480px)' });

  //Subject
  const [subject_set, setSubjectSet] = useState([]);

  useEffect(() => {
    if (props.course_id) {
      CourseModel.courseDetail({ course_id: props.course_id, only_type: 'subject' }).then(
        ({ code, course }) => {
          transSubject(course.subject_set);
        },
      );
    }
  }, [props.course_id]);

  const transSubject = (trans_subject_set) => {
    let response_subject_set = [];
    for (let i = 0; i < trans_subject_set.length; i++) {
      let is_chapter = true;
      if (i) {
        if (
          trans_subject_set[i - 1].chapter_description === trans_subject_set[i].chapter_description
        ) {
          is_chapter = false;
        }
      }
      if (is_chapter) {
        response_subject_set.push({
          chapter_description: trans_subject_set[i].chapter_description,
          subject_set: [
            {
              index_name: trans_subject_set[i].index_name,
              title: trans_subject_set[i].title,
              image_url: trans_subject_set[i].image_url,
              thumbnail_image_url: trans_subject_set[i].thumbnail_image_url,
              preview_youtube_id: trans_subject_set[i].preview_youtube_id,
            },
          ],
        });
      } else {
        response_subject_set[response_subject_set.length - 1].subject_set.push({
          index_name: trans_subject_set[i].index_name,
          title: trans_subject_set[i].title,
          image_url: trans_subject_set[i].image_url,
          thumbnail_image_url: trans_subject_set[i].thumbnail_image_url,
          preview_youtube_id: trans_subject_set[i].preview_youtube_id,
        });
      }
    }
    setSubjectSet(response_subject_set);
  };

  const onPreviewBtnClick = (video_id) => {
    showDialog({
      type: dialog_type_custom,
      component: PreviewModal,
      component_props: {
        video_id: video_id,
      },
    });
  };

  return (
    <>
      {subject_set.length ? (
        <>
          {subject_set.map((chapter, index) => {
            return (
              <div className='curriculum-item' key={index}>
                {chapter.subject_set.map((subject, index) => {
                  if (index === 0) {
                    let subject_cover_view = subject.preview_youtube_id ? (
                      <ReactPlayer
                        className='youtube-player'
                        url={`https://www.youtube.com/watch?v=${subject.preview_youtube_id}`}
                      />
                    ) : subject.thumbnail_image_url ? (
                      <img src={replace_cloudfront_url(subject.thumbnail_image_url)} alt='' />
                    ) : null;
                    let subject_cover_view_classname = subject.thumbnail_image_url
                      ? 'preview-box'
                      : '';
                    return (
                      <div key={index} className={subject_cover_view_classname}>
                        {subject_cover_view}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
                <div className='curriculum-detail'>
                  <h4>{chapter.chapter_description}</h4>
                  <ul>
                    {chapter.subject_set.map((subject, index) => {
                      return (
                        <li key={index}>
                          <div className='chapter-content-number'>{index + 1}</div>
                          <h5
                            style={{
                              paddingRight: subject.preview_youtube_id ? (is_mobile ? 40 : 90) : 0,
                            }}
                          >
                            {subject.title}
                          </h5>
                          {subject.preview_youtube_id && (
                            <div
                              className='preview-video-btn-wrap'
                              onClick={() => {
                                onPreviewBtnClick(subject.preview_youtube_id);
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-play-circle-outline-white.svg`}
                                alt={'강의 미리보기'}
                              />
                              <span>미리보기</span>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
          {/*{video_id && is_modal && (*/}
          {/*    <PreviewModal video_id={video_id} setModal={setModal} is_modal={is_modal} />*/}
          {/*)}*/}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
ProductCurriculum.propTypes = propTypes;

export default ProductCurriculum;
