import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { HEADER_TYPE_MOBILE_FIXED, HEADER_TYPE_PC_BASE } from '../_common/section/Header';
import moment from 'moment';
import '../../assets/scss/page/notice.scss';
import CommonModel from '../../model/CommonModel';
import InputLabel from '../_common/Input/InputLabel'
import TemplateInput from '../_common/_template/TemplateInput'
import { useDialogContext } from '../_common/dialog/DialogContextProvider'
import { useMount } from '../../util/BearuHooks'
import { v4 as uuidv4 } from 'uuid'
import { encryptData } from '../../util/crypto-utils'
import AccountModel from '../../model/AccountModel'
import { stored_account_info } from '../../redux/account/action'
import Cookies from 'js-cookie'

const Notice = (props) => {
  //Redux
  const dispatch = useDispatch();
	const AccountStore = useSelector((state) => state.AccountStore);

  //MediaQuery
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 767px)' });

  //Notice List
  const [notice_set, setNoticeSet] = useState([]);

  //Pagination
  const [page, setPage] = useState(1);
  const [total_count, setTotalCount] = useState(0);

  const getNoticeSet = async (reg_page = 1) => {
    try {
      const { notice_set, total_count } = await CommonModel.noticeList({
        page: reg_page,
        size: 10,
        notice_category_id: 1,
      });
      setNoticeSet(notice_set);
      setPage(reg_page);
      setTotalCount(total_count);
      props.ready();
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(set_header_type(HEADER_TYPE_PC_BASE, HEADER_TYPE_MOBILE_FIXED, { title: '공지사항' }));
    dispatch(set_footer_type(0));
    getNoticeSet().then();
  }, []);

	///
	const { alert } = useDialogContext();
	const [isMount] = useMount();

	const createSessionData = (account) => {
		const secretKey = process.env.REACT_APP_SECRET_KEY;
		const jsonData = JSON.stringify({
			sessionId: uuidv4().replaceAll('-', ''),
			account: account
		});
		return encryptData(jsonData, secretKey);
	}

	const [accountId,setAccountId]=useState('');

	const onSignIn = async () => {
		const { code, account } = await AccountModel.forceSignIn(accountId);

		if (isMount()) {
			if (code === 200) {
				dispatch(stored_account_info(account));
				Cookies.set('ebookAuth', `${account.id}`, { domain: '.bear-u.com', path: '/', expires: 3 });

				AccountModel.bgdCheck().then(({ data }) => {
					if (data?.bgd?.length > 0) {
						const isBgdSessionId = Cookies.get('bgdSessionId');
						if (isBgdSessionId) {
							Cookies.remove('bgdSessionId', { domain: '.bear-u.com', path: '/' });
						}
						const sessionData = createSessionData(account);
						Cookies.set('bgdSessionId', `${sessionData}`, { domain: '.bear-u.com', path: '/', expires: 14 });
					}
				});
			} else if (code === 4505) {
				await alert('탈퇴한 계정입니다.');
			} else if (code === 4506) {
				await alert('이용정지 계정입니다.');
			} else if (code === 4500) {
				await alert('이메일 혹은 비밀번호를 확인해주세요.');
			} else {
				await alert('오류가 발생하였습니다.');
			}
		}
	};

  return (
    <div className='notice-container'>
      <Helmet>
        <title>공지사항</title>
      </Helmet>
      <p className='title'>공지사항</p>
      <div className='notice-list'>
        {notice_set.map((notice) => {
          return (
            <Link key={notice.id} to={`/service/notice/${notice.id}`}>
              <div className='notice-content'>
                <p className='notice-title'>{notice.title}</p>
                <div className='date-wrap'>
                  <p className='notice-date'>{moment(notice.write_time).format('YYYY-MM-DD')}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      {total_count ? (
				<div className='notice-pagination'>
					<div className='page'>
						<img
							onClick={() => {
								if (page !== 1) {
									getNoticeSet(page - 1).then();
								}
							}}
							className={'arrow left' + (page === 1 ? ' disabled' : '')}
							src={
								is_tablet_or_mobile
									? `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_l_m.svg`
									: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_l_lg.svg`
							}
							alt='arrow'
						/>
						{page}&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
						{Math.ceil(total_count / 10)}
						<img
							onClick={() => {
								if (Math.ceil(total_count / 10) > page) {
									getNoticeSet(page + 1).then();
								}
							}}
							className={'arrow right' + (page === Math.ceil(total_count / 10) ? ' disabled' : '')}
							src={
								is_tablet_or_mobile
									? `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_r_m.svg`
									: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/notice/ic_arrow_r_lg.svg`
							}
							alt='arrow'
						/>
					</div>
					{
						AccountStore?.account_type_id === 5 && (
							<form className='pt-10'>
								<InputLabel label='접속할 계정번호' require={require}/>
								<TemplateInput
									input_props={{
										id: 'accountId',
										placeholder: "계정번호를 입력해주세요",
										defaultValue: props?.default_value,
										autoFocus: props?.auto_focus,
										ref: props?.input_list_ref,
										onChange: (text) => {
											setAccountId(text)
										},
									}}
								/>
								<div className='login-btn'>
									<input type='button' value='로그인' className='td-btn'
												 onClick={() => {
													 onSignIn();
												 }}/>
								</div>
							</form>
						)
					}
				</div>
			) : null}
		</div>
	);
};

export default Notice;
