import {
    STORED_STATIC_CODE,
    SET_HEADER_TYPE,
    SET_FOOTER_TYPE,
    SET_TMP,
    STORED_SERVICE,
    RESET_TMP,
    SET_NOTI,
    SET_VERSION,
    RESET_STATIC_CODE
} from "../action_type";
import update from "react-addons-update";

const INIT_STATIC_CODE_STATE = {
    course_category_set: [],
    provider_type_set: [],
    job_class_set: [],
    notice_category_set: [],
    faq_category_set: [],
    shipper_set: [],
    currency_code_set: []
};

const INIT_STATE = {
    version: {},
    static_code: INIT_STATIC_CODE_STATE,
    service: {},
    header: {
        pc_header_type: 0,
        mo_header_type: 0,
        header_set: {
            pk: null,
            title: null,
            data: {
                title: null,
                no_footer: false,
                no_top_btn: false,
                no_channel_talk_button: false,
                no_search_btn: false,
                no_back_btn: false,
                no_bottom_nav: false,
                pop_up_btn: false
            }
        }
    },
    footer: {
        // pc_footer_type = 0 - 기본 (모바일 혼용)
        // pc_footer_type = 1 - 패키지 페이지 (모바일 혼용)
        pc_footer_type: 0, // 모바일은 pc 호환
        footer_set: {
            data: {
                read_only: false
            }
        }
    },
    tmp: {
        my_course_set: [],
        my_favorite_course_set: [],
        my_favorite_pacakge_set: [],
        my_favorite_kit_set: [],
        recent_course_set: [],
        noti_course_set: [],
        last_subject_set: [],
        read_notice_set: [],
        account_validate_count: 0,
        purchase: null,
        redirect_url: null,
        is_new_noti: false,
        noti_count: 0,
        course_update_date: null,
        viewer_auto_play: false,
        is_top_banner_visible: false,
    }
};

const INIT_HEADER_SET = {
    pk: null,
    title: null,
    data: {
        title: null,
        no_footer: false,
        no_top_btn: false,
        no_channel_talk_button: false,
        no_search_btn: false,
        no_back_btn: false,
        no_bottom_nav: false,
        pop_up_btn: false
    }
};

const INIT_FOOTER_SET = {
    data: {
        read_only: false
    }
};

const INIT_TMP_SET = {
    my_course_set: [],
    my_favorite_course_set: [],
    my_favorite_pacakge_set: [],
    my_favorite_kit_set: [],
    recent_course_set: [],
    noti_course_set: [],
    last_subject_set: [],
    read_notice_set: [],
    account_validate_count: 0,
    purchase: null,
    redirect_url: null,
    is_new_noti: false,
    noti_count: 0,
    course_update_date: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
    const tmp_state = state;
    let is_push = true;
    switch (action.type) {
        case STORED_STATIC_CODE:
            return update(state, {
                static_code: {
                    [action.code_name]: {$set: action.data}
                }
            });
        case RESET_STATIC_CODE:
            return update(state, {
                static_code: {$set: INIT_STATIC_CODE_STATE}
            });
        case STORED_SERVICE:
            return update(state, {service: {[action.key]: {$set: action.data}}});

        case SET_HEADER_TYPE:
            return update(state, {
                header: {
                    pc_header_type: {$set: action.pc_header_type},
                    mo_header_type: {$set: action.mo_header_type},
                    header_set: {$set: action.data || INIT_HEADER_SET}
                }
            });

        case SET_FOOTER_TYPE:
            return update(state, {
                footer: {
                    pc_footer_type: {$set: action.pc_footer_type},
                    footer_set: {$set: action.data || INIT_FOOTER_SET}
                }
            });

        case SET_TMP:
            if (action.key === "recent_course_set") {
                if (action.data.length === 0) {
                    tmp_state.tmp.recent_course_set = [];
                } else {
                    for (let i = 0; i < tmp_state.tmp.recent_course_set.length; i++) {
                        if (tmp_state.tmp.recent_course_set[i].id === action.data.id) {
                            is_push = false;
                        }
                    }
                    if (is_push) {
                        if (tmp_state.tmp.recent_course_set.length > 1) {
                            tmp_state.tmp.recent_course_set.shift();
                        }
                        tmp_state.tmp.recent_course_set.push(action.data);
                    } else {
                        if (tmp_state.tmp.recent_course_set.length > 1) {
                            tmp_state.tmp.recent_course_set.reverse();
                        }
                    }
                }
            } else if (action.key === "my_course_set") {
                let sort_course_set = [];
                const sort_last_subject_set = state.tmp.last_subject_set;
                if (sort_last_subject_set.length) {
                    sort_last_subject_set.sort((a, b) => {
                        if (a.update_date < b.update_date) {
                            return 1;
                        }
                        if (a.update_date > b.update_date) {
                            return -1;
                        }
                        return 0;
                    });
                }
                for (let j = 0; j < sort_last_subject_set.length; j++) {
                    for (let i = 0; i < action.data.length; i++) {
                        if (!action.data[i].is_expired && action.data[i].id === sort_last_subject_set[j].course_id) {
                            sort_course_set.push(action.data[i]);
                            break;
                        }
                    }
                }

                for (let i = 0; i < action.data.length; i++) {
                    let check = false;
                    for (let j = 0; j < sort_course_set.length; j++) {
                        if (action.data[i].id === sort_course_set[j].id) {
                            check = true;
                            break;
                        }
                    }
                    if (!check) {
                        sort_course_set.push(action.data[i]);
                    }
                }

                return update(state, {tmp: {my_course_set: {$set: sort_course_set}}});
            } else {
                if (action.key === "account_validate_count") {
                    const account_validate_count = state.tmp?.account_validate_count || 0;
                    return update(state, {tmp: {account_validate_count: {$set: account_validate_count + 1}}});
                } else {
                    return update(state, {tmp: {[action.key]: {$set: action.data}}});
                }
            }
            return tmp_state;

        case SET_NOTI:
            return update(state, {
                tmp: {is_new_noti: {$set: action.is_new_noti}, noti_count: {$set: action.noti_count}}
            });

        case SET_VERSION:
            return update(state, {version: {$set: action.version}});

        case RESET_TMP:
            return update(state, {
                tmp: {$set: INIT_TMP_SET}
            });
        default:
            return state;
    }
};
