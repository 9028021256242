import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';
import '../../assets/scss/page/sign_up.scss';
import { set_footer_type, set_header_type, set_tmp } from '../../redux/common/action';
import { custom_hash } from '../../common';

const SignUpComplete = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const AccountStore = useSelector((state) => state.AccountStore);
  const [hash_id, setHashId] = useState(null);

  useEffect(() => {
    custom_hash(AccountStore.id).then((data) => {
      setHashId(data);
    });
    if (props.history.action === 'POP') {
      onRedirect();
    } else {
      props.ready();
    }
    dispatch(
      set_header_type(0, 2, {
        title: '회원가입 완료',
        no_footer: true,
        no_top_btn: true,
      })
    );
    dispatch(set_footer_type(0));

    ReactPixel.trackCustom('가입 완료');
  }, []);

  const onRedirect = () => {
    let redirect_url = CommonStore.tmp.redirect_url;
    const give_coupon_token = JSON.parse(localStorage.getItem('Channel_G_C'));

    if (give_coupon_token) {
      redirect_url = `${give_coupon_token.location.pathname}${give_coupon_token.location.search}`;
      localStorage.removeItem('Channel_G_C');
    }
    dispatch(set_tmp('redirect_url', null));
    props.history.replace(redirect_url || '/');
  };

  const myCouponOnRedirect = () => {
    let redirect_url = '/user/mypage/coupon';
    const give_coupon_token = JSON.parse(localStorage.getItem('Channel_G_C'));

    if (give_coupon_token) {
      redirect_url = `${give_coupon_token.location.pathname}${give_coupon_token.location.search}`;
      localStorage.removeItem('Channel_G_C');
    }
    dispatch(set_tmp('redirect_url', null));
    props.history.replace(redirect_url);
  };

  return (
    <div className='user-container complete-container'>
      <input name='hashId' type='hidden' value={hash_id || ''} />
      <Helmet>
        <title>회원가입 완료</title>
      </Helmet>
      {props.history.action !== 'POP' && (
        <div className='td-wrapper'>
          <img
            className='complete-logo'
            src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-common-icon.png`}
            alt='logo'
          />
          <p className='title'>환영합니다 🤗</p>
          <div className='desc-container'>
            <p className='desc'>
              회원가입을 축하드립니다.
              <br />
              최고의 서비스를 제공 드리기위해,
              <br />
              열심히 발로 뛰는 베어유가 되겠습니다.
            </p>
          </div>
          <div className={'btn-wrap'}>
            <button className='border-btn btn' onClick={myCouponOnRedirect}>
              바로 사용가능 쿠폰 확인하기
            </button>
            <button className='td-btn btn mt-3' onClick={onRedirect}>
              계속 구경하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(SignUpComplete);
