import React, { useEffect, useRef, useState } from 'react';

import CourseModel from '../../../model/CourseModel';
import CourseCard2 from '../component/CourseCard2';
import '../../../assets/scss/page/all_course_list.scss';
import { useObserver, getSeed } from '../../../util/BearuHooks';
import ProductModel from '../../../model/ProductModel';

const AllCourseList = (props) => {
  const [course_set, setCourseSet] = useState([]);
  const [package_set, setPackageSet] = useState([]);

  const _is_mount = useRef(false);

  let _is_last = useRef([false, false]); //package, course
  let _is_pending = useRef([false, false]); //package, course
  let page = useRef([1, 1]); //package, course
  let size = 12;

  useEffect(() => {
    _is_last.current = [false, false];
    _is_pending.current = [false, false];
    page.current = [1, 1];
    setCourseSet([]);
    setPackageSet([]);
  }, []);

  const course_load_ref = useObserver(
    { threshold: 0 },
    () => {
      onCourseSet();
    },
    null,
    [],
  );

  const package_load_ref = useObserver(
    { threshold: 0 },
    () => {
      onPackageSet();
    },
    null,
    [],
  );

  const onPackageSet = () => {
    if (!_is_last.current[0] && !_is_pending.current[0]) {
      _is_pending.current[0] = true;
      ProductModel.packageList({
        page: page.current[0],
        size,
        order_option: 'random',
        seed: getSeed(),
      }).then(({ product_set }) => {
        if (!_is_mount.current) {
          setPackageSet((state) => [...state, ...product_set]);
          if (product_set.length < size) {
            _is_last.current[0] = true;
          }
          page.current[0] += 1;
          _is_last.current[0] = false;
        }
      });
    }
  };

  const onCourseSet = () => {
    if (!_is_last.current[1] && !_is_pending.current[1]) {
      _is_pending.current[1] = true;
      CourseModel.courseList({
        page: page.current[1],
        size,
        order_option: 'random',
        seed: getSeed(),
      }).then(({ course_set }) => {
        if (!_is_mount.current) {
          setCourseSet((state) => [...state, ...course_set]);
          if (course_set.length < size) {
            _is_last.current[1] = true;
          }
          page.current[1] += 1;
          _is_pending.current[1] = false;
        }
      });
    }
  };

  return (
    <>
      <div style={{ display: 'block', width: '100%', height: '1px' }} ref={package_load_ref} />

      <div className='all-course-wrap'>
        <div className='course-list overview-list'>
          {course_set.map((course) => {
            return <CourseCard2 key={course.id} course={course} />;
          })}
        </div>
      </div>

      <div style={{ display: 'block', width: '100%', height: '1px' }} ref={course_load_ref} />
    </>
  );
};

export default AllCourseList;
