import React, {useEffect, useRef, useState} from "react";
import CommonModel from "../../../model/CommonModel";
import {useMount} from "../../../util/BearuHooks";
import {on_text_ellipsis, random_pick} from "../../../common";
import ConvertRedirectUrl from "../../../util/ConvertRedirectUrl";
import {useDispatch} from "react-redux";
import {set_tmp} from "../../../redux/common/action";

const TopBanner = (props) => {
    const dispatch = useDispatch();
    const [isMount] = useMount();

    const root = useRef(null);
    const [banner, setBanner] = useState(null);
    const [is_hide, setHide] = useState(false);
    const [is_visible, setVisible] = useState(false);

    const getBannerSet = async () => {
        const {banner_set} = await CommonModel.bannerList({banner_type_id: 4});
        if (isMount()) {
            let picked_banner = random_pick(banner_set);
            // pick_banner 뒤에 ? 붙여줘야 오류 안남
            if (picked_banner?.redirect_url.indexOf("bear-u.com") === -1) {
                picked_banner.is_not_bearu_link = true;
            }
            setBanner(picked_banner);
        }
    };

    useEffect(() => {
        dispatch(set_tmp("is_top_banner_visible", is_visible));
    }, [is_visible]);

    useEffect(() => {
        if (banner && !is_hide) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [banner, is_hide]);

    useEffect(() => {
        getBannerSet();
    }, []);

    return (
        <>
            {banner && (
                <div
                    ref={root}
                    className={"top-banner" + (is_hide ? " hide" : "")}
                    style={{
                        backgroundColor: banner.theme_color_hex,
                        color: `${banner.accent_color_hex}`,
                    }}>
                    <div className="top-banner-wrap">
                        <div className="left-wrap">
                            <ConvertRedirectUrl
                                redirect_url={banner.redirect_url}
                                is_not_bearu_link={banner.is_not_bearu_link}>
                                <div className="content">
                                    <h2>{on_text_ellipsis(`${banner.title}`, 20)}</h2>
                                    <h3>{on_text_ellipsis(`${banner.description}`, 44)}</h3>
                                </div>
                            </ConvertRedirectUrl>
                        </div>

                        <div className="right-wrap">
                            <ConvertRedirectUrl
                                redirect_url={banner.redirect_url}
                                is_not_bearu_link={banner.is_not_bearu_link}>
                                <p
                                    className="icon-image"
                                    style={{
                                        background: `url(${banner.image_url}) no-repeat center center/cover`,
                                    }}
                                />
                            </ConvertRedirectUrl>
                            <p
                                className="close-btn"
                                onClick={() => {
                                    setHide(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TopBanner;
