import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../../assets/scss/page/oppy.scss';
import { url_data_set } from '../../constant/common_url';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { set_header_type } from '../../redux/common/action';

const Creditbank = (props) => {
  const dispatch = useDispatch();
  const { page_name } = useParams();
  const CommonStore = useSelector((state) => state.CommonStore);
  const is_top_banner_visible = CommonStore.tmp?.is_top_banner_visible ?? false;

  useEffect(() => {
    let origin_class_name = window.document.body.className;
    window.document.body.className = 'overflow';
    dispatch(
      set_header_type(0, 1, { no_footer: true, no_top_btn: true, no_channel_talk_button: true }),
    );

    return () => {
      window.document.body.className = origin_class_name;
    };
  }, []);

  const creditBankContent = () => {
    return <iframe title={'creditbank oppy'} src={url_data_set.credit_bank} />;
  };

  return (
    <div className={'oopy-wrap' + (is_top_banner_visible ? '' : ' hide')}>
      <Helmet>
        <title>베어유 : 학점은행제</title>
      </Helmet>
      {creditBankContent()}
    </div>
  );
};

export default Creditbank;
