import * as Yup from 'yup';

// 각 질문의 기본값을 설정하는 함수
export const defaultValues = (fields, accountData, bgdRecruitFormId) => {
	const answersDefaults = fields.reduce((defaults, field) => {
		switch (field.question_type) {
			case 'text':
			case 'textarea':
			case 'select':
			case 'radio':
				defaults[field.question_id] = '';
				break;
			case 'checkbox':
				defaults[field.question_id] = '';
				break;
			default:
				defaults[field.question_id] = '';
		}
		return defaults;
	}, {});

	return {
		account_id: accountData?.id || '',
		phone: accountData?.info?.phone || '',
		email: accountData?.info?.email || '',
		answers: Object.keys(answersDefaults).map(key => ({
			question_id: key,
			type: fields.find(field => field.question_id.toString() === key)?.question_type || '',
			value: answersDefaults[key]
		}))
	};
};

// 유효성 검사 스키마 생성 함수
export const formValidationSchema = (fields) => {
	const questionSchema = fields.reduce((schema, field) => {
		const rule = (() => {
			switch (field.question_type) {
				case 'text':
				case 'textarea':
					return Yup.string().required('필수 입력 항목을 입력해주세요');
				case 'checkbox':
					return Yup.array().min(1, '1개 이상 선택해주십시오');
				case 'select':
				case 'radio':
					return Yup.string().required('1개 이상 선택해주십시오');
				default:
					return Yup.mixed().required('필수 입력 항목을 입력해주세요');
			}
		})();
		schema[`${field.question_type}${field.question_id}`] = rule;
		return schema;
	}, {});

	// 전체 데이터 구조에 대한 유효성 검사 스키마
	return Yup.object().shape({
		account_id: Yup.string().required('필수 입력 항목을 입력해주세요'),
		phone: Yup.string().required('필수 입력 항목을 입력해주세요'),
		email: Yup.string().required('필수 입력 항목을 입력해주세요').email('잘못된 양식'),
		answers: Yup.array().of(
			Yup.object().shape({
				question_id: Yup.string().required('필수 입력 항목을 입력해주세요'),
				type: Yup.string().required('필수 입력 항목을 입력해주세요'),
				value: Yup.string().required('필수 입력 항목을 입력해주세요')
			})
		).required('Answers are required').min(1, 'At least one answer is required'),
	});
};
