import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import CourseModel from "../../../model/CourseModel";
import { useDialogContext } from "../dialog/DialogContextProvider";
import moment from "moment";
import PropTypes from "prop-types";
import { ableSelectAll, ProfileImage, resetSizeTextarea } from "../section/QnaContainer";
import { useMediaQuery } from "react-responsive";
import { createStarRating } from "../section/ReviewContainer";
import { useSelector } from "react-redux";
import LoadingSpinner from "./LoadingSpinner";
import ReviewReply from "./ReviewReply";
import { moveRefToTop } from "../../course/CourseDetail";
import EbookModel from "../../../model/EbookModel";

const propTypes = {
    type: PropTypes.oneOf(["course", "ebook"]),
    review: PropTypes.object,
    key: PropTypes.number,
    user_nickname: PropTypes.string,
    visible_edit_buttons: PropTypes.bool,
    setAvgRating: PropTypes.func,
    is_ebook_author: PropTypes.bool,
    onLoadReview: PropTypes.func,
};

const Review = (props) => {
    //댓글 관련 useState
    const [stated_review, setStatedReview] = useState(props.review);
    const [edit_mode, setEditMode] = useState(false);
    const [hover_rating, setHoverRating] = useState(props.review.rating);
    const [reply_page, setReplyPage] = useState(1);

    //대댓글 관련 useState
    const [reply_list, setReplyList] = useState(null);
    const [write_reply_mode, setWriteReplyMode] = useState(false);
    const [is_opened_reply_area, setIsOpenedReplyArea] = useState(false);
    const [just_wrote_reply_list, setJustWroteReplyList] = useState([]);
    // const [visible_more_button, setVisibleMoreButton] = useState(true);
    const [is_request_loading, setIsRequestLoading] = useState(false);
    const visible_more_button = stated_review.comment_count > 0 && reply_list && reply_list.length < stated_review.comment_count;
    const [replyContent, setReplyContent] = useState("");

    // dom ref
    const review_textarea_ref = useRef(null);
    const write_reply_textarea_ref = useRef(null);
    const reply_list_ref = useRef(null);
    const review_ref = useRef(null);
    const review_text_ref = useRef(null);

    // ref
    const my_star_rating = useRef(props.review.rating);
    const is_posting_reply = useRef(false);

    const AccountStore = useSelector((state) => state.AccountStore);
    const is_tablet_or_mobile = useMediaQuery({query: "(max-width: 765px)"});
    const {confirm, alert} = useDialogContext();

    const modifyReview = async () => {
        let code;
        let data;

        if (props.type === "course") {
            const result = await CourseModel.courseReviewModify({
                review_id: stated_review.id,
                content: review_textarea_ref.current.value.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
                rating: my_star_rating.current,
            });
            code = result.code;
            data = result.data;
        }
        if (props.type === "ebook") {
            const result = await EbookModel.ebookReviewEdit({
                review_id: stated_review.review_id,
                content: review_textarea_ref.current.value.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
                rating: my_star_rating.current,
            });
            code = result.code;
            data = result.data;
        }

        if (code === 200) {
            is_posting_reply.current = false;

            props.setAvgRating(data.avg_rating);
            setStatedReview({...data.review});
            setEditMode(false);
            props.onLoadReview(prev => ({
                ...prev,
                avg_rating: data.avg_rating,
            }));
        } else {
            alert("댓글 수정 실패(네트워크 오류)");
        }
    };

    const writeReviewReply = async () => {
        let code;
        let data;

        if (props.type === "course") {
            const result = await CourseModel.courseReviewWrite({
                course_id: stated_review.course_id,
                parent_id: stated_review.id,
                content: replyContent.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
            });
            code = result.code;
            data = result.data;
        }
        if (props.type === "ebook") {
            const result = await EbookModel.ebookReviewWrite({
                ebook_id: stated_review.ebook_id,
                parent_id: stated_review.review_id,
                content: replyContent.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
            });
            code = result.code;
            data = result.data;
        }

        if (code === 200) {
            is_posting_reply.current = false;

            if (props.type === 'ebook') { // ebook의 경우, 작성한 대댓글이 뒤에
                // 남은 모든댓글 불러와 펼치기
                const lastReply = reply_list?.[reply_list.length - 1];
                const pageSize = 30;
                const remainCount = (stated_review.comment_count + 1) - (reply_list?.length || 0);

                let lastVisibleData = lastReply?.create_time;
                const remainAllReplyList = [];
                for (let i = 0; i < Math.ceil(remainCount / pageSize); i++) {
                    const result = await EbookModel.ebookReviewList({
                        parent_id: stated_review.review_id,
                        size: pageSize,
                        last_visible_data: lastVisibleData,
                    });
                    remainAllReplyList.push(...result.data.review_set);
                    lastVisibleData = result.data.review_set[result.data.review_set.length - 1]?.create_time;
                }

                setStatedReview({...stated_review, comment_count: stated_review.comment_count + 1});
                setReplyList(prev => [...prev || [], ...remainAllReplyList || []]);
                if (!reply_list?.length) {
                    setIsOpenedReplyArea(true);
                    setWriteReplyMode(false);
                }
            } else {
                setJustWroteReplyList([data.review, ...just_wrote_reply_list]);
            }
            setReplyContent('');
            setWriteReplyMode(false);
        } else {
            alert("대댓글 작성 실패(네트워크 오류)");
        }
    };

    const requestNextReviewReplyList = async (is_reset = false) => {
        const page_size = 10;
        setReplyPage(reply_page + 1);

        let code;
        let review_set;

        if (props.type === "course") {
            const result = await CourseModel.courseReviewReplyList({
                course_id: stated_review.course_id,
                parent_id: stated_review.review_id,
                page:reply_page,
                page_size: page_size,
            });
            code = result.code;
            review_set = result.review_set;
        }
        if (props.type === "ebook") {
            const lastReply = reply_list?.[reply_list.length - 1];
            const result = await EbookModel.ebookReviewList({
                ebook_id: stated_review.ebook_id,
                parent_id: stated_review.review_id,
                size: page_size,
                last_visible_data: lastReply?.create_time,
            });
            code = result.code;
            review_set = result.data.review_set;
        }

        if (code === 200) {
            let new_review_list = is_reset ? [] : reply_list;
            if (is_reset) {
                setIsOpenedReplyArea(true);

                // 방금 쓴 댓글 있을 시, 중복 제거
                if (just_wrote_reply_list.length > 0) {
                    let already_has_reply_id = just_wrote_reply_list.map((reply) => reply.id || reply.review_id);
                    review_set = review_set.filter((review) => !already_has_reply_id.includes(review.id));
                }
            }

            setReplyList([...new_review_list, ...review_set]);
            setIsRequestLoading(false);
        } else {
            alert("대댓글 불러오기에 실패했습니다 ㅠㅠ");
        }
    };

    const resetReplyList = async (except_id) => {
        if (reply_list !== null && reply_list.length > 0 && reply_list.find((reply) => (reply.id || reply.review_id) === except_id)) {
            setReplyList(reply_list.filter((reply) => (reply.id || reply.review_id) !== except_id));
        } else {
            setJustWroteReplyList(just_wrote_reply_list.filter((reply) => (reply.id || reply.review_id) !== except_id));
        }

        let tmp_reply_count = stated_review.comment_count - 1;

        setStatedReview({...stated_review, comment_count: tmp_reply_count});
    };

    const createReplyList = (reply) => {
        let user_nickname = reply.account_nickname;
        if (!reply.account_nickname) {
            user_nickname = "익명의 수강생";
        }

        if (reply.account_type_id === 5) {
            user_nickname = "관리자";
        }

        return (
            <ReviewReply
                reply={reply}
                type={props.type}
                key={reply.review_id}
                user_nickname={user_nickname}
                resetReplyList={resetReplyList}
                visible_edit_buttons={
                    AccountStore !== null && // 로그인한 사람
                    reply.reply_status_id < 3 && // 삭제되지 않은 댓글에만
                    (AccountStore?.id === reply.account_id || [2, 4, 5].includes(AccountStore?.account_type_id)) // 마스터, 관리자, 작가만
                }
            />
        );
    };

    const canWriteReply = [2, 4, 5].includes(AccountStore?.account_type_id) // 마스터, 관리자, 최고관리자
        || props.is_ebook_author // 작가인 경우
        || (props.type === 'ebook' && props.review?.account_id === AccountStore?.id && stated_review.comment_count > 0); // 내 댓글의 대댓글이 있는 경우
    const isStickyReplyForm = canWriteReply
        && props.type === 'ebook'
        && stated_review.comment_count > 0
        && is_opened_reply_area;

    const replyWriteForm = (
        <div className="content reply" ref={reply_list_ref}>
            <img
                className="reply-icon"
                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_reply.png`}
                alt="댓글 아이콘"
            />
            <div className="main-column-wrap">
                <div className="profile-info-row">
                    <span>
                        <ProfileImage
                            img_src={AccountStore?.info?.picture_thumbnail_image_url}
                            auth_check={AccountStore}
                        />
                        <h4>
                            {AccountStore?.account_type_id === 5 ? "관리자" : (AccountStore?.info?.nickname || "익명의 수강생")}
                        </h4>
                    </span>
                    {!isStickyReplyForm && (
                        <button onClick={() => setWriteReplyMode(false)}>
                            <img
                                className="close-btn-img"
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
                                alt="닫기 버튼"
                            />
                        </button>
                    )}
                </div>
                <div className="textarea-wrap">
                <textarea
                    className="new_reply_textarea"
                    placeholder="내용을 입력하세요."
                    maxLength={300}
                    ref={write_reply_textarea_ref}
                    value={replyContent}
                    onKeyDown={(e) => ableSelectAll(e)}
                    onFocus={() => {
                        if (is_tablet_or_mobile) {
                            moveRefToTop(
                                review_text_ref,
                                props.is_viewer_ver
                                    ? review_text_ref.current.offsetHeight + 400
                                    : review_text_ref.current.offsetHeight - 100
                            );
                        }
                    }}
                    onChange={(e) => {
                        setReplyContent(e.target.value);
                        resetSizeTextarea(write_reply_textarea_ref);
                    }}
                />
                    <button
                        className="submit-save-btn"
                        onClick={async () => {
                            if (!replyContent) {
                                alert("댓글을 입력해주세요");
                            } else if (!is_posting_reply.current) {
                                is_posting_reply.current = true;
                                await writeReviewReply();
                            }
                        }}>
                        <p>등록</p>
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="content review" ref={review_ref}>
            <div className="pc-profile">
                <ProfileImage img_src={stated_review.account_profile_img_url} auth_check={props.review} />
            </div>

            <div className="main-column-wrap">
                <div className="profile-info-row">
                    {is_tablet_or_mobile ? (
                        <div className="mobile-profile">
                            <ProfileImage img_src={stated_review.account_profile_img_url} auth_check={props.review} />
                            <h4>{props.user_nickname}</h4>
                        </div>
                    ) : (
                        <h4>{props.user_nickname}</h4>
                    )}

                    <div className={"reply-date-info"}>
                        {`${moment(
                            stated_review.update_time ? stated_review.update_time : stated_review.create_time
                        ).format("YYYY.MM.DD")} ${stated_review.update_time ? "수정됨" : ""}`}
                    </div>
                </div>
                <div className="review-middle-row">
                    <>
                        {createStarRating(
                            my_star_rating,
                            edit_mode ? hover_rating : stated_review.rating,
                            setHoverRating,
                            edit_mode
                        )}
                    </>

                    <span>
                        {props.visible_edit_buttons ? (
                            <span className="modify-wrap">
                                {edit_mode ? (
                                    <button
                                        className="close-btn"
                                        onClick={() => {
                                            setEditMode(false);
                                            setHoverRating(stated_review.rating);
                                        }}>
                                        <img
                                            className="close-btn-img"
                                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
                                            alt="닫기 버튼"
                                        />
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            onClick={() => {
                                                setEditMode(true);
                                                setTimeout(() => {
                                                    resetSizeTextarea(review_textarea_ref);
                                                    review_textarea_ref.current.focus();
                                                }, 50);
                                            }}>
                                            수정
                                        </button>
                                        <button
                                            onClick={async () => {
                                                if (await confirm("정말로 삭제 할까요?")) {
                                                    await props.deleteReview(stated_review.id || stated_review.review_id, stated_review.rating);
                                                }
                                            }}>
                                            삭제
                                        </button>
                                    </>
                                )}
                            </span>
                        ) : null}
                    </span>
                </div>

                {edit_mode ? (
                    <div className="textarea-wrap edit-mode" ref={review_text_ref}>
                        <textarea
                            placeholder="내용을 입력하세요."
                            maxLength={300}
                            onKeyDown={(e) => ableSelectAll(e)}
                            ref={review_textarea_ref}
                            defaultValue={stated_review.content}
                            onFocus={() => {
                                if (is_tablet_or_mobile) {
                                    moveRefToTop(review_textarea_ref);
                                }
                            }}
                            onChange={() => {
                                resetSizeTextarea(review_textarea_ref);
                            }}
                        />
                        <button
                            className="submit-save-btn"
                            onClick={async () => {
                                if (!review_textarea_ref.current.value) {
                                    alert("후기를 작성해주세요");
                                } else if (!is_posting_reply.current) {
                                    is_posting_reply.current = true;
                                    await modifyReview();
                                }
                            }}>
                            <p>저장</p>
                        </button>
                    </div>
                ) : (
                    <p className={stated_review.reply_status_id > 2 ? "deleted" : ""} ref={review_text_ref}>
                        {stated_review.content}
                    </p>
                )}
                <div className="bottom-row">
                    {stated_review.reply_status_id < 3 ? (
                        <>
                            {stated_review.comment_count > 0 ? (
                                <button
                                    onClick={async () => {
                                        if (is_opened_reply_area) {
                                            setIsOpenedReplyArea(false);
                                            setJustWroteReplyList([]);
                                            setReplyList(null);
                                        } else if (!is_request_loading) {
                                            setIsRequestLoading(true);
                                            await requestNextReviewReplyList(true);
                                        }
                                        props.resetSwiperHeight && props.resetSwiperHeight();
                                    }}>
                                    <div>답글 {stated_review.comment_count}개</div>

                                    <img
                                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_arrow_${
                                            is_opened_reply_area ? "up" : "down"
                                        }.png`}
                                        alt="답글 펼치기 아이콘"
                                    />
                                </button>
                            ) : null}

                            {/*대댓글 달기 start*/}
                            {(canWriteReply && props.type !== 'ebook'
                                || (canWriteReply && props.type === 'ebook' && !stated_review.comment_count)) ? (
                                <button
                                    onClick={() => {
                                        setWriteReplyMode(true);
                                        // 답글 달기 클릭 시 textarea로 이동
                                        // (ref 없을 때 대비해 setTimeout)
                                        setTimeout(() => {
                                            write_reply_textarea_ref?.current.focus();
                                        }, 50);
                                    }}>
                                    답글달기
                                </button>
                            ) : null}
                            {/*대댓글 달기 end*/}
                        </>
                    ) : null}
                </div>

                {/*대댓글 달기 start*/}
                {(AccountStore && write_reply_mode) ? replyWriteForm : null}
                {/*대댓글 달기 end*/}

                {/*방금 쓴 대댓글(상단 표시 대댓글)*/}
                {just_wrote_reply_list.map((just_wrote_reply) => {
                    return createReplyList(just_wrote_reply);
                })}

                {/*댓글 열기, 접기*/}
                {is_opened_reply_area
                    ? reply_list?.map((reply) => {
                        return createReplyList(reply);
                    })
                    : null}

                {is_opened_reply_area && visible_more_button ? (
                    <div className="more-reply-button">
                        <img
                            className="reply-icon"
                            src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_reply.png`}
                            alt="댓글 아이콘"
                        />
                        <button
                            onClick={async () => {
                                if (!is_request_loading) {
                                    setIsRequestLoading(true);
                                    await requestNextReviewReplyList();
                                }
                            }}>
                            답글 더보기
                        </button>
                        {is_request_loading ? <LoadingSpinner /> : null}
                    </div>
                ) : null}

                {(AccountStore && isStickyReplyForm) && replyWriteForm}
            </div>
        </div>
    );
};

Review.propTypes = propTypes;

export default withRouter(Review);
