import EbookModel from '../model/EbookModel';

function useEbookViewer() {
  const openEbookViewer = async (ebookCode) => {
    const result = await EbookModel.ebookViewerUrl({
      ebookCode,
    });

    window.open(result.viewer_url, '_blank');
  };

  return { openEbookViewer };
}

export default useEbookViewer;
