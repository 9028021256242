import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CourseModel from "../../../model/CourseModel";
import {useDialogContext} from "../dialog/DialogContextProvider";
import {ProfileImage, resetSizeTextarea} from "../section/QnaContainer";
import {ableSelectAll} from "../section/QnaContainer";
import {moveRefToTop} from "../../course/CourseDetail";

const propTypes = {
    reply: PropTypes.object,
    user_nickname: PropTypes.string,
    visible_edit_buttons: PropTypes.bool,
};

const QnaReply = (props) => {
    const [edit_mode, setEditMode] = useState(false);
    const [stated_reply, setStatedReply] = useState(props.reply);

    const reply_edit_textarea_ref = useRef(null);
    const is_posting_reply = useRef(false);

    const {confirm} = useDialogContext();

    const modifyQnaReply = async () => {
        let {code, qna} = await CourseModel.courseQNAModify({
            qna_id: stated_reply.id,
            content: reply_edit_textarea_ref.current.value.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, "\n"),
        });

        if (code === 200) {
            is_posting_reply.current = false;

            setStatedReply(qna);
            setEditMode(false);
        } else {
            alert("댓글 수정 실패(네트워크 오류)");
        }
    };

    const deleteQna = async () => {
        let {code} = await CourseModel.courseQNADelete({qna_id: stated_reply.id});

        if (code === 200) {
            setStatedReply({...stated_reply, content: "삭제한 댓글입니다.", reply_status_id: 3});
        } else {
            alert("댓글 삭제 실패(네트워크 오류)");
        }
    };

    return (
        <div className="content reply">
            <img
                className="reply-icon"
                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_reply.png`}
                alt="댓글 아이콘"
            />
            <div className="main-column-wrap">
                <div className="profile-info-row">
                    <span className="profile-info-wrap">
                        <ProfileImage img_src={stated_reply?.account_profile_img_url} auth_check={props.reply} />
                        <h4>{props.user_nickname}</h4>
                    </span>

                    {props.visible_edit_buttons ? (
                        <span className="modify-wrap">
                            {edit_mode ? (
                                <button
                                    onClick={() => {
                                        setEditMode(false);
                                    }}>
                                    <img
                                        className="close-btn-img"
                                        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
                                        alt="닫기 버튼"
                                    />
                                </button>
                            ) : (
                                <>
                                    <button
                                        onClick={() => {
                                            setEditMode(true);
                                            setTimeout(() => {
                                                resetSizeTextarea(reply_edit_textarea_ref);
                                                reply_edit_textarea_ref.current.focus();
                                            }, 50);
                                        }}>
                                        수정
                                    </button>
                                    <button
                                        onClick={async () => {
                                            if (await confirm("정말로 삭제 할까요?")) {
                                                await deleteQna();
                                            }
                                        }}>
                                        삭제
                                    </button>
                                </>
                            )}
                        </span>
                    ) : null}
                </div>
                {edit_mode ? (
                    <div className="textarea-wrap edit-mode">
                        <textarea
                            placeholder="내용을 입력하세요."
                            maxLength={300}
                            ref={reply_edit_textarea_ref}
                            onKeyDown={(e) => ableSelectAll(e)}
                            onFocus={() => {
                                moveRefToTop(reply_edit_textarea_ref);
                            }}
                            onChange={() => {
                                resetSizeTextarea(reply_edit_textarea_ref);
                            }}
                            defaultValue={stated_reply.content}
                        />
                        <button
                            className="submit-save-btn"
                            onClick={async () => {
                                if (!reply_edit_textarea_ref.current.value) {
                                    alert("댓글을 입력해주세요:)");
                                } else if (!is_posting_reply.current) {
                                    is_posting_reply.current = true;
                                    await modifyQnaReply();
                                }
                            }}>
                            <p>저장</p>
                        </button>
                    </div>
                ) : (
                    <p className={stated_reply.reply_status_id > 2 ? "deleted" : ""}>{stated_reply.content}</p>
                )}

                <div className="bottom-row">
                    <span>
                        {`${moment(
                            stated_reply.update_time ? stated_reply.update_time : stated_reply.create_time
                        ).format("YYYY.MM.DD")} ${stated_reply.update_time ? "수정됨" : ""}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

QnaReply.propTypes = propTypes;

export default QnaReply;
