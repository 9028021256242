import React, { useEffect, useRef } from 'react';
import EventModel from '../../model/EventModel';
import { alert_and_redirect } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

// 이런 느낌...
// https://bear-u.com/event/coupon/giving?coupon_code=7f9a4323-7f1a-4ac7-916a-f38f13a8b99c&coupon_code=3b2f885b-ed2a-4296-9260-ff4d8d737d34&coupon_code=97a70787-e18d-4e5b-90c3-6c0c9c74daec
const GivingCoupon = (props) => {
  const dispatch = useDispatch();
  const _is_mount = useRef(false);
  const AccountStore = useSelector((state) => state.AccountStore);
  const { alert } = useDialogContext();

  useEffect(() => {
    const give_coupon_token = JSON.parse(localStorage.getItem('Channel_G_C'));
    if (give_coupon_token) {
      localStorage.removeItem('Channel_G_C');
    }

    _is_mount.current = true;
    dispatch(set_header_type(0, 0, { no_footer: true, no_top_btn: true }));
    dispatch(set_footer_type(0));
    let coupon_code_set = new URLSearchParams(props.location.search).getAll('coupon_code');
    if (AccountStore) {
      EventModel.couponGiving({ coupon_code_set })
        .then(({ code }) => {
          if (_is_mount.current) {
            if (code === 200) {
              alert_and_redirect(
                alert,
                window.history,
                '내 쿠폰함 확인하기',
                '/user/mypage/coupon',
              );
            } else if (code === 4808) {
              if (window.history.length <= 2) {
                //outside event
                alert_and_redirect(alert, window.history, '이미 참여하신 이벤트입니다.', '/');
              } else {
                alert_and_redirect(
                  alert,
                  window.history,
                  '이미 참여하신 이벤트입니다.',
                  '/user/mypage/coupon',
                );
              }
            } else {
              alert_and_redirect(alert, window.history, '유효하지 않은 이벤트입니다.');
            }
          }
        })
        .catch((e) => {
          alert_and_redirect(alert, window.history, '알수없는 오류가 발생하였습니다.');
        });
    } else {
      const now = new Date().getTime();
      localStorage.setItem('Channel_G_C', JSON.stringify(props));
      props.history.push({
        pathname: '/user/signin',
        state: {
          redirect_url: `${props.location.pathname}${props.location.search}`,
        },
      });
    }
    return () => {
      _is_mount.current = false;
    };
  }, []);
  return <></>;
};

export default GivingCoupon;
