import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { replace_cloudfront_url, set_favorite_icon } from '../../../common';
import BaseCard, { BADGE_TYPE_PACKAGE } from './BaseCard';
import ProductModel from '../../../model/ProductModel';
import { set_account_favorite_count } from '../../../redux/account/action';
import { useDialogContext } from '../dialog/DialogContextProvider';

const PackageCard2 = (props) => {
  const product = props.product;
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const _favorite_pending = useRef(false);
  const { confirm } = useDialogContext();

  const onBookmarkClick = async () => {
    if (AccountStore) {
      if (!_favorite_pending.current) {
        _favorite_pending.current = true;
        ProductModel.packageFavorite({
          package_id: product.id,
        }).then(({ is_favorite, favorite_count, account_favorite_count }) => {
          // @TODO 병곤 향후 add favorite item 느낌으로 작업해서, MyFavoriteList2 쪽에서 갱신하는 코드를 안넣을수도 있는 가정 있음 (id 중복체크 가능성
          dispatch(set_account_favorite_count(account_favorite_count));
          set_favorite_icon('package', product.id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  // 패키지 뱃지
  const getBadge = (product) => {
    return BADGE_TYPE_PACKAGE;
  };

  return (
    <div
      className={`gtm-data gtm-click package-id-${product.id} card-item-v2`}
      data-ai-pid={`p${product.id.toString().padStart(8, '0')}`}
      data-ai-pnm={product.title.replace(/\r\n/g, ' ')}
      data-ai-ppc={product.price}
      data-ai-ppb=''
      data-ai-pca=''
    >
      <BaseCard
        card_badge={getBadge(product)}
        image_url={replace_cloudfront_url(product.circle_image_url)}
        short_title={product.short_title}
        title={product.title}
        tag_set={product.tag_set}
        redirect_to_url={props.redirect_to_url || `/package/${product.id}`}
        on_bookmark_click={onBookmarkClick}
        is_bookmark={product.is_favorite}
        favorite_count={product.favorite_count}
        hide_bookmark={props.hide_bookmark}
        classification_name={product.classification_name}
      />
    </div>
  );
};

export default withRouter(PackageCard2);
