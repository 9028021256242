import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import Alert from './Alert';
import Confirm from './Confirm';
import {
  button_type_none,
  dialog_type_alert,
  dialog_type_confirm,
  dialog_type_custom,
} from './DialogContextProvider';

const hash_prefix = '#dialog_';
const init_window_state = {
  init: false,
  overflow: 'overlay',
};
const Dialogs = (props) => {
  const history = useHistory();

  const prev_window_state = useRef(init_window_state);
  const pushed_item_list = useRef([]);
  const last_url = useRef(null);
  const visit_key_map = useRef({});
  const request_redirect = useRef(false);

  const forward_history = props.forward_history ?? false;

  useEffect(() => {
    // scroll
    let visible_list_size = 0;
    props.list?.forEach((dialog) => {
      if (dialog.visible) {
        visible_list_size++;
      }
    });
    if (visible_list_size === 0) {
      window.document.body.style.overflow = prev_window_state.current.overflow;

      // reset prev window state
      prev_window_state.current = init_window_state;
    } else {
      // init prev window state
      if (visible_list_size === 1 && !prev_window_state.current.init) {
        prev_window_state.current = {
          init: true,
          overflow: window.document.body.style.overflow || '',
        };
      }
      window.document.body.style.overflow = 'hidden';
    }

    // pushed list
    // eslint-disable-next-line array-callback-return
    props.list?.map((dialog) => {
      if (
        !pushed_item_list.current.find((pushed_item) => {
          return pushed_item.dialog.id === dialog.id;
        })
      ) {
        history.push(`${history.location.search}${hash_prefix}${dialog.id}`);
        pushed_item_list.current.push({
          pushed: true,
          dialog: dialog,
        });
      }
    });
  }, [props.list_change_hash]);

  // 하위 컴포넌트에서 hideDialog 호출하는 부분을 캐치하기 위해 override
  const dialog_utils = props.dialog_utils
    ? {
        ...props.dialog_utils,
        // @Override
        // @TODO hideDialog 에서 callback 지원 될 수 있게 구현하기
        hideDialog: (dialog_id, button_type = button_type_none) => {
          _hideDialog(dialog_id, button_type);
        },
        moveTo: async ({ url }) => {
          request_redirect.current = true;
          history.replace(url);
        },
      }
    : null;

  useEffect(() => {
    let unlisten = history.listen((location) => {
      let hash = location.key;
      let current_url = window.location.href;
      let is_visited = visit_key_map.current[hash] ? true : false;

      if (!request_redirect.current) {
        let current_dialog = dialog_utils?.findDialog(_extractDialogId(current_url));
        let prev_dialog = dialog_utils?.findDialog(_extractDialogId(last_url.current));
        if (current_dialog) {
          if (is_visited) {
            if (forward_history || true) {
              dialog_utils.showDialogById(current_dialog.id);
            }
            _hideDialog(prev_dialog?.id, undefined, true);
          } else {
            // nothing
          }
        } else {
          _hideDialog(prev_dialog?.id, undefined, true);
        }
      } else {
        dialog_utils?.hideDialogAll();
        request_redirect.current = false;
      }

      last_url.current = current_url;
      visit_key_map.current[hash] = hash;
    });
    return () => {
      unlisten();
    };
  }, []);

  const _hideDialog = async (dialog_id, button_type = button_type_none, back_state = false) => {
    if (!back_state) {
      history.goBack();
    }
    props.dialog_utils.hideDialog(dialog_id, button_type); // call by direct
  };

  const _extractDialogId = (path, default_value = 0) => {
    if (!path) return default_value;

    let prefix_find = path.indexOf(hash_prefix);
    if (prefix_find >= 0) {
      return (
        parseInt(path.substring(prefix_find + hash_prefix.length, path.length)) || default_value
      );
    } else {
      return default_value;
    }
  };

  return (
    <>
      {props.list &&
        props.list.map((dialog, index) => {
          try {
            if (dialog.visible) {
              if (dialog.type === dialog_type_alert) {
                return <Alert key={index} dialog_props={dialog} dialog_utils={dialog_utils} />;
              } else if (dialog.type === dialog_type_confirm) {
                return <Confirm key={index} dialog_props={dialog} dialog_utils={dialog_utils} />;
              } else if (dialog.type === dialog_type_custom) {
                return (
                  <dialog.component
                    key={index}
                    dialog_props={dialog}
                    dialog_utils={dialog_utils}
                    {...dialog.component_props}
                  />
                );
              }
            }
          } catch (e) {}
          return null;
        })}
    </>
  );
};

export default Dialogs;
