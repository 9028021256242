import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import '../../../assets/scss/component/_book_review_slider.scss';
import { Link } from 'react-router-dom';

function BookReviewSlider({ reviewList }) {
  return (
    <div className='book-review-slider'>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        navigation={{
          prevEl: '.book-review-slider .slider-button-prev',
          nextEl: '.book-review-slider .slider-button-next',
        }}
        modules={[Navigation]}
        breakpoints={{
          767: {
            slidesPerView: 2,
          },
        }}
      >
        {reviewList.map((review) => (
          <SwiperSlide key={review.id}>
            <div className='book-review-item'>
              <div className='book-thumbnail'>
                <img src={review.main_image_url} alt='book thumbnail' />
              </div>
              <div className='book-review-wrap'>
                <div className='book-review'>{review.contents}</div>
                <div className='book-info'>
                  <span className='book-title'>{review.title}</span>
                  <Link to={`/ebook/${review.book_id}`}>자세히보기</Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <button type='button' className='slider-button slider-button-prev'>
        <img src={`${process.env.REACT_APP_IMG_URL}/ebook/slider-prev.png`} alt='prev' />
      </button>
      <button type='button' className='slider-button slider-button-next'>
        <img src={`${process.env.REACT_APP_IMG_URL}/ebook/slider-next.png`} alt='next' />
      </button>
    </div>
  );
}

BookReviewSlider.propTypes = {
  reviewList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      review: PropTypes.string,
      bookTitle: PropTypes.string,
      bookThumbnail: PropTypes.string,
    }),
  ),
};

export default BookReviewSlider;
