import { createContext, useContext, useRef, useState } from 'react';

import { sleep } from '../../../common';
import Dialogs from './Dialogs';

export const dialog_type_alert = 1;
export const dialog_type_confirm = 2;
export const dialog_type_custom = 3;

export const button_type_none = 0;
export const button_type_positive = 1;
export const button_type_negative = 2;

// @TODO 앞으로가기 처리
const dialog_context = createContext();
export const DialogContextProvider = (props) => {
  const last_dialog_id = useRef(0);
  const dialogs = useRef([]);

  // for render
  const [render_time, setRenderTime] = useState(0);

  // @TODO 구현하기 (분기처리만 하면 됨)
  const forward_history = props.forward_history ?? false;

  /**
   * @param {
   *  type(int,*): dialogs type (dialog_type_alert, dialog_type_confirm)
   *  message(string)
   *  cancelable(bool)
   *  positive_button_message(string)
   *  negative_button_message(string)
   *  component(object class only): support only dialog_type_custom
   *  component_props: props of component
   * } dialog_params
   * @return {
   *  resolve(dialog_id): if dialog_id is 0 cannot created dialog
   * } promise
   */
  const showDialog = ({
    type,
    message,
    cancelable,
    positive_button_message,
    negative_button_message,
    component,
    component_props,
  }) => {
    if (!type) {
      return false;
    }

    let _resolve;
    const promise = new Promise((__resolve, __reject) => {
      _resolve = __resolve;
    });

    // push dialog
    let id = 0;
    id = _pushDialog({
      type,
      message,
      cancelable: cancelable === undefined ? true : cancelable, // default value
      positive_button_message,
      negative_button_message,
      component,
      component_props,
      resolve: (button_type) => {
        _resolve({ id, button_type });
      },
    });

    return {
      id,
      promise,
      // @TODO hpark dialog 제어용 유틸함수 내려줄 가능성
    };
  };

  // const resolveDialog = (dialog_id)=>{
  //     let dialog = findDialog(dialog_id);
  //     if(dialog){
  //         dialog.resolve();
  //     }
  // }

  const showDialogById = (dialog_id, button_type = button_type_none) => {
    let dialog = findDialog(dialog_id);
    if (dialog && !dialog.visible) {
      dialog.visible = true;
      requestRender();
    }
  };

  const hideDialog = (dialog_id, button_type = button_type_none) => {
    let need_render = false;
    dialogs.current.forEach((dialog) => {
      if (dialog.id === dialog_id && dialog.visible) {
        dialog.resolve(button_type);
        dialog.visible = false;
        need_render = true;
      }
    });

    if (need_render) {
      requestRender();
    }

    return need_render;
  };

  const hideDialogAll = (button_type = button_type_none) => {
    let need_render = false;
    dialogs.current.forEach((dialog) => {
      if (dialog.visible) {
        need_render = true;
      }
      dialog.resolve(button_type);
      dialog.visible = false;
    });

    if (need_render) {
      requestRender();
    }

    return need_render;
  };

  const _pushDialog = (dialog_params) => {
    last_dialog_id.current = last_dialog_id.current + 1;
    dialogs.current.push({
      ...dialog_params,
      id: last_dialog_id.current,
      visible: true,
    });
    requestRender();
    return last_dialog_id.current;
  };

  const findDialog = (dialog_id) => {
    return dialogs.current.find((dialog) => {
      return dialog.id === dialog_id;
    });
  };

  const getCurrentDialogId = (default_value = 0) => {
    let hash_prefix = '#dialog_';
    let path = window.location.href;
    let prefix_find = path.indexOf(hash_prefix);
    if (prefix_find >= 0) {
      return (
        parseInt(path.substring(prefix_find + hash_prefix.length, path.length)) || default_value
      );
    } else {
      return default_value;
    }
  };

  const requestRender = () => {
    setRenderTime(new Date().getTime());
  };

  return (
    <dialog_context.Provider
      // @TODO useMemo 로 감싸 내릴 가능성 useMemo(() => { return {...}; }, [])
      value={{
        showDialog,
        hideDialog,
        hideDialogAll,
        findDialog,
        getCurrentDialogId,
        confirm: async (message) => {
          if (!message) {
            return;
          }

          let dialog = showDialog({
            type: dialog_type_confirm,
            message,
          });

          let res = await dialog.promise;
          await sleep(50); //hack
          return res.button_type === button_type_positive;
        },
        alert: async (message) => {
          if (!message) {
            return;
          }

          let dialog = showDialog({
            type: dialog_type_alert,
            message,
          });

          await dialog.promise;
          await sleep(50); //hack
          return true;
        },
      }}
    >
      {props.children}

      <Dialogs
        forward_history={forward_history}
        list_change_hash={render_time}
        list={dialogs.current}
        dialog_utils={{
          showDialogById,
          hideDialog,
          hideDialogAll,
          findDialog,
        }}
      />
    </dialog_context.Provider>
  );
};

export const useDialogContext = () => {
  return useContext(dialog_context);
};
