import {combineReducers} from "redux";
import AccountStore from "./account/reducer"
import CommonStore from "./common/reducer"

const reducers = combineReducers({
    AccountStore:AccountStore,
    CommonStore:CommonStore,
});

export default reducers;
