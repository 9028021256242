import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import '../../../assets/scss/page/sign_up.scss';
import {useDispatch, useSelector} from "react-redux";
import CommonModel from "../../../model/CommonModel";
import {stored_static_code} from "../../../redux/common/action";
import PropTypes from "prop-types";

const propTypes = {
    select_type: PropTypes.number,
    changeListener: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    select_set: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        sale_short_name: PropTypes.string
    }))
};

// select_type
// 1: 기본
// 2: sale info
// 3: kit info
const SelectBox = (props) => {
    const [select_set, setSelectSet] = useState([]);
    const dispatch = useDispatch();
    const CommonStore = useSelector(state => state.CommonStore);
    const [select_name, setSelectName] = useState("");
    const [is_init, setIsInit] = useState(false);

    useEffect(() => {
        if (props.name === "job") {
            setIsInit(true);
            if (!CommonStore.static_code.job_class_set.length) {
                CommonModel.staticCode({code_name: "job_class_set"}).then(({code_set}) => {
                    dispatch(stored_static_code("job_class_set", code_set));
                    setSelectSet(code_set);
                });
            } else {
                setSelectSet(CommonStore.static_code.job_class_set);
            }
        }
    }, []);

    useEffect(() => {
        if (props.name === "job") {
            for (let i = 0; i < select_set.length; i++) {
                if (select_set[i].id === props.value) {
                    setSelectName(select_set[i].name);
                }
            }
        }
    }, [is_init]);

    useEffect(() => {
        if (props.select_set) {
            for (let i = 0; i < props.select_set.length; i++) {
                if (props.select_set[i].inventory_count === 0) {
                    props.select_set[i].title = "(품절) " + props.select_set[i].title;
                    props.select_set[i].is_disabled = true;
                }
            }
        }
    }, [props.select_set]);

    const onSelect = (selected) => {
        const e = {target: {name: props.name, value: selected.id}};
        setSelectName(selected.name);

        if (props.select_type === 1) props.changeListener(e);
        else props.changeListener(e.target, e.target.name);
    };

    const custom_style = {
        option: (styles, state) => ({
            ...styles,
            textDecoration: state.isDisabled ? "line-through" : "inherit",
            color: '#ccc',
            cursor: state.isDisabled ? 'default' : 'pointer',
            pointerEvents: state.isDisabled ? "none" : "inherit",
        }),
    }

    return (
        <div className="select-box">
            <Select
                styles={{...custom_style}}
                className={props.select_type !== 1 ? "sale-select-box" : ""}
                value={props.select_type === 1 ?
                    select_set.filter(({name}) => name === select_name)
                    : select_name
                }
                isDisabled={props.select_type === 3 || props.select_type === 2 ? (!props.select_set?.length) : false}
                onChange={onSelect}
                name={props.name}
                options={props.select_type === 1 ? select_set : props.select_set}
                isSearchable={false}
                placeholder={props.placeholder || ""}
                maxMenuHeight={200}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => props.select_type === 1 ? option.name : (props.select_type === 2 ? option.sale_short_name : option.title)}
                isOptionDisabled={(option) => props.select_type === 3 && option.inventory_count === 0}
            />
        </div>
    )
}

SelectBox.propTypes = propTypes;

export default SelectBox;



