import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

import { set_header_type, set_tmp, stored_static_code } from '../../redux/common/action';
import PurchaseModel from '../../model/PurchaseModel';
import '../../assets/scss/page/purchase_complate.scss';
import CommonModel from '../../model/CommonModel';
import { alert_and_redirect, init_comma } from '../../common';
import AccountModel from '../../model/AccountModel';
import { stored_account_info } from '../../redux/account/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import ProductModel from '../../model/ProductModel';
import PixelModel from '../../model/PixelModel';

const max_request_count = 5;

const PurchaseResult = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const AccountStore = useSelector((state) => state.AccountStore);

  const gtm_data = props.location.state?.gtm_data || null;
  const orderd_kit_set = props.location.state?.orderd_kit_set || [];

  const [title, setTitle] = useState('주문 확인 중 입니다.');
  const [desc, setDesc] = useState('');
  const [purchase_info, setPurchaseInfo] = useState(null);
  const [vbank_info, setVbankInfo] = useState(null);
  const _is_mount = useRef(false);
  const [is_complete, setIsComplete] = useState(true);
  const [coupon_code, setCouponCode] = useState('');
  const [event_title, setEventTitle] = useState('');
  const bank_account_number_ref = useRef(null);
  const request_interval = useRef(null);
  const request_count = useRef(0);
  const { alert } = useDialogContext();
  const [product_set, setProductSet] = useState(null);

  useEffect(() => {
    _is_mount.current = true;
    setPurchaseInfo(null);
    setTitle('주문 확인 중 입니다.');
    setDesc('');

    dispatch(
      set_header_type(0, 2, {
        title: '주문 완료',
        no_footer: true,
        no_top_btn: true,
      }),
    );
    request_interval.current = setInterval(() => {
      if (request_count.current + 1 >= max_request_count) {
        clearInterval(request_interval.current);
        alert_and_redirect(alert, props.history, '잘못된 요청입니다.');
        return;
      }

      let url_params = new URLSearchParams(window.location.search);
      let merchant_uid = props.location.state?.merchant_uid || url_params.get('merchant_uid');
      let imp_uid = props.location.state?.imp_uid || url_params.get('imp_uid');
      if (!CommonStore.static_code.currency_code_set?.length) {
        CommonModel.staticCode({ code_name: 'currency_code_set' }).then(({ code_set }) => {
          dispatch(stored_static_code('currency_code_set', code_set));
        });
      }

      PurchaseModel.purchaseValidate({
        merchant_uid,
        imp_uid,
      }).then(
        ({
          code,
          purchase_info,
          vbank_info,
          msg,
          is_user_confirm,
          coupon_code,
          event_title,
          import_status,
        }) => {
          if (_is_mount.current) {
            if (code === 200) {
              onResultSetting(
                purchase_info,
                vbank_info,
                msg,
                is_user_confirm,
                coupon_code,
                event_title,
                import_status,
              );
            }
          }
        },
      );
      AccountModel.validate({ access_token: null }).then(({ code, account }) => {
        if (_is_mount.current) {
          dispatch(set_tmp('account_validate_count'));
          if (code === 200) {
            dispatch(stored_account_info(account));
          }
        }
      });

      request_count.current++;
    }, 1500);

    return () => {
      clearTimeout(request_interval.current);
      _is_mount.current = false;
    };
  }, [props.location.search]);

  const onResultSetting = (
    purchase_info,
    vbank_info,
    err_msg,
    is_user_confirm,
    coupon_code,
    event_title,
    import_status,
  ) => {
    clearTimeout(request_interval.current);

    if (import_status === 'failed') {
      setTitle('앗! 결제에 실패 했습니다...');
      setDesc(err_msg);
    } else {
      if (purchase_info.purchase_status === 1 || purchase_info.purchase_status === 2) {
        ///mobon script start///
        const device = 'B';
        let product_type_short = '';
        let product_code = '';
        ProductModel.packageList({ page: 1, size: 30 }).then(({ product_set }) => {
          setProductSet(product_set);
          for (let i = 0; i < product_set.length; i++) {
            if (product_set[i].short_title === purchase_info?.product_name) {
              product_type_short = 'p';
            }
          }

          if (product_type_short !== 'p' && !purchase_info.user_data.courseId) {
            product_type_short = 'k';
          }

          product_code = product_type_short + purchase_info.user_data.kitInfoIds;

          const script = document.createElement('script');

          script.type = 'text/javascript';
          script.innerText =
            'var ENP_VAR = { conversion: { product: [] } };' +
            '    ENP_VAR.conversion.product.push(' +
            '        {' +
            "            productCode : '" +
            product_code +
            "'," +
            "            productName : '" +
            purchase_info?.product_name +
            "'," +
            "            price : '" +
            purchase_info.purchased_price +
            "'," +
            "            qty : '1'," +
            '        },' +
            '    );' +
            "    ENP_VAR.conversion.ordCode = '" +
            purchase_info?.id +
            "';" +
            "    ENP_VAR.conversion.totalPrice = '" +
            purchase_info.purchased_price +
            "';" +
            "    ENP_VAR.conversion.totalQty = '1';" +
            '(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src="https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js";t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");' +
            "enp('create', 'conversion', 'tendominc', { device: '" +
            device +
            "' });" +
            "enp('send', 'conversion', 'tendominc');";
          document.body.appendChild(script);

          if (product_type_short === '' || product_type_short === 'p') {
            let product_type = '';
            let product_code_origin = '';

            if (product_type_short == 'p') {
              product_type = 'product';
              product_code_origin = purchase_info.user_data.productId;
            } else {
              if (purchase_info.user_data.courseId) {
                product_type = 'course';
                product_code_origin = purchase_info.user_data.courseId;
              }
            }

            // TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청

            // PurchaseModel.postProductSeatCount({
            //   type: product_type,
            //   type_id: product_code_origin,
            // }).then(({ code, data }) => {
            //   if (code !== 200) {
            //     console.log('error' + code);
            //   }
            // });
          }
        });

        /// mobon script end ///
      }
      const isEbookType = ['purchase-ebook', 'purchase-ebook-ticket'].includes(
        purchase_info?.user_data?.type,
      );

      if (purchase_info.purchase_status === 1) {
        ReactPixel.trackCustom('결제 완료', { product_name: purchase_info?.product_name });
        setTitle('주문 해주셔서 감사합니다.');
        setDesc('구매하신 상품은 내 강의 또는 배송조회에서 확인하실 수 있습니다.');
        setDesc(
          isEbookType
            ? '구매하신 상품은 내 전자책 또는 배송조회에서 확인하실 수 있습니다.'
            : '구매하신 상품은 내 강의 또는 배송조회에서 확인하실 수 있습니다.',
        );
        setPurchaseInfo(purchase_info);
        setVbankInfo(vbank_info);
        setEventTitle(event_title);
        setCouponCode(coupon_code);
        if (!is_user_confirm) {
          setIsComplete(true);
        }
        PixelModel.postConversionAPI({
          eventName: 'Purchase',
          AccountStore: AccountStore,
          customDataOptions: {
            value: purchase_info?.purchased_price,
            content_name: purchase_info?.product_name,
          },
        });
      } else if (purchase_info.purchase_status === 2) {
        ReactPixel.trackCustom('결제 완료', { product_name: purchase_info?.product_name });
        setTitle('주문 해주셔서 감사합니다.');
        setDesc(
          isEbookType
            ? '구매하신 상품은 내 전자책 또는 배송조회에서 확인하실 수 있습니다.'
            : '구매하신 상품은 내 강의 또는 배송조회에서 확인하실 수 있습니다.',
        );
        setDesc('구매하신 상품은 내 강의 또는 배송조회에서 확인하실 수 있습니다.');
        setPurchaseInfo(purchase_info);
        setEventTitle(event_title);
        setCouponCode(coupon_code);
        if (!is_user_confirm) {
          setIsComplete(true);
        }
        PixelModel.postConversionAPI({
          eventName: 'Purchase',
          AccountStore: AccountStore,
          customDataOptions: {
            value: purchase_info?.purchased_price,
            content_name: purchase_info?.product_name,
          },
        });
      } else if (purchase_info.purchase_status === 3) {
        setTitle('환불된 주문입니다...');
        setPurchaseInfo(purchase_info);
      } else if (purchase_info.purchase_status === 4) {
        setTitle('앗! 결제에 실패 했습니다...');
        setDesc(err_msg);
      } else {
        alert_and_redirect(alert, props.history, '잘못된 요청입니다. (2)');
      }
    }
    props.ready();
  };

  const onTextCopyBtnClick = async () => {
    if (bank_account_number_ref.current) {
      await alert('클립보드에 복사되었습니다.');
      let range = document.createRange();
      range.selectNode(bank_account_number_ref.current.childNodes[0]);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeRange(range);
    }
  };

  return (
    <div className='user-container purchase-result-container'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {is_complete ? (
        <>
          <input
            type='hidden'
            name='purchasedPrice'
            value={purchase_info?.purchased_price || '0'}
          />
          <input type='hidden' name='currencyCode' value={purchase_info?.currency_code || ''} />
          <input type='hidden' name='productName' value={purchase_info?.product_name || ''} />
          <input type='hidden' name='ao_oid' value={purchase_info?.merchant_uid || ''} />
          <input type='hidden' name='ao_aff' value={event_title || ''} />
          <input type='hidden' name='ao_rev' value={purchase_info?.purchased_price || '0'} />
          <input type='hidden' name='ao_rev_sale' value={purchase_info?.discounted_price || ''} />
          <input type='hidden' name='ao_tx' value={purchase_info?.purchased_price / 10 || '0'} />
          <input type='hidden' name='ao_cpn' value={coupon_code || ''} />
          <input type='hidden' name='ao_shp' value='' />
          <input type='hidden' name='ao_pca' value={gtm_data?.pca || ''} />
          <input type='hidden' name='ao_pqt' value={1} />
          <input type='hidden' name='ao_currency' value={purchase_info?.currency_code || ''} />
          {gtm_data ? (
            <>
              <input type='hidden' name='ai_pid[]' value={gtm_data.pid || ''} />
              <input type='hidden' name='ai_pnm[]' value={gtm_data.pnm || ''} />
              <input type='hidden' name='ai_ppc[]' value={gtm_data.ppc || ''} />
              <input type='hidden' name='ai_ppb[]' value={gtm_data.ppb || ''} />
              <input type='hidden' name='ai_pca[]' value={gtm_data.pca || ''} />
              <input type='hidden' name='ai_pva[]' value='' />
              <input type='hidden' name='ai_pqt[]' value={1} />
            </>
          ) : null}
          {orderd_kit_set.map((kit) => {
            return (
              <div key={kit.id}>
                <input
                  type='hidden'
                  name='ai_pid[]'
                  value={`k${kit.id.toString().padStart(8, '0')}`}
                />
                <input
                  type='hidden'
                  name='ai_pnm[]'
                  value={kit.title.replace(/\r\n/g, ' ') || ''}
                />
                <input type='hidden' name='ai_ppc[]' value={kit.price || ''} />
                <input type='hidden' name='ai_ppb[]' value='' />
                <input type='hidden' name='ai_pca[]' value='옵션상품' />
                <input type='hidden' name='ai_pva[]' value='' />
                <input type='hidden' name='ai_pqt[]' value={kit.order_count || ''} />
              </div>
            );
          })}
        </>
      ) : null}

      <div className='td-wrapper'>
        <div className='purchase-result-section'>
          <img
            alt='로고'
            src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-common-icon.png`}
          />
          <p>{title}</p>
        </div>
        <div className='purchase-detail-section'>
          {desc ? (
            <p>
              <img
                alt='info-icon'
                src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-information-outline.svg`}
              />{' '}
              {desc}
            </p>
          ) : null}
          {purchase_info ? (
            <ul className='purchase-table'>
              <li>
                <h6>상품명</h6>
                <h4>{purchase_info?.product_name}</h4>
              </li>
              {CommonStore.static_code.currency_code_set ? (
                <li>
                  <h6>결제금액</h6>
                  <h4>
                    {purchase_info && init_comma(purchase_info.purchased_price)}
                    {
                      CommonStore.static_code.currency_code_set.find(
                        (data) => data.id === purchase_info?.currency_code,
                      ).unit_name
                    }{' '}
                    (
                    {
                      CommonStore.static_code.currency_code_set.find(
                        (data) => data.id === purchase_info?.currency_code,
                      ).name
                    }
                    )
                  </h4>
                </li>
              ) : null}
              {purchase_info.purchase_status === 1 && purchase_info.purchase_paymethod === 4 ? (
                <li>
                  <h6>결제일</h6>
                  <h4>결제 대기 중</h4>
                  {vbank_info ? (
                    <div className='bank-info'>
                      <h6>가상계좌 발급정보</h6>
                      <h4>
                        은행명: {vbank_info.bank_name}
                        <br />
                        계좌번호:{' '}
                        <span ref={bank_account_number_ref}>{vbank_info.bank_number}</span>
                        <input
                          type='button'
                          value={'클립보드 복사 버튼'}
                          onClick={onTextCopyBtnClick}
                        />
                        <br />
                        이체금액: {init_comma(purchase_info.purchased_price)}원<br />
                        입금기한: {moment(vbank_info.expire_time).format('YYYY-MM-DD HH:mm:ss')}
                        <br />
                        <br />
                        <b>* 본 정보는 우측 상단 알림메시지를 통해서도 확인 가능합니다.</b>
                      </h4>
                    </div>
                  ) : null}
                </li>
              ) : (
                <li>
                  <h6>결제일</h6>
                  <h4>{moment(purchase_info?.create_time).format('YYYY-MM-DD HH:mm:ss')}</h4>
                </li>
              )}
            </ul>
          ) : (
            // loading
            <></>
          )}
        </div>

        {purchase_info?.purchase_status === 1 || purchase_info?.purchase_status === 2 ? (
          <div className='purchase-button-section'>
            {['purchase-ebook', 'purchase-ebook-ticket'].includes(
              purchase_info?.user_data?.type,
            ) ? (
              <Link to='/user/mypage/ebook' className='td-btn'>
                내 전자책 목록으로 이동
              </Link>
            ) : (
              <Link to='/user/mypage/course' className='td-btn'>
                내 강의 목록으로 이동
              </Link>
            )}
          </div>
        ) : null}
        {purchase_info?.purchase_status === 3 ? (
          <div className='purchase-button-section'>
            <Link to='/' className='td-btn'>
              홈으로 이동
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PurchaseResult;
