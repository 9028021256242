import React, { useCallback } from 'react';
import EbookModel from "../model/EbookModel";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDialogContext } from "../views/_common/dialog/DialogContextProvider";
import { set_account_ebook_favorite_count } from "../redux/account/action";

function useEbookBookmark() {
  const history = useHistory();
  const location = useLocation();
  const AccountStore = useSelector(state => state.AccountStore);
  const { confirm } = useDialogContext();
  const dispatch = useDispatch();

  const toggleEbookBookmark = useCallback(async (ebookId) => {
    if (!AccountStore) {
      if (await confirm("로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?")) {
        history.push({
          pathname: "/user/signin",
          state: {
            step: 1,
            redirect_url: `${location.pathname}${location.search}`
          }
        });
      }

      return;
    }

    const { data } = await EbookModel.ebookFavorite({ ebook_id: ebookId });
    dispatch(set_account_ebook_favorite_count(data.account_ebook_favorite_count));

    return data;
  }, [AccountStore, confirm, history]);

  return {
    toggleEbookBookmark,
  };
}

export default useEbookBookmark;


