import React from 'react';
import { useMediaQuery } from 'react-responsive';

const PromotionVideo = () => {
  // const is_tablet_or_mobile = useMediaQuery({query: "(max-width: 767px)"});
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 620px)' });

  return (
    <div className='promotion-video-container'>
      <video
        src={
          is_tablet_or_mobile
            ? `${process.env.REACT_APP_IMG_URL}/assets/promotion_video/home_banner_mobile.mp4`
            : `${process.env.REACT_APP_IMG_URL}/assets/promotion_video/home_banner_pc.mp4`
        }
        playsInline='playsInline'
        muted='muted'
        loop='loop'
        autoPlay='autoPlay'
      />
    </div>
  );
};

export default PromotionVideo;
