import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import RecruitFormModel from '../../model/RecruitFormModel';

const BgdClassList = () => {
  const { eventCode } = useParams();
  const { alert } = useDialogContext();
  const history = useHistory();

  const [classes, setClasses] = useState([]);

  const getData = async () => {
    try {
      const res = await RecruitFormModel.getClasses({ eventCode });

      if (res.length === 0) {
        await alert('강의 준비 중입니다.');
        history.goBack();
      } else {
        setClasses(res);
      }
    } catch (e) {
      await alert('강의 준비 중입니다.');
      history.goBack();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (classes.length === 0) {
    return null;
  }

  return (
    <div className='bgd-container'>
      <div style={{ marginTop: '100px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <p style={{ fontSize: '40px', fontWeight: 'bold' }}>빡공단 클래스</p>
          <p style={{ fontSize: '20px' }}>빡공단 클래스의 상세 내용을 확인해 보세요</p>
        </div>

        <div className='course-list overview-list' style={{ marginTop: '25px' }}>
          {classes.map((item, idx) => (
            <div key={idx} className='base-card' style={{ width: '100%' }}>
              <div className='thumbnail-wrap'>
                <div
                  className='thumbnail'
                  style={{
                    background: `url(${item.main_thumbnail_image_url}) no-repeat center center/cover`,
                  }}
                />
              </div>

              <div className='text-content'>
                <div className='title'>{item.title}</div>
              </div>

              <div className='card-master-desc'>
                {item.brand_name && item.category_name
                  ? `${item.category_name}`
                  : item.tag_set?.map((tag, index) => (
                      <React.Fragment key={index}>
                        {tag}
                        {index !== item.tag_set.length - 1 && ', '}
                      </React.Fragment>
                    ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BgdClassList;
