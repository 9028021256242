import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alert_and_redirect, init_comma } from '../../../common';
import PurchaseModel from '../../../model/PurchaseModel';
import SelectBox from '../../_common/component/SelectBox';
import { set_tmp } from '../../../redux/common/action';
import { useMediaQuery } from 'react-responsive';
import { useDialogContext } from '../../_common/dialog/DialogContextProvider';
import { get_rental_month_period, sort_kit_list } from '../../../bearu_utils';

const propTypes = {
  product: PropTypes.shape({
    short_title: PropTypes.string,
  }),
  rental_time_desc: PropTypes.string,
  setIsPurchase: PropTypes.func,
  seat_count: PropTypes.number,
  error_message: PropTypes.string, // purchase 로 이동할수없게됨
};

const ProductInvoice = (props) => {
  const { product, event, load_count } = props;
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const { confirm, alert } = useDialogContext();

  const [is_visible, setIsVisible] = useState(false);
  const [total_price, setTotalPrice] = useState(0);
  const [purchase_price, setPurchasePrice] = useState(null);
  const [discount_set, setDiscountSet] = useState([]);
  const [kit_set, setKitSet] = useState([]);
  const [_is_first_price_init, setIsFirstPriceInit] = useState(false); // 모바일 초기 가격 보여주기용
  const [all_kit_set, setAllKitSet] = useState([]);

  const purchase_info_desc_ref = useRef(null);
  const _is_mount = useRef(false);

  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  let select_kit_set = [];

  useEffect(() => {
    let validate_kit_set = [];
    for (let i = 0; i < kit_set.length; i++) {
      let check = false;
      for (let j = 0; j < validate_kit_set.length; j++) {
        if (validate_kit_set[j].id === kit_set[i].id) {
          validate_kit_set[j].order_count++;
          check = true;
          break;
        }
      }
      if (!check) {
        validate_kit_set.push({ id: kit_set[i].id, order_count: 1 });
      }
    }
    if (load_count && product) {
      PurchaseModel.priceValidate({
        currency_code: 'KRW',
        package_id: product?.id,
        kit_set: validate_kit_set,
        event_code: event?.event_code,
      }).then(async ({ code, total_price, purchase_price, discount_set }) => {
        if (_is_mount.current) {
          if (code === 200) {
            setTotalPrice(total_price);
            setPurchasePrice(purchase_price);
            setDiscountSet(discount_set);
            if (!_is_first_price_init) {
              props.set_total_price(total_price);
              props.set_purchase_price(purchase_price);
              props.set_discount_price(discount_set);
              setIsFirstPriceInit(true);
            }
          } else if (code === 4805) {
            await alert('최대 수량 이상 선택할 수 없습니다.');
          } else if (code === 4701) {
            await alert('품절된 상품입니다.');
            setKitSet((prevState) => {
              prevState.pop();
              return [...prevState];
            });
          } else if (code === 4812) {
            alert_and_redirect(
              alert,
              props.history,
              '해당 이벤트는 첫 구매시에만 참여가능합니다.',
              `/package/${product.id}`,
            );
          } else if (code === 4813) {
            alert_and_redirect(
              alert,
              props.history,
              '이미 참여하신 이벤트입니다.',
              `/package/${product.id}`,
            );
          } else {
            await alert('잘못된 요청입니다.');
          }
        }
      });
    }
  }, [product, kit_set, event, load_count]);

  useEffect(() => {
    _is_mount.current = true;
    document.addEventListener('click', onOutsideClick, true);
    let default_kit = [];
    let all_kit_set = [];
    product?.kit_info_set.forEach((kit_info) => {
      all_kit_set.push(kit_info);
      if (kit_info.is_default_option) {
        default_kit.push(kit_info);
      }
    });
    // 만약 패키지에 키트가 없을 경우, Course 에 있는 키트는 포함한다.
    if (all_kit_set.length < 0) {
      product?.course_set.forEach((course) => {
        course.kit_info_set.forEach((course_kit_info) => {
          let already_kit = all_kit_set.find((kit) => kit.id === course_kit_info.id);
          if (!already_kit) {
            all_kit_set.push(course_kit_info);
          }
        });
      });
    }
    setKitSet(default_kit);
    // all_kit_set 정렬
    sort_kit_list(all_kit_set);
    setAllKitSet(all_kit_set);
    return () => {
      document.removeEventListener('click', onOutsideClick, true);
      _is_mount.current = false;
    };
  }, [product]);

  const onOutsideClick = (e) => {
    if (purchase_info_desc_ref.current && !purchase_info_desc_ref.current.contains(e.target)) {
      setIsVisible(false);
    }
  };

  const onChange = (target_id, target_name) => {
    if (target_name === 'kit') {
      if (product.only_single_kit && kit_set.length) {
        let select_kit = all_kit_set.find((kit) => kit.id === target_id.value);
        setKitSet([select_kit]);
      } else {
        let already_kit = kit_set.find((kit) => kit.id === target_id.value);
        if (!already_kit) {
          let select_kit = all_kit_set.find((kit) => kit.id === target_id.value);
          select_kit_set.push(select_kit);
          setKitSet((state) => [...state, ...select_kit_set]);
        }
      }
      // setKitSet(state => [...state, ...select_kit_set]);
    }
  };

  const onKitRemoveBtn = (target_id) => {
    kit_set.splice(
      kit_set.findIndex((kit) => kit.id === target_id),
      1,
    );
    setKitSet((state) => [...state, ...select_kit_set]);
  };

  const onPurchase = async () => {
    if (AccountStore) {
      if (!AccountStore.info?.name || !AccountStore.info?.email || !AccountStore.info?.phone) {
        if (await confirm('결제를 위해 개인정보를 입력해주세요.')) {
          props.history.push({
            pathname: '/user/info',
            state: {
              redirect_url: `${props.location.pathname}${props.location.search}`,
            },
          });
        }
      } else {
        if (!kit_set.length && product.is_require_with_kit) {
          await alert('최소 하나의 옵션상품을 선택 하셔야합니다.');
        } else {
          let purchase_desc = '';
          try {
            let product_course_set_length = product.course_set.length;
            const max_visible_item = 20;
            if (product_course_set_length > max_visible_item) {
              purchase_desc = `${product.course_set
                .slice(0, max_visible_item)
                .map((data) => data.short_title)
                .join(', ')} 외 ${product_course_set_length - max_visible_item}건`;
            } else {
              purchase_desc = product.course_set.map((data) => data.short_title).join(', ');
            }
          } catch {}
          dispatch(
            set_tmp('purchase', {
              purchase_type: 'package',
              title: `[${product.short_title}] ${product.title}`,
              can_coupon: event ? event.can_use_with_coupon : product.can_use_coupon,
              purchase_item: {
                product_id: product.id,
                short_title: product.short_title,
                description: purchase_desc,
                original_price: product.original_price,
              },
              kit_set: kit_set,
              event_code: event?.event_code,
              gtm_data: {
                pnm: product.title.replace(/\r\n/g, ' '),
                pid: `p${product.id.toString().padStart(8, '0')}`,
                ppc: product.price,
                ppb: '',
                pca: '',
              },
              expire_date: new Date(),
            }),
          );

          let need_move_to_purchase = true;
          if (props.error_message) {
            need_move_to_purchase = await confirm(
              `${props.error_message}\n결제페이지로 이동할까요?`,
            );
          }

          if (need_move_to_purchase) {
            props.history.push('/purchase');
          }
        }
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  const isPriceReady = () => {
    return purchase_price !== null;
  };

  return (
    <div
      className='invoice-wrap product-invoice gtm-detail gtm-data'
      data-ai-pid={`p${product?.id.toString().padStart(8, '0')}`}
      data-ai-pnm={product?.title.replace(/\r\n/g, ' ')}
      data-ai-ppc={product?.price}
      data-ai-ppb=''
      data-ai-pca='패키지'
    >
      {product ? (
        <>
          <div
            className='invoice-title-wrap'
            style={!is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
          >
            <div className='invoice-title'>
              <h6>PACKAGE</h6>

              <img
                className={`td-favorite package-detail-id-${product.id} ${product.is_favorite ? '' : ' enp_mobon_wish'}`}
                src={
                  product.is_favorite
                    ? `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detailpage-active-2.png`
                    : `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detail-2.png`
                }
                onClick={props.onFavorite}
                alt='저장하기'
              />
            </div>

            <p>
              [{product.short_title}] {product.title}
            </p>
          </div>

          <div
            className='mo-invoice-title-wrap'
            style={is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
          >
            <h3>구매하기</h3>
            <button
              onClick={() => {
                props.setIsPurchase(false);
              }}
            >
              닫기
            </button>
          </div>

          <div className='invoice-content-wrap td-dark-scroll'>
            <div className='product-course-period-wrap'>
              <h5>
                <b>PACKAGE</b>&nbsp;&nbsp;수강권
              </h5>
              {props.rental_time_desc ? <h5>{props.rental_time_desc}</h5> : null}
            </div>
            {/*{product.kit_info_set.length ?*/}
            {all_kit_set.length ? (
              <div className='kit-option-select-wrap'>
                <SelectBox
                  select_type={3}
                  name='kit'
                  // select_set={product?.kit_info_set}
                  select_set={all_kit_set}
                  changeListener={onChange}
                  placeholder={all_kit_set.length ? '옵션상품을 선택하세요.' : '옵션상품 없음'}
                  // placeholder={(product?.kit_info_set.length !== 0 ? "옵션상품을 선택하세요." : "옵션상품 없음")}
                />
                <ul className='selected-list'>
                  {kit_set.map((kit, index) => {
                    return (
                      <li key={kit.id}>
                        <div className='selected-title'>
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-close-gray.svg`}
                            alt='삭제'
                            onClick={() => {
                              onKitRemoveBtn(kit.id);
                            }}
                          />
                          <h5>{kit.title}</h5>
                        </div>
                        <h6>{init_comma(kit.price_info.map((price) => price.our_sale_price))}원</h6>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}

            <div className='img-group'>
              <div className='course-ticket-wrap'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-ticket-confirmation-outline-white.svg`}
                  alt='수강권'
                />
                <span>수강권</span>
              </div>

              <div className='course-secret-note-wrap'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-notebook-outline-white.svg`}
                  alt='비법노트 제공'
                />
                <span>비법노트</span>
              </div>
              <p>구성된 강의 {product.course_set?.length}개의 내용을 모두 포함하여 제공합니다.</p>
            </div>

            {isPriceReady() ? (
              <div className='invoice-content'>
                <div className='purchase-info'>
                  <ul>
                    <li>
                      <h5>실구매가</h5>
                      <h6>{init_comma(product.original_price)}원</h6>
                    </li>

                    {discount_set
                      .filter((_discount) => _discount.price !== 0)
                      .map((discount, index) => {
                        return (
                          <li key={index}>
                            <h5 style={{ color: '#ff0067' }}>{discount.title}</h5>
                            <h6
                              style={{
                                color: '#ff0067',
                                fontWeight: '700',
                              }}
                            >
                              -{init_comma(discount.price)}원
                            </h6>
                          </li>
                        );
                      })}

                    <li className='calculate-price'>
                      <h6>
                        <b>
                          {Math.round(
                            100 -
                              (-(total_price - purchase_price - product.original_price) /
                                product.original_price) *
                                100,
                          ) || 0}
                          %
                        </b>
                        {init_comma(-(total_price - purchase_price - product.original_price))}원
                      </h6>
                    </li>
                  </ul>

                  {kit_set.length ? (
                    <ul className='product-option-item'>
                      <li>
                        <h5>옵션상품</h5>
                        <h6>{init_comma(total_price - product.original_price)}원</h6>
                      </li>
                    </ul>
                  ) : null}

                  <ul className='product-total-price'>
                    <li>
                      <h5>최종 구매가</h5>
                      <h6>{init_comma(purchase_price)}원</h6>
                    </li>
                  </ul>
                </div>

                <div className='total-price'>
                  {is_visible && (
                    <div ref={purchase_info_desc_ref} className='questions-box'>
                      <p>*수강기간 기준 월 금액, 무이자 최대 6개월 가능</p>
                    </div>
                  )}

                  <div
                    className='purchase-info-description-wrap'
                    style={{
                      ...(product?.rental_time < 30 && { visibility: 'hidden' }),
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-information-outline-white.svg`}
                      alt='수강기간 기준 월 금액, 무이자 최대 6개월 가능'
                      onClick={() => setIsVisible(true)}
                    />
                    <span>수강기간 {get_rental_month_period(product?.rental_time)}개월시</span>
                  </div>

                  <div className='total-price-wrap'>
                    <h6>
                      {get_rental_month_period(product?.rental_time) > 1 ? '월' : ''}{' '}
                      {init_comma(
                        Math.floor(
                          purchase_price /
                            parseInt(get_rental_month_period(product?.rental_time) || 1),
                        ),
                      )}
                      원
                    </h6>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='purchase-btn-wrap'>
            <button className='td-btn' onClick={onPurchase}>
              <p className='purchase-btn-text'>
                수강 신청{' '}
                {/*<span className="seat-count">남은자리 <span>{props.seat_count}</span>건</span>*/}
              </p>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

ProductInvoice.propTypes = propTypes;

export default withRouter(ProductInvoice);
