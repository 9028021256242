import axios from "axios";

class EventModel {
    async eventTime({event_id}) {
        const {data: {code, data}} = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/event/time/${event_id}`
        );
        let event = code === 200 ? data.event : null;
        let is_expire = code === 200 ? data.is_expire : null;
        let is_start = code === 200 ? data.is_start : null;
        let course_replace_sale_info = code === 200 ? data.course_replace_sale_info : null;
        return {code, event, is_expire, course_replace_sale_info, is_start};
    }

    async couponGiving({coupon_code_set}) {
        const {data: {code}} = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v1/event/coupon`,
            {coupon_code_set}
        );
        return {code};
    }

    async eventTimeBanner() {
        const {data: {code, data}} = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/event/activetime`
        );
        return {code, data};
    }
}

export default new EventModel();