import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CourseModel from '../../../model/CourseModel';
import { get_avg, replace_cloudfront_url } from '../../../common';
import { Link, withRouter } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import moment from 'moment';

SwiperCore.use([Navigation]);

const propTypes = {
  course_id: PropTypes.number.isRequired,
};

const ProductReview = (props) => {
  const [review_set, setReviewSet] = useState([]);
  const create_star_set = Array.from({ length: 5 });
  const star_ref = useRef(null);
  const [rating_set, setRatingSet] = useState([]);

  useEffect(() => {
    let slice_rating_set = [];
    let empty_review = [];
    CourseModel.courseReviewList({ course_id: props.course_id, page: 1, page_size: 10 }).then(
      ({ review_set }) => {
        setReviewSet(review_set);
        if (review_set.length) {
          for (let i = 0; i < 10 - review_set.length; i++) {
            empty_review.push({});
          }
          setReviewSet(review_set.concat(empty_review));
        }

        for (let i = 0; i < review_set.length; i++) {
          slice_rating_set.push(review_set[i].rating);
        }
        setRatingSet(slice_rating_set);
      },
    );
  }, [props.course_id]);

  const slider_option = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    navigation: {
      prevEl: review_set.length ? '.product-review-swiper-prev-unique' : '',
      nextEl: review_set.length ? '.product-review-swiper-next-unique' : '',
    },
    loopedSlides: 10,
    loop: true,
    autoplay: {
      delay: 1800,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  };

  return (
    <div className='product-review-list'>
      <div
        className='review-rating-total-wrap'
        style={{ padding: review_set.length ? '22px 0' : '48px 0' }}
      >
        {!review_set.length ? (
          <h4>리뷰가 준비되면 보여드릴게요!</h4>
        ) : (
          <>
            <h4>평점 {get_avg(rating_set, 1)}점을 받은 클래스입니다.</h4>
            <ul className='star-rating' ref={star_ref}>
              {create_star_set.map((star, index) => {
                let star_rating = Math.floor(get_avg(rating_set, 1));
                let half_rating = star_rating < get_avg(rating_set, 1);
                let is_on = false;
                let is_half = false;
                if (index + 1 <= star_rating) {
                  is_on = true;
                }
                if (half_rating) {
                  is_half = true;
                }
                return <li className={is_on ? 'on' : is_half ? 'half' : ''} key={index} />;
              })}
            </ul>
          </>
        )}
      </div>
      <div className='preview-review-wrap' style={{ marginTop: review_set.length ? 24 : 0 }}>
        <div className='td-layer' />
        <Swiper {...slider_option}>
          {review_set.map((review, index) => {
            return (
              <SwiperSlide key={index}>
                <div className='preview-review'>
                  {review.review_id ? (
                    <>
                      <div className='preview-review-header'>
                        <div className='preview-user-info'>
                          <div
                            className='preview-profile'
                            style={
                              review.account_profile_img_url
                                ? {
                                    background:
                                      'url(' +
                                      replace_cloudfront_url(review.account_profile_img_url) +
                                      ') no-repeat center center/cover',
                                  }
                                : {
                                    background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/profile-default.png) no-repeat center center/cover`,
                                  }
                            }
                          />
                          <div className='preview-user'>
                            <h5>{review.account_nickname}</h5>
                            <h6>{moment(review.create_time).format('YYYY.M.D')}</h6>
                          </div>
                        </div>
                        <ul className='star-rating'>
                          {create_star_set.map((star, index) => {
                            if (index + 1 > review.rating) {
                              return <li key={index} />;
                            } else {
                              return <li key={index} className='on' />;
                            }
                          })}
                        </ul>
                      </div>
                      <div className='preview-review-content'>
                        <p>{review.content}</p>
                      </div>
                    </>
                  ) : (
                    <div className='empty-review-wrap'>
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/icon-comment.svg`}
                        alt=''
                      />
                      <h3>많은 응원 부탁드려요</h3>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {review_set.length ? (
          <>
            <div className='product-review-swiper-prev-unique' />
            <div className='product-review-swiper-next-unique' />
          </>
        ) : null}
      </div>
      <div className='course-review-more-btn'>
        <Link to={`/course/${props.course_id}`}>
          {review_set.length ? '이 클래스의 후기 더 자세히보기' : '이 클래스 보러 가기'}
        </Link>
      </div>
    </div>
  );
};

ProductReview.propTypes = propTypes;

export default withRouter(ProductReview);
