import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { set_tmp } from '../../../redux/common/action';
import CouponModal from '../../_common/dialog/CouponModal';
import AccountModel from '../../../model/AccountModel';
import '../../../assets/scss/page/my_page_main.scss';
import { dialog_type_custom, useDialogContext } from '../../_common/dialog/DialogContextProvider';
import { create_uuid } from '../../../common';
import { useMount } from '../../../util/BearuHooks';
import { createCategoryInfo } from '../../_collections/mypage';
import CommonModel from '../../../model/CommonModel';

const MyPageMain = (props) => {
  //Redux
  const AccountStore = useSelector((state) => state.AccountStore);
  const CommonStore = useSelector((state) => state.CommonStore);
  const dispatch = useDispatch();
  //

  //Modal
  const { showDialog } = useDialogContext();
  //

  const {
    tmp: { course_update_date = {} },
  } = CommonStore;

  const [isMount] = useMount();
  const [banner_set, setBannerSet] = useState(null);
  const [login_icon_url, setLoginIconUrl] = useState(null);

  const { is_side_bar, selected_category, is_modal } = props;

  const category_info = createCategoryInfo(
    AccountStore?.course_count,
    AccountStore?.favorite_count,
    AccountStore?.ebook_count,
    AccountStore?.ebook_favorite_count,
    AccountStore?.coupon_count,
    AccountStore?.ship_count
  );

  const recentClass = () => {
    return CommonStore.tmp?.my_course_set.filter((course) => !course.is_expired).slice(0, 2);
  };

  const doSignOut = () => {
    AccountModel.signOut().then(() => {
			const isBgdSessionId = Cookies.get('bgdSessionId');
			if (isBgdSessionId) {
				Cookies.remove('bgdSessionId', { domain: '.bear-u.com', path: '/' });
			}
      Cookies.remove('ebookAuth', { domain: '.bear-u.com', path: '/' });
      let course_card_set = document.querySelectorAll(`.list-item`);
      course_card_set.forEach((data) => {
        let img = data.querySelector('.thumbnail-wrap img');
        img.src = `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-default-2.svg`;
      });
      window.location.replace('/');
    });
  };

  useEffect(async () => {
    if (course_update_date !== moment().format('YYYY-MM-DD')) {
      await AccountModel.myCourseList().then(({ course_set }) => {
        if (isMount()) {
          let expire_noti = [];
          for (let i = 0; i < course_set.length; i++) {
            let expire_day = moment(course_set[i].rental_expire_time).diff(moment(), 'days');
            if (expire_day < 30 && expire_day >= 0) {
              expire_noti.push({
                id: create_uuid(),
                is_notice: false,
                is_read: false,
                course_id: course_set[i].id,
                notice_category_id: 100,
                notice_category_name: `${expire_day}`,
                title: `[${course_set[i].short_title}]`,
                redirect_site_map_url: `/user/mypage/course`,
              });
            }
          }
          dispatch(set_tmp('noti_course_set', expire_noti));
          dispatch(set_tmp('my_course_set', course_set));
          dispatch(set_tmp('course_update_date', moment().format('YYYY-MM-DD')));
        }
      });
    }
  });

  useEffect(() => {
    CommonModel.bannerList({ page: 1, size: 5, banner_type_id: 3 }).then(({ banner_set }) => {
      if (isMount()) {
        setBannerSet(banner_set);
      }
    });
  }, []);

  useEffect(() => {
    if (AccountStore?.account_provider_id === 1) {
    } else if (AccountStore?.account_provider_id === 2) {
      setLoginIconUrl(`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_fb.png`);
    } else if (AccountStore?.account_provider_id === 3) {
    } else if (AccountStore?.account_provider_id === 4) {
      setLoginIconUrl(`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_kakao.png`);
    }
  }, []);

  const getNickname = () => {
    if (AccountStore?.account_type_id === 5) {
      if (!AccountStore?.info?.nickname) {
        return `익명의 관리자`;
      } else {
        return `${AccountStore?.info?.nickname} (관리자)`;
      }
    } else if (!AccountStore?.info?.nickname) {
      return '익명의 수강생';
    } else {
      return AccountStore?.info?.nickname;
    }
  };

  return (
    <>
      {/*마이페이지 메인*/}
      <div className='account-wrap'>
        <div className='account-info'>
          <div className='info-wrap'>
            <div className='profile-img-wrap'>
              <img
                className='profile-img'
                src={
                  AccountStore?.info?.picture_thumbnail_image_url
                    ? AccountStore?.info?.picture_thumbnail_image_url
                    : `${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_bear_m3.png`
                }
                alt='프로필이미지'
              />
            </div>

            <div>
              <div className='account-info-row'>
                {login_icon_url !== null && <img src={login_icon_url} alt='sns 이미지' />}
                <p>{getNickname()}</p>
              </div>
              {AccountStore?.account_provider_id === 1 && (
                <div className='email-info'>{AccountStore?.account_name}</div>
              )}
            </div>
          </div>
          <Link to='/user/info'>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_setting${
                is_side_bar || is_modal ? '' : '_mo'
              }.png`}
              alt='개인정보 수정 아이콘'
            />
          </Link>
        </div>

        <div
          className='enrollment-btn'
          onClick={() => {
            showDialog({
              type: dialog_type_custom,
              component: CouponModal,
            });
          }}
        >
          수강 코드 / 쿠폰 등록하기
        </div>
      </div>

      {recentClass().length > 0 && !is_side_bar ? (
        <div className='recent-class-wrap'>
          <p className='info-text'>최근 들은 클래스</p>
          {recentClass().map((course, index) => (
            <Link className='recent-class' to={`/course/${course.id}`} key={index}>
              <img src={course.circle_image_url} alt='강의 이미지' />
              <div className='class-info-text'>
                <p>{course.short_title}</p>
                <p className='class-progress'>{`수강률 ${parseFloat((course.attendance_rate || 0) * 100).toFixed(
                  1
                )}%`}</p>
              </div>
            </Link>
          ))}
        </div>
      ) : null}

      <div className={`category-wrap ${is_side_bar ? 'side_bar' : ''}`}>
        {category_info?.map((category, index) => (
          <Link
            className={`category-tap ${category.pathname === selected_category ? 'selected' : ''}`}
            to={`/user/mypage/${category.pathname}`}
            key={index}
          >
            <div>
              {is_side_bar ? (
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/mypage/icon_my_${category.pathname}.svg`}
                  alt='카테고리 아이콘'
                />
              ) : null}
              <p className='info-text'>{category.name}</p>
            </div>
            <p className='count-txt'>{category.count}</p>
          </Link>
        ))}
      </div>

      {!props.is_modal && banner_set !== null ? (
        <div className={'my-page-banner-bg' + (banner_set.length > 0 ? '' : ' default')}>
          <a
            className='my-page-banner-wrap'
            href={banner_set.length > 0 ? banner_set[0]?.redirect_url : 'https://tally.so/r/nW8JaR'}
          >
            <img
              className='my-page-banner'
              src={
                banner_set.length > 0
                  ? banner_set[0].image_url
                  : `${process.env.REACT_APP_IMG_URL}/static/v2/png/banner/master_apply_banner_1.png`
              }
              alt='마이페이지배너'
            />
          </a>
        </div>
      ) : null}

      {is_side_bar ? null : (
        <button className={`log-out-btn ${props.is_modal ? 'modal' : ''}`} onClick={doSignOut}>
          로그아웃
        </button>
      )}
    </>
  );
};

export default withRouter(MyPageMain);
