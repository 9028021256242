import React, { useEffect } from 'react';
import '../../assets/scss/page/terms_policy.scss';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';

const Privacy = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  useEffect(() => {
    dispatch(set_header_type(0, 2, { title: '개인정보 처리방침' }));
    dispatch(set_footer_type(0));
    props.ready();
  }, []);
  return (
    <div className='terms-policy-container'>
      <Helmet>
        <title>개인정보 처리방침</title>
      </Helmet>
      <section className='terms-wrap'>
        <p className='title pc-visible'>개인정보 처리방침</p>
        <ul className='terms-list'>
          <li className='terms-con'>
            <h3 className='terms-subtitle'>주식회사 텐덤 개인정보 처리방침</h3>
            <div className='terms-text-wrap'>
              <p className='terms-text terms-maintext'>
                (주)텐덤(이하 “회사”라 합니다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를
                보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과
                같이 개인정보 처리지침을 수립 공개합니다.
              </p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제1조(개인정보의 처리목적)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의
                목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법
                제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
              </p>
              <br />
              <ul className='terms-con-list'>
                <li>
                  1.회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지, 회원자격 유지 및 관리
                </li>
                <li>
                  2.만 14세 미만 아동의 개인정보 수집·처리시 법정대리인의 동의여부 확인, 법정 대리인
                  권리행사 시 본인 확인
                </li>
                <li>3.서비스 이용 시 컨텐츠, 재화, 서비스, 계약서, 청구서 등의 전송 또는 배송</li>
                <li>4.요금 결제·정산, 채권추심, 제세공과금 처리</li>
                <li>5.문의사항 또는 불만처리, 공지사항 기타 각종 통지, 다양한 서비스 제공</li>
                <li>
                  6.인구통계학적 특성에 따른 분석 및 서비스 제공, 광고 게재, 맞춤형 서비스 제공
                </li>
                <li>7.서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계</li>
                <li>8.신규 서비스(제품) 개발 및 마케팅과 광고에의 활용</li>
                <li>9.이벤트 및 광고성 정보 제공 및 참여기회 제공, 참여 확인</li>
                <li>10.서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재</li>
              </ul>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제2조(개인정보의 처리 및 보유기간)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                1.회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에
                동의 받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.
              </p>
              <p className='terms-text'>2.각각의 개인정보의 처리 및 보유 기간은 다음과 같습니다.</p>
              <p className='terms-text'>
                a.홈페이지 회원 가입 및 관리 등에 관한 기록 : 홈페이지 탈퇴 시까지. 단, 그 이후에도
                아래 각 호 사유에 해당하는 경우에는 해당 사유 종료 시까지
              </p>
              <p className='terms-text'>
                (1) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료
                시까지
              </p>
              <p className='terms-text'>
                (2) 홈페이지 이용에 따른 채권·채무관계가 남아있을 시 해당 채권·채무관계 소멸 시까지
              </p>
              <p className='terms-text'>
                b.재화 또는 서비스 제공 등에 관한 기록 : 재화·서비스 공급 완료 및 요금 결제·정산
                완료 시까지. 단, 그 이후에도 관계법령의 규정에 의하여보존할 필요가 있는 경우 회사는
                아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보유합니다.
              </p>
              <p className='terms-text'>
                (1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 의거, 계약 또는 청약철회 등에
                관한 기록 : 5년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 소비자의 불만또는
                분쟁처리에 관한 기록 : 3년, 표시·광고에 관한 기록 : 6개월
              </p>
              <p className='terms-text'>
                (2) 「국세기본법」에 의거 세법이 규정하는 모든 거래에 관한 장부 및 증빙자료 : 5년
              </p>
              <p className='terms-text'>
                (3) 「전자금융거래법」에 의거 전자금융 거래에 관한 기록 : 5년
              </p>
              <p className='terms-text'>(4) 「통신비밀보호법」에 의거 서비스 방문 기록 : 3개월</p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제3조(개인정보의 제3자 제공)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                1.회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만
                처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조에 해당하는
                경우에만 개인정보를 제3자에게 제공합니다.
              </p>
              <p className='terms-text'>
                2.위탁한 개인정보는 회원탈퇴, 위탁계약 종료, 이용자의 삭제 요청이 있는 경우 지체
                없이 파기합니다.
              </p>
              <p className='terms-text'>
                3.위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보처리방침을
                통하여 공개합니다.
              </p>

              <div className='terms-text'>
                <table className='terms-table privacy'>
                  <thead>
                    <tr>
                      <th>구분</th>
                      <th>수탁자</th>
                      <th>위탁 업무</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>알림 발송</td>
                      <td>다우기술, 엔에이치엔, 스티비 주식회사</td>
                      <td>메시지 발송, 이메일 발송, 카카오톡 알림톡 등 정보성 메시지</td>
                    </tr>
                    <tr>
                      <td>고객 상담</td>
                      <td>채널코퍼레이션</td>
                      <td>고객 상담을 위한 서비스</td>
                    </tr>
                    <tr>
                      <td>서버 제공</td>
                      <td>아마존 웹 서비스</td>
                      <td>서비스 제공을 위한 인프라</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제4조(정보주체의 권리·의무 및 행사방법)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                1.정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수
                있습니다.
              </p>
              <p className='terms-text'>(a) 개인정보 열람 요구</p>
              <p className='terms-text'>(b) 오류 등이 있을 경우 정정 요구</p>
              <p className='terms-text'>(c) 삭제 요구</p>
              <p className='terms-text'>(d) 처리 정지 요구</p>
              <p className='terms-text'>
                2.제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을
                통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
              </p>
              <p className='terms-text'>
                3.정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정
                또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제3자에게 제공하지 않습니다.
              </p>
              <p className='terms-text'>
                4.제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 적법한
                대리권을 가진 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙
                별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              </p>
              <p className='terms-text'>
                5.정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체
                본인이나 타인의 개인정보 및 사생활을 침해하여서는 안 됩니다.
              </p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제5조(처리하는 개인정보 항목)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
              <br />
              <p className='terms-text'>1.홈페이지 회원 가입 및 관리</p>
              <p className='terms-text'>
                필수항목 : 이메일, 비밀번호, 이름, 성별, 생년월일, 전화번호, 직종
              </p>
              <p className='terms-text'>선택항목 : 프로필 사진, 닉네임, 주소</p>
              <p className='terms-text'>2.결제 서비스</p>
              <p className='terms-text'>필수항목 : 구매내역</p>
              <p className='terms-text'>
                3.인터넷 서비스 이용과정에서 접속IP정보, 쿠키(Cookie), MAC주소, 단말기 식별번호,
                서비스 이용기록, 결제기록, 수강기록, 컨텐츠다운로드 기록, 방문기록, 불량 이용기록
                등의 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
              </p>
              <p className='terms-text'>
                4.통계학적 분석을 통한 신규서비스 개발, 서비스 개선, 마케팅전략수립
              </p>
              <p className='terms-text'>
                선택항목 : 주소, 휴대전화번호, 관심분야, 회사명, 직책, 부서, 직무, 연차, 프로필
                사진, 연령대, 최종학력, 직업, 가입경로, 과거 구매내역, 부가 서비스 및 맞춤식 서비스
                이용 또는 이벤트 응모 과정에서 입력한 정보, 개별 학습 내용 및 서비스 후기, 서비스
                설문 정보
              </p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제6조(개인정보의 파기)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                1.회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을
                때에는 지체 없이 해당 개인정보를 파기합니다.
              </p>
              <p className='terms-text'>
                2.정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도
                불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
                별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
              </p>
              <p className='terms-text'>
                3.회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록
                로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에
                기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
              </p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>
              제7조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
            </h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                1.회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로
                불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는
                서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
                컴퓨터 내의 하드디스크에 저장되기도 합니다.
              </p>
              <p className='terms-text'>
                2.쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
                인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                사용됩니다.
              </p>
              <p className='terms-text'>
                3.쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보
                메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
              </p>
              <p className='terms-text'>
                4.쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
              </p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제8조(개인정보 보호책임자)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                1.회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                지정하고 있습니다.
              </p>
              <br />
              <p className='terms-text'>개인정보 보호책임자</p>
              <p className='terms-text'>성명 : 유원일</p>
              <p className='terms-text'>직책 : 대표</p>
              <br />
              <p className='terms-text'>
                2.회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의,
                불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
                있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
              </p>
            </div>
          </li>
          <li className='terms-con'>
            <h4 className='terms-subtitle'>제9조(개인정보 처리방침 변경)</h4>
            <div className='terms-text-wrap'>
              <p className='terms-text'>
                회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보 처리방침을
                수정할 수 있습니다. 개인정보 처리방침이 변경되는 경우 회사는 변경 사항을 게시하며,
                변경된 개인정보 처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다. 다만,
                개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중대한 변경이 발생할
                때에는 최소 30일 전에 미리 알려드리겠습니다. 이 개인정보 처리방침은 2019. 1. 21부터
                적용됩니다.
              </p>
              <br />
              <p className='terms-text'>개인정보관리책임자 성명: 유원일</p>
              <p className='terms-text'>이메일: info@tendom.co.kr</p>
              <p className='terms-text'>문의메일: {CommonStore.service.info.contact_email}</p>
              <br />
              <p className='terms-text'>
                귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
                개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의
                신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
              </p>
              <p className='terms-text'>
                기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
                바랍니다.
              </p>
              <br />
              <p className='terms-text'>1.대검찰청 사이버수사과 (cybercid.spo.go.kr)</p>
              <p className='terms-text'>2.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Privacy;
