// uuid 생성
const moment = require('moment');
export const create_uuid = () => {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : r && 0x3 | 0x8).toString(16);
  });
};

// 숫자 3자리마다 콤마
export const init_comma = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 경과된 일 수 구하기
export const get_past_day = (date) => {
  let today = new Date();
  let write_time = new Date(date);
  return Math.ceil((today.getTime() - write_time.getTime()) / (1000 * 3600 * 24));
};

// 평균 구하기
export const get_avg = (array, digits) => {
  let avg;
  avg = array.reduce((sum, curr) => sum + Number(curr), 0) / array.length;

  if (Number.isInteger(avg)) {
    return avg.toFixed(1);
  } else {
    return Math.floor(avg * Math.pow(10, digits)) / Math.pow(10, digits);
  }
};

// Lagacy URL Matching
export const convert_redirect_url = (url) => {
  const convert_url = new URL(url, window.location.origin);
  let return_url = url;
  const path_name = convert_url.pathname;
  if (convert_url.host.indexOf('bear-u.com') !== -1) {
    return_url = path_name + convert_url.search;
  }
  if (['/home', '/home/'].includes(path_name)) {
    return `/`;
  } else if (['/user/login.php'].includes(path_name)) {
    return '/user/signin';
  } else if (['/user/register.php'].includes(path_name)) {
    return '/user/signup';
  } else if (['/user/privacy_edit.php'].includes(path_name)) {
    return '/user/info';
  } else if (['/user/pw_change.php'].includes(path_name)) {
    return '/user/password/change';
  } else if (['/user/id_find.php'].includes(path_name)) {
    return '/user/email/find';
  } else if (['/user/pw_find.php'].includes(path_name)) {
    return '/user/password/find';
  } else if (['/user/with_drawal.php'].includes(path_name)) {
    return '/user/dropout';
  } else if (['/user/my_courses.php'].includes(path_name)) {
    return '/user/mypage/course';
  } else if (['/user/favorites.php', '/user/mo_favorites.php'].includes(path_name)) {
    return '/user/mypage/favorite';
  } else if (['/user/my_coupons.php', '/user/mo_my_coupons.php'].includes(path_name)) {
    return '/user/mypage/coupon';
  } else if (['/user/delivery.php', '/user/mo_delivery.php'].includes(path_name)) {
    return '/user/mypage/shipping';
  } else if (['/course/overview.php'].includes(path_name)) {
    return `/overview/all`;
  } else if (['/course', '/course/'].includes(path_name)) {
    return `/course/${convert_url.searchParams.get('courseId')}?event_code=${convert_url.searchParams.get(
      'eventCode',
    )}`;
  } else if (['/event/time_event.php'].includes(path_name)) {
    return `/event/time/${convert_url.searchParams.get('eventCode')}`;
  } else if (['/product', '/product/'].includes(path_name)) {
    return `/package/${convert_url.searchParams.get('productId')}`;
  } else if (['/kit', '/kit/'].includes(path_name)) {
    return `/kit/${convert_url.searchParams.get('kitInfoId')}?add_kit=${convert_url.searchParams.get(
      'additionalKitInfoIds',
    )}`;
  } else if (['/course/search.php'].includes(path_name)) {
    return `/search?q=${convert_url.searchParams.get('keyword')}`;
  } else if (['/service/notice.php'].includes(path_name)) {
    return `/service/notice`;
  } else if (['/bootcamp/super_z.php'].includes(path_name)) {
    return `/superz`;
  } else if (['/service/notice_detail.php'].includes(path_name)) {
    return `/service/notice/${convert_url.searchParams.get('noticeId')}`;
  } else if (['/service/master_apply.php'].includes(path_name)) {
    return `/service/master`;
  } else if (['/course/viewer.php'].includes(path_name)) {
    return `/course/${convert_url.searchParams.get('courseId')}/viewer`;
  } else if (['/course/curation.php'].includes(path_name)) {
    return `/couration/${convert_url.searchParams.get('curationId')}`;
  } else if (['/event/giving_coupon_event.php'].includes(path_name)) {
    let copon_code = convert_url.searchParams.getAll('couponEventText[]');
    return `/event/coupon/giving?coupon_code=${copon_code.join('&coupon_code=')}`;
  } else if (['/survey/'].includes(path_name)) {
    return `/service/survey/${convert_url.searchParams.get('surveyId')}`;
  } else if (['/service/terms.php'].includes(path_name)) {
    return `/service/terms`;
  } else if (['/service/privacy.php'].includes(path_name)) {
    return `/service/privacy`;
  } else if (['/service/faq.php'].includes(path_name)) {
    return `/service/faq`;
  } else if (['/course/viewer_experimental.php'].includes(path_name)) {
    return `/service/info/lab`;
  } else if (['/service/viewer_error.php'].includes(path_name)) {
    return `/service/info/viewer`;
  } else if (['/bgd.php'].includes(path_name)) {
    return `/bgd`;
  } else {
    return return_url;
  }
};

// 기존 및 리뉴얼 버전 북마크 둘다 가능
export const set_favorite_icon = (type, id, is_favorite, favorite_count) => {
  let card_set = document.querySelectorAll(`.${type}-id-${id}`);
  let detail_img_set = document.querySelectorAll(`.${type}-detail-id-${id}`);

  detail_img_set.forEach((detail_img) => {
    if (is_favorite) {
      detail_img.src = `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_bookmark_new_1.svg`;
      remove_class(detail_img, 'enp_mobon_wish');
    } else {
      detail_img.src = `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_bookmark_new_0.svg`;
      add_class(detail_img, 'enp_mobon_wish');
    }
  });
  card_set.forEach((data) => {
    if (data.querySelectorAll('.bookmark-wrap').length) {
      let bookmark_wrap = data.querySelector('.bookmark-wrap');
      //   let bookmark_count = data.querySelector('.bookmark-count');
      if (is_favorite) {
        add_class(bookmark_wrap, 'active');
      } else {
        remove_class(bookmark_wrap, 'active');
      }
      //   bookmark_count.textContent = favorite_count;
    } else {
      let img = data.querySelector('.thumbnail-wrap img');
      let span = data.querySelector('.course-desc-wrap span');
      if (is_favorite) {
        img.src = `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-select-2.svg`;
        add_class(img, 'enp_mobon_wish');
      } else {
        img.src = `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-default-2.svg`;
        remove_class(img, 'enp_mobon_wish');
      }
      span.textContent = favorite_count;
    }
  });
};

export const blob_to_data_url = (_blob, _cb) => {
  let reader = new FileReader();
  reader.onload = function () {
    let dataUrl = reader.result;
    _cb && _cb(encodeURI(dataUrl));
  };
  reader.onerror = function () {
    _cb && _cb(null);
  };
  reader.readAsDataURL(_blob);
};

export const alert_and_redirect = async (_alert, history, msg, redirect_url = null) => {
  await _alert(msg); // dialog context alert
  if (history.action === 'POP') {
    if (redirect_url) {
      window.location.replace(redirect_url);
    } else {
      window.location.replace('/');
    }
  } else {
    try {
      history.goBack();
    } catch (e) {
      if (redirect_url) {
        window.location.replace(redirect_url);
      } else {
        history.back();
      }
    }
  }
};

export const is_today_active_range = (startDate, endDate) => {
  const today = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return today.isBetween(start, end, null, '[]');
};

export const get_kor_date_with_week = (date, format = 'YYYY년 M월 DD일') => {
  let week_kor = ['일', '월', '화', '수', '목', '금', '토'];
  return `${moment(date).format(format)}(${date ? week_kor[new Date(date).getDay()] : '-'})`;
};

export const day_of_week = (date_string) => {
  let day_of_week_list = ['일', '월', '화', '수', '목', '금', '토'];
  return day_of_week_list[new Date(date_string).getDay()];
};

export const get_hash_in_url = (url, defaultValue = '') => {
  return url ? url.substring(url.indexOf('#')) : defaultValue;
};

export const replace_cloudfront_url = (text) => {
  if (text) {
    return text.replace(
      /https:\/\/s3.ap-northeast-2.amazonaws.com\/img.bear-u.com/gi,
      'https://drv987r6zjtyw.cloudfront.net',
    );
  } else {
    return text;
  }
};

// shuffle
export const shuffle = (array) => {
  array.sort(() => Math.random() - 0.5);
};

// 클래스 추가
export const add_class = (element, class_name) => {
  element.classList.add(class_name);
};

// 클래스 제거
export const remove_class = (element, class_name) => {
  element.classList.remove(class_name);
};

export const extract_get_parameter = (url, default_value = '') => {
  if (!url) return default_value;

  var position = url.indexOf('?');
  return position >= 0 ? url.substring(position + 1, url.length) : default_value;
};

// return {day: 0, hour: 0, minute: 0}
export const date_diff = (start_date_string, end_date_string) => {
  let day = 0;
  let hour = 0;
  let minute = 0;
  let second = 0;
  if (start_date_string && end_date_string) {
    let diff_date = moment(start_date_string).diff(end_date_string);
    day = Math.floor(moment.duration(diff_date).asDays());
    hour = moment.duration(diff_date).hours();
    minute = moment.duration(diff_date).minutes();
    second = moment.duration(diff_date).seconds();
  }
  return {
    day,
    hour,
    minute,
    second,
  };
};

export const object_to_parameter = (object, exclusives = []) => {
  let res = null;
  let length = object ? Object.keys(object).length : 0;
  if (length > 0) {
    res = '';
    let index = 0;
    for (let key in object) {
      if (
        !exclusives.find((elem) => {
          return elem === key;
        })
      ) {
        res += key + '=' + encodeURIComponent(object[key]);
        if (index !== length - 1) {
          res += '&';
        }
        index++;
      }
    }
  }
  return res;
};

export const try_json_parse = (json_string) => {
  let json = null;
  try {
    json = JSON.parse(json_string);
  } catch (e) {}
  return json;
};

export const legacy_parameters = [
  { from: 'userKitShippingId', transform: 'user_kit_shipping_id' },
  { from: 'courseId', transform: 'course_id' },
  { from: 'courseQnaId', transform: 'course_qna_id' },
  { from: 'courseReviewId', transform: 'course_review_id' },
  { from: 'merchantUid', transform: 'merchant_uid' },
];

export const transform_legacy_get_parameters = (get_object) => {
  let new_get_object = null;
  let length = get_object ? Object.keys(get_object).length : 0;
  if (length > 0) {
    new_get_object = { ...get_object };
    let transform_key_list = [];
    for (let key in new_get_object) {
      let legacy = legacy_parameters.find((elem) => {
        return elem.from === key;
      });
      if (legacy) {
        new_get_object[legacy.transform] = new_get_object[key];
        transform_key_list.push(key);
      }
    }

    transform_key_list.forEach((elem) => {
      delete new_get_object[elem];
    });
  }
  return new_get_object;
};

export const create_notification_url = (url, get_object) => {
  let new_url = url;

  if (get_object) {
    let replaced_key_list = [];
    for (let key in get_object) {
      let template_key = `:${key}`;
      if (new_url.indexOf(template_key) >= 0) {
        new_url = new_url.replaceAll(template_key, get_object[key]);
        replaced_key_list.push(key);
      }
    }

    let parameter_string = object_to_parameter(get_object, replaced_key_list);
    new_url =
      new_url.indexOf('?') >= 0
        ? new_url + '&' + parameter_string
        : new_url + '?' + parameter_string;
  } else {
    // @TODO hpark :path 값 제거할 가능성
  }

  return new_url;
};

export const history_push = ({ history, url, get_object }) => {
  let new_url = url;
  let get_parameter_string = object_to_parameter(get_object);
  if (get_parameter_string) {
    new_url =
      new_url + (new_url.indexOf('?') >= 0 ? get_parameter_string : '?' + get_parameter_string);
  }

  history?.push(new_url);
};

export const select = (flag, a = null, b = null) => {
  return flag ? a : b;
};

// return promise
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const storage_get = (key) => {
  let result = null;
  try {
    result = JSON.parse(localStorage.getItem(key));
    if (result.expire_time && result.time + result.expire_time < new Date().getTime()) {
      //expired
      result = null;
    }
  } catch {}
  return result?.value || null;
};

export const storage_set = (key, value, expire_time = null) => {
  let current_time = new Date().getTime();
  localStorage.setItem(
    key,
    JSON.stringify({
      value,
      time: current_time,
      expire_time: expire_time,
    }),
  );
};

export const storage_remove = (key) => {
  localStorage.removeItem(key);
};

export const is_string_empty = (str, with_trim = false) => {
  let is_empty = false;
  if (typeof str === 'string') {
    is_empty = str === '';
    if (with_trim) {
      is_empty |= str.trim() === '';
    }
  } else {
    // null, undefined
    is_empty = str === null || str === undefined;
  }

  return is_empty;
};

export const custom_hash = async (value) => {
  const utf8 = new TextEncoder().encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

// from https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
// Merge a `source` object to a `target` recursively
export const merge_object = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (let key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge_object(target[key], source[key]));
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

export const insert_string = (string, index, value) => {
  return string.substr(0, index) + value + string.substr(index);
};

// phone dash 추가
export const add_dash_to_phone_number = (phone_number) => {
  if (typeof phone_number !== 'string') {
    return '';
  }

  phone_number = remove_dash_to_number(phone_number);
  if (phone_number.length > 3) {
    phone_number = insert_string(phone_number, 3, '-');
    if (phone_number.length > 8) {
      phone_number = insert_string(phone_number, 8, '-');
    }
  }
  return phone_number;

  // 정규식 버전
  // return phone_number.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
};

// birth dash 추가
export const add_dash_to_birth_date = (birth_date) => {
  if (typeof birth_date !== 'string') {
    return '';
  }

  birth_date = remove_dash_to_number(birth_date);
  if (birth_date.length > 4) {
    birth_date = insert_string(birth_date, 4, '-');
    if (birth_date.length > 7) {
      birth_date = insert_string(birth_date, 7, '-');
    }
  }
  return birth_date;
};

// dash 제거
export const remove_dash_to_number = (phone_number) => {
  return typeof phone_number === 'string' ? phone_number.replaceAll('-', '') : '';
};

// text ellipsis 처리
export const on_text_ellipsis = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

// random array pick
export const random_pick = (array, default_value = null) => {
  let result = default_value;
  try {
    const random = Math.floor(Math.random() * array.length);
    return array[random];
  } catch {}
  return result;
};
