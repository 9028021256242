import CryptoJS from 'crypto-js';

export const encryptData = (data, secretKey) => {
	return CryptoJS.AES.encrypt(data, secretKey).toString();
}

export const decryptData = (encryptedData, secretKey) => {
	const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
	return decryptedBytes.toString(CryptoJS.enc.Utf8);
}
