import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import CourseModel from '../../model/CourseModel';
import { Helmet } from 'react-helmet';
import CourseCard2 from '../_common/component/CourseCard2';
import '../../assets/scss/page/search.scss';
import { useObserver } from '../../util/BearuHooks';
import PackageCard from '../_common/component/PackageCard2';
import CommonModel from '../../model/CommonModel';
import ProductModel from '../../model/ProductModel';
import KitCard from '../_common/component/KitCard2';
import CurationCard from '../_common/component/CurationCard';
import { useMediaQuery } from 'react-responsive';
import PopularList2 from '../_common/section/PopularList2';
import { Link } from 'react-router-dom';
import { HEADER_TYPE_MOBILE_SEARCH, HEADER_TYPE_PC_BASE } from '../_common/section/Header';
import EbookModel from '../../model/EbookModel';
import BookList from '../ebook/component/BookList';
import useEbookBookmark from '../../hook/useEbookBookmark';

const Search = (props) => {
  const dispatch = useDispatch();
  const [search_course_set, setCourseSet] = useState([]);
  const [search_package_set, setPackageSet] = useState([]);
  const [search_kit_set, setKitSet] = useState([]);
  const [search_curation_set, setCurationSet] = useState([]);
  const [search_ebook_set, setEbookSet] = useState([]);
  const [request_now, setRequestNow] = useState(true);
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { toggleEbookBookmark } = useEbookBookmark();

  const search = new URLSearchParams(props.location.search).get('q');
  const [search_text, setSearchText] = useState(search);
  const _is_mount = useRef(false);

  let _is_last = [false, false, false, false, false]; //course, package, kit, curation, ebook
  let _is_pending = [false, false, false, false, false]; //course, package, kit, curation, ebook
  let page = [1, 1, 1, 1, 1]; //course, package, kit, curation, ebook
  let size = 12;

  useEffect(() => {
    dispatch(set_footer_type(0));
    dispatch(
      set_header_type(HEADER_TYPE_PC_BASE, HEADER_TYPE_MOBILE_SEARCH, {
        title: '검색',
        no_search_btn: true,
      }),
    );

    _is_mount.current = true;
    return () => {
      _is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    _is_last = [false, false, false, false, false];
    _is_pending = [false, false, false, false, false];
    page = [1, 1, 1, 1, 1];
    setCourseSet([]);
    setPackageSet([]);
    setKitSet([]);
    setCurationSet([]);
    setEbookSet([]);
    setRequestNow(true);
    setSearchText(search);

    props.ready();
  }, [search]);

  const course_load_ref = useObserver(
    { threshold: 0, rootMargin: '30px' },
    () => {
      onCourseSet();
    },
    null,
    [search],
  );

  const package_load_ref = useObserver(
    { threshold: 0 },
    () => {
      onPackageSet();
    },
    null,
    [search],
  );

  const kit_load_ref = useObserver(
    { threshold: 0 },
    () => {
      onKitSet();
    },
    null,
    [search],
  );

  const curation_load_ref = useObserver(
    { threshold: 0 },
    () => {
      onCurationSet();
    },
    null,
    [search],
  );

  const ebook_load_ref = useObserver(
    { threshold: 0 },
    () => {
      onEbookSet();
    },
    null,
    [search],
  );

  const onCourseSet = () => {
    if (search && !_is_last[0] && !_is_pending[0]) {
      _is_pending[0] = true;
      CourseModel.courseList({
        page: page[0],
        size,
        search_text: search,
      }).then(({ course_set }) => {
        if (_is_mount.current) {
          setRequestNow(false);
          setCourseSet((state) => [...state, ...course_set]);
          if (course_set.length < size) {
            _is_last[0] = true;
          }
          page[0] += 1;
          _is_pending[0] = false;
        }
      });
    }
  };

  const onPackageSet = () => {
    if (search && !_is_last[1] && !_is_pending[1]) {
      _is_pending[1] = true;
      ProductModel.packageList({
        page: page[1],
        size,
        search_text: search,
      }).then(({ product_set }) => {
        if (_is_mount.current) {
          setRequestNow(false);
          setPackageSet((state) => [...state, ...product_set]);
          if (product_set.length < size) {
            _is_last[1] = true;
          }
          page[1] += 1;
          _is_last[1] = false;
        }
      });
    }
  };

  const onKitSet = () => {
    if (search && !_is_last[2] && !_is_pending[2]) {
      _is_pending[2] = true;
      ProductModel.kitList({
        page: page[2],
        size,
        search_text: search,
      }).then(({ kit_set }) => {
        if (_is_mount.current) {
          setRequestNow(false);
          setKitSet((state) => [...state, ...kit_set]);
          if (kit_set.length < size) {
            _is_last[2] = true;
          }
          page[2] += 1;
          _is_last[2] = false;
        }
      });
    }
  };

  const onCurationSet = () => {
    if (search && !_is_last[3] && !_is_pending[3]) {
      _is_pending[3] = true;
      CommonModel.curationList({
        search_text: search,
      }).then(({ curation_set }) => {
        if (_is_mount.current) {
          setRequestNow(false);
          setCurationSet((state) => [...state, ...curation_set]);
          if (curation_set.length < size) {
            _is_last[3] = true;
          }
          page[3] += 1;
          _is_last[3] = false;
        }
      });
    }
  };

  const onEbookSet = () => {
    const bookFetchSize = 10;

    if (search && !_is_last[4] && !_is_pending[4]) {
      _is_pending[4] = true;
      EbookModel.ebookList({
        search_text: search,
        page: page[4],
      }).then(({ books }) => {
        if (_is_mount.current) {
          setRequestNow(false);
          setEbookSet((state) => [...state, ...books]);
          if (books.length < bookFetchSize) {
            _is_last[4] = true;
          }
          page[4] += 1;
          _is_pending[4] = false;
        }
      });
    }
  };

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const doSearch = (_search_text) => {
    props.history.push(`/search?q=${_search_text}`);
  };

  const handleBookmarkClick = async (book) => {
    const result = await toggleEbookBookmark(book.id);
    setEbookSet((prev) =>
      prev.map((b) =>
        b.id === book.id
          ? {
              ...b,
              is_favorite: result.is_favorite,
              favorite_count: result.favorite_count,
            }
          : b,
      ),
    );
  };

  return (
    <div className='search-container'>
      <Helmet>
        <title>{search_text || '검색'}</title>
      </Helmet>
      <div style={{ width: '100%', height: 1, position: 'absolute', top: 34, left: 0 }} />
      {!is_tablet_or_mobile && !search && (
        <div className='search-header-wrap'>
          <div className='td-wrapper-v2'>
            <div>
              <p className='search-title'>관심있는 클래스를 검색해보세요</p>
              <div className={'search-input-wrap'}>
                <div className={'search-border'} />
                <input
                  value={search_text}
                  onChange={onChangeSearchText}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      doSearch(search_text);
                    }
                  }}
                  placeholder={
                    is_tablet_or_mobile ? '관심있는 클래스를 검색해보세요' : '검색어를 입력해주세요'
                  }
                  className={'search-input'}
                  type='text'
                />
                <button
                  className={'search-btn'}
                  style={{
                    background: `url("${process.env.REACT_APP_IMG_URL}/static/png/common/icon-search.png") no-repeat center center/cover`,
                  }}
                  onClick={() => {
                    doSearch(search_text);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!search_text && is_tablet_or_mobile && (
        <ul className='search-nav-set'>
          <li style={{ fontWeight: 'bold' }}>
            <Link to='/overview/all'>클래스</Link>
          </li>
          <li style={{ fontWeight: 'bold' }}>
            <Link to='/ebook'>전자책</Link>
          </li>
          <li style={{ fontWeight: 'bold' }}>
            <Link to='/bgd'>빡공단</Link>
          </li>
          {/* //TODO:  2023-09-4, 17:47 -kwak: 0원 수강 비활성화 */}
          {/*<li style={{fontWeight: "bold"}}>
                        <Link to="/package/262">0원 수강</Link>
                    </li>*/}
          <li style={{ fontWeight: 'bold' }}>
            <Link to='/service/event'>이벤트</Link>
          </li>
          <li style={{ fontWeight: 'bold' }}>
            <Link to='/service/notice'>공지사항</Link>
          </li>
          <li style={{ fontWeight: 'bold' }}>
            <a href='https://tally.so/r/nW8JaR'>클래스 판매등록 신청</a>
          </li>
          <li style={{ fontWeight: 'bold' }}>
            <a target='_blank' rel='noreferrer' href={'https://bearu-ebook.oopy.io'}>
              전자책 작가 신청
            </a>
          </li>
        </ul>
      )}
      <div
        style={{
          minHeight: search_text ? (is_tablet_or_mobile ? '160px' : '400px') : '0',
          padding: '0 16px',
        }}
      >
        {!request_now ? (
          !search_course_set.length &&
          !search_ebook_set.length &&
          !search_package_set.length &&
          !search_kit_set.length &&
          !search_curation_set.length ? (
            <div className='search-content-wrap'>
              <div className='td-wrapper-v2'>
                <div className='search-keyword-wrapper empty-set'>
                  {!search_course_set.length && search && (
                    <>
                      <p className='search-title'>
                        <span>"{search}"</span>에 대한 검색 결과가 없습니다
                      </p>
                      <div className='empty-img'>
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/search/logo_bear_b1.svg`}
                          alt={'검색결과가 없습니다.'}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            search && (
              <div className='search-content-wrap'>
                <div className='td-wrapper-v2 search'>
                  <div className='search-keyword-wrapper result'>
                    <p className='search-title'>
                      <span>"{search}"</span>에 대한 검색 결과입니다.
                    </p>
                  </div>
                </div>
              </div>
            )
          )
        ) : null}

        {search_package_set.length ? (
          <div className='search-content-wrap'>
            <div className='td-wrapper-v2 search'>
              <p className='search-result'>패키지</p>
              <div className='course-list overview-list'>
                {search_package_set.map((product) => {
                  return <PackageCard key={product.id} product={product} />;
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ display: 'block', width: '100%', height: '1px' }} ref={package_load_ref} />

        {search_course_set.length ? (
          <div className='search-content-wrap'>
            <div className='td-wrapper-v2 search'>
              <p className='search-result'>클래스</p>
              <div className='course-list overview-list'>
                {search_course_set.map((course) => {
                  return <CourseCard2 key={course.id} course={course} />;
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ display: 'block', width: '100%', height: '1px' }} ref={course_load_ref} />

        {search_ebook_set.length ? (
          <div className='search-content-wrap ebook-wrap'>
            <div className='td-wrapper-v2 search'>
              <p className='search-result'>전자책</p>
              <div className='course-list ebook-list'>
                <BookList bookList={search_ebook_set} onBookmarkClick={handleBookmarkClick} />
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ display: 'block', width: '100%', height: '1px' }} ref={ebook_load_ref} />

        {search_kit_set.length ? (
          <div className='search-content-wrap'>
            <div className='td-wrapper-v2 search'>
              <p className='search-result'>키트</p>
              <div className='course-list overview-list'>
                {search_kit_set.map((kit) => {
                  return <KitCard key={kit.id} kit={kit} />;
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ display: 'block', width: '100%', height: '1px' }} ref={kit_load_ref} />

        {search_curation_set.length ? (
          <div className='search-content-wrap'>
            <div className='td-wrapper-v2 search'>
              <p className='search-result'>큐레이션</p>
              <div className='course-list search-curation'>
                {search_curation_set.map((curation) => {
                  return <CurationCard key={curation.id} curtaion={curation} />;
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ display: 'block', width: '100%', height: '1px' }} ref={curation_load_ref} />
      </div>
      <div className='search-popular-wrap'>
        <div className='td-wrapper-v2 search'>
          <PopularList2 list_type={1} />
        </div>
      </div>
    </div>
  );
};

export default Search;
