import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import SwiperCore, { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';
import { useMediaQuery } from 'react-responsive';

import { convert_redirect_url, replace_cloudfront_url } from '../../../common';
import CommonModel from '../../../model/CommonModel';
import '../../../assets/scss/component/class_slider.scss';

SwiperCore.use([Navigation, Autoplay, Mousewheel, Pagination]);

// slide_type = 1 : 클래스 슬라이드 배너
const propTypes = {
  slide_type: PropTypes.number,
  option: PropTypes.object,
};

const ClassSlider = (props) => {
  const [banner_set, setBannerSet] = useState([]);
  const swiper_container_ref = useRef(null);
  const _is_mount = useRef(false);
  const [change_background_color, setChangeBackgroundColor] = useState('rgba(237,237,237,1)');
  const [background_color_set, setBackgroundColorSet] = useState();
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    _is_mount.current = true;
    if (props.slide_type === 1) {
      CommonModel.bannerList({ page: 1, size: 5, banner_type_id: 1 }).then(({ banner_set }) => {
        if (_is_mount.current) {
          swiper_container_ref.current.style.minHeight = 'inherit';
          setBannerSet(banner_set);
          setBackgroundColorSet(banner_set.map((banner) => banner.theme_color_hex));
        }
      });
    }
    return () => {
      _is_mount.current = false;
    };
  }, []);

  const onSlideChangeTransitionStart = (e) => {
    let current_index = banner_set.findIndex((value, index) => e.realIndex === index);

    for (let i = 0; i < banner_set.length; i++) {
      setChangeBackgroundColor(background_color_set[current_index]);
    }
  };

  return (
    <div
      className={is_tablet_or_mobile && props.slide_type === 1 ? 'mo-main-slide-wrap' : ''}
      style={{
        backgroundColor:
          props.slide_type === 1
            ? change_background_color
              ? change_background_color
              : background_color_set[background_color_set.length - 4]
            : {},
      }}
    >
      <Swiper
        ref={swiper_container_ref}
        {...props.option}
        onSlideChangeTransitionStart={(e) => {
          if (props.slide_type === 1) onSlideChangeTransitionStart(e);
        }}
      >
        {banner_set.map((banner, index) => {
          if (is_tablet_or_mobile && props.slide_type === 1) {
            return (
              <SwiperSlide key={index}>
                <div
                  className='main-slider'
                  style={{ backgroundColor: props.slide_type === 1 ? banner.theme_color_hex : {} }}
                >
                  <a
                    href={
                      props.slide_type === 1
                        ? convert_redirect_url(banner.redirect_url)
                        : `/course/${banner.id}`
                    }
                    className='promotion-click'
                  >
                    <div className='td-wrapper-v2'>
                      <div className='segment'>
                        <h2>{banner.title}</h2>
                      </div>
                      <div className='dimmed-layer' />
                      <div
                        className='image'
                        style={{
                          background:
                            'url(' +
                            (props.slide_type === 1
                              ? banner.image_url
                              : replace_cloudfront_url(banner.main_thumbnail_image_url)) +
                            ') no-repeat center center/cover',
                        }}
                      />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            );
          } else {
            return (
              <SwiperSlide key={index}>
                <div
                  className='main-slider'
                  style={{ backgroundColor: props.slide_type === 1 ? banner.theme_color_hex : {} }}
                >
                  <a
                    href={
                      props.slide_type === 1
                        ? convert_redirect_url(banner.redirect_url)
                        : `/course/${banner.id}`
                    }
                    className='promotion-click'
                  >
                    <div className='td-wrapper-v2'>
                      <div className='segment'>
                        <h2>{banner.title}</h2>
                        <h3>{banner.description}</h3>
                      </div>
                      <div
                        className='image'
                        style={{
                          background:
                            'url(' +
                            (props.slide_type === 1
                              ? banner.image_url
                              : replace_cloudfront_url(banner.main_thumbnail_image_url)) +
                            ') no-repeat center center/cover',
                        }}
                      />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </div>
  );
};

ClassSlider.propTypes = propTypes;

export default ClassSlider;
