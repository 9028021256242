import React, { useEffect, useState } from "react";
import { MYPAGE_FAVORITE_EBOOK } from "../../_collections/mypage";
import AccountModel from "../../../model/AccountModel";
import EmptyList from "./EmptyList";
import LoadingSpinner from "../../_common/component/LoadingSpinner";
import EbookCard from "../component/EbookCard";
import { Link } from "react-router-dom";
import useEbookBookmark from "../../../hook/useEbookBookmark";

const MyEbookList = () => {
    const [loading, setLoading] = useState(false);
    const [myEbookList, setMyEbookList] = useState(null);
    const { toggleEbookBookmark } = useEbookBookmark();

    const requestUpdateCourseList = async () => {
        setLoading(true);
        const { ebook_favorite_set } = await AccountModel.myFavoriteEbookList();
        setMyEbookList(ebook_favorite_set.map(ebook => ({
            ...ebook,
            is_favorite: true,
        })));
        setLoading(false);
    };

    useEffect(() => {
        requestUpdateCourseList();
    }, []);

    const handleBookmarkClick = async (ebookId) => {
        const result = await toggleEbookBookmark(ebookId);
        setMyEbookList(prev => prev.map(ebook => ({
            ...ebook,
            is_favorite: ebook.ebook_id === ebookId ? result.is_favorite : ebook.is_favorite,
        })));
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    if (myEbookList?.length === 0) return <EmptyList category={MYPAGE_FAVORITE_EBOOK} />;

    return (
        <div className="my-ebook-list">
            <div className="my-ebook-list-inner">
                {myEbookList?.map((ebook) => (
                  <div className="ebook-item">
                      <Link to={`/ebook/${ebook.ebook_id}`}>
                          <EbookCard
                            thumbnailUrl={ebook.main_image_url}
                            title={ebook.title}
                            author={ebook.author_name}
                            category={ebook.category_name}
                            description={`총 ${ebook.total_pages}페이지`}
                            originPrice={ebook.original_price}
                            price={ebook.price}
                            hasBookmarkButton
                            isBookmarked={ebook.is_favorite}
                            isMine={false}
                            onBookmarkClick={() => handleBookmarkClick(ebook.ebook_id)}
                          />
                      </Link>
                  </div>
                ))}
            </div>
        </div>
    );
};

export default MyEbookList;
