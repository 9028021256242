import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {stored_static_code} from "../../../redux/common/action";
import {useMediaQuery} from "react-responsive";
import PropTypes from "prop-types";
import CommonModel from "../../../model/CommonModel";
import ShippingProgress from "../../account/component/ShippingProgress";
import moment from "moment";

const propTypes = {
    kit: PropTypes.object.isRequired,
};

const TrackShippingModal = (props) => {
    //Dialog
    const dialog_props = props.dialog_props || {};
    const dialog_utils = props.dialog_utils || {};

    //Redux
    const dispatch = useDispatch();
    const CommonStore = useSelector((state) => state.CommonStore);

    //MediaQuery
    const is_mobile = useMediaQuery({query: "(max-width: 480px)"});

    //Shipping State
    const {kit} = props;
    const [shipper_set, setShipperSet] = useState([]);
    const [ship_info_set] = useState([
        {name: "배송사", value: ""},
        {name: "송장번호", value: kit?.ship_info.shipping_number},
        {name: "배송지", value: kit?.ship_info.ordered_address},
        {name: "배송지 코드", value: kit?.ship_info.ordered_address_code},
        {name: "수신인", value: kit?.ship_info.ordered_user_name},
        {name: "수신인 연략처", value: kit?.ship_info.ordered_user_phone},
        {name: "요청사항", value: kit?.ship_info.shipping_requested_message},
    ]);

    useEffect(() => {
        if (!CommonStore.static_code.shipper_set.length) {
            CommonModel.staticCode({code_name: "shipper_set"}).then(({code_set}) => {
                dispatch(stored_static_code("shipper_set", code_set));
                setShipperSet(code_set);
            });
        } else {
            setShipperSet(CommonStore.static_code.shipper_set);
        }
    }, []);

    const onCloseModalBtn = () => {
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    };

    return (
        <div className="td-modal track-shipping-modal">
            <div className="td-dimmed" onClick={onCloseModalBtn} />
            <div className="modal-content">
                <div className="modal-header">
                    {is_mobile ? (
                        <>
                            <h3>배송조회</h3>
                            <div
                                className="modal-close-btn"
                                style={{
                                    background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
                                }}
                                onClick={onCloseModalBtn}
                            />
                        </>
                    ) : (
                        <>
                            <h3>배송조회</h3>
                            <p>{kit.kit_info.title}</p>
                        </>
                    )}
                </div>
                <div className="modal-section">
                    {is_mobile && <h4 className="mo-ship-title">{kit.kit_info.title}</h4>}
                    <div className="track-shipping-wrap">
                        <h6>{moment(kit.purchased_time).format("YY.MM.DD")} 구매</h6>
                        <ShippingProgress shipping_status={kit.ship_info.shipping_status} />
                        <h5>구매내역</h5>
                        <p>
                            · (옵션) {kit.kit_info.title}{" "}
                            {kit.ordered_count - kit.refunded_count > 1 &&
                                `${kit.ordered_count - kit.refunded_count}개`}
                        </p>
                    </div>
                    <div className="shipping-info-list">
                        {/* @TODO 혁님은 봐주십쇼.. 키트상품 리스트 title 클릭으로 배송 info 펼쳐지게끔 하는 작업..해주세욧..ㅎㅎ */}
                        {kit.ship_info.digital_content_info_html ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: kit.ship_info.digital_content_info_html,
                                }}
                            />
                        ) : (
                            <>
                                {ship_info_set.map((ship_info, index) => {
                                    let shipper_name = "";
                                    for (let i = 0; i < shipper_set.length; i++) {
                                        if (kit.ship_info.shipper === shipper_set[i].id) {
                                            shipper_name = shipper_set[i].name;
                                        }
                                    }
                                    if (index === 0) {
                                        ship_info.value = shipper_name;
                                    }
                                    return (
                                        <div key={index} className="shipping-info-item">
                                            <h5>· {ship_info.name}</h5>
                                            <p>{ship_info.value}</p>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
                <div className="confirm-btn-wrap">
                    <div className="confirm-btn td-btn" onClick={onCloseModalBtn}>
                        확인
                    </div>
                </div>
            </div>
        </div>
    );
};

TrackShippingModal.propTypes = propTypes;

export default TrackShippingModal;
