import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import SelectBox from '../../_common/component/SelectBox';
import { alert_and_redirect, init_comma, select } from '../../../common';
import PurchaseModel from '../../../model/PurchaseModel';
import { set_tmp } from '../../../redux/common/action';
import { useDialogContext } from '../../_common/dialog/DialogContextProvider';

// invoice_type = 1 / 상세페이지 내 invoice
// invoice_type = 2 / 기간연장, 구매팝업(모달) 내 invoice
const propTypes = {
  course: PropTypes.object.isRequired,
  invoice_type: PropTypes.number.isRequired,
  setIsPurchase: PropTypes.func,
  seat_count: PropTypes.number,
  event: PropTypes.any,
  on_selected_sale: PropTypes.func,
  error_message: PropTypes.string, // purchase 로 이동할수없게됨
};

const CourseInvoice = (props) => {
  const { course, invoice_type, event } = props;
  const { confirm, alert } = useDialogContext();
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);

  const [total_price, setTotalPrice] = useState(0);
  const [discount_set, setDiscountSet] = useState([]);
  const [kit_set, setKitSet] = useState([]);
  const [sale_set, setSaleSet] = useState(null);
  const [_is_load, setIsLoad] = useState(false);
  const [_is_first_price_init, setIsFirstPriceInit] = useState(false); // 모바일 초기 가격 보여주기용
  const [purchase_price, setPurchasePrice] = useState(null);
  const _is_mount = useRef(false);

  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  let select_kit_set = [];

  useEffect(() => {
    let validate_kit_set = [];
    for (let i = 0; i < kit_set.length; i++) {
      let check = false;
      for (let j = 0; j < validate_kit_set.length; j++) {
        if (validate_kit_set[j].id === kit_set[i].id) {
          validate_kit_set[j].order_count++;
          check = true;
          break;
        }
      }
      if (!check) {
        validate_kit_set.push({ id: kit_set[i].id, order_count: 1 });
      }
    }

    if (_is_load && sale_set && course) {
      PurchaseModel.priceValidate({
        currency_code: 'KRW',
        course_sale_info_id: invoice_type === 2 ? sale_set.course_sale_info_id : sale_set.id,
        extend_month: invoice_type === 2 ? sale_set.extend_month : null,
        kit_set: validate_kit_set,
        event_code: event?.event_code,
      }).then(async ({ code, discount_set, total_price, purchase_price }) => {
        if (_is_mount.current) {
          if (code === 200) {
            setTotalPrice(total_price);
            setPurchasePrice(purchase_price);
            setDiscountSet(discount_set);
            if (!_is_first_price_init && invoice_type === 1) {
              props.set_purchase_price(purchase_price);
              props.set_discount_price(discount_set);
              setIsFirstPriceInit(true);
            }
          } else if (code === 4805) {
            await alert('최대 수량 이상 선택할 수 없습니다.');
          } else if (code === 4701) {
            await alert('품절된 상품입니다.');
            setKitSet((prevState) => {
              prevState.pop();
              return [...prevState];
            });
          } else if (code === 4812) {
            alert_and_redirect(
              alert,
              props.history,
              '해당 이벤트는 첫 구매시에만 참여가능합니다.',
              `/course/${course.id}`,
            );
          } else if (code === 4813) {
            alert_and_redirect(
              alert,
              props.history,
              '이미 참여하신 이벤트입니다.',
              `/course/${course.id}`,
            );
          } else {
            await alert('잘못된 요청입니다.');
          }
        }
      });
    }
  }, [course, kit_set, sale_set, event, _is_load]);

  let sale = [];
  useEffect(() => {
    _is_mount.current = true;
    sale = course?.sale_info_set[0];
    setSaleSet(sale);
    props.on_selected_sale && props.on_selected_sale(sale);

    let default_kit = [];
    course.kit_info_set.forEach((kit_info) => {
      if (kit_info.is_default_option) {
        default_kit.push(kit_info);
      }
    });
    setKitSet(default_kit);
    setIsLoad(true);

    return () => {
      _is_mount.current = false;
    };
  }, [course]);

  const onChange = (target_id, target_name) => {
    if (target_name === 'kit') {
      if (course.only_single_kit && kit_set.length) {
        let select_kit = course?.kit_info_set.find((kit) => kit.id === target_id.value);
        setKitSet([select_kit]);
      } else {
        let already_kit = kit_set.find((kit) => kit.id === target_id.value);
        if (!already_kit) {
          let select_kit = course?.kit_info_set.find((kit) => kit.id === target_id.value);
          select_kit_set.push(select_kit);
          setKitSet((state) => [...state, ...select_kit_set]);
        }
      }
    } else {
      let select_sale = course?.sale_info_set.find((sale) => sale.id === target_id.value);
      setSaleSet(select_sale);
      props.on_selected_sale && props.on_selected_sale(select_sale);
    }
  };

  const onKitRemoveBtn = (target_id) => {
    kit_set.splice(
      kit_set.findIndex((kit) => kit.id === target_id),
      1,
    );
    setKitSet((state) => [...state, ...select_kit_set]);
  };

  const onPurchase = async () => {
    if (AccountStore) {
      if (!AccountStore.info?.name || !AccountStore.info?.email || !AccountStore.info?.phone) {
        if (await confirm('결제를 위해 개인정보를 입력해주세요.')) {
          props.history.push({
            pathname: '/user/info',
            state: {
              redirect_url: `${props.location.pathname}${props.location.search}`,
            },
          });
        }
      } else {
        if (course.is_require_with_kit && !kit_set.length) {
          await alert('최소 하나의 옵션상품을 선택 하셔야합니다.');
        } else {
          dispatch(
            set_tmp('purchase', {
              purchase_type: 'course',
              is_extend_course: invoice_type === 2,
              title:
                invoice_type === 1
                  ? `[${course.short_title}] ${course.title}`
                  : `[${course.short_title}] ${course.description}`,
              can_coupon: event ? event.can_use_with_coupon : !course.cannot_use_coupon,
              purchase_item: {
                sale_id: invoice_type === 2 ? sale_set.course_sale_info_id : sale_set.id,
                course_id: course.id,
                extend_month: invoice_type === 2 ? sale_set.extend_month : null,
                short_title: sale_set.sale_short_name,
                rental_time_in_day: sale_set.rental_time_in_day,
                description: invoice_type === 1 ? course.description : null,
                original_price: sale_set.price_info[0].original_price,
              },
              event_code: event?.event_code,
              kit_set: kit_set,
              extend_month: invoice_type === 2 ? sale_set.extend_month : null,
              gtm_data: {
                pnm: course.title.replace(/\r\n/g, ' '),
                pid: `c${course.id.toString().padStart(8, '0')}`,
                ppc: sale_set?.price_info[0]?.price,
                ppb: course.brand_name,
                pca: course.category_name,
              },
              expire_date: new Date(),
            }),
          );
          let need_move_to_purchase = true;
          if (props.error_message) {
            need_move_to_purchase = await confirm(
              `${props.error_message}\n결제페이지로 이동할까요?`,
            );
          }

          if (need_move_to_purchase) {
            props.moveToPurchase && props.moveToPurchase();
          }

          ReactPixel.trackCustom('결제 버튼 클릭', {
            course_title: course?.title,
          });
        }
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  const PurchaseDescription = useCallback(() => {
    let _purchase_price = purchase_price || 0;
    return (
      <>
        <div className='total-price-wrap'>
          <h4>
            결제 금액
            {sale_set?.rental_time_in_day / 30 > 1
              ? ` (${Math.round(sale_set?.rental_time_in_day / 30)}개월로 분할 결제 시)`
              : ''}
          </h4>
          {sale_set ? (
            <>
              {invoice_type === 1 && (
                <h6>
                  {sale_set.rental_time_in_day / 30 > 1 ? '월' : ''}{' '}
                  {init_comma(
                    sale_set.rental_time_in_day >= 30
                      ? Math.floor(_purchase_price / (sale_set.rental_time_in_day / 30))
                      : _purchase_price,
                  )}
                  원
                </h6>
              )}
              {invoice_type === 2 && <h6>{init_comma(Math.floor(_purchase_price))}원</h6>}
            </>
          ) : (
            <h6>월 0원</h6>
          )}
        </div>
        {select(
          invoice_type === 1 && (sale_set?.rental_time_in_day || 0) >= 30,
          <p>*수강기간 기준 월 금액, 무이자 최대 12개월 가능</p>,
        )}
      </>
    );
  }, [invoice_type, purchase_price, sale_set]);

  return (
    <div
      className='invoice-wrap gtm-data'
      data-ai-pnm={course?.title.replace(/\r\n/g, ' ')}
      data-ai-pid={`c${course?.id.toString().padStart(8, '0')}`}
      data-ai-ppc={course?.min_price}
      data-ai-ppb={course?.brand_name}
      data-ai-pca={course?.category_name}
    >
      <div
        className='invoice-title-wrap'
        style={!is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
      >
        <div className='invoice-title'>
          {invoice_type === 1 && (
            <>
              {course?.course_sale_status_id === 1 ? (
                <span>오픈예정 ・ {course.category_name}</span>
              ) : (
                <span>VOD ・ {course.category_name}</span>
              )}
            </>
          )}

          {course?.course_sale_status_id && !course?.extend_sale_info && (
            <img
              className={`course-detail-id-${course?.id} td-favorite ${course?.is_favorite ? '' : ' enp_mobon_wish'}`}
              src={
                course?.is_favorite
                  ? `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_bookmark_new_1.svg`
                  : `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_bookmark_new_0.svg`
              }
              onClick={props.onFavorite}
              alt='저장하기'
            />
          )}
        </div>

        <p>{course?.title}</p>
      </div>

      <div
        className='mo-invoice-title-wrap'
        style={is_tablet_or_mobile && invoice_type === 1 ? { display: '' } : { display: 'none' }}
      >
        <h3>구매하기</h3>
        <button
          onClick={() => {
            props.setIsPurchase(false);
          }}
        >
          닫기
        </button>
      </div>

      {course?.course_sale_status_id === 1 ? (
        <div className='coming-soon-btn-wrap'>
          <div className='coming-soon-btn'>오픈예정</div>
        </div>
      ) : !course?.is_mine ? (
        <>
          <div className='invoice-content-wrap td-scroll'>
            <div className='option-select-wrap'>
              <SelectBox
                select_type={2}
                name='sale'
                select_set={course?.sale_info_set}
                changeListener={onChange}
                placeholder={course?.sale_info_set[0]?.sale_short_name || '판매상품 없음'}
              />

              {course.kit_info_set.length ? (
                <div className='kit-option-select-wrap'>
                  <h4>옵션 상품</h4>
                  <SelectBox
                    select_type={3}
                    name='kit'
                    select_set={course?.kit_info_set}
                    placeholder={
                      course?.kit_info_set.length !== 0 ? '옵션상품을 선택하세요.' : '옵션상품 없음'
                    }
                    changeListener={onChange}
                  />
                </div>
              ) : null}

              <div className='img-group'>
                {course.include_vod ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/course-ticket-2.png`}
                    alt='수강권'
                  />
                ) : null}
                {course.include_secret_note ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/masternote-2.png`}
                    alt='비법노트 제공'
                  />
                ) : null}
                {course.include_document ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/course-data-2.png`}
                    alt='강의자료 포함'
                  />
                ) : null}
              </div>
            </div>

            <div className='invoice-content'>
              <div className='purchase-list-wrap'>
                <h4>구매 목록</h4>
                <ul className='selected-list'>
                  {sale_set && (
                    <li>
                      <h5>· {sale_set.sale_short_name}</h5>
                      <h6>
                        {course && (
                          <>
                            <span>
                              {init_comma(sale_set.price_info.map((price) => price.original_price))}
                            </span>
                            {init_comma(sale_set.price_info.map((price) => price.price))}원
                          </>
                        )}
                      </h6>
                    </li>
                  )}
                  {kit_set.map((kit, index) => {
                    return (
                      <li className='kit-list' key={index}>
                        <div className='kit-title'>
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-delete.png`}
                            alt='삭제'
                            onClick={() => {
                              onKitRemoveBtn(kit.id);
                            }}
                          />
                          <h5>{kit.title}</h5>
                        </div>
                        <h6 className='kit-subtitle'>
                          {init_comma(kit.price_info.map((price) => price.our_sale_price))}원
                        </h6>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className='purchase-info'>
                <ul>
                  <li>
                    <h5>실 구매가 (일시불 결제 시)</h5>
                    <h6>{course && init_comma(total_price)}원</h6>
                  </li>

                  {discount_set
                    .filter((_discount) => _discount.price !== 0)
                    .map((discount, index) => {
                      return (
                        <li key={index}>
                          <h5>{discount.title}</h5>
                          <h6 className='sale-price'>{`-${init_comma(discount.price)}원`}</h6>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className='total-price'>
                <PurchaseDescription />
              </div>
            </div>
          </div>

          <div className='purchase-btn-wrap'>
            {sale_set ? (
              <button className='td-btn' onClick={onPurchase}>
                수강 신청
              </button>
            ) : null}
          </div>
        </>
      ) : (
        <div className='is-mine-course-btn-wrap'>
          <Link to={`/course/${course.id}/viewer`} className='td-btn'>
            강의 바로가기
          </Link>
        </div>
      )}
    </div>
  );
};

CourseInvoice.propTypes = propTypes;

export default withRouter(CourseInvoice);
