import MyCouponList2 from "../account/section/MyCouponList2";
import MyCourseList2 from "../account/section/MyCourseList2";
import MyFavoriteList2 from "../account/section/MyFavoriteList2";
import MyShippingList2 from "../account/section/MyShippingList2";
import MyEbookList from "../account/section/MyEbookList";
import MyEbookFavoriteList from "../account/section/MyEbookFavoriteList";

export const MYPAGE_COURSE = "course";
export const MYPAGE_FAVORITE = "favorite";
export const MYPAGE_EBOOK = "ebook";
export const MYPAGE_FAVORITE_EBOOK = "favorite-ebook";
export const MYPAGE_COUPON = "coupon";
export const MYPAGE_SHIPPING = "shipping";

export const MYPAGE_COURSE_KO = "내 클래스";
export const MYPAGE_FAVORITE_KO = "저장한 클래스";
export const MYPAGE_EBOOK_KO = "내 전자책";
export const MYPAGE_FAVORITE_EBOOK_KO = "저장한 전자책";
export const MYPAGE_COUPON_KO = "쿠폰함";
export const MYPAGE_SHIPPING_KO = "배송조회";

export const createCategoryInfo = (
  course_count,
  course_favorite_count,
  ebook_count,
  ebook_favorite_count,
  coupon_count,
  ship_count,
  )=>{
    return [
        {
            name: MYPAGE_COURSE_KO,
            count: course_count || 0,
            pathname: MYPAGE_COURSE,
            component: MyCourseList2,
        },
        {
            name: MYPAGE_FAVORITE_KO,
            count: course_favorite_count || 0,
            pathname: MYPAGE_FAVORITE,
            component: MyFavoriteList2,
        },
        {
            name: MYPAGE_EBOOK_KO,
            count: ebook_count || 0,
            pathname: MYPAGE_EBOOK,
            component: MyEbookList,
        },
        {
            name: MYPAGE_FAVORITE_EBOOK_KO,
            count: ebook_favorite_count || 0,
            pathname: MYPAGE_FAVORITE_EBOOK,
            component: MyEbookFavoriteList,
        },
        {
            name: MYPAGE_COUPON_KO,
            count: coupon_count || 0,
            pathname: MYPAGE_COUPON,
            component: MyCouponList2,
        },
        {
            name: MYPAGE_SHIPPING_KO,
            count: ship_count || 0,
            pathname: MYPAGE_SHIPPING,
            component: MyShippingList2,
        },
    ];
}
