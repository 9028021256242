import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";

const propTypes = {
    on_data_changed: PropTypes.func, // text, validated
    force_error: PropTypes.bool, // error 상황 처리
};

const AgreeCheckList = (props) => {
    const is_agree_check = useRef(false);
    const [is_error, setIsError] = useState(false);
    const [agree_list, setAgreeList] = useState([
        {
            title: "이용약관",
            url: "/service/terms",
            is_check: false,
        },
        {
            title: "개인정보처리방침",
            url: "/service/privacy",
            is_check: false,
        },
    ]);

    useEffect(() => {
        if (!props.force_error) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [props.force_error]);

    const onAgreeListCheck = (index) => {
        agree_list[index].is_check = !agree_list[index].is_check;
        setAgreeList([...agree_list]);

        is_agree_check.current = Object.values(agree_list).every((value) => value.is_check);
    };

    return (
        <div className="agree-check-wrap">
            <ul>
                {agree_list.map((agree, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => {
                                onAgreeListCheck(index);
                                setIsError(false);
                                props.on_data_changed && props.on_data_changed(is_agree_check.current);
                            }}>
                            {agree.is_check ? (
                                <img
                                    src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_check_after.svg`}
                                    alt="체크 활성화"
                                />
                            ) : (
                                <p className={"agree-icon" + (is_error ? " error" : "")} tabIndex={0} />
                            )}
                            <p>
                                [필수]
                                <a target="_blank" href={agree.url} rel="noreferrer">
                                    {agree.title}
                                </a>
                                에 동의합니다.
                            </p>
                        </li>
                    );
                })}
            </ul>
            {is_error && <p className="default-msg">필수약관에 동의해주세요</p>}
        </div>
    );
};

AgreeCheckList.propTypes = propTypes;

export default AgreeCheckList;
