import React, { useEffect } from 'react';
import '../../assets/scss/page/terms_policy.scss';
import { useDispatch } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { Helmet } from 'react-helmet';

const DataDeleteTerms = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_header_type(0, 2, { title: '데이터 삭제 약관' }));
    dispatch(set_footer_type(0));
    props.ready();
  }, []);

  return (
    <div className='terms-policy-container'>
      <Helmet>
        <title>데이터 삭제에 대한 약관 안내</title>
      </Helmet>
      <p className='title pc-visible'>데이터 삭제에 대한 약관 안내</p>
      <ul className='terms-list'>
        <li className='terms-con'>
          <h4 className='terms-subtitle'>
            사용자 데이터에 액세스하는 앱은 사용자가 데이터 삭제를 요청할 방법을 제공해야 합니다.
          </h4>
        </li>
        <li className='terms-con'>
          <h4 className='terms-subtitle'>[개인정보에 안전관리]</h4>
          <div className='terms-text-wrap'>
            <p className='terms-text'>
              회사는 수집한 개인정보 관리에 대해서 기술적·조직적으로 엄중한 보안대책을 강구하고
              있습니다.
            </p>
            <p className='terms-text'>
              회사는 고객이 계정 삭제를 신청하지 않는 한, 원칙적으로 고객의 정보 대부분을
              보유합니다. 고객으로부터 계정 삭제 신청이 있는 경우, 일정기간 보유한 후, 적용법 및
              사내규정에 따라 고객 정보를 삭제합니다.
            </p>
          </div>
        </li>
        <li className='terms-con'>
          <h4 className='terms-subtitle'>[개인정보에 데이터 삭제 방침]</h4>
          <div className='terms-text-wrap'>
            <p className='terms-text'>
              1. 사용자가 데이터 삭제를 요청할 경우 '주식회사 텐덤'에서 제공하는 기술적 수단을 통해
              사용자의 데이터 삭제 요청을 수신하였음을 확인하고 요청에 대한 결과를 제공해야합니다.
            </p>
            <p className='terms-text'>
              2. 앱 이용자가 최초 요구 시 특정한 보유기간 동안 '개인정보'를 보유할 수 있습니다. 단,
              '이용자'가 삭제를 명시적으로 요구할 경우 수집한 '개인정보'를 즉시 삭제합니다.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DataDeleteTerms;
