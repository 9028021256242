import React, {useEffect, useState} from "react";
import Lottie from "react-lottie";
import {gift_bear_option} from "../../../constant/option";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import EventModel from "../../../model/EventModel";
import {useMount} from "../../../util/BearuHooks";

const PopUpButton = (props) => {
    const [isMount] = useMount();
    const history = useHistory();
    const CommonStore = useSelector((state) => state.CommonStore);
    const [event_code, setEventCode] = useState(null);

    const pop_up_btn = CommonStore.header.header_set?.pop_up_btn;
    const tooltip_msg = "이거 한번 보구강";

    const requestGetTimeEvent = async () => {
        const {data} = await EventModel.eventTimeBanner();
        if (isMount()) {
            setEventCode(data?.event_code);
        }
    };

    useEffect(() => {
        requestGetTimeEvent();
    }, []);

    if (!pop_up_btn) {
        return null;
    } else {
        if (event_code) {
            return (
                <div
                    className="pop-up-button"
                    onClick={() => {
                        history.push(`/event/time/${event_code}`);
                    }}>
                    <div className="gift-bear-lottie">
                        <Lottie options={gift_bear_option} />
                    </div>

                    <div className="tooltip-wrap">
                        <div className="tooltip">
                            {tooltip_msg}
                            <div className="polygon" />
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
};

export default PopUpButton;
