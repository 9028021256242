import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import '../../assets/scss/page/master.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from 'react-helmet';
import { useObserver } from '../../util/BearuHooks';
import SwiperCore, { Autoplay } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

SwiperCore.use([Autoplay]);

const Master = (props) => {
  const is_mobile = useMediaQuery({ query: '(max-width: 480px)' });
  const dispatch = useDispatch();
  const with_ref = useRef(null);
  const AccountStore = useSelector((state) => state.AccountStore);
  const [is_sticky, setIsSticky] = useState(false);
  const master_set = [
    {
      desc: (
        <p className='master-desc'>
          고액 컨설팅 강의
          <br />돈 쓰지마세요.
        </p>
      ),
      info: '-아마존셀링 안지수',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/master/marster1.jpg`,
    },
    {
      desc: (
        <p className='master-desc'>
          오늘도 값진 기회를
          <br />
          놓치고 계신가요?
        </p>
      ),
      info: '-포토샵 강아유',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/master/marster2.jpg`,
    },
    {
      desc: (
        <p className='master-desc'>
          더 이상 야근하는
          <br />
          회사생활은 그만
        </p>
      ),
      info: '-파워포인트, 엑셀 이광희',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/master/marster3.jpg`,
    },
    {
      desc: (
        <p className='master-desc'>
          단돈 10만원으로
          <br />
          200만원까지
        </p>
      ),
      info: '-스마트스토어 조남규',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/master/marster4.jpg`,
    },
    {
      desc: (
        <p className='master-desc'>
          왜 디지털마케팅에
          <br />
          집중할까?
        </p>
      ),
      info: '-디지털마케터 곽근봉',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/master/marster6.jpg`,
    },
    {
      desc: (
        <p className='master-desc'>
          유 퀴즈 온 더 블록
          <br />
          일러스트 작가
        </p>
      ),
      info: '-디지털드로잉 127작가',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/master/marster7.jpg`,
    },
  ];
  const [is_disable, setIsDisable] = useState(false);
  const { confirm, alert } = useDialogContext();

  const disable_ref = useObserver(
    {},
    () => {
      setIsDisable(true);
    },
    () => {
      setIsDisable(false);
    },
    [],
  );

  const header_nav_control_ref = useObserver(
    {},
    () => {
      setIsSticky(false);
    },
    () => {
      setIsSticky(true);
    },
    [],
  );

  useEffect(() => {
    dispatch(
      set_header_type(1, null, {
        no_footer: true,
      }),
    );
    dispatch(set_footer_type(0));
    props.ready();
  }, []);

  const scrollWith = () => {
    with_ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onApplyBtnClick = async () => {
    try {
      if (AccountStore) {
        props.history.push({
          pathname: '/service/survey/R9ra9qcD',
          state: { title: `${document.title.toString()} 지원서` },
        });
      } else {
        if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
          props.history.push({
            pathname: '/user/signin',
            state: {
              step: 1,
              redirect_url: `${props.location.pathname}${props.location.search}`,
            },
          });
        }
      }
    } catch (e) {
      await alert('앗 무엇인가 잘못됐습니다..\n관리자에게 문의 해주세요ㅜㅜ');
    }
  };

  return (
    <div className='master-container'>
      <Helmet>
        <title>마스터 신청</title>
      </Helmet>

      {is_sticky && (
        <div className={'master-nav' + (is_sticky ? ' sticky' : '')}>
          <div className='td-wrapper'>
            <img
              onClick={() => {
                props.history.push('/');
              }}
              src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-home-icon-white.png`}
              alt='홈으로 이동'
            />
            <div className='master-btn master-apply-btn' onClick={onApplyBtnClick}>
              마스터 신청하기
            </div>
          </div>
        </div>
      )}

      <div className='master-top-banner'>
        <div className='master-top-wrapper'>
          <p className='title'>“베어유는 매일 고민합니다.”</p>
          <p className='sub-title'>마스터님을 최고로 만들 방법을</p>
          <button className='master-btn activity-btn master-apply-btn' onClick={onApplyBtnClick}>
            마스터 신청하기
          </button>
          {!is_disable && <div className='master-background' />}
        </div>
        <div ref={header_nav_control_ref} style={{ width: '100%', height: 1 }} />
      </div>

      <div className='master-apply-content'>
        <div className='master-swipe-wrapper'>
          <Swiper
            slidesPerView={'auto'}
            centeredSlides={false}
            spaceBetween={16}
            loop={true}
            autoplay={{
              delay: 1800,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              769: { centeredSlides: true },
            }}
            className='mySwiper'
          >
            {master_set.map((master, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className='master-slide-item'>
                    <img alt='master' className='master-img' src={master.img_url} />
                    <div className='master-text-wrapper'>
                      {master.desc}
                      <p className='master-info'>{master.info}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className='master-support-wrapper'>
          <div className='support-wrapper'>
            <div className='support-card-wrapper'>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-1%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>증가하는</p>
                <p className='desc'>사용자</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-2%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>활성화된</p>
                <p className='desc'>플랫폼</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-3%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>체계적인</p>
                <p className='desc'>프로세스</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-4%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>성장하는</p>
                <p className='desc'>기업</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-5%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>열려있는</p>
                <p className='desc'>소통</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-6%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>상승하는</p>
                <p className='desc'>매출</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-7%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>무궁무진한</p>
                <p className='desc'>가능성</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-8%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>번뜩이는</p>
                <p className='desc'>아이디어</p>
              </div>
              <div className='support-card'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-master-9%402x.png`}
                  alt='sup-icon'
                />
                <p className='title'>성공적인</p>
                <p className='desc'>관계</p>
              </div>
            </div>
            <div className='support-desc-wrapper'>
              <p className='support-1-text master-title-text'>마스터님을 서포트해드릴</p>
              <p className='support-2-text master-title-text'>충분한 능력이 있습니다.</p>
              <p className='support-3-text master-sub-title-text'>
                타 재능 플랫폼과는 다릅니다
                <br />
                마스터님의 브랜딩까지 함께 고민하겠습니다
              </p>
              <p className='support-4-text' onClick={scrollWith}>
                서포트 전략 바로보기
              </p>
              <p className='support-5-text'>믿고맡겨봐</p>
            </div>
          </div>
        </div>

        <div className='master-increase-wrapper'>
          <p className='increase-1-text master-title-text'>수많은 플랫폼 소용돌이 속</p>
          <p className='increase-2-text master-title-text'>베어유는 성장합니다.</p>
          <p className='increase-3-text master-sub-title-text'>
            서비스 6개월만에 이용자 수 급상승 중
          </p>
          {is_mobile ? (
            <img
              className='increase-img'
              src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-salesgraph-app.png`}
              alt='graph'
            />
          ) : (
            <img
              className='increase-img'
              src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-salesgraph%403x.png`}
              alt='graph'
            />
          )}
          <div className='graph-label'>
            <p>
              <span className='graph-label-1' />
              &nbsp;&nbsp;누적 매출액
            </p>
            <p>
              <span className='graph-label-2' />
              &nbsp;&nbsp;누적 사용자
            </p>
          </div>
        </div>

        <div className='master-with-wrapper' ref={with_ref}>
          <p className='with-1-text master-title-text'>베어유는 언제나</p>
          <p className='with-2-text master-title-text'>마스터님과 함께합니다.</p>
          <p className='with-3-text master-sub-title-text'>베어유의 서포트 전략을 확인하세요</p>
          <Swiper
            slidesPerView={'auto'}
            breakpoints={{
              481: {
                centeredSlides: false,
              },
              320: {
                centeredSlides: true,
              },
            }}
            className='with-card-wrapper'
          >
            <SwiperSlide>
              <div className='with-card'>
                <p className='with-card-title aa'>Branding</p>
                <div className='with-card-line' />
                <p className='with-card-desc'>
                  클래스 한 번 열고 끝?
                  <br />
                  마스터님을 모두의 브랜드로
                  <br />
                  만들어드립니다.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='with-card'>
                <p className='with-card-title bb'>Managing</p>
                <div className='with-card-line' />
                <p className='with-card-desc'>
                  마스터님의 재능을
                  <br />
                  공유하고, 수익으로까지
                  <br />
                  이끌어냅니다.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='with-card'>
                <p className='with-card-title cc'>Supporting</p>
                <div className='with-card-line' />
                <p className='with-card-desc'>
                  철저한 사전 기획,
                  <br />
                  제작, 편집, 검수, 진행까지
                  <br />
                  함께합니다
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='with-card'>
                <p className='with-card-title dd'>Marketing</p>
                <div className='with-card-line' />
                <p className='with-card-desc'>
                  쉽지 않은 마케팅
                  <br />
                  SNS, 클래스 홍보까지
                  <br />
                  걱정마세요.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className='master-making-wrapper'>
          <p className='making-1-text master-title-text'>베어유 강의 메이킹 과정을</p>
          <p className='making-2-text master-title-text'>빠짐없이 공개합니다.</p>
          <p className='making-3-text master-sub-title-text'>
            기획부터 판매, 홍보 마케팅까지 함께해요.
          </p>
          <div className='making-card-wrapper'>
            <div className='making-card'>
              <div className='making-img-wrapper'>
                <img
                  className='making-img aa'
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-making-1%402x.png`}
                  alt='making'
                />
              </div>
              <p className='making-line'>1</p>
              <p className='making-title'>강의계획서 작성 및 검토</p>
              <p className='making-desc'>
                강의계획서 초안을 작성해 보내주시면 정밀한 검토 후 연락을 드립니다.
              </p>
            </div>
            <div className='making-card'>
              <div className='making-img-wrapper'>
                <img
                  className='making-img bb'
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-making-2%402x.png`}
                  alt='making'
                />
              </div>
              <p className='making-line'>2</p>
              <p className='making-title'>클래스 매니저 미팅</p>
              <p className='making-desc'>
                마스터님의 소중한 재능과 함께할 수 있다면 매니저가 연락을 드립니다.
              </p>
            </div>
            <div className='making-card'>
              <div className='making-img-wrapper'>
                <img
                  className='making-img cc'
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-making-3%402x.png`}
                  alt='making'
                />
              </div>
              <p className='making-line'>3</p>
              <p className='making-title'>강의 촬영 및 편집</p>
              <p className='making-desc'>
                컨셉에 맞는 스튜디오에서 클래스 컨셉에 맞춘 강의 촬영을 진행합니다.
              </p>
            </div>
            <div className='making-card'>
              <div className='making-img-wrapper'>
                <img
                  className='making-img dd'
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/master/img-making-4%402x.png`}
                  alt='making'
                />
              </div>
              <p className='making-line'>4</p>
              <p className='making-title'>판매 및 홍보</p>
              <p className='making-desc'>
                와디즈 선 런칭, SNS 홍보 및 매출극대화, VOD까지 판매합니다.
              </p>
            </div>
          </div>
        </div>

        <div className='master-confirm-wrapper'>
          <p className='confirm-main-title master-title-text'>
            마지막으로 <span>꼭! 확인해주세요.</span>
          </p>
          <div className='confirm'>
            <p className='title'>1. 누구에게나 쉽고 친절한 강의</p>
            <p className='desc'>
              · 처음 시도하는 분들도 간단하게 따라할 수 있는 강의를 지향합니다.
              <br />
              · 마스터님의 경험을 존중하며, 노하우와 지식을 마음껏 펼치세요.
              <br />
              · 누구를 위한 강의인지, 무엇을 나누고 싶은지를 꼭 생각해 주세요.
              <br />· 한 번에 파트너가 되지 못해도 기회는 항상 있으니 걱정마세요.
            </p>
            <p className='title'>2. 대상과 컨셉의 목표가 명확한 강의</p>
            <p className='desc'>
              · 누가 들으면 좋고 효율적인 강의인지 꼭 생각해 주세요.
              <br />
              · 들었을 때 무엇을 얻을 수 있는지 생각해 주세요.
              <br />
              · 내 클래스의 특징이 무엇인지 한 줄로 소개할 수 있어야 합니다.
              <br />· 하루 10분씩 캐주얼한 강의를 지향합니다.
            </p>
            <p className='title'>3. 적극적인 소통과 확실한 준비</p>
            <p className='desc'>
              · 마스터 신청 양식은 명료하고 확실하게 보내주시면 더욱 좋습니다.
              <br />
              · 검토 후 연락을 드리게 되면 전달 드리는 파일과 일정을 꼭 확인해 주세요.
              <br />
              · 마스터 신청을 완료하셨나요? 매니저의 연락을 잠시만 기다려주세요.
              <br />· 최고의 클래스 마스터가 될 때까지 베어유가 함께하겠습니다.
            </p>
          </div>
          <div ref={disable_ref} style={{ width: '100%', height: 1 }} />
          <button className='master-btn activity-btn master-apply-btn' onClick={onApplyBtnClick}>
            마스터 신청하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Master;
