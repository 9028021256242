import axios from 'axios';

class CommonModel {
  async staticCode({ code_name }) {
    const {
      data: {
        code,
        data: { code_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/code`, {
      params: { code_name },
    });
    return { code, code_set };
  }

  async serviceInfo() {
    const {
      data: {
        data: { service_info },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/service`);
    return { service_info };
  }

  async bannerList({ page, size, banner_type_id }) {
    const {
      data: {
        code,
        data: { banner_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/banner`, {
      params: { page, size, banner_type_id },
    });
    return { code, banner_set };
  }

  async noticeList({ page, size, notice_category_id, status_type }) {
    const {
      data: {
        code,
        data: { notice_set, total_count },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/notice`, {
      params: { page, size, notice_category_id, status_type },
    });
    return { code, notice_set, total_count };
  }

  async noticeDetail({ notice_id }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/notice/${notice_id}`);
    let notice = null;
    let next_notice = null;
    if (code === 200) {
      notice = data['notice'];
      next_notice = data['next_notice'];
    }
    return { code, notice, next_notice };
  }

  async previewYoutube() {
    const {
      data: {
        code,
        data: { preview_youtube },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/preview/youtube`);
    return { code, preview_youtube };
  }

  async faqList() {
    const {
      data: {
        code,
        data: { faq_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/faq`);
    return { code, faq_set };
  }

  async tagList({ page, size }) {
    const {
      data: {
        code,
        data: { tag_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/tag`, {
      params: { page, size },
    });
    return { code, tag_set };
  }

  async curationList({ search_text }) {
    const {
      data: {
        code,
        data: { curation_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/curation`, {
      params: { search_text },
    });
    return { code, curation_set };
  }

  async curationDetail({ id }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/curation/${id}`);
    return {
      code,
      curation: code === 200 ? data.curation : null,
    };
  }

  async couponPublish({ coupon_code }) {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/common/coupon/publish`, {
      coupon_code,
    });

    return { code };
  }

  async superZ() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/superz`);
    return { code, data };
  }

  async hardStudy() {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/hardstudy/new-latest`);
    return { code, data };
  }

  async typeform({ survey_id }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/typeform`, {
      params: { survey_id },
    });
    return { code, data };
  }

  async version(platform) {
    const {
      data: { code, data },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/common/version?platform=${platform}`,
    );
    return { version: code === 200 ? data?.version : null };
  }

  /*@TODO 추후 트리거 범용적 사용 고려하여 네이밍 변경 가능성 */
  async popUpBannerList(url) {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/common/pop-up-banner`, {
      params: {
        referer_url: url,
      },
    });
    const { code, data } = res.data;
    const banner_set = data?.banner_set || [];
    return { code, banner_set };
  }

  async postAnalytics({ action, value, refer }) {
    if (!refer) {
      refer = window.location.pathname + window.location.search;
    }
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/common/analytics`, {
      action,
      value,
      refer,
    });
    return code === 200 ? data : null;
  }
}

export default new CommonModel();
