import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {set_account_favorite_count} from "../../../redux/account/action";
import ProductModel from "../../../model/ProductModel";
import {replace_cloudfront_url, set_favorite_icon} from "../../../common";
import { useDialogContext } from "../dialog/DialogContextProvider";

const PackageCard = (props) => {
    const product = props.product;
    const dispatch = useDispatch();
    const AccountStore = useSelector(state => state.AccountStore);
    const price = useRef(0);
    const _favorite_pending = useRef(false);
    const { confirm } = useDialogContext();

    const onFavorite = async() => {
        if (AccountStore) {
            if (!_favorite_pending.current) {
                _favorite_pending.current = true;
                ProductModel.packageFavorite({
                    package_id: product.id
                }).then(({
                             is_favorite,
                             favorite_count,
                             account_favorite_count
                         }) => {
                    dispatch(set_account_favorite_count(account_favorite_count));
                    set_favorite_icon("package", product.id, is_favorite, favorite_count);
                    _favorite_pending.current = false;
                });
            }
        } else {
            if (await confirm("로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?")) {
                props.history.push({
                    pathname: "/user/signin",
                    state: {
                        step: 1,
                        redirect_url: `${props.location.pathname}${props.location.search}`
                    }
                });
            }
        }
    };
    if (product.price) {
        price.current = Math.round(100 - ((product.price / product.original_price) * 100));
    }

    return (
        <div className={`gtm-data gtm-click list-item package-id-${product.id}`}
             data-ai-pid={`p${product.id.toString().padStart(8, "0")}`}
             data-ai-pnm={product.title.replace(/\r\n/g, " ")}
             data-ai-ppc={product.price}
             data-ai-ppb=""
             data-ai-pca=""
        >
            <div className="thumbnail-wrap">
                <img
                    className={`td-favorite ${product.is_favorite ? "" : " enp_mobon_wish"}`}
                    src={
                        product.is_favorite ?
                            `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-select-2.svg`
                            :
                            `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-default-2.svg`
                    }
                    alt="클래스 저장하기"
                    onClick={onFavorite}
                />
                <div className="badge-wrap package-badge">
                    <img src={`${process.env.REACT_APP_IMG_URL}/static/png/common/badge-package.png`} alt="패키지"/>
                </div>
                <Link to={`/package/${product.id}`} className={"gtm-click"}>
                    <div
                        className="course-image"
                        style={{background: `url("${replace_cloudfront_url(product.main_thumbnail_image_url)}") no-repeat center center/cover`}}
                    />
                </Link>
            </div>
            <div className="course-desc-wrap">
                <Link to={`/package/${product.id}`} className={"gtm-click"}>
                    <h4>[{product.short_title}] {product.title}</h4>
                </Link>
                <div className="bottom-wrap">
                    <p>
                        <b>패키지 할인 {price.current}%</b>
                    </p>
                    <div className="favorites-wrap">
                        <img src={`${process.env.REACT_APP_IMG_URL}/static/png/common/icon-favorites-2.png`}
                             alt="클래스 저장"/>
                        <span>{product.favorite_count}</span>
                    </div>
                </div>
            </div>
            {props.is_add_button ?
                <Link className="link-button" to={`/package/${product.id}`}>패키지 보러가기</Link>
                :
                null
            }
        </div>
    );
};

export default withRouter(PackageCard);

